import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ListProductsFail = ({ open, handleClose }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="product-listing-failure-dialog-title"
        aria-describedby="product-listing-failure-dialog-description"
      >
        <DialogTitle id="product-listing-failure-dialog-title">
          {'Error!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="product-listing-failure-dialog-description">
            Product listing was unsuccessful
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Try again
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ListProductsFail.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default ListProductsFail;
