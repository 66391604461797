import {
  GET_FOLLOWING_START,
  GET_FOLLOWING_FAIL,
  GET_FOLLOWING_SUCCESS,
  FETCH_USER_LIKED_PRODUCTS_START,
  FETCH_USER_LIKED_PRODUCTS_SUCESS,
  FETCH_USER_LIKED_PRODUCTS_FAIL,
} from './socialsTypes';

const initialState = {
  following: null,
  followingErr: null,
  followingLoading: false,

  followers: null,

  likedProductsLoading: false,
  likedProducts: null,
  likedProductsErr: null,
};

const socialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FOLLOWING_START: {
      return {
        ...state,
        followingLoading: true,
      };
    }
    case GET_FOLLOWING_SUCCESS: {
      return {
        ...state,
        followingLoading: false,
        following: action.payload.following,
      };
    }
    case GET_FOLLOWING_FAIL: {
      return {
        ...state,
        followingLoading: false,
        followingErr: action.payload,
      };
    }
    case FETCH_USER_LIKED_PRODUCTS_START: {
      return {
        ...state,
        likedProductsLoading: true,
      };
    }
    case FETCH_USER_LIKED_PRODUCTS_SUCESS: {
      return {
        ...state,
        likedProductsLoading: false,
        likedProducts: action.payload.products,
      };
    }
    case FETCH_USER_LIKED_PRODUCTS_FAIL: {
      return {
        ...state,
        likedProductsLoading: false,
        likedProductsErr: action.payload,
      };
    }

    default:
      return state;
  }
};

export default socialsReducer;
