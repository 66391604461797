import { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';

const useStyles = makeStyles({
  btn: {
    textTransform: 'capitalize',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    color: '#111111',
    fontSize: '1rem',
    fontWeight: 600,
  },
  btnClicked: {
    color: '#d81e5b',
    textTransform: 'capitalize',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '1rem',
    fontWeight: 600,
  },
});

export default function BaseMenu({ menuTitle, children }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isClicked, setisClicked] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setisClicked(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setisClicked(false);
  };
  const classes = useStyles();

  return (
    <div className={classes.menu}>
      <Button
        aria-controls="main-navigation-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="text"
        className={isClicked ? classes.btnClicked : classes.btn}
      >
        {menuTitle}
      </Button>
      <Menu
        id="main-navigation-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            width: '100%',
          },
        }}
      >
        {children}
      </Menu>
    </div>
  );
}

BaseMenu.propTypes = {
  menuTitle: PropTypes.string.isRequired,
  children: PropTypes.node,
};
