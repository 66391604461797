/* eslint-disable react/prop-types */
import AppHeader from '../../parts/Shared/AppHeader';
import Footer from '../../parts/Shared/Footer';
import MainNavigation from '../../parts/Home/MainNavigation';
import StickyBanner from '../StickyBanner';

const HomeLayout = ({ children }) => {
  return (
    < >
      <AppHeader />
      <main style={{ overflow: 'hidden' }}>
        <MainNavigation />

        <div>{children}</div>

      </main>

  
      <Footer />
      <StickyBanner />

      
    </>
  );
};

export default HomeLayout;
