import {
  CHECK_LOGGED_IN,
  SIGN_UP_SUCCESS,
  LOGIN_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_OUT,
  VERIFY_PHONE_START,
  VERIFY_PHONE_SUCCESS,
  VERIFY_PHONE_FAIL,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_REQUEST_FAIL,
  PASSWORD_RESET_REQUEST_SUCCESS,
  LOGIN_FAIL,
  VERIFY_PASSWORD_RESET_CODE_FAIL,
  VERIFY_PASSWORD_RESET_CODE_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  GET_USER_DETAILS_START,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  SIGN_UP_START,
  CHECK_WAITLIST_FAIL,
  CHECK_WAITLIST_SUCCESS,
  CHECK_WAITLIST_START,
  ADD_WAITLIST_FAIL,
  ADD_WAITLIST_SUCCESS,
  RESEND_VER_OTP_START,
  RESEND_VER_OTP_SUCCESS,
  RESEND_VER_OTP_FAIL,
  LOGIN_START,
} from './authTypes';

import { LoginFailurePixel, LoginInitiatedPixel,LoginSuccessPixel, SignUpFailurePixel, SignUpInitiatedPixel } from '../../_helpers/facebookpixel/PixelEvents';

import { log_firebase_event } from '../../common/FirebaseCommon';
import { LOGIN_FIREBASE } from '../../common/firebaseConstants';
import { act } from 'react-dom/test-utils';
const makeObj = (str) => JSON.parse(str);

const initialState = {
  loading: false,
  isLoggedIn: false,
  token: localStorage.getItem('_REG_TK'),
  profile: JSON.parse(localStorage.getItem('_PRF')),
  username: null,

  regLoading: false,
  regErr: null,
  regErrorFound:false,
  regErrormessage:"",
  loginErr: null,
  loginErroFound:false,
  loginErrorMessage:"",
  loginLoading: false,

  phoneVerLoading: false,
  phoneVerSuccess: null,
  phoneVerErr: null,

  resendVerLoading: false,
  resendVerSuccess: null,
  resendVerFail: null,

  // For reset pwd
  pwdResetRequestLoading: null,
  passwordResetPhonenumber: sessionStorage.getItem('req_id'),
  pwdResetRequestError: null,
  pwdResetCode: null,
  pwdResetCodeErr: null,

  isResetCodeValid: null,
  isPasswordReset: null,
  passwordResetError: null,
  userDetails: null,
  fecthUserErr: null,

  // waitlist
  isOnWaitlist: null,
  isOnWaitlistLoading: false,
  hasJoinedWaitlist: null,
  hasJoinedWaitlistErr: null,
  waitlistProfile: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_START: {
      SignUpInitiatedPixel();
      return {
        ...state,
        regLoading: true,
      };
    }
    case LOGIN_START: {
      LoginInitiatedPixel();
      return {
        ...state,
        loginLoading: true,
      };
    }
    case CHECK_LOGGED_IN:
    case SIGN_UP_SUCCESS:
    case LOGIN_SUCCESS: {
      // log_firebase_event(
      //   LOGIN_FIREBASE,
      //   {
      //     profile: action.payload.profile,
      //     token: action.payload.token,
      //     username: action.payload.username
      //   }

      // )
      console.log("login success")
      LoginSuccessPixel();
      return {
        ...state,
        regLoading: false,
        isLoggedIn: true,
        loginLoading: false,
        profile: action.payload.profile,
        token: action.payload.token,
        username: action.payload.username,
      };
    }
    // case SIGN_UP_SUCCESS: {
    //   return {
    //     ...state,
    //     loading: false,
    //     isLoggedIn: true,
    //     token: action.payload.token,
    //     profile: action.payload.profile,
    //   };
    // }
    case SIGN_UP_FAILURE:
      SignUpFailurePixel();
      return {
        ...state,
        regErr: action.payload.error,
        regErrormessage:action.payload.message,
        regErrorFound:action.payload.errorFound,
        
        regLoading: false,
      };
    // case LOGIN_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoggedIn: true,
    //     loading: false,
    //     profile: action.payload.profile,
    //     token: action.payload.token,
    //   };
    // }
    case LOGIN_FAIL:
      LoginFailurePixel()
      return {
        ...state,
        loginLoading: false,
        loginErr: action.payload.error,
        isLoggedIn: false,
        loginErroFound:true,
        loginErrorMessage:action.payload.message
      };
    case VERIFY_PHONE_START: {
      return {
        ...state,
        phoneVerLoading: true,
      };
    }
    case VERIFY_PHONE_SUCCESS: {
      return {
        ...state,
        phoneVerLoading: false,
        phoneVerSuccess: true,
      };
    }
    case VERIFY_PHONE_FAIL: {
      return {
        ...state,
        phoneVerLoading: false,
        phoneVerErr: action.payload,
      };
    }
    case SIGN_OUT: {
      localStorage.clear();

      return {
        ...state,
        profile: null,
        token: null,
        isLoggedIn: false,
        username: null,
      };
    }
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        pwdResetRequestLoading: true,
      };
    case PASSWORD_RESET_REQUEST_SUCCESS:
      return {
        ...state,
        pwdResetRequestLoading: false,
        pwdResetCode: action.payload.code,
        passwordResetPhonenumber: action.payload.phone,
      };
    case PASSWORD_RESET_REQUEST_FAIL:
      return {
        ...state,
        pwdResetRequestLoading: false,
        pwdResetRequestError: action.payload,
      };
    case VERIFY_PASSWORD_RESET_CODE_SUCCESS:
      return {
        ...state,
        isResetCodeValid: true,
        pwdResetCodeErr: action.payload,
      };
    case VERIFY_PASSWORD_RESET_CODE_FAIL:
      return {
        ...state,
        isResetCodeValid: false,
        pwdResetCodeErr: action.payload,
      };
    case RESET_PASSWORD_SUCCESS:
      sessionStorage.removeItem('req_id');
      return {
        ...state,
        isPasswordReset: true,
      };
    case RESET_PASSWORD_FAIL:
      sessionStorage.removeItem('req_id');
      return {
        ...state,
        isPasswordReset: false,
        passwordResetError: action.payload,
      };

    case GET_USER_DETAILS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };

    case GET_USER_DETAILS_FAIL:
      return {
        ...state,
        fecthUserErr: action.payload,
        loading: false,
      };

    case CHECK_WAITLIST_START:
      return {
        ...state,
        isOnWaitlistLoading: true,
      };

    case CHECK_WAITLIST_SUCCESS:
      return {
        ...state,
        isOnWaitlistLoading: false,
        isOnWaitlist: 'true',
        waitlistProfile: action.payload,
      };
    case CHECK_WAITLIST_FAIL:
      return {
        ...state,
        isOnWaitlistLoading: false,
        isOnWaitlist: 'false',
      };
    case ADD_WAITLIST_SUCCESS:
      return {
        ...state,
        hasJoinedWaitlist: true,
      };
    case ADD_WAITLIST_FAIL:
      return {
        ...state,
        hasJoinedWaitlist: false,
        hasJoinedWaitlistErr: action.payload,
      };

    case RESEND_VER_OTP_START:
      return {
        ...state,
        resendVerLoading: true,
      };
    case RESEND_VER_OTP_SUCCESS:
      return {
        ...state,
        resendVerLoading: false,
        resendVerSuccess: true,
      };
    case RESEND_VER_OTP_FAIL:
      return {
        ...state,
        resendVerLoading: false,
        resendVerFail: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
