import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { FiPackage } from 'react-icons/fi';

import BaseBtnFilled from '../../../components/BaseBtnFilled';
const BuyerNotifyShipping = ({ timestamp, orderID }) => {
  return (
    <Box display="flex">
      <Avatar style={{ background: '#45BC56', marginRight: '8px' }}>
        <FiPackage style={{ color: '#ffffff' }} />
      </Avatar>
      <div className="notification-box">
        <p className="txt-time f-10">{timestamp}</p>
        <p className="txt-bold txt-blackTrue">Your item has been shipped</p>
        <p className="txt-gray5 f-14">
          Hold on tight, your item is on the way!
        </p>
        {/* <Box mt={2}>
          <BaseBtnFilled fullWidth>Track Order</BaseBtnFilled>
        </Box> */}
      </div>
    </Box>
  );
};

BuyerNotifyShipping.propTypes = {
  orderID: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default BuyerNotifyShipping;
