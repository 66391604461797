import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const BaseCard = withStyles({
  root: {
    padding: '16px 16px',
    borderRadius: '16px',
    width: '375px',
  },
})(Card);

export default BaseCard;
