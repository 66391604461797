import { useState, useEffect } from 'react';
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import ResultsLayout from './ResultsLayout';
import WelcomeBanner from '../../parts/Home/WelcomeBanner';
import ListingCard from '../../parts/Products/ListingCard';
import CategoryPageNav from '../../parts/Search/CategoryPageNav';
import SameSellerPageNav from '../../parts/Search/SameSellerPageNav';
import NoResults from '../../parts/Search/NoResults';
import {
  fetchLevel2Categories,
  fetchSellerProductsResults,
} from '../../redux/search/searchActions';
import {
  fetchProductConditions,
  fetchProductColorsOptions,
} from '../../redux/products/productsActions';

import promo1 from '../../assets/images/black-hat-model.jpg';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const mapState = ({ search, product }) => ({
  allCategories: search.allCategories,
  displayCategories: search.l2CategoriesList,
  productConditions: product.productConditions,
  productColors: product.productColors,
  seller: product.productStoreId,

  results: search.sameSellerProductsResults,
  resultsCount: search.sameSellerProductsResultsCount,
  resultsError: search.sameSellerProductsResultsError,
  loading: search.loadingSameSellerProductsResults,
});

const SameSellerPageResults = () => {
  // const classes = useStyles();
  //   const history = useHistory();
  const { category } = useParams();
  const { pathname, search } = useLocation();
  //   const { url } = useRouteMatch();
  let query = useQuery();

  const dispatch = useDispatch();
  const {
    allCategories,
    displayCategories,
    productColors,
    productConditions,
    loading,
    results,
    resultsCount,
    resultsError,
    seller,
  } = useSelector(mapState);

  useEffect(() => {
    dispatch(fetchSellerProductsResults(seller));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchLevel2Categories(category));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductConditions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductColorsOptions());
  }, [dispatch]);

  return (
    <section>
      <div className="bg-home">
        <WelcomeBanner
          bannerBackgroundColor="#FFF5F6"
          themeColor="#D81E5B"
          promoImg={promo1}
          promoTitle="WELCOME TO LUKHU"
        />

        <ResultsLayout
          menu={
            <SameSellerPageNav
              allCategories={allCategories}
              productColors={productColors}
              productConditions={productConditions}
            />
          }
          content={
            <>
              {loading && <NoResults msg="Loading ..." />}
              {!loading && resultsError && <NoResults msg="Error!" />}
              {!loading && results && resultsCount < 1 && (
                <NoResults msg="No listings" />
              )}
              {results &&
                results.map((item) => (
                  <Grid item xs={6} sm={4} md={3} key={item.id}>
                    <ListingCard product={item} />
                  </Grid>
                ))}
            </>
          }
        />
      </div>
    </section>
  );
};

export default SameSellerPageResults;
