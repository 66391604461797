import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Favorite from '@material-ui/icons/Favorite';
import SecurityIcon from '@material-ui/icons/Security';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';

import AboutSeller from '../../parts/Products/AboutSeller';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import BaseBtnFilled from '../../components/BaseBtnFilled';

import SelectDisplayOptions from '../../parts/Home/SelectDisplayOptions';
import MerchantItemsDisplay from '../../parts/Home/MerchantItemsDisplay';
import ProductDetails from '../../parts/Products/ProductDetails';
import BaseErrorDisplay from '../../components/BaseErrorDisplay';
import BaseLoadingDisplay from '../../components/BaseLoadingDisplay';
import Tooltip from '@material-ui/core/Tooltip';
import{ CART_NEW_ITEM }from '../../redux/cart/cartTypes'
import { url } from '../../api';
import useScript from '../../hooks/useScript';
import MetaTags from 'react-meta-tags';


import {
  fetchSingleProduct,
  changeDisplayImage,
} from '../../redux/products/productsActions';
import {
  likeProduct,
  fetchUserLikedProducts,
} from '../../redux/socials/socialsActions';
import { addCartItem, addNewCart } from '../../redux/cart/cartActions';
import AskSellerDialog from '../../parts/Messaging/AskSellerDialog';
import OrderFeedbackDialog from '../../parts/Messaging/OrderFeedbackDialog';
import { fetchFollowedMerchants } from '../../redux/socials/socialsActions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: 'none',
    width: '90%',
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '95%',
    },
  },
  content: {
    padding: '0px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  flexSpace: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  prodImg: {
    width: 450,
    objectFit: 'cover',
    maxHeight: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  imgPreview: {
    paddingRight: '8px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
  },
  prodTitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },

  section1: {
    marginBottom: '16px',
  },
  section2: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '16px',
  },

  section3: {
    marginBottom: '16px',
  },

  section4: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },

  section5: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    marginBottom: '16px',
  },
  btn: {
    width: '136px',
    padding: '2px',
  },
  disabledBtn: {
    width: '136px',
    padding: '2px',
    backgroundColor: '#E2E2E2',
    border: 'none',
    color: '#717171',
    '&:hover': {
      backgroundColor: '#E2E2E2',
    },
  },

  section6: {
    width: '100%',
    backgroundColor: 'rgb(96,29,255, 0.1)',
    borderRadius: '4px',
    color: '#481CFB',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: '16px',
  },
  imgcard: {
    height: '64px',
    width: '64px',
    marginBottom: '8px',
  },
  media: {
    height: '64px',
  },
  likesBtn: {
    padding: '8px',
  },
}));

const mapState = ({ product, auth, cart, social }) => ({
  productDetails: product.productDetails,
  storeId: product.storeId,
  category: product.category,
  loading: product.loading,
  fetchError: product.fetchError,
  sellerProducts: product.sellerProducts,
  similarProducts: product.similarProducts,
  displayImage: product.displayImage,
  productStoreDetails: product.productStoreDetails,
  authToken: auth.token,
  profile: auth.profile,
  shoppingBagItems: cart.shoppingBagItems,
  following: social.following,
  likedProducts: social.likedProducts,
  productCategory: product.productCategory,
});

const SingleProductView = () => {
  const classes = useStyles();
  localStorage.setItem("pageName",`Product details`)
  useScript("../assets/pixel/home.js");



  // Open ask seller
  const [openAskSeller, setOpenAskSeller] = useState(false);

  const handleClickOpenAskSeller = () => {
    setOpenAskSeller(true);
  };
  const handleCloseAskSeller = () => {
    setOpenAskSeller(false);
  };

  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    productDetails,
    loading,
    fetchError,
    sellerProducts,
    similarProducts,
    displayImage,
    productStoreDetails,
    authToken,
    shoppingBagItems,
    following,
    profile,
    likedProducts,
    productCategory,
  } = useSelector(mapState);


 
  useEffect(() => {
   
    dispatch(fetchSingleProduct(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (authToken) {
      dispatch(fetchFollowedMerchants());
    }
    return () => {};
  }, []);
  useEffect(() => {
    if (authToken) {
      dispatch(fetchUserLikedProducts());
    }
    return () => {};
  }, [dispatch]);

  const getButtonAction = () => {
    if (following) {
      const currentMerchant = following.find((item) => item.uuid === id);
      return currentMerchant;
    }
  };
  const [isAddedToBag, setisAddedToBag] = useState(false);
  const [isLiked, setisLiked] = useState(false);

  const checkIsAddedToBag = () => {
    console.log("the studd",shoppingBagItems)
    if (shoppingBagItems && shoppingBagItems.length !== 0) {
      const currentProduct = shoppingBagItems.find((item) => item.id === id);
      if (currentProduct) {
        setisAddedToBag(true);
      } else {
        setisAddedToBag(false);
      }
    }
  };

  useEffect(() => {
    checkIsAddedToBag();
    return () => {};
  }, [isAddedToBag]);

  const handleChangeSelectedImage = (imageUrl) => {
    if (productDetails.images.length === 0) {
      return '';
    } else {
      const newSelected = productDetails.images.find(
        (img) => img.secure_url === imageUrl
      );
      dispatch(changeDisplayImage(newSelected.secure_url));
    }
  };

  const handleBuyNow = () => {
    if (authToken) {
      // ADD TO CART
     
      const body = { productId: id,loggedIn:true };
     
      dispatch(addCartItem(body));
      setisAddedToBag(true);
    } else {
      const body = { productId: id,loggedIn:false };
     
      dispatch(addCartItem(body));
      // take to login screen
      // history.push('/auth');
    }
  };

  const handleLikeProduct = () => {
    if (!profile || !authToken) {
      history.push('/auth');
      return;
    }
    dispatch(likeProduct(id));
    setisLiked(!isLiked);
  };

  const checkIsLiked = () => {
    if (likedProducts && likedProducts.length > 0) {
      const currProduct = likedProducts.find((item) => item.id === id);
      // console.log('CURRENT', currProduct);
      if (currProduct) {
        setisLiked(true);
      }
    }
  };

  useEffect(() => {
    checkIsLiked();
    return () => {};
  }, [isLiked]);




  const getToolTipTitle = () => {
    if (!profile || !authToken) {
      return 'Login to Like product';
    } else {
      return 'Like product';
    }
  };
  if (loading) {
    return <BaseLoadingDisplay />;
  }

  if (fetchError) {
    return (
      <BaseErrorDisplay
        message=" Oops! There was an error while loading this product. We&#39;re working
      on it."
      />
    );
  }

   console.log("productmmmmm",productDetails)
  return (
    <section style={{ backgroundColor: '#f5f5f5' }}>
      <MetaTags>
  
      <div itemscope itemtype="http://schema.org/Product">
          <meta itemprop="brand" content={`${productDetails !== null ? productDetails.storeName : ""}`}></meta>
          <meta itemprop="name" content={`${productDetails !== null ? productDetails.name : ""}`}></meta>
          <meta itemprop="description" content={`${productDetails !== null ? productDetails.description : ""}`}></meta>
          <meta itemprop="productID" content={`${productDetails !== null ? productDetails.id : ""}`}></meta>
          <meta itemprop="url" content={`https://lukhu.co.ke/catalog/${productDetails !== null ? productDetails.id : ""}`}></meta>
          <meta itemprop="image" content={displayImage}></meta>
            <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
              <link itemprop="availability" href="http://schema.org/InStock"></link>
              <link itemprop="itemCondition" href="http://schema.org/NewCondition"></link>
              <meta itemprop="price" content={`${productDetails !== null ? productDetails.price : ""}`}></meta>
              <meta itemprop="priceCurrency" content="KES"></meta>
            </div>
      </div>
      </MetaTags>
        <MetaTags>
       
{/* checking */}
<meta property="product:category" content={`${productDetails !== null ? productDetails.googleProductCategoryId : ""}`}></meta>
<meta property="og:title" content={`${productDetails !== null ? productDetails.category : ""}`}></meta>
<meta property="og:description" content={`${productDetails !== null ? productDetails.description : ""}`}></meta>
<meta property="og:url" content={`https://lukhu.co.ke/catalog/${productDetails !== null ? productDetails.id : ""}`}></meta>
<meta property="og:image" content={displayImage}></meta>
<meta property="product:brand" content={`${productDetails !== null ? productDetails.storeName : ""}`}></meta>
<meta property="product:availability" content="in stock"></meta>
<meta property="product:condition" content={`${productDetails !== null ? productDetails.condition.includes("new") ? "new" : "used" : ""}`}></meta>
<meta property="product:price:amount" content={`${productDetails !== null ? productDetails.price : ""}`}></meta>
<meta property="product:price:currency" content="KES"></meta>
<meta property="product:retailer_item_id" content={`${productDetails !== null ? productDetails.id : ""}`}></meta>
        </MetaTags>

      {productDetails && (
        <div className="bg-white pt-8">
          <Container maxWidth="lg">
            <Card className={classes.root}>
              <div className={classes.imgPreview}>
                {productDetails.images.length > 0 &&
                  productDetails.images.map((img) => (
                    <Card
                      className={classes.imgcard}
                      key={img.url}
                      onClick={() => handleChangeSelectedImage(img.secure_url)}
                    >
                      <CardActionArea>
                        <CardMedia
                          className={classes.media}
                          image={img.secure_url}
                          title="Product Image"
                        />
                      </CardActionArea>
                    </Card>
                  ))}
              </div>

              <CardMedia
                component="img"
                className={classes.prodImg}
                image={displayImage}
                title="Product Image"
              />

              <CardContent className={classes.content}>
                <div className={classes.section1}>
                  <h3 className="txt-semi-bold f-20">{productDetails.name}</h3>
                </div>

                <div className={classes.section2}>
                  <p className="f-14">
                    <span className="txt-bold  txt-capitalize">Size</span> :{' '}
                    {productDetails.size}
                  </p>
                  <p className="f-14">
                    <span className="txt-bold  txt-capitalize">Condition</span>{' '}
                    :{' '}
                    <span className="txt-capitalize">
                      {productDetails.condition}
                    </span>
                  </p>
                </div>
                <div className={classes.section3}>
                  <p>{productDetails.description}</p>
                </div>

                <div className={classes.section4}>
                  <Box p={1} style={{ backgroundColor: '#F5F6F7' }}>
                    <p className="txt-ruby">
                      Price:{' '}
                      <span className="txt-bold">
                        KSh {productDetails.price}
                      </span>
                    </p>
                  </Box>
                  <Box className="flex-center">
                    <span
                      style={{
                        fontFamily: "'Montserrat', 'sans-serif'",
                        fontSize: '0.875rem',
                      }}
                    >
                      {productDetails.likes}
                    </span>
                    <Tooltip title={getToolTipTitle()}>
                      <IconButton
                        className={classes.likesBtn}
                        onClick={handleLikeProduct}
                        disabled={isLiked ? true : false}
                      >
                        {isLiked ? (
                          <Favorite
                            style={{
                              color: 'red',
                              fontSize: '1.125rem',
                            }}
                          />
                        ) : (
                          <FavoriteBorderIcon
                            style={{
                              color: 'black',

                              fontSize: '1.125rem',
                            }}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </div>

                <Divider />

                <div className={classes.section5}>
                  {isAddedToBag ? (
                    <BaseBtnFilled className={classes.disabledBtn} disabled>
                      added to bag
                    </BaseBtnFilled>
                  ) : (
                    <BaseBtnFilled
                      className={classes.btn}
                      onClick={handleBuyNow}
                    >
                      buy now
                    </BaseBtnFilled>
                  )}

                  <BaseBtnOutlined
                    className={classes.btn}
                    onClick={handleClickOpenAskSeller}
                  >
                    ask seller
                  </BaseBtnOutlined>
                </div>
                {/* TO ADD POST MVP */}
                {/* <div className={classes.section6}>
                <div className="d-flex" style={{ alignItems: 'center' }}>
                  <SecurityIcon
                    style={{ fontSize: '1.125rem', marginRight: '8px' }}
                  />
                  <p className="f-14">
                    All in-app purchases are covered by Lukhu Buyer Protection
                  </p>
                </div>

                <IconButton
                  aria-label="read the Lukhu buyer protection policy"
                  color="inherit"
                >
                  <ArrowForwardIcon style={{ fontSize: '1.125rem' }} />
                </IconButton>
              </div> */}
                {productStoreDetails && (
                  <AboutSeller
                    storeId={productStoreDetails.id}
                    storeName={productStoreDetails.businessName}
                    storeLocation={productStoreDetails.location}
                    storeReviews={productStoreDetails.numberOfRatings}
                    storeRating={productStoreDetails.rating}
                    storeAvatar={productStoreDetails.image}
                  />
                )}
              </CardContent>
            </Card>
          </Container>
        </div>
      )}

      <div className="mb-24"></div>

      {/* More from this seller */}
      {sellerProducts && (
        <MerchantItemsDisplay
          loading={loading}
          headingColor="#111111"
          title="MORE FROM THIS SELLER"
          productArr={sellerProducts}
          linkTo={`/store/${productStoreDetails?.businessName}`}
       
          emptyDisplayMessage="No more products from this seller"
        />
      )}

      <div className="mb-24"></div>
      <div className="bg-white " style={{ height: '16px' }} />
      <div className="mb-24"></div>

      {/* similar items */}
      {similarProducts && (
        <SelectDisplayOptions
          title="SIMILAR ITEMS"
          productArr={similarProducts}
          emptyMessage="No similar items to show"
          link={`/similar-items/${productCategory}`}
          linkName="see all"
        />
      )}
      <div style={{ marginBottom: '48px' }}></div>

      {productStoreDetails && (
        <AskSellerDialog
          open={openAskSeller}
          handleClose={handleCloseAskSeller}
          sellerID={productStoreDetails.id}
        />
      )}
    </section>
  );
};

export default SingleProductView;
