import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import { sampleDraftsIComplete } from '../Home/dummyData';

const checKIncompleteDraft = (product) => {
  // get missing product attributes
  let missingDetails = [];
  let missingDetailsTranform = [];
  Object.entries(product).forEach(([key, value]) => {
    if (!value) {
      missingDetails.push(key);
    }
  });
  // console.log('Missing before:', miss  ingDetails);

  // replace missing details keys with UI names
  missingDetails.forEach((detail) => {
    if (detail === 'productImg') {
      missingDetailsTranform.push('image');
    }
    if (detail === 'productName') {
      missingDetailsTranform.push('name');
    }
    if (detail === 'productSize') {
      missingDetailsTranform.push('size');
    }
    if (detail === 'productCondtion') {
      missingDetailsTranform.push('condition');
    }
    if (detail === 'productPrice') {
      missingDetailsTranform.push('price');
    }
    if (detail === 'productLocation') {
      missingDetailsTranform.push('location');
    }
  });
  // console.log('Missing tranform:', missingDetailsTranform);
  return missingDetailsTranform;
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: 'transparent',
    boxShadow: 'none',
    width: '314px',
  },

  content: {
    flex: '1 0 auto',
    padding: '0 8px',
  },
  cover: {
    width: 101,
  },

  font1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.75rem',
  },
  font2: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '0.75rem',
    color: '#707070',
  },
  font3: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontWeight: 700,
    fontSize: '0.875rem',
  },
  flexLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    width: '150px',
  },
}));
const DraftsIncomplete = () => {
  const classes = useStyles();

  return (
    <div>
      {sampleDraftsIComplete.map((item) => (
        <div key={item.id} className={classes.flexLayout}>
          <div style={{ margin: '4px' }}>
            <Card className={classes.root}>
              <CardMedia
                className={classes.cover}
                image={item.productImg}
                title="product"
              />
              <CardContent className={classes.content}>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.font1}
                >
                  {item.productName}
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.font2}
                >
                  <span style={{ fontWeight: 700 }}>Condition: </span>
                  {item.productCondtion}
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.font2}
                >
                  <span style={{ fontWeight: 700 }}>Size: </span>
                  {item.productSize}
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.font3}
                >
                  Ksh {item.productPrice}
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div>
            <p className="f-14" style={{ color: '#BE1520' }}>
              Please add: {checKIncompleteDraft(item).join(', ')}
            </p>
            <BaseBtnOutlined className={classes.btn} size="small">
              Add Details
            </BaseBtnOutlined>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DraftsIncomplete;
