import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';

import BetaLayout from './BetaLayout';
import CustomCard from './CustomCard';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import BaseInput2 from '../../components/BaseInput2';
import CustomInput from './CustomInput';
import CustomContainer from './CustomContainer';
import { checkWaitlist } from '../../redux/auth/authActions';
import { formatPhoneNumberNew } from '../../_helpers/format-phone';
import flagIcon from '../../assets/images/flag_icon_2.jpg';
import GoBackLink from './GoBackLink';
import LoginLink from './LoginLink';

const validationSchema = yup.object({
  phoneNumber: yup
    .string()
    .required('Please enter phone number')
    .matches(
      /^((01)|(07)|(7)|(1))[0-9]+$/,
      'Invalid format! Please try : 07XX...'
    )
    .min(9, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    boxShadow: '0px 6px 13px #4242421A',
    maxWidth: '450px',
  },
  content: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 32px 32px 32px',
  },

  btn: {
    width: '360px',
    textTransform: 'inherit',
    fontSize: '0.875rem',
  },
  errorClass: {
    border: '1px solid red',
    borderRadius: '2px',
  },
}));

const mapState = ({ auth }) => ({
  isOnWaitlist: auth.isOnWaitlist,
});

const BetaWelcome = () => {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();
  const { isOnWaitlist } = useSelector(mapState);
  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const phone = formatPhoneNumberNew(values.phoneNumber);
      // console.log(phone);
      dispatch(checkWaitlist(phone));
      formik.resetForm();
    },
  });
  if (isOnWaitlist == 'true') {
    return <Redirect to="/beta-confirm-access" />;
  }
  if (isOnWaitlist == 'false') {
    return <Redirect to="/beta-save-seat" />;
  }
  return (
    <BetaLayout>
      <CustomContainer>
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <h2 className=" txt-ruby">Welcome! 🤗</h2>
            <div className="mb-24" />
            <p className="txt-violet mb-16">
              Please enter the phone number you used to sign up to our waitlist
              below to confirm your Lukhu account for our beta early access
              program.
            </p>
            <div className="mb-24" />
            <Box width="85%">
              <form
                onSubmit={formik.handleSubmit}
                noValidate
                autoComplete="off"
              >
                <CustomInput
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  placeholder="phone number *"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  fullWidth
                  startAdornment={
                    <>
                      <img
                        src={flagIcon}
                        style={{
                          marginRight: '8px',
                          width: '24px',
                          height: '24px',
                        }}
                      />
                      <p
                        className=" txt-mont  f-14 txt-black"
                        style={{ marginRight: '8px' }}
                      >
                        +254
                      </p>
                    </>
                  }
                  className={
                    formik.errors.username && formik.touched.username
                      ? classes.errorClass
                      : ''
                  }
                />
                {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                  <p className="input-error">
                    <small>{formik.errors.phoneNumber}</small>
                  </p>
                )}
                <div style={{ marginBottom: '48px' }} />

                <BaseBtnFilled type="submit" className={classes.btn}>
                  Let me in
                </BaseBtnFilled>
              </form>
              <div></div>
              <LoginLink />
              <GoBackLink />
            </Box>
            <div style={{ marginBottom: '48px' }} />
          </CardContent>
        </Card>
      </CustomContainer>
    </BetaLayout>
  );
};

export default BetaWelcome;
