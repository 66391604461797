import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  font1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  font2: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    color: '#707070',
  },
  font3: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontWeight: 700,
    fontSize: '1rem',
  },
}));

const EnteredDeliveryDetails = ({
  name,
  phoneNumber,
  location,
  otherInfo,
  building,
  otherNumber,
  deliveryCost,
  deliveryOption,
  deliveryTimeline,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Box>
        <p className="txt-mont txt-bold f-14 mb-4">
          Delivery via {deliveryOption}{' '}
        </p>
        <p className="txt-gray4 f-12 mb-4">{deliveryTimeline} </p>
        <Box display="flex" mb={0.5}>
          <Box mr={1}>
            <p className="f-12  txt-gray4  ">{name}</p>
          </Box>
          <Box mr={1}>
            <p className="txt-gray4 f-12">{phoneNumber}</p>
          </Box>
          <Box mr={1}>
            <p className="txt-gray4 f-12">{location}</p>
          </Box>
          <Box mr={1}>
            <p className="txt-gray4 f-12">{otherInfo}</p>
          </Box>
        </Box>
        <p className="txt-bold  txt-black">KSh {deliveryCost}</p>
      </Box>
    </div>
  );
};

EnteredDeliveryDetails.propTypes = {
  name: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  otherInfo: PropTypes.string,
  building: PropTypes.string,
  otherNumber: PropTypes.string,
  deliveryOption: PropTypes.string,
  deliveryCost: PropTypes.string,
  deliveryTimeline: PropTypes.string,
};
export default EnteredDeliveryDetails;
