import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { FiPackage } from 'react-icons/fi';

const MerchantNotifyItemReceived = ({ timestamp, buyerName }) => {
  const sampleNot = {
    time: '9.08 AM',
    buyer: 'peekaboo_77',
  };
  return (
    <Box display="flex">
      <Avatar style={{ background: '#45BC56', marginRight: '8px' }}>
        <FiPackage style={{ color: '#ffffff' }} />
      </Avatar>
      <div className="notification-box">
        <p className="txt-time f-10">{timestamp}</p>
        <p className="txt-bold txt-blackTrue">
          {buyerName} has received the item
        </p>
        <p className="txt-gray5 f-14">
          Time for them to rock it and enjoy it to the max!{' '}
        </p>
      </div>
    </Box>
  );
};

MerchantNotifyItemReceived.propTypes = {
  buyerName: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default MerchantNotifyItemReceived;
