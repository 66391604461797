import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Rating from '@material-ui/lab/Rating';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { FaMoneyBillWave } from 'react-icons/fa';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { getYear } from '../../_helpers/get-year';
import MerchantAvatar from '../Shops/MerchantAvatar';

import { url } from '../../api';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '8px',
  },
  media: {
    width: 151,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
  },
  content: {
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  flex: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  font1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  font2: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    color: '#707070',
  },
  font3: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontWeight: 700,
    fontSize: '1rem',
  },
  icon: {
    fontSize: '0.875rem',
    color: '#707070',
  },
  iconText: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    border: '1px solid #D80000',
    color: '#D80000',
    width: '160px',
  },
  cardHeader: {
    padding: '8px',
    '& action': {
      alignSelf: 'center',
    },
  },
  accSummary: {
    margin: 0,
  },
  accDetails: {
    marginLeft: '48px',
  },
  accContent: {
    width: '90%',
    display: 'flex',
    // justifyContent: 'space-between',
    // margin: theme.spacing(1, 0),
    alignItems: 'center',
  },
  iconSmall: {
    fontSize: '1rem',
    margin: theme.spacing(0, 0.5, 0, 0),
    color: '#707070',
  },
  accContentSpacing: {
    marginRight: '8px',
    marginLeft: '8px',
  },
}));

const CheckoutStoreDisplay = ({ storeId }) => {
  const classes = useStyles();

  const [storeDetails, setstoreDetails] = useState(null);
  const [fetchErr, setfetchErr] = useState(null);
  const getStoreDetails = async () => {
    try {
      const res = await axios.get(`${url}/store?id=${storeId}`);
      const store = res.data.store[0];
      setstoreDetails(store);
      //   "store": [
      //     {
      //         "id": "bf057afc-29dd-4f7c-9df2-605d643ed41d",
      //         "image": null,
      //         "description": null,
      //         "businessName": "CoolStores",
      //         "phoneNumber": "254771908836",
      //         "building": "Wema Heights",
      //         "placeId": null,
      //         "status": "INCOMPLETE",
      //         "modifiedOn": "2021-09-30T10:29:40.000Z",
      //         "createdOn": "2021-09-30T10:29:29.000Z"
      //     }
      // ]
    } catch (error) {
      // console.log(error);
      setfetchErr(error);
    }
  };

  useEffect(() => {
    getStoreDetails();
    return () => {};
  }, []);
  return (
    <>
      {storeDetails && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="seller-info"
            id="seller"
            className={classes.accSummary}
          >
            <div className="d-flex d-flex-align-center">
              <MerchantAvatar />
              <div style={{ marginLeft: '16px' }}>
                <p className="txt-lato txt-violet txt-bold ">
                  {storeDetails.businessName}
                </p>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accDetails}>
            <div className={classes.accContent}>
              <div>
                <div
                  className={classes.iconText}
                  style={{ marginBottom: '4px' }}
                >
                  <LocationOnIcon className={classes.iconSmall} />
                  <Typography variant="body2" className={classes.font2}>
                    {storeDetails.location}
                  </Typography>
                </div>
                <div className={classes.iconText}>
                  <AccessTimeIcon className={classes.iconSmall} />
                  <Typography variant="body2" className={classes.font2}>
                    Joined{' '}
                    <span style={{ textTransform: 'lowercase' }}>in</span>{' '}
                    {getYear(storeDetails.createdOn)}
                  </Typography>
                </div>
              </div>
              <Divider
                orientation="vertical"
                flexItem
                className={classes.accContentSpacing}
              />

              <div>
                <Rating
                  name="merchant-rating"
                  value={storeDetails.rating}
                  readOnly
                  emptyIcon={<StarBorderIcon style={{ fontSize: '1.5rem' }} />}
                  style={{ fontSize: '1.5rem' }}
                />

                <Typography variant="body2" className={classes.font2}>
                  {storeDetails.numberOfRatings} reviews
                </Typography>
              </div>

              <Divider
                orientation="vertical"
                flexItem
                className={classes.accContentSpacing}
              />
              <div>
                <div
                  className={classes.iconText}
                  style={{ marginBottom: '4px' }}
                >
                  <FaMoneyBillWave className={classes.iconSmall} />
                  <Typography
                    variant="body2"
                    style={{ color: '#111111', fontWeight: 'bold' }}
                  >
                    {storeDetails.numberOfTransactions}
                  </Typography>
                </div>
                <Typography variant="body2" className={classes.font2}>
                  Transactions
                </Typography>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

CheckoutStoreDisplay.propTypes = {
  storeId: PropTypes.string.isRequired,
};

export default CheckoutStoreDisplay;
