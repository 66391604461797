import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';

import '../../assets/styles/Messaging.css';
import MessagingHistory from '../../parts/Messaging/MessagingHistory';
import ChatSection from '../../parts/Messaging/ChatSection';
import InfoSection from '../../parts/Messaging/InfoSection';
import {
  fetchCurrentChat,
  fetchUserMessages,
} from '../../redux/message/messageActions';

const mapState = ({ message }) => ({
  messagesLoading: message.messagesLoading,
  messages: message.messages,
  messagesErr: message.messagesErr,
  currChatId: message.currChatId,
});
const MessagingHome = () => {
  const dispatch = useDispatch();
  const { messagesLoading, messages, messagesErr, currChatId } = useSelector(
    mapState
  );

  const [openLeft, setOpenLeft] = useState(false);
  const [openRight, setOpenRight] = useState(false);

  useEffect(() => {
    dispatch(fetchUserMessages());
    return () => {};
  }, [dispatch]);

  // const LeftSection = () => {
  //   return (
  //     <MessagingHistory
  //       messagesLoading={messagesLoading}
  //       messages={messages}
  //       messagesErr={messagesErr}
  //     />
  //   );
  // };
  // const MiddleSection = () => {
  //   return <ChatSection />;
  // };

  // const RightSection = () => {
  //   return <InfoSection />;
  // };

  return (
    <div>
      <div className="ctn-messaging">
        <div className="ctn-messages">
          <div>
            <MessagingHistory
              messagesLoading={messagesLoading}
              messages={messages}
              messagesErr={messagesErr}
            />
          </div>
          <div>
            <ChatSection />
          </div>
        </div>
        <div>
          <InfoSection />
        </div>
      </div>
    </div>
  );
};

export default MessagingHome;
