// import briefcase from '../../assets/images/briefcase_1.jpg';
// import jeans from '../../assets/images/denim_jeans_1.jpg';
// import sneakers from '../../assets/images/sneakers_1.jpg';
// import dresses from '../../assets/images/kids-dress_1.jpg';

// import leather_handbag_brown from '../../assets/images/leather-handbag-brown.jpg';
// import nike_shoes from '../../assets/images/nike-shoes.jpg';
// import open_heels from '../../assets/images/open-high-heels.jpg';
// import tye_dye from '../../assets/images/tye-n-dye-shirt.jpg';

import storeplaceholder from '../../assets/images/store.png';

export const sampleEmpty = [];

// export const sampleListing = [
//   {
//     storeName: 'dapperstore',
//     productName: 'Nike Air Structure OG',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: briefcase,
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'Nike Air Structure OG',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: jeans,
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'Nike Air Structure OG',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: sneakers,
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'Nike Air Structure OG',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: dresses,
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'Nike Air Structure OG',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: briefcase,
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'Nike Air Structure OG',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: jeans,
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'Nike Air Structure OG',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: sneakers,
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'Nike Air Structure OG',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: dresses,
//   },
// ];
// export const pickedForYou = [
//   {
//     storeName: 'dapperstore',
//     productName: 'Jimi Hendrix Tie-Dye T-shirt…',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: tye_dye,
//     productTag: 'popular',
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'Brown Bag for Men',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: briefcase,
//     productTag: 'on sale',
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'ACW Hoodie',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: tye_dye,
//     productTag: 'popular',
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'Jimi Hendrix Tie-Dye T-shirt…',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: tye_dye,
//     productTag: 'on sale',
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'Brown Bag for Men',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: briefcase,
//     productTag: 'on sale',
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'ACW Hoodie',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: tye_dye,
//     productTag: 'popular',
//   },
//   {
//     storeName: 'dapperstore',
//     productName: 'Burch Heels',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: open_heels,
//     productTag: 'popular',
//   },

//   {
//     storeName: 'dapperstore',
//     productName: 'Brown CN Handbag',
//     productSize: 'US 7',
//     productCondtion: 'New',
//     productLocation: 'Roysambu',
//     productPrice: '7500',
//     productImg: leather_handbag_brown,
//     productTag: 'on sale',
//   },
// ];

// export const productImages = [
//   { id: 1, img: tye_dye },
//   { id: 2, img: nike_shoes },
//   { id: 3, img: open_heels },
//   { id: 4, img: tye_dye },
// ];

export const productInfo = {
  title: 'Jimi Hendrix Tie-Dye T-shirt',
  brand: 'Hendrix Inc.',
  size: 'US M',
  condition: 'Excellent Condition',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
  price: '1,350',
  likes: 12,
};

export const sampleSeller1 = {
  avatar: storeplaceholder,
  name: 'kamera_58',
  location: 'Westlands, Nairobi',
  reviewsCount: 15,
  dateCreated: '2021',
  transactions: 150,
  ratingAvg: 3,
  followersCount: 100,
  followinfCount: 257,
};
export const sampleSeller2 = {
  avatar: storeplaceholder,
  name: 'jersey254',
  location: 'Westlands, Nairobi',
  dateCreated: '2021',
  reviewsCount: 5,
  transactions: 150,
  ratingAvg: 4,
  followersCount: 100,
  followinfCount: 257,
};

export const drawerCat = [
  { title: 'Tops', values: ['Type 1', 'Type 2', 'Type 3'] },
  { title: 'Bottoms', values: ['Type a', 'Type b', 'Type c'] },
  { title: 'Underwear', values: ['Type i', 'Type ii', 'Type iii'] },
  { title: 'Outerwear', values: ['Type 1', 'Type 2', 'Type 3'] },
  { title: 'Accessories', values: ['Type 1', 'Type 2', 'Type 3'] },
  { title: 'Shoes', values: ['Type 1', 'Type 2', 'Type 3'] },
  { title: 'Suits', values: ['Type 1', 'Type 2', 'Type 3'] },
];

export const sellerBadges = ['Top Seller', 'Verified', 'In Your Area'];
export const deliveryOptions = [
  'Express Delivery',
  'Mtaani Direct',
  'Mtaani Direct',
];

export const priceRange = [
  'Under KSh 1,000',
  'KSh 1,000 - KSh 2,000',
  'KSh 1,000 - KSh 2,000',
  'KSh 4,000+',
];

export const setupSizes = [
  'Tops',
  'Bottoms',
  'Underwear',
  'Outerwear',
  'Suits',
];

export const sampleSize = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const sampleColors = [
  { id: 1, name: 'black', hex: '#000000' },
  { id: 2, name: 'white', hex: '#FFFFFF' },
  { id: 3, name: 'red', hex: '#FF0000' },
  { id: 4, name: 'yellow', hex: '#FFFF00' },
  { id: 5, name: 'gray', hex: '#AAAAAA' },
  { id: 6, name: 'orange', hex: '#F08428' },
  { id: 7, name: 'blue', hex: '#5185F8' },
  { id: 8, name: 'pink', hex: '#5185F8' },
  { id: 9, name: 'green', hex: '#2CAC36' },
  { id: 10, name: 'purple', hex: '#B11DFF' },
];

export const lorem =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco';

export const sampleFeedback = [
  {
    name: 'officialdrip254',
    rating: '4',
    comment: 'Lorem ipsum dolor sit amet, mod tempor incididunt ut labore',
    datePosted: '2 weeks',
  },
  {
    name: 'janeyfromtheblock',
    rating: '3',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    datePosted: '2 weeks',
  },
  {
    name: 'yuki.yuki',
    rating: '2',
    comment:
      'Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    datePosted: '2 weeks',
  },
  {
    name: 'Lorem ipsum dolor.',
    rating: '5',
    comment: 'Lorem ipsum dolor.',
    datePosted: '2 weeks',
  },
  {
    name: 'mr.blue.79',
    rating: '3',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    datePosted: '2 weeks',
  },
  {
    name: 'black.maestro',
    rating: '2',
    comment:
      'Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    datePosted: '2 weeks',
  },
];
export const sampleFeedbackSent = [
  {
    name: 'yuki.yuki',
    rating: '2',
    comment:
      'Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    datePosted: '2 weeks',
  },
  {
    name: 'Lorem ipsum .',
    rating: '5',
    comment: 'Lorem ipsum dolor.',
    datePosted: '2 weeks',
  },
  {
    name: 'mr.blue.79',
    rating: '3',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    datePosted: '2 weeks',
  },
];
export const sampleListing = [];

export const sampleCart = [
  {
    id: 1,
    // productImg: briefcase,
    productName: 'Jimi Hendrix Tie-Dye T-shirt',
    productSize: 'US M',
    productCondtion: 'Excellent Condition',
    productPrice: '1,350',
    productLocation: 'Westlands, Nairobi',
  },

  {
    id: 3,
    // productImg: nike_shoes,
    productName: 'Jimi Hendrix Tie-Dye T-shirt',
    productSize: 'US M',
    productCondtion: 'Excellent Condition',
    productPrice: '1,350',
    productLocation: 'Westlands, Nairobi',
  },
];

export const samplePickupStore = [
  {
    id: 'Lukhu Pickup LM01',
    address: {
      name: 'Zenith Pickup Store',
      location: 'Woodvale Grove, Westlands',
    },
    openingHrs: 'Monday - Friday 8.00AM to 6.00PM',
    contact: '+254 745 678 901',
    deliveryCost: '150',
  },
  {
    id: 'Lukhu Pickup LM03',
    address: {
      name: 'Zenith Pickup Store',
      location: 'Woodvale Grove, Westlands',
    },
    openingHrs: 'Monday - Friday 8.00AM to 6.00PM',
    contact: '+254 745 678 901',
    deliveryCost: '150',
  },
];

export const sampleSoldItems = [
  {
    date: '13 May, 2021',
    prodName: 'Tory Burch Heels',
    prodPrice: '4,550',
  },
  {
    date: '13 May, 2021',
    prodName: 'Off-White Caravaggio T-shirt',
    prodPrice: '4,550',
  },
  {
    date: '13 May, 2021',
    prodName: 'Stone Island Navy Jacket',
    prodPrice: '4,550',
  },
  {
    date: '13 May, 2021',
    prodName: 'RMN MIK T-shirt',
    prodPrice: '4,550',
  },
  {
    date: '13 May, 2021',
    prodName: 'Nike Vapormax',
    prodPrice: '4,550',
  },
];

export const sampleDraftsComplete = [
  {
    id: 1,
    // productImg: briefcase,
    productName: 'Jimi Hendrix Tie-Dye T-shirt',
    productSize: 'US M',
    productCondtion: 'Excellent Condition',
    productPrice: '1,350',
    productLocation: 'Westlands, Nairobi',
  },
  {
    id: 2,
    // productImg: tye_dye,
    productName: 'Jimi Hendrix Tie-Dye T-shirt',
    productSize: 'US M',
    productCondtion: 'Excellent Condition',
    productPrice: '1,350',
    productLocation: 'Westlands, Nairobi',
  },
];

export const sampleDraftsIComplete = [
  {
    id: 1,
    // productImg: briefcase,
    productName: 'Jimi Hendrix Tie-Dye T-shirt',
    productSize: 'US M',
    productCondtion: '',
    productPrice: '',
    productLocation: 'Westlands, Nairobi',
  },
  {
    id: 2,
    // productImg: tye_dye,
    productName: 'Jimi Hendrix Tie-Dye T-shirt',
    productSize: '',
    productCondtion: 'Excellent Condition',
    productPrice: '1,350',
    productLocation: '',
  },
];

export const sampleChatMessageSnippet = {
  sender: 'jersey254',
  message:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
};

export const sampleListed = [
  // {
  //   productImg: tye_dye,
  // },
  // {
  //   productImg: briefcase,
  // },
  // {
  //   productImg: jeans,
  // },
  // {
  //   productImg: sneakers,
  // },
  // {
  //   productImg: dresses,
  // },
  // {
  //   productImg: open_heels,
  // },
];
export const simulateCart2 = [
  {
    cart_id: 6,
    store_id: 37,
    cart_total: 250,
    number_of_items: 1,
    items: [
      {
        id: 'db9f45cc-ca1f-437b-89cd-213847ba02fa',
        name: 'Fashion blouse',
        size: '12 UK',
        condition: 'almost new',
        image: '',
        price: 250,
      },
    ],
  },
  {
    cart_id: 19,
    store_id: 39,
    cart_total: 200,
    number_of_items: 1,
    items: [
      {
        id: 'dc64457b-c63a-45a6-b080-0eb6f6dda633',
        name: 'Denim skirt',
        size: '18 UK',
        condition: 'brand new',
        image: '',
        price: 200,
      },
      {
        id: 'adc6e9f3-886f-4863-acfb-ec53045350a4',
        name: 'Boy clothing set',
        size: '3-6 months Kids',
        condition: 'almost new',
        image: '',
        price: 700,
      },
    ],
  },
];
export const simulateCart = [
  {
    id: 'db9f45cc-ca1f-437b-89cd-213847ba02fa',
    name: 'Fashion blouse',
    size: '12 UK',
    location: null,
    condition: 'almost new',
    image: '',
    price: 250,
  },
  {
    id: 'adc6e9f3-886f-4863-acfb-ec53045350a4',
    name: 'Boy clothing set',
    size: '3-6 months Kids',
    location: null,
    condition: 'almost new',
    image: '',
    price: 700,
  },
  {
    id: '84278df3-3949-47eb-9f4f-74969b11a998',
    name: 'Bohemian style skirt',
    size: '18 UK',
    location: null,
    condition: 'almost new',
    image: '',
    price: 700,
  },
];

export const sampleSavedAddress = [
  {
    firstName: 'John ',
    lastName: 'Odindo',
    phoneNo: '+254 721 515 912',
    location: 'Nairobi, Langata, Park 2 Estate',
    buildingName: 'Wema Heights',
    buikdingDetails: '2 fl',
    moreDetails: 'Next to Kibaki’s Butchery',
  },
];

export const sampleHistory = [
  {
    id: 147,
    type: 'NOTIFICATION',
    image: '',
    last_message:
      'Your payment on order  has been confirmed Your payment on order  has been confirmed',
    user: 'JeffreyN',
    userId: '01',
    status: 'BOUGHT PENDING',
    itemImage: '',
    created_on: '2021-12-06T13:44:05.000Z',
  },
  {
    id: 146,
    type: 'NOTIFICATION',
    image: '',
    last_message:
      'Your payment on order LOKU4R7ARI3I19T2KE4VS1ESC6BZKLZTS8 has been confirmed',
    user: 'mully',
    userId: '01',
    status: 'BOUGHT PENDING',
    itemImage: '',
    created_on: '2021-12-06T13:38:46.000Z',
  },
  {
    id: 148,
    type: 'CHAT',
    image: '',
    last_message:
      'Your payment on order LOCMQJ96TARMHATUFFI18OH6Z39KXJPLLY has been confirmed',
    user: 'JeffreyN',
    userId: '02',
    status: 'BOUGHT PENDING',
    itemImage: '',
    created_on: '2021-12-06T13:44:05.000Z',
  },
  {
    id: 149,
    type: 'CHAT',
    image: '',
    last_message:
      'Your payment on order LOCMQJ96TARMHATUFFI18OH6Z39KXJPLLY has been confirmed',
    user: 'JeffreyN',
    userId: '03',
    status: 'BOUGHT PENDING',
    itemImage: '',
    created_on: '2021-12-06T13:44:05.000Z',
  },
];

export const sampleChat = [
  {
    id: 148,
    type: 'CHAT',
    image: '',
    message: 'test',
    received: false,
    from: 'JeffreyN',
    to: 'personperson',
    created_on: '2021-12-06T11:31:00.000Z',
  },
  // {
  //   id: 122,
  //   type: 'CHAT',
  //   image: '',
  //   message: 'test',
  //   received: true,
  //   from: 'JeffreyN',
  //   to: 'personperson',
  //   created_on: '2021-12-06T11:31:00.000Z',
  // },
];

// const chat = {id:272
//   type:"CHAT"
//   image:""
//   message:"testing post"
//   messageStatus:"READ"
//   received:false
//   from:"graceM"
//   to:"lmacharia1"
//   isProduct:false
//   isOrder:false
//   details:null
//   created_on:"2022-02-10T09:54:58.000Z"
//   }

// {
//   "messageId": number,
//   "message": string,
//   "type": string,
//   "from": string,
//   "fromId": string,
//   "isOrder": false,
//   "isProduct": false,
//   "created_on": string
// }
