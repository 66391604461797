import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F7F7F7',
    padding: '8px',
    marginLeft: 0,
    marginRight: 0,
  },
  card: {
    display: 'flex',
    height: '107px',
    borderRadius: 0,
    boxShadow: 'none',
    background: 'transparent',
  },
  cover: {
    width: '107px',
    height: '107px',
  },
}));

const OrderImage = ({
  prodName,
  prodSize,
  prodCondition,
  prodPrice,
  prodImage,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.cover}
          image={prodImage}
          title="product ordered"
        />
        <CardContent className={classes.content}>
          <p className="txt-mont txt-semi-bold f-14 ">{prodName}</p>
          <p className="txt-lato f-14 txt-lightColor">
            <span style={{ fontWeight: 700 }}>Size: </span>
            {prodSize}
          </p>
          <p className="txt-lato f-14 txt-lightColor">
            <span style={{ fontWeight: 700 }}>Condition: </span>
            {prodCondition}
          </p>
          <p className="txt-bold f-14 txt-lato"> Ksh {prodPrice}</p>
        </CardContent>
      </Card>
    </Box>
  );
};

OrderImage.propTypes = {
  prodName: PropTypes.string.isRequired,
  prodSize: PropTypes.string.isRequired,
  prodPrice: PropTypes.string.isRequired,
  prodCondition: PropTypes.string.isRequired,
  prodImage: PropTypes.string.isRequired,
};

export default OrderImage;
