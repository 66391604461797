import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

const BaseErrorDisplay = ({ message }) => {
  return (
    <Box
      height="10vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={2}
    >
      <p className="txt-center txt-ruby">{message}</p>
    </Box>
  );
};
BaseErrorDisplay.propTypes = {
  message: PropTypes.string.isRequired,
};
export default BaseErrorDisplay;
