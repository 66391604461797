import { useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import { url } from '../../api';

const useStyles = makeStyles({
  root: {
    padding: '16px 8px',
    borderRadius: '16px',
  },
  content: {
    textAlign: 'center',
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: ' 1fr 1fr',
    gridGap: '4px',
  },
  btn: {
    color: '#ffffff',
    fontSize: '0.75rem',
    fontWeight: 700,
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    textTransform: 'capitalize',
  },
});

const mapState = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  isLoggedIn: auth.isLoggedIn,
  isPhoneVerified: auth.isPhoneVerified,
  phoneNumber: auth.regPhoneNumber,
  signupError: auth.regErr,
  profile: auth.profile,
  token: auth.token,
});
const SelectCategory = () => {
  const classes = useStyles();
  const history = useHistory();
  const { profile, token } = useSelector(mapState);
  const [selected, setselected] = useState(false);

  const updatePreference = async (category) => {
    axios
      .patch(`${url}/users/${profile.id}/preference`, category, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.message) {
          setselected(true);
          // console.log('sel', selected);
          // ('sel', res.data.message);
          history.push('/auth/login-form');
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const selectWomensWear = () => {
    const catId = {
      Category: 2,
    };
    updatePreference(catId);
  };
  const selectMensWear = () => {
    const catId = {
      Category: 1,
    };
    updatePreference(catId);
  };
  const selectKidssWear = () => {
    const catId = {
      Category: 3,
    };
    updatePreference(catId);
  };
  const selectEverything = () => {
    const catId = {
      Category: 4,
    };
    updatePreference(catId);
  };

  // if (selected) return <Redirect to="/auth/login/login-form" />;

  return (
    <Card className={classes.root}>
      {selected}
      <CardContent className={classes.content}>
        <h2 className="txt-ruby mb-16"> Take your pick</h2>
        <p className="txt-violet mb-24">
          Select your preferred category for better results
        </p>
        <div className="cat-grid">
          <div className="cat1">
            <div className="cat-txt">
              <Button
                variant="text"
                className={classes.btn}
                fullWidth
                size="small"
                onClick={selectWomensWear}
              >
                Shop womenswear
              </Button>
            </div>
          </div>
          <div className="cat2">
            <div className="cat-txt">
              <Button
                variant="text"
                className={classes.btn}
                fullWidth
                size="small"
                onClick={selectMensWear}
              >
                Shop menswear
              </Button>
            </div>
          </div>
          <div className="cat3">
            <div className="cat-txt">
              <Button
                variant="text"
                className={classes.btn}
                fullWidth
                size="small"
                onClick={selectKidssWear}
              >
                Shop kidswear
              </Button>
            </div>
          </div>
          <div className="cat4">
            <div className="cat-txt">
              <Button
                variant="text"
                className={classes.btn}
                fullWidth
                size="small"
                onClick={selectEverything}
              >
                Shop everything
              </Button>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SelectCategory;
