import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';

import CheckoutProductDisplay from './CheckoutProductDisplay';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import CheckoutStoreDisplay from './CheckoutStoreDisplay';
import { checkoutFromCart } from '../../redux/checkout/checkoutActions';
import { fetchCartItems, removeCartItem } from '../../redux/cart/cartActions';
import CheckoutVoucherProductDisplay from './CheckoutVoucherProductDisplay';

const useStyles = makeStyles((theme) => ({
  productCard: {
    marginBottom: '8px',
  },
  btn: {
    width: '160px',
  },
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: 'transparent',
    boxShadow: 'none',
    width: '314px',
  },
  content: {
    flex: '1 0 auto',
    padding: '0 8px',
  },
  cover: {
    width: 101,
  },
  font1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.75rem',
  },
  font2: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '0.75rem',
    color: '#707070',
  },
  font3: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontWeight: 700,
    fontSize: '0.875rem',
  },
}));

const CheckedOutCartItems = ({ cartItems }) => {
  const classes = useStyles2();
  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
        {cartItems.map((item) => (
          <div key={item.id} style={{ margin: '4px' }}>
            <Card className={classes.root}>
              <CardMedia
                className={classes.cover}
                image={item.image}
                title="product"
              />
              <CardContent className={classes.content}>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.font1}
                >
                  {item.name}
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.font2}
                >
                  <span style={{ fontWeight: 700 }}>Condition: </span>
                  {item.condition}
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.font2}
                >
                  <span style={{ fontWeight: 700 }}>Size: </span>
                  {item.size}
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.font3}
                >
                  Ksh {item.price}
                </Typography>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
};

CheckedOutCartItems.propTypes = {
  cartItems: PropTypes.array.isRequired,
};

const mapState = ({ checkout, cart }) => ({
  isCartCheckedout: checkout.isCartCheckedout,
  checkedOutCart: checkout.checkedOutCart,
  cartItems: cart.cartItems,
  shoppingBagItems: cart.shoppingBagItems,
});

const CartVoucher = ({ handleNext }) => {
  const classes = useStyles();


  // console.log("cart_items",cartItems)
  const dispatch = useDispatch();
  const { isCartCheckedout, cartItems, checkedOutCart } = useSelector(mapState);

  useEffect(() => {
    dispatch(fetchCartItems());
    return () => {};
  }, [dispatch]);

  const handleRemoveFromCart = (id) => {
    const body = {
      productId: id,
    };

    // console.log('body', body);
    dispatch(removeCartItem(body));
  };

  const [storeCartItems, setstoreCartItems] = useState([]);

  const handleCheckout = (id) => {
    const cart = cartItems.filter((item) => item.cart_id === id);
    const items = cart[0].items;
    // console.log(items);
    dispatch(checkoutFromCart(id));

    setstoreCartItems(items);
    handleNext();
  };

  
  return (
    <>
      {cartItems && (
        <>
          {checkedOutCart ? (
            <CheckedOutCartItems cartItems={storeCartItems} />
          ) : (
            <div>
              {cartItems && cartItems.length === 0 && (
                <div>
                  <p className="f-12 txt-violet txt-center">
                    Your bag is currently empty.
                  </p>
                </div>
              )}
              {cartItems &&
                cartItems.map((item) => (
                  <Card className={classes.productCard} key={item.cart_id}>

                    {item.items.map((item) => {
                      return (
                        <CheckoutVoucherProductDisplay
                          key={item.id}
                          condition={item.condition}
                          image={item.image}
                          price={item.price}
                          size={item.size}
                          title={item.name}
                          handleRemoveFromCart={() =>
                            handleRemoveFromCart(item.id)
                          }
                        />
                      );
                    })}
                    <CardActions
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
               
                    </CardActions>
                  </Card>
                ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

CartVoucher.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default CartVoucher;
