import {
  FETCH_MAIN_CATEGORIES_LIST_START,
  FETCH_MAIN_CATEGORIES_LIST_SUCCESS,
  FETCH_MAIN_CATEGORIES_LIST_FAIL,
  FETCH_SUB_CATEGORIES_LIST_START,
  FETCH_SUB_CATEGORIES_LIST_SUCCESS,
  FETCH_SUB_CATEGORIES_LIST_FAIL,
  FETCH_CATEGORY_PRODUCTS_RESULTS_START,
  FETCH_CATEGORY_PRODUCTS_RESULTS_SUCCESS,
  FETCH_CATEGORY_PRODUCTS_RESULTS_FAIL,
  FETCH_ALL_CATEGORIES_START,
  FETCH_ALL_CATEGORIES_SUCCESS,
  FETCH_ALL_CATEGORIES_FAIL,
  FETCH_SEARCH_RESULTS_START,
  FETCH_SEARCH_RESULTS_SUCCESS,
  FETCH_SEARCH_RESULTS_FAIL,
  FETCH_FITLER_RESULTS_START,
  FETCH_FITLER_RESULTS_FAIL,
  FETCH_FITLER_RESULTS_SUCCESS,
  FETCH_L2_START,
  FETCH_L2_SUCCESS,
  FETCH_L2_FAIL,
  FETCH_L2_CAT_CHILDREN_START,
  FETCH_L2_CAT_CHILDREN_FAIL,
  FETCH_L2_CAT_CHILDREN_SUCCESS,
  SET_SUBCAT_ID,
  SET_L2_CATEGORIES,
  FETCH_UNDER1K_START,
  FETCH_UNDER1K_SUCCESS,
  FETCH_UNDER1K_FAIL,
  FETCH_SAME_SELLER_FAIL,
  FETCH_SAME_SELLER_START,
  FETCH_SAME_SELLER_SUCCESS,
  FETCH_OVER1K_FAIL,
  FETCH_OVER1K_START,
  FETCH_OVER1K_SUCCESS,
} from './searchTypes';

const initialState = {
  loading: false,

  loadingAllCategories: false,
  allCategories: null,
  allCategoriesError: null,

  mainCategoriesList: null,
  subCategoriesList: null,
  fetchMainCategoriesListError: null,
  fetchSubCategoriesListError: null,

  // new
  categoryProductsResults: null,
  categoryProductsResultsCount: null,
  categoryProductsResultsError: null,
  loadingCategoryProductsResults: false,
  //

  // new
  under1kProductsResults: null,
  under1kProductsResultsCount: null,
  under1kProductsResultsError: null,
  loadingUnder1kProductsResults: false,
  //

   // new
   over1kProductsResults: null,
   over1kProductsResultsCount: null,
   over1kProductsResultsError: null,
   loadingOver1kProductsResults: false,
   //
 
  // new
  sameSellerProductsResults: null,
  sameSellerProductsResultsCount: null,
  sameSellerProductsResultsError: null,
  loadingSameSellerProductsResults: false,
  //

  searchQuery: null,
  loadingSearchQuery: false,
  searchQueryResults: null,
  searchQueryResultsCount: null,
  searchQueryResultsError: null,

  loadingFilterResults: false,
  filterResults: null,
  filterResultsCount: null,
  filterResultsError: null,

  l2Results: null,
  l2Count: null,
  l2Loading: null,
  l2Error: null,
  l2Id: null,
  l2CategoryChildren: null,
  l2ChildrenLoading: false,
  l2Children: null,
  l2ChildrenError: null,

  l2CategoriesList: null,
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MAIN_CATEGORIES_LIST_START:
    case FETCH_SUB_CATEGORIES_LIST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_MAIN_CATEGORIES_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        fetchMainCategoriesListError: action.payload,
      };
    }
    case FETCH_SUB_CATEGORIES_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        fetchSubCategoriesListError: action.payload,
      };
    }
    case FETCH_MAIN_CATEGORIES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        mainCategoriesList: action.payload.mainCategories,
        allCategories: action.payload.allCategories,
      };
    }

    case FETCH_SUB_CATEGORIES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        subCategoriesList: action.payload,
      };
    }

    case FETCH_CATEGORY_PRODUCTS_RESULTS_START: {
      return {
        ...state,
        loadingCategoryProductsResults: true,
      };
    }

    case FETCH_CATEGORY_PRODUCTS_RESULTS_SUCCESS: {
      return {
        ...state,
        loadingCategoryProductsResults: false,
        categoryProductsResults: action.payload.results,
        categoryProductsResultsCount: action.payload.count,
      };
    }
    case FETCH_CATEGORY_PRODUCTS_RESULTS_FAIL: {
      return {
        ...state,
        loadingCategoryProductsResults: false,
        categoryProductsResultsError: action.payload,
      };
    }

    case FETCH_ALL_CATEGORIES_START: {
      return {
        ...state,
        loadingAllCategories: true,
      };
    }
    case FETCH_ALL_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loadingAllCategories: false,
        allCategories: action.payload,
      };
    }
    case FETCH_ALL_CATEGORIES_FAIL: {
      return {
        ...state,
        loadingAllCategories: false,
        allCategoriesError: action.payload,
      };
    }

    case FETCH_SEARCH_RESULTS_START: {
      return {
        ...state,
        loadingSearchQuery: true,
        searchQuery: action.payload,
      };
    }
    case FETCH_SEARCH_RESULTS_SUCCESS: {
      return {
        ...state,
        loadingSearchQuery: false,
        searchQueryResults: action.payload.results,
        searchQueryResultsCount: action.payload.count,
      };
    }
    case FETCH_SEARCH_RESULTS_FAIL: {
      return {
        ...state,
        loadingSearchQuery: false,
        searchQueryResultsError: action.payload,
      };
    }
    case FETCH_FITLER_RESULTS_START: {
      return {
        ...state,
        loadingFilterResults: true,
      };
    }
    case FETCH_FITLER_RESULTS_SUCCESS: {
      return {
        ...state,
        loadingFilterResults: false,
        filterResults: action.payload.results,
        filterResultsCount: action.payload.count,
      };
    }
    case FETCH_FITLER_RESULTS_FAIL: {
      return {
        ...state,
        loadingFilterResults: false,
        filterResultsError: action.payload,
      };
    }
    case FETCH_L2_START: {
      return {
        ...state,
        l2Loading: true,
      };
    }
    case FETCH_L2_SUCCESS: {
      return {
        ...state,
        l2Loading: false,
        l2Results: action.payload.results,
        l2Count: action.payload.count,
        l2Id: action.payload.subCatId,
      };
    }
    case FETCH_L2_FAIL: {
      return {
        ...state,
        l2Loading: false,
        l2Error: action.payload,
      };
    }
    case FETCH_L2_CAT_CHILDREN_START: {
      return {
        ...state,
        l2ChildrenLoading: true,
      };
    }
    case FETCH_L2_CAT_CHILDREN_SUCCESS: {
      return {
        ...state,
        l2ChildrenLoading: false,
        l2Children: action.payload,
      };
    }
    case FETCH_L2_CAT_CHILDREN_FAIL: {
      return {
        ...state,
        l2ChildrenLoading: false,
        l2ChildrenError: action.payload,
      };
    }
    case SET_SUBCAT_ID: {
      return {
        ...state,
        l2Id: action.payload,
      };
    }
    case SET_L2_CATEGORIES: {
      return {
        ...state,
        l2CategoriesList: action.payload,
      };
    }

    case FETCH_OVER1K_START: {
      return {
        ...state,
        loadingOver1kProductsResults: true,
      };
    }
    case FETCH_UNDER1K_START: {
      return {
        ...state,
        loadingUnder1kProductsResults: true,
      };
    }
    case FETCH_UNDER1K_SUCCESS: {
      return {
        ...state,
        loadingUnder1kProductsResults: false,
        under1kProductsResults: action.payload.results,
        under1kProductsResultsCount: action.payload.count,
      };
    }

    case FETCH_OVER1K_SUCCESS: {
      return {
        ...state,
        loadingOver1kProductsResults: false,
        over1kProductsResults: action.payload.results,
        over1kProductsResultsCount: action.payload.count,
      };
    }

    case FETCH_OVER1K_FAIL: {
      return {
        ...state,
        loadingOver1kProductsResults: false,
        over1kProductsResultsError: action.payload,
      };
    }
    case FETCH_UNDER1K_FAIL: {
      return {
        ...state,
        loadingUnder1kProductsResults: false,
        under1kProductsResultsError: action.payload,
      };
    }
    case FETCH_SAME_SELLER_START: {
      return {
        ...state,
        loadingSameSellerProductsResults: true,
      };
    }
    case FETCH_SAME_SELLER_SUCCESS: {
      return {
        ...state,
        loadingSameSellerProductsResults: false,
        sameSellerProductsResults: action.payload.results,
        sameSellerProductsResultsCount: action.payload.count,
      };
    }
    case FETCH_SAME_SELLER_FAIL: {
      return {
        ...state,
        loadingSameSellerProductsResults: false,
        sameSellerProductsResultsError: action.payload,
      };
    }
    default:
      return state;
  }
};

export default searchReducer;
