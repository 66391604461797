import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 240,
    padding: '16px',
  },
}));

const TemporaryDrawer = ({ open, onClose, children }) => {
  const classes = useStyles();
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {children}
    </Drawer>
  );
};

TemporaryDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};
export default TemporaryDrawer;
