import { getAnalytics,isSupported,logEvent } from "firebase/analytics";
import firebase_app from "../firebaseConfig.js";


export const log_firebase_event = (event_name,param) =>{
    const analytics = getAnalytics(firebase_app)
    console.log(analytics)
    logEvent(analytics,event_name,param)
}

export const log_clicks_to_firebase = (event_name) =>{
    const analytics = getAnalytics(firebase_app)
    console.log(analytics)
    logEvent(analytics,event_name)

}
