import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const MerchantProductCard = ({ picture, price, size, rootStyles }) => {
  const classes = useStyles();
  return (
    <Card className={rootStyles}>
      <CardMedia component="img" image={picture} />
      <CardContent className={classes.content}>
        <p className="txt-bold ">KSh {price}</p>
        <p className="txt-light">Size: {size}</p>
      </CardContent>
    </Card>
  );
};

MerchantProductCard.propTypes = {
  picture: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  rootStyles: PropTypes.object,
};

export default MerchantProductCard;
