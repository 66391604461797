import { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';

import TemporaryDrawer from '../../parts/Shared/TemporaryDrawer';

const useStyles = makeStyles((theme) => ({
  headerGrid: {
    marginBottom: '16px',
  },
  divider: {
    height: '2px',
    backgroundColor: '#E1E1E1',
  },
  menuIconBtn: {
    paddingLeft: 0,
  },
}));

const ResultsLayout = ({ menu, content, title }) => {
  const classes = useStyles();
  const [showSidebar, setShowSidebar] = useState(false);
  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);
  return (
    <Container maxWidth="lg">
      <Hidden mdUp>
        <Box display="flex" alignItems="center">
          <IconButton
            className={classes.menuIconBtn}
            onClick={handleShowSidebar}
          >
            <MenuIcon style={{ color: '#4a4062', fontWeight: 'bold' }} />
          </IconButton>
          <h2 className="txt-violet f-14">Menu</h2>
        </Box>
      </Hidden>
      <Grid container>
        <Grid container item xs={12} spacing={1} className={classes.headerGrid}>
          <Grid item xs={12}>
            <Box pb={2} pt={2}>
              <h4>{title}</h4>
            </Box>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
        <TemporaryDrawer open={showSidebar} onClose={handleCloseSidebar}>
          {menu}
        </TemporaryDrawer>
        <Hidden smDown>
          <Grid container item xs={12} md={2}>
            {menu}
          </Grid>
        </Hidden>

        <Grid container item xs={12} md={10} spacing={1}>
          {content}
        </Grid>
      </Grid>
    </Container>
  );
};

ResultsLayout.propTypes = {
  menu: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default ResultsLayout;
