import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

const BaseLoadingDisplay = () => {
  return (
    <Box
      height="80vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={2}
    >
      <p className="txt-center txt-ruby">Loading ...</p>
    </Box>
  );
};

export default BaseLoadingDisplay;
