/* eslint-disable react/prop-types */
import AppHeader from '../../parts/Shared/AppHeader';
import MainNavigation from '../../parts/Home/MainNavigation';
import StickyBanner from '../StickyBanner';

const AllHeadersLayout = ({ children }) => {
  return (
    <>
      <AppHeader />
      <main>
        <MainNavigation />
        {children}
       
      </main>
      
    </>
  );
};

export default AllHeadersLayout;
