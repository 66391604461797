import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const BaseInput2 = withStyles({
  root: {
    maxHeight: '40px',
    backgroundColor: '#F5F2FE',
    padding: '4px 8px',
    fontSize: '0.75rem',
  },
})(InputBase);
export default BaseInput2;
