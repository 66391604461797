import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const BaseInput3 = withStyles({
  root: {
    maxHeight: '50px',
    backgroundColor: '#FFF',
    padding: '4px 8px',
    fontSize: '0.75rem',
  },
})(InputBase);
export default BaseInput3;
