/* eslint-disable react/prop-types */
import AppHeader from '../../parts/Shared/AppHeader';
import StickyBanner from '../StickyBanner';

const CheckoutLayout = ({ children }) => {
  return (
    <>
      <AppHeader />
      <main>{children}</main>
      {/* <StickyBanner /> */}

    </>
  );
};

export default CheckoutLayout;
