/* 
  This component fetches cart items. 
  Displayed when the user clickS on the cart/ shopping bag. 
*/

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import { fetchCartItems } from '../../redux/cart/cartActions';
import BaseBtn from '../../components/BaseBtn';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: 'none',
    maxWidth: '375px',
  },

  cover: {
    width: 96,
  },
  font1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  font2: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '0.75rem',
  },
  font3: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontWeight: 700,
    fontSize: '1rem',
  },
  flex: {
    display: 'flex',
  },
  btn: {
    backgroundColor: '#481CFB',
    color: '#FFFFFF',
    textTransform: 'lowercase',
    '&:hover': {
      background: '#481CFB',
    },
  },
}));

const emptyCartMsg = 'Your bag is currently empty.';

function RenderItems({
  itemImage,
  itemName,
  itemSize,
  itemCondition,
  itemPrice,
}) {
  const classes = useStyles();
  return (
    <div>
      {/* {itemsToRender.map((item) => ( */}
      <MenuItem>
        <Card className={classes.root}>
          <CardMedia
            className={classes.cover}
            image={itemImage}
            title="product photo"
          />
          <CardContent className={classes.content}>
            <Typography component="p" variant="body1" className={classes.font1}>
              {itemName}
            </Typography>
            <div className={classes.flex}>
              <Typography
                component="p"
                variant="body1"
                className={classes.font2}
                style={{ marginRight: '8px' }}
              >
                <span style={{ fontWeight: 700 }}>Size: </span>
                {itemSize}
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={classes.font2}
              >
                <span style={{ fontWeight: 700 }}>Condition: </span>{' '}
                {itemCondition}
              </Typography>
            </div>

            <Typography component="p" variant="body1" className={classes.font3}>
              KSh {itemPrice}
            </Typography>
          </CardContent>
        </Card>
      </MenuItem>
      {/* ))} */}
    </div>
  );
}

RenderItems.propTypes = {
  itemImage: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  itemSize: PropTypes.string.isRequired,
  itemCondition: PropTypes.string.isRequired,
  itemPrice: PropTypes.string.isRequired,
};

const mapState = ({ cart }) => ({
  shoppingBagItems: cart.shoppingBagItems,
  shoppingBagItemsCount: cart.shoppingBagItemsCount,
  shoppingBagItemsLoading: cart.shoppingBagItemsLoading,
  combinedCartTotal: cart.combinedCartTotal,
});
const CartDropDown = ({ handleClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    shoppingBagItems,
    shoppingBagItemsCount,
    shoppingBagItemsLoading,
    combinedCartTotal,
  } = useSelector(mapState);

  useEffect(() => {
    dispatch(fetchCartItems());
  }, []);

  return (
    <>
      {shoppingBagItemsLoading && (
        <p className="f-14 txt-violet txt-center">Loading...</p>
      )}
      {shoppingBagItems && shoppingBagItems.length ? (
        <>
          {shoppingBagItems.map((item) => (
            <RenderItems
              key={item.id}
              itemCondition={item.condition}
              itemImage={item.image}
              itemName={item.name}
              itemPrice={item.price}
              itemSize={item.size}
            />
          ))}

          <Divider variant="middle" />
          <MenuItem>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Button size="small">SUBTOTAL</Button>
              <p className="txt-bold f-14">{combinedCartTotal}</p>
            </div>
          </MenuItem>

          <MenuItem>
            <BaseBtn fullWidth className={classes.btn} onClick={handleClick}>
              <span className="txt-capitalize">P</span>roceed to checkout
            </BaseBtn>
          </MenuItem>
        </>
      ) : (
        <MenuItem
          style={{
            padding: '32px',
          }}
        >
          <p className="f-12" style={{ color: '#A6A7A8' }}>
            {emptyCartMsg}
          </p>
        </MenuItem>
      )}
    </>
  );
};

CartDropDown.propTypes = {
  handleClick: PropTypes.func.isRequired,
};
export default CartDropDown;
