import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { spacing, flexbox } from '@material-ui/system';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import mpesaLogo from '../../assets/images/mpesa-logo.svg';
import visaLogo from '../../assets/images/visa-logo.svg';
import mastercardLogo from '../../assets/images/mastercard-logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F8F8F8',
    padding: '16px',
  },
  heading: {
    textTransform: 'uppercase',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    fontWeight: 600,
    color: '#4A4062',
  },
  heading2: {
    textTransform: 'uppercase',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '0.75rem',
    fontWeight: 600,
    color: '#D81E5B',
  },
  content: {
    color: '#707071',
    fontSize: '0.75rem',
    fontFamily: ['Lato', 'sans-serif'].join(','),
  },
  logoCtn: {
    marginRight: '8px',
    width: '25%',
  },
}));

const processTerms =
  'Once you have completed the checkout process and made your payment, the seller will be notified to initiate the shipping process and ensure that you receive your item as soon as possible.';

const paymentTerms =
  'All in-app purchases on Lukhu are covered by our Buyer Protection program. This ensures that your funds and items are safe up until the transaction is complete. This also ensures a seamless return process.';

const deliveryTerms =
  'We have three delivery options on Lukhu. Depending on your preference, you can select which option works best for you and we will do out best to ensure you receive your item on time and in the best possible condition.';

const satisfactionTerms =
  'Once you have completed the checkout process and made your payment, the seller will be notified to initiate the shipping process and ensure that you receive your item as soon as possible.';

const CheckoutTerms = () => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.root}>
      <Box p={2}>
        <Box width="90%" mb={3}>
          <Typography variant="h4" gutterBottom className={classes.heading}>
            THE LUKHU PROCESS
          </Typography>
          <Typography variant="body2" gutterBottom className={classes.content}>
            {processTerms}
          </Typography>
        </Box>
        <Box width="90%" mb={3}>
          <Typography variant="h4" gutterBottom className={classes.heading}>
            PAYMENTS
          </Typography>
          <Typography variant="body2" gutterBottom className={classes.content}>
            {processTerms}
          </Typography>
        </Box>
        <Box width="90%" mb={3}>
          <Typography variant="h4" gutterBottom className={classes.heading}>
            DELIVERY
          </Typography>
          <Typography variant="body2" gutterBottom className={classes.content}>
            {deliveryTerms}
          </Typography>
        </Box>
        <Box width="90%" mb={3}>
          <Typography variant="h4" gutterBottom className={classes.heading}>
            NOT SATISFIED?
          </Typography>
          <Typography variant="body2" gutterBottom className={classes.content}>
            {satisfactionTerms}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="h4" gutterBottom className={classes.heading2}>
            100% SECURE PAYMENTS THROUGH LUKHU PAY
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <div>
            <img src={mpesaLogo} alt="M-Pesa logo" style={{ width: '80%' }} />
          </div>
          <div>
            <img
              src={mastercardLogo}
              alt="Mastercard Logo"
              style={{ width: '80%' }}
            />
          </div>
          <div>
            <img src={visaLogo} alt="Visa Logo" style={{ width: '80%' }} />
          </div>
        </Box>
      </Box>
    </Paper>
  );
};

export default CheckoutTerms;
