import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const SetPriceInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #C1C1C1',
    fontSize: 16,
    // width: '64px',
    // padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: ['Lato', 'sans-serif'].join(','),
    '&:focus': {
      borderColor: '#111111',
    },
  },
}))(InputBase);

export default SetPriceInput;
