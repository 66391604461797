import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const CustomSelect = withStyles((theme) => ({
  input: {
    position: 'relative',
    border: 'none',
    fontFamily: ['Lato', 'sans-serif'].join(', '),
    textTransform: 'capitalize',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: '#111111',
  },
}))(InputBase);

export default CustomSelect;
