import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import InfoIcon from '@material-ui/icons/Info';

import '../../assets/styles/List.css';
import BaseInput from '../../components/BaseInput';
import BaseBtn from '../../components/BaseBtn';
import ProductImageDisabled from '../../parts/Products/ProductImageDisabled';
import ProductImageInput from '../../parts/Products/ProductImageInput';
import { url } from '../../api';
import ListProductsSuccess from './ListProductsSuccess';
import ListProductsFail from './ListProductsFail';
import { fetchProductConditions } from '../../redux/products/productsActions';
import { IconButton } from '@material-ui/core';
import { getProductDetails } from '../../common/ImportantFunctions';
import useScript from '../../hooks/useScript';


const useStyles = makeStyles((theme) => ({
  txtArea: {
    borderColor: '#0000001F',
    borderRadius: '4px',
    backgroundColor: '#F5F2FE',
    padding: '4px 8px',
    fontSize: '16px',
    fontFamily: 'Lato',
    width: '100%',

    outline: 'none',
    '&:hover': {
      borderColor: '#4800FB',
    },
    '&:focus': {
      borderColor: '#4800FB',
    },
  },
  btnFilled: {
    backgroundColor: '#4900ff',
    border: '2px solid #4900ff',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
      cursor : 'pointer'
    },
    width: '150px',
  },
  btnFilledDelete: {
    backgroundColor: '#FF0000',
    border: '2px solid #FF0000',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#FF0000',
      color: '#FFF',
      cursor : 'pointer'
    },
    width: '150px',
  },
  btnOutlined: {
    width: '150px',
    border: '1.5px solid #4800FB',
    color: '#4800FB',
    fontFamily: 'inherit',
  },

  spacing: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  helperTxt: {
    color: '#7C7B7C',
    fontSize: '12px',
  },
  pText: {
    color: '#6B6B6B',
    fontSize: '16px',
  },
  h3Text: {
    color: '#111111',
    fontSize: '18px',
  },
  rightMargin: {
    marginRight: '4px',
  },
  optionTxt: {
    textTransform: 'capitalize',
    fontFamily: 'Lato',
    fontSize: '14px',
  },
  optionSelectedTxt: {
    textTransform: 'capitalize',
    fontFamily: 'Lato',
    fontSize: '14px',
  },
  paper: {
    border: '1px solid',

    padding: '8px',
    background: '#eee',
    maxWidth: '120px',
    maxHeight: '150px',
  },
  customTooltip: {
    maxWidth: 200,
  },
  progress: {
    color: 'white',
  },
  tooltipIcon: {
    color: '#8A8A8C',
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#4A4062',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
  },
  tooltip: {
    backgroundColor: '#4A4062',
    borderRadius: '10px',
    padding: '8px',
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const mapState = ({ product, auth }) => ({
  productConditions: product.productConditions,
  token: auth.token,
  profile: auth.profile,
});

const EditProducts = () => {
  const classes = useStyles();
  const history = useHistory();
  const { productConditions, token, profile } = useSelector(mapState);
  const dispatch = useDispatch();

  localStorage.setItem("pageName","Edit Product")
  useScript("./assets/pixel/home.js");

  useEffect(() => {
    dispatch(fetchProductConditions());
    return () => {};
  }, [dispatch]);

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  const [openFailureDialog, setOpenFailureDialog] = useState(false);

  const handleCLoseFailureDialog = () => {
    setOpenFailureDialog(false);
  };

  // From validation

  const[productDetails,setProductDetails] =  useState({})
  const [nameError, setnameError] = useState('');
  const [descriptionError, setdescriptionError] = useState('');
  const [colorError, setcolorError] = useState('');
  const [priceError, setpriceError] = useState('');
  const [maincategoryError, setmainCategoryError] = useState('');
  const [subcategoryError, setsubcategoryError] = useState('');
  const [conditionError, setconditionError] = useState('');
  const [quantityError, setquantityError] = useState('');
  const [imagesError, setimagesError] = useState('');
  const [draftsError, setdraftsError] = useState('');

  const [previewImage1, setPreviewImage1] = useState(null);
  const [fileImage1, setFileImage1] = useState(null);
  const [uploadedImage1, setUploadedImage1] = useState(null);

  const [previewImage2, setPreviewImage2] = useState(null);
  const [fileImage2, setFileImage2] = useState(null);
  const [uploadedImage2, setUploadedImage2] = useState(null);

  const [previewImage3, setPreviewImage3] = useState(null);
  const [fileImage3, setFileImage3] = useState(null);
  const [uploadedImage3, setUploadedImage3] = useState(null);

  const [previewImage4, setPreviewImage4] = useState(null);
  const [fileImage4, setFileImage4] = useState(null);
  const [uploadedImage4, setUploadedImage4] = useState(null);

  const [isImage1Selected, setisImage1Selected] = useState(false);
  const [isImage2Selected, setisImage2Selected] = useState(false);
  const [isImage3Selected, setisImage3Selected] = useState(false);
  const [isImage4Selected, setisImage4Selected] = useState(false);

  // let allImageFiles = [];

  const handleChangeImage1 = (e) => {
    setFileImage1(e.target.files[0]);
    setPreviewImage1(URL.createObjectURL(e.target.files[0]));
    setisImage1Selected(true);
    // clear images error
    setimagesError('');
  };

  const handleChangeImage2 = (e) => {
    setFileImage2(e.target.files[0]);
    setPreviewImage2(URL.createObjectURL(e.target.files[0]));
    setisImage2Selected(true);
    // allImageFiles.push(fileImage2);
  };
  const handleChangeImage3 = (e) => {
    setFileImage3(e.target.files[0]);
    setPreviewImage3(URL.createObjectURL(e.target.files[0]));
    setisImage3Selected(true);
    // allImageFiles.push(fileImage3);
  };
  const handleChangeImage4 = (e) => {
    setFileImage4(e.target.files[0]);
    setPreviewImage4(URL.createObjectURL(e.target.files[0]));
    setisImage4Selected(true);
    // allImageFiles.push(fileImage4);
  };

  /* 
  Fetch and set product categories 
  The subcategory options are dependent on their predecessor category.
  */

  // New implementation
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  useEffect( async () => {
    let isCancelled = false;

    const fetchData = async () => {
      const response = await axios.get(
        `${url}/products/attributes/category?id=0`
      );
      if (!isCancelled) {
        setCategoriesOptions(response.data.options);
      }
    };
    fetchData();

    const respo = await getProductDetails(localStorage.getItem("product_id")).then((k)=>k)
    setproductCondition(respo.data.conditionId)
    setproductName(respo.data.name)
    setproductSize(respo.data.size)
    setproductDesc(respo.data.description)
    setproductColor(respo.data.color)
    setProductPrice(respo.data.price)
    setproductQty(respo.data.quantity)
    setstoreId(respo.data.storeId)
    try{
      // console.log("images",respo.data.images[0].secure_url)
      
      setPreviewImage1(respo.data.images[0].secure_url)
      const uploadded =  respo.data.images[0].secure_url.split("product/")[1]
      // console.log("uploaded",uploadded)
      setUploadedImage1(uploadded)
      setisImage1Selected(true)
    }catch(error){
      
    }
    try{
  
      setPreviewImage2(respo.data.images[1].secure_url)
      const uploadded =  respo.data.images[1].secure_url.split("product/")[1]
      setUploadedImage2(uploadded)
      setisImage2Selected(true)
    }catch(error){
      
    }
    try{

      setPreviewImage3(respo.data.images[2].secure_url)
      const uploadded =  respo.data.images[2].secure_url.split("product/")[1]
      setUploadedImage3(uploadded)
      setisImage3Selected(true)
    }catch(error){
      
    }
    try{

      setPreviewImage4(respo.data.images[3].secure_url)
      const uploadded =  respo.data.images[3].secure_url.split("product/")[1]
      setUploadedImage4(uploadded)
      setisImage4Selected(true)
    }catch(error){
      
    }

    


    // console.log(respo)
    return () => (isCancelled = true);
  }, []);

  //categories input states
  const [mainCategory, setMainCategory] = useState(null);
  const [subCategoryOne, setSubCategoryOne] = useState(null);
  const [subCategoryTwo, setSubCategoryTwo] = useState(null);

  const [isMainCatSelected, setIsMainCatSelected] = useState(false);
  const [isSubCatOneSelected, setIsSubCatOneSelected] = useState(false);

  const mainCategoryOptions = categoriesOptions.filter(
    (category) => category.parent_id === 0
  );
  const subCategoryOneOptions = categoriesOptions.filter(
    (item) => item.parent_id == mainCategory
  );
  const subCategoryTwoOptions = categoriesOptions.filter(
    (item) => item.parent_id == subCategoryOne
  );

  const handleChangeMainCategory = (event) => {
    setMainCategory(event.target.value);
    setIsMainCatSelected(true);
  };
  const handleChangeSubCatOne = (event) => {
    setSubCategoryOne(event.target.value);
    setIsSubCatOneSelected(true);
  };
  const handleChangeSubCatTwo = (event) => {
    setSubCategoryTwo(event.target.value);
  };

  const [sizesOptions, setsizesOptions] = useState([]);
  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      const response = await axios.get(
        `${url}/products/attributes/sizes?category=${subCategoryOne}`
      );
      // console.log('SIZES:', response.data.options);
      const sizes = response.data.options;
      if (!isCancelled) {
        setsizesOptions(sizes);
      }
    };
    fetchData();
    return () => (isCancelled = true);
  }, [subCategoryOne]);

  const [fetchedColors, setfetchedColors] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    const attribute = 'COLORS';
    axios.get(`${url}/products/attributes/${attribute}`).then((response) => {
      const colors = response.data.options;
      if (!isCancelled) {
        setfetchedColors(colors);
      }
    });
    // .catch((error) => console.log(error));
    return () => (isCancelled = true);
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [productName, setproductName] = useState('');
  const [productCondition, setproductCondition] = useState('');
  // const [productConditionId, setproductConditionId] = useState('');
  const [productDesc, setproductDesc] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productQty, setproductQty] = useState('');
  const [productColor, setproductColor] = useState('');
  const [productSize, setproductSize] = useState('');
  const [storeId, setstoreId] = useState('');
  const [isSubmittingDelete,setisSubmittingDelete] =  useState(false)

  const setFormData = (imageFile) => {
    let file = imageFile;
    let formData = new FormData();
    formData.append('file', file);
    formData.append('tag', 'product');
    return formData;
  };

  useEffect(() => {
    if (fileImage1) {
      const formData = setFormData(fileImage1);
      const postForm = async () => {
        const response = await axios.post(
          `${url}/media/upload/image`,
          formData
        );
        const uploadedFile = response.data.fileName;
        // console.log(uploadedFie);
        setUploadedImage1(uploadedFile);
        // console.log('Image 1', uplloadedImage1);
      };
      postForm();
    }
  }, [fileImage1]);

  useEffect(() => {
    if (fileImage2) {
      const formData = setFormData(fileImage2);
      const postForm = async () => {
        const response = await axios.post(
          `${url}/media/upload/image`,
          formData
        );
        const uploadedFile = response.data.fileName;
        setUploadedImage2(uploadedFile);
      };
      postForm();
    }
  }, [fileImage2]);
  useEffect(() => {
    if (fileImage2) {
      const formData = setFormData(fileImage2);
      const postForm = async () => {
        const response = await axios.post(
          `${url}/media/upload/image`,
          formData
        );
        const uploadedFile = response.data.fileName;
        setUploadedImage2(uploadedFile);
      };
      postForm();
    }
  }, [fileImage2]);
  useEffect(() => {
    if (fileImage2) {
      const formData = setFormData(fileImage2);
      const postForm = async () => {
        const response = await axios.post(
          `${url}/media/upload/image`,
          formData
        );
        const uploadedFile = response.data.fileName;
        setUploadedImage2(uploadedFile);
      };
      postForm();
    }
  }, [fileImage2]);
  useEffect(() => {
    if (fileImage3) {
      const formData = setFormData(fileImage3);
      const postForm = async () => {
        const response = await axios.post(
          `${url}/media/upload/image`,
          formData
        );
        const uploadedFile = response.data.fileName;
        setUploadedImage3(uploadedFile);
      };
      postForm();
    }
  }, [fileImage3]);
  useEffect(() => {
    if (fileImage4) {
      const formData = setFormData(fileImage4);
      const postForm = async () => {
        const response = await axios.post(
          `${url}/media/upload/image`,
          formData
        );
        const uploadedFile = response.data.fileName;
        setUploadedImage4(uploadedFile);
      };
      postForm();
    }
  }, [fileImage4]);
  useEffect(() => {
    const store = localStorage.getItem('store');

    const storeID = store ? store : profile.storeId;

    setstoreId(storeID);
  }, []);

  const resetForm = () => {
    window.location.reload();
  };

  const viewListedProduct = () => {
    history.push('/my-profile/listed-items');
  };

  const continueListing = () => {
    resetForm();
    handleCloseSuccessDialog();
  };

  const handleSubmitPostDelete = (event) =>{
    event.preventDefault();
    setisSubmittingDelete(true);
    const product_id =  localStorage.getItem("product_id")

    axios
    .delete(`${url}/products/${product_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      // console.log('Data: ', response.data.message);
      // console.log('status', response.status);
      // setIsSubmitting(false);
      localStorage.setItem("product_id","")
      history.push('/my-profile/listed-items')
    })
    .catch((error) => {
      // console.log('err', error);
      setIsSubmitting(false);
      setOpenFailureDialog(true);
    });
  }
  const handleSubmitPost = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const allImages = [
      uploadedImage1,
      uploadedImage2,
      uploadedImage3,
      uploadedImage4,
    ];
    const uploadedImages = allImages.filter((item) => item);
    // console.log('All Images: ', uploadedImages);

    if (uploadedImages.length === 0) {
      setimagesError('At least one image is required.');
      setIsSubmitting(false);
      return;
    }
    if (!productName) {
      setnameError('Item name is required');
      setIsSubmitting(false);
      return;
    }
    if (!productDesc) {
      setdescriptionError('Item description is required');
      setIsSubmitting(false);
      return;
    }
    if (!productColor) {
      setcolorError('Item color is required');
      setIsSubmitting(false);
      return;
    }
    if (!productPrice) {
      setpriceError('Item price is required');
      setIsSubmitting(false);
      return;
    }
    if (!mainCategory) {
      setmainCategoryError('Item main category is required');
      setIsSubmitting(false);
      return;
    }
    if (!subCategoryOne) {
      setsubcategoryError('Item sub-category is required');
      setIsSubmitting(false);
      return;
    }
    if (!productQty) {
      setquantityError('Item quantity is required');
      setIsSubmitting(false);
      return;
    }
    if (!productCondition) {
      setconditionError('Item condition is required');
      setIsSubmitting(false);
      return;
    }
    const productDetails = {
      // condition: Number(productConditionId),
      condition: Number(productCondition),

      name: productName,
      description: productDesc,
      size: Number(productSize),
      color: Number(productColor),
      price: Number(productPrice),
      category: Number(subCategoryTwo)
        ? Number(subCategoryTwo)
        : Number(subCategoryOne)
        ? Number(subCategoryOne)
        : Number(mainCategory),
      quantity: Number(productQty),
      shouldList: true,
      images: uploadedImages.toString(),
    };

    // console.log(productDetails);
    const id_product =  localStorage.getItem("product_id")
    axios
      .put(`${url}/products/${id_product}`, productDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log('Data: ', response.data.message);
        // console.log('status', response.status);
        // setIsSubmitting(false);
        setOpenSuccessDialog(true);
      })
      .catch((error) => {
        // console.log('err', error);
        setIsSubmitting(false);
        setOpenFailureDialog(true);
      });
  };

  const [openTT, setOpenTT] = useState(false);

  const handleOpenTT = () => {
    setOpenTT(!openTT);
  };

  return (
    <section>
      {/* <Container maxWidth="md"> */}
      <div className="container">
        <form noValidate autoComplete="off">
          {productConditions && (
            <Grid container>
              <Grid container item>
                <Grid item xs={12}>
                  <header className="sctn-centered-header">
                    <h2 style={{ color: '#011111' }}>New item</h2>
                    {/* <a href="/my-products">Your Drafts</a> */}
                  </header>
                  <Divider />
                </Grid>

                <Grid container item spacing={1}>
                  <Grid item xs={12}>
                    <div className={classes.spacing}>
                      <h3 className={classes.h3Text}>Upload Photos</h3>
                      <p className={classes.pText}>
                        Add up to 4 photos in JPG or PNG format
                      </p>
                    </div>
                  </Grid>
                  {/* PRODUCT IMAGE 1 */}
                  <Grid item xs={12} sm={4} md={3} lg={3}>
                    <ProductImageInput
                      inputId="previewImage1"
                      inputName="previewImage1"
                      onChange={handleChangeImage1}
                      isImageSelected={isImage1Selected}
                      image={previewImage1}
                    />
                  </Grid>

                  {/*  PRODUCT IMAGE 2 */}
                  <Grid item xs={12} sm={4} md={3} lg={3}>
                    {isImage1Selected ? (
                      <ProductImageInput
                        inputId="previewImage2"
                        inputName="previewImage2"
                        onChange={handleChangeImage2}
                        isImageSelected={isImage2Selected}
                        image={previewImage2}
                      />
                    ) : (
                      <ProductImageDisabled />
                    )}
                  </Grid>

                  {/* PRODUCT IMAGE 3 */}
                  <Grid item xs={12} sm={4} md={3} lg={3}>
                    {isImage2Selected ? (
                      <ProductImageInput
                        inputId="previewImage3"
                        inputName="previewImage3"
                        onChange={handleChangeImage3}
                        isImageSelected={isImage3Selected}
                        image={previewImage3}
                      />
                    ) : (
                      <ProductImageDisabled />
                    )}
                  </Grid>

                  {/* PRODUCT IMAGE 4 */}
                  <Grid item xs={12} sm={4} md={3} lg={3}>
                    {isImage3Selected ? (
                      <ProductImageInput
                        inputId="previewImage4"
                        inputName="previewImage4"
                        onChange={handleChangeImage4}
                        isImageSelected={isImage4Selected}
                        image={previewImage4}
                      />
                    ) : (
                      <ProductImageDisabled />
                    )}
                  </Grid>
                </Grid>
                {imagesError && (
                  <div>
                    <p className="input-error">{imagesError}</p>
                  </div>
                )}

                <Grid container item spacing={1}>
                  <Grid item xs={12}>
                    <div className={classes.spacing}>
                      <h3 className={classes.h3Text}>Item details</h3>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <BaseInput
                      id="productName"
                      name="productName"
                      label="item name"
                      placeholder="e.g Rubber shoes"
                      value={productName}
                      onChange={(e) => setproductName(e.target.value)}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                    {nameError && <p className="input-error">{nameError}</p>}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <BaseInput
                      id="productCondition"
                      name="productCondition"
                      label="condition"
                      value={productCondition}
                      onChange={(e) => setproductCondition(e.target.value)}
                      select
                      fullWidth
                      variant="outlined"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option aria-label="None" value="" />
                      {productConditions &&
                        productConditions.map((item) => (
                          <option
                            key={item.id}
                            value={item.id}
                            className={classes.optionTxt}
                          >
                            {item.name}
                          </option>
                        ))}
                    </BaseInput>
                    {conditionError && (
                      <p className="input-error">{conditionError}</p>
                    )}
                  </Grid>

                  {/* COLOR */}
                  <Grid item xs={12} sm={6} md={4}>
                    <BaseInput
                      id="productColor"
                      name="productColor"
                      label="color"
                      value={productColor}
                      onChange={(e) => setproductColor(e.target.value)}
                      select
                      fullWidth
                      variant="outlined"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option aria-label="None" value="" />
                      {fetchedColors &&
                        fetchedColors.map((color) => (
                          <option
                            key={color.id}
                            value={color.id}
                            className={classes.optionTxt}
                          >
                            {color.name}
                          </option>
                        ))}
                    </BaseInput>
                    {colorError && <p className="input-error">{colorError}</p>}
                  </Grid>
                </Grid>

                {/* DESCRIPTION  */}
                <Grid container item>
                  <Grid item xs={12}>
                    <div className={classes.spacing}>
                      <h3 className={classes.h3Text}>Item description</h3>
                      <p className={classes.pText}>
                        Tell buyers about your item. Make sure to specify any
                        relevant information here.
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      id="description"
                      name="description"
                      rowsMin={8}
                      className={classes.txtArea}
                      value={productDesc}
                      onChange={(e) => setproductDesc(e.target.value)}
                    />
                    {descriptionError && (
                      <p className="input-error">{descriptionError}</p>
                    )}
                  </Grid>
                </Grid>

                <Grid container item spacing={1}>
                  <Grid item xs={12}>
                    <div className={classes.spacing}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <div>
                          <h3 className={classes.h3Text}>More information</h3>
                          <p className={classes.pText}>
                            Let buyers know the specifics of your item such as
                            category and price
                          </p>
                        </div>
                        <div>
                          <BootstrapTooltip
                            title="Please note that there will be a 15% commission added to your listing price. This caters to shipping kits, Buyer & Seller Protection among other costs and ensures that you have an exceptional experience on Lukhu."
                            placement="left-center"
                            className={classes.tooltip}
                            open={openTT}
                          >
                            <IconButton
                              aria-label="Please note that there will be a 15% commission added to your listing price. This caters to shipping kits, Buyer & Seller Protection among other costs and ensures that you have an exceptional experience on Lukhu."
                              placement="left-center"
                              onClick={handleOpenTT}
                            >
                              <InfoIcon className={classes.tooltipIcon} />
                            </IconButton>
                          </BootstrapTooltip>
                        </div>
                      </Box>
                    </div>
                  </Grid>

                  {/* CATEGORIES e.g Womenswear > Tops > Blouses */}
                  <Grid item xs={12} sm={6} md={4}>
                    <BaseInput
                      id="mainCategory"
                      name="mainCategory"
                      label=" main category "
                      value={mainCategory}
                      onChange={handleChangeMainCategory}
                      fullWidth
                      select
                      variant="outlined"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      style={{ fontSize: '8px' }}
                    >
                      <option aria-label="None" value="" />
                      {mainCategoryOptions.map((cat) => (
                        <option
                          key={cat.id}
                          value={cat.id}
                          className={classes.optionTxt}
                        >
                          {cat.name}
                        </option>
                      ))}
                    </BaseInput>
                    {maincategoryError && (
                      <p className="input-error">{maincategoryError}</p>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <BaseInput
                      id="subCategory1"
                      name="subCategory1"
                      label="Sub-category 1"
                      select
                      value={subCategoryOne}
                      onChange={handleChangeSubCatOne}
                      fullWidth
                      variant="outlined"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      disabled={isMainCatSelected ? false : true}
                    >
                      <option aria-label="None" value="" />
                      {subCategoryOneOptions &&
                        subCategoryOneOptions.map((cat) => (
                          <option
                            key={cat.id}
                            value={cat.id}
                            className={classes.optionTxt}
                          >
                            {cat.name}
                          </option>
                        ))}
                    </BaseInput>
                    {subcategoryError && (
                      <p className="input-error">{subcategoryError}</p>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <BaseInput
                      id="subCategory2"
                      name="subCategory2"
                      label="Sub-category 2"
                      select
                      value={subCategoryTwo}
                      onChange={handleChangeSubCatTwo}
                      fullWidth
                      variant="outlined"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      disabled={isSubCatOneSelected ? false : true}
                    >
                      <option aria-label="None" value="" />
                      {!subCategoryTwoOptions.length && (
                        <option value="">No sub-category available</option>
                      )}
                      {subCategoryTwoOptions &&
                        subCategoryTwoOptions.map((cat) => (
                          <option
                            key={cat.id}
                            value={cat.id}
                            className={classes.optionTxt}
                          >
                            {cat.name}
                          </option>
                        ))}
                    </BaseInput>
                  </Grid>

                  {/* SIZE */}
                  <Grid item xs={12} sm={6} md={4}>
                    <BaseInput
                      id="productSize"
                      name="productSize"
                      label="size"
                      value={productSize}
                      onChange={(e) => setproductSize(e.target.value)}
                      select
                      fullWidth
                      variant="outlined"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option aria-label="None" value="" />
                      {sizesOptions &&
                        sizesOptions.map((size) => (
                          <option
                            key={size.id}
                            value={size.id}
                            className={classes.optionTxt}
                          >
                            {size.sizeCategory + ' ' + size.value}
                          </option>
                        ))}

                      {!sizesOptions.length && (
                        <option value="" className={classes.optionTxt}>
                          Please select categories first.
                        </option>
                      )}
                    </BaseInput>
                  </Grid>

                  {/* PRICE */}
                  <Grid item xs={12} sm={6} md={4}>
                    <BaseInput
                      id="productPrice"
                      name="productPrice "
                      label="price"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={productPrice}
                      onChange={(e) => setProductPrice(e.target.value)}
                    />
                    {priceError && <p className="input-error">{priceError}</p>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <BaseInput
                      id="productQty"
                      name="productQty"
                      label="Product quantity "
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={productQty}
                      onChange={(e) => setproductQty(e.target.value)}
                    />
                    {quantityError && (
                      <p className="input-error">{quantityError}</p>
                    )}
                  </Grid>
                </Grid>

                <Grid container item spacing={1}>
                  <Grid item xs={12}>
                    {draftsError && (
                      <div className="mt-16">
                        <p className="input-error txt-center">{draftsError}</p>
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid container item>
                  <Grid item xs={12}>
                    <div className="list-product-btn">
                      <BaseBtn
                        className={classes.btnFilled}
                        onClick={handleSubmitPost}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <CircularProgress
                            className={classes.progress}
                            size={24}
                          />
                        )}
                        {!isSubmitting && (
                          <span>
                            Edit
                            <span style={{ textTransform: 'lowercase' }}>
                              {' '}
                              item
                            </span>
                          </span>
                        )}
                      </BaseBtn>


                      <BaseBtn
                        className={classes.btnFilledDelete}
                        onClick={handleSubmitPostDelete}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmittingDelete && (
                          <CircularProgress
                            className={classes.progress}
                            size={24}
                          />
                        )}
                        {!isSubmittingDelete && (
                          <span>
                            Delete
                            <span style={{ textTransform: 'lowercase' }}>
                              {' '}
                              item
                            </span>
                          </span>
                        )}
                      </BaseBtn>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </form>
        {/* </Container> */}
      </div>
      <ListProductsSuccess
        open={openSuccessDialog}
        handleClose={handleCloseSuccessDialog}
        continueListing={continueListing}
        viewProduct={viewListedProduct}
      />
      <ListProductsFail
        open={openFailureDialog}
        handleClose={handleCLoseFailureDialog}
      />
    </section>
  );
};

export default EditProducts;
