import {
  FETCH_DELIVERY_OPTIONS,
  CHECKOUT,
  SET_DELIVERY_OPTION,
  ADD_DELIVERY_ADDRESS_DETAILS_START,
  ADD_DELIVERY_ADDRESS_DETAILS_SUCCESS,
  ADD_DELIVERY_ADDRESS_DETAILS_FAIL,
  COMPLETE_PAYMENT_FAIL,
  COMPLETE_PAYMENT_START,
  COMPLETE_PAYMENT_SUCCESS,
  CREATE_ORDER_START,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  ADD_MPESA_NO,
  GET_SAVED_DELIVERY_ADDRESS_START,
  GET_SAVED_DELIVERY_ADDRESS_SUCCESS,
  GET_SAVED_DELIVERY_ADDRESS_FAIL,
  ADD_CARD_DETAILS,
  FETCH_MTAANI_REGIONS_START,
  FETCH_MTAANI_REGIONS_SUCCESS,
  FETCH_MTAANI_REGIONS_FAIL,
  FETCH_MTAANI_ZONES_START,
  FETCH_MTAANI_ZONES_SUCCESS,
  FETCH_MTAANI_ZONES_FAIL,
  FETCH_MTAANI_STORES_SUCCESS,
  FETCH_MTAANI_STORES_START,
  FETCH_MTAANI_STORES_FAIL,
  GET_DELIVERY_DETAILS_START,
  GET_DELIVERY_DETAILS_SUCCESS,
  GET_DELIVERY_DETAILS_FAIL,
  SET_PICKUP_STORE,
} from './checkoutTypes';

import { log_firebase_event } from '../../common/FirebaseCommon';
import { BEGIN_CHECKOUT_PROCESS_FIREBASE } from '../../common/firebaseConstants';

const initialState = {
  deliveryOptions: null,
  isCartCheckedout: false,

  hasDeliveryDetails: false, // ==> update when one enters delivery address or selectes one
  deliveryId: localStorage.getItem('DEL_ID'),
  deliveryIdLoading: false,
  deliveryIdErr: null,
  deliveryAddress: null,
  completePaymentLoading: false,
  completePaymentSucess: null,
  completePaymentErr: null,
  checkedOutCart: localStorage.getItem('CHECKOUT'),

  delDetailsLoading: false,
  delDetails: null,
  delDetailsErr: null,

  pickupStoreDetails: localStorage.getItem('P_STORE'),

  orderLoading: false,
  orderCreated: false,
  orderId: localStorage.getItem('ORD_ID'),
  orderItemsTotal: null,
  deliveryFee: null,
  orderSubTotal: null,
  orderGrandTotal: null,
  orderErr: null,

  mobileMoneyNo: null,
  phoneNumber: null,
  paymentType: localStorage.getItem('PAY_OPT'),

  cardNumber: null,
  cvv: null,
  cardExpiry: null,
  fName: null,
  mName: null,
  lName: null,

  savedDeliveryAddressLoading: false,
  savedDeliveryAddress: null,
  savedDeliveryAddressErr: null,

  mtaaniLoading: false,
  mtaaniError: null,
  mtaaniRegions: null,
  mtaaniZones: null,
  mtaaniStores: null,

  selectedDeliveryType: JSON.parse(localStorage.getItem('DELIVERY')),
  // profile: JSON.parse(localStorage.getItem('_PRF')),
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DELIVERY_OPTIONS: {
      return {
        deliveryOptions: action.payload,
      };
    }

    case CHECKOUT: {

      log_firebase_event(
        BEGIN_CHECKOUT_PROCESS_FIREBASE,
        {
          checkedOutCart: action.payload
        }
      )
      return {
        ...state,
        isCartCheckedout: true,
        checkedOutCart: action.payload,
      };
    }

    case ADD_DELIVERY_ADDRESS_DETAILS_START: {
      return {
        ...state,
        deliveryIdLoading: true,
        delDetailsLoading: true,
      };
    }

    case ADD_DELIVERY_ADDRESS_DETAILS_SUCCESS: {
      log_firebase_event(
        ADD_DELIVERY_ADDRESS_DETAILS_SUCCESS,
        {
          deliveryIdLoading: false,
        delDetailsLoading: false,
        deliveryId: action.payload.delID,
        deliveryAddress: action.payload.delAddress,
        delDetails: action.payload.delAddress,
        hasDeliveryDetails: true
        }

      )
      return {
        ...state,
        deliveryIdLoading: false,
        delDetailsLoading: false,
        deliveryId: action.payload.delID,
        deliveryAddress: action.payload.delAddress,
        delDetails: action.payload.delAddress,
        hasDeliveryDetails: true,
      };
    }
    case ADD_DELIVERY_ADDRESS_DETAILS_FAIL: {
      return {
        ...state,
        deliveryIdLoading: false,
        deliveryIdErr: action.payload,
      };
    }
    case COMPLETE_PAYMENT_START: {
      return {
        ...state,
        completePaymentLoading: true,
      };
    }
    case COMPLETE_PAYMENT_SUCCESS: {
      return {
        ...state,
        completePaymentLoading: false,
        completePaymentSucess: true,
      };
    }
    case COMPLETE_PAYMENT_FAIL: {
      return {
        ...state,
        completePaymentLoading: false,
        completePaymentErr: action.payload,
      };
    }
    case SET_DELIVERY_OPTION: {
      return {
        ...state,
        hasDeliveryDetails: true,
        selectedDeliveryType: action.payload,
      };
    }
    case CREATE_ORDER_START: {
      return {
        ...state,
        orderLoading: true,
      };
    }

    case CREATE_ORDER_SUCCESS: {
      log_firebase_event(
        CREATE_ORDER_SUCCESS,
        {
          orderId: action.payload.orderId,
        orderItemsTotal: action.payload.orderItemsTotal,
        deliveryFee: action.payload.deliveryFee,
        orderSubTotal: action.payload.orderSubTotal,
        orderGrandTotal: action.payload.orderGrandTotal,
        paymentType: action.payload.paymentOption,
        }
      )
      return {
        ...state,
        orderLoading: false,
        orderCreated: true,
        orderId: action.payload.orderId,
        orderItemsTotal: action.payload.orderItemsTotal,
        deliveryFee: action.payload.deliveryFee,
        orderSubTotal: action.payload.orderSubTotal,
        orderGrandTotal: action.payload.orderGrandTotal,
        paymentType: action.payload.paymentOption,
      };
    }

    case CREATE_ORDER_FAIL: {
      return {
        ...state,
        orderLoading: false,
        orderErr: action.payload,
      };
    }

    case ADD_MPESA_NO: {
      log_firebase_event(
        ADD_MPESA_NO,
        {
          phoneNumber: action.payload 
        }
      )
      return {
        ...state,
        phoneNumber: action.payload,
      };
    }
    case ADD_CARD_DETAILS: {
      log_firebase_event(
        ADD_CARD_DETAILS,
        {
          cardExpiry: action.payload.cardExpiry,
          fName: action.payload.firstName,
          mName: action.payload.middleName,
          lName: action.payload.lastName
        }
      )
      return {
        ...state,
        cardNumber: action.payload.cardNumber,
        cvv: action.payload.cvv,
        cardExpiry: action.payload.cardExpiry,
        fName: action.payload.firstName,
        mName: action.payload.middleName,
        lName: action.payload.lastName,
      };
    }
    case GET_SAVED_DELIVERY_ADDRESS_START: {
      return {
        ...state,
        savedDeliveryAddressLoading: true,
      };
    }
    case GET_SAVED_DELIVERY_ADDRESS_SUCCESS: {
      return {
        ...state,
        savedDeliveryAddressLoading: false,
        savedDeliveryAddress: action.payload,
      };
    }
    case GET_SAVED_DELIVERY_ADDRESS_FAIL: {
      return {
        ...state,
        savedDeliveryAddressLoading: false,
        savedDeliveryAddressErr: action.payload,
      };
    }
    case FETCH_MTAANI_REGIONS_START:
    case FETCH_MTAANI_ZONES_START:
    case FETCH_MTAANI_STORES_START: {
      return {
        ...state,
        mtaaniLoading: true,
      };
    }
    case FETCH_MTAANI_REGIONS_SUCCESS: {
      return {
        ...state,
        mtaaniLoading: false,
        mtaaniRegions: action.payload,
      };
    }
    case FETCH_MTAANI_ZONES_SUCCESS: {
      return {
        ...state,
        mtaaniLoading: false,
        mtaaniZones: action.payload,
      };
    }
    case FETCH_MTAANI_STORES_SUCCESS: {
      return {
        ...state,
        mtaaniLoading: false,
        mtaaniStores: action.payload,
      };
    }
    case FETCH_MTAANI_ZONES_FAIL:
    case FETCH_MTAANI_REGIONS_FAIL:
    case FETCH_MTAANI_STORES_FAIL: {
      return {
        ...state,
        mtaaniLoading: false,
        mtaaniError: action.payload,
      };
    }

    case GET_DELIVERY_DETAILS_START: {
      return {
        ...state,
        delDetailsLoading: true,
      };
    }
    case GET_DELIVERY_DETAILS_SUCCESS: {
      return {
        ...state,
        delDetailsLoading: false,
        delDetails: action.payload,
      };
    }
    case GET_DELIVERY_DETAILS_FAIL: {
      return {
        ...state,
        delDetailsLoading: false,
        delDetailsErr: action.payload,
      };
    }
    case SET_PICKUP_STORE: {
      log_firebase_event(
        SET_PICKUP_STORE,
        {
          pickupStoreDetails: action.payload.store,
          deliveryId: action.payload.delID,
        }

      )
      return {
        ...state,
        pickupStoreDetails: action.payload.store,
        deliveryId: action.payload.delID,
      };
    }
    default:
      return state;
  }
};

export default checkoutReducer;
