import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Route,
  useRouteMatch,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { Container, Paper, Grid, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// nav icons
import {
  Storefront,
  Description,
  AccountBalanceWallet,
  Assessment,
  Feedback,
  Folder,
  Settings,
  Help,
  AccountCircle,
} from '@material-ui/icons';

import MerchantProfileCard from '../../parts/Shops/MerchantProfileCard';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import MerchantAdminPanelSelling from './MerchantAdminPanelSelling';
import MerchantAdminPanelSold from './MerchantAdminPanelSold';
import MerchantAdminPanelFeedback from './MerchantAdminPanelFeedback';
import MerchantAdminPanelWallet from './MerchantAdminPanelWallet';
import MerchantAdminPanelWithdraw from './MerchantAdminPanelWithdraw';
import MerchantAdminPanelDrafts from './MerchantAdminPanelDrafts';
// import MerchantAdminPanelHelp from './MerchantAdminPanelHelp';
import MerchantAdminPanelStatistics from './MerchantAdminPanelStatistics';
import ComingSoon from '../ComingSoon';
import {
  fetchMerchantStoreProducts,
  fetchMerchantDraftProducts,
  fetchMerchantSoldProducts,
  fetchMerchantStoreDetails,
  fetchMerchantStoreStats,
} from '../../redux/merchant/merchantActions';
import { getYear } from '../../_helpers/get-year';
import WalletTopup from '../../parts/Shops/WalletTopup';
import {
  fetchWallet,
  fetchWalletTransactions,
} from '../../redux/wallet/walletActions';
import MerchantEditProfile from './MerchantEditProfile';
import UserProfile from '../../pages/Profile/UserProfile';

const useStyles = makeStyles((theme) => ({
  navCtn: {
    width: '',
  },

  bg: {
    backgroundColor: 'white',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    minHeight: '100%',
    borderRadius: '15px',
    padding: '26px 0',
    // width: '100%',
  },
  root: {
    background: 'transparent',
    marginTop: '24px',
    // padding: '8px',
  },
  notselectedList: {
    color: '#898989',
    '&:hover': {
      color: '#481CFB',
      fontWeight: 700,
    },
  },
  selectedList: {
    borderRight: '4px solid #481CFB',
    color: '#481CFB',
    fontWeight: 700,
  },
  listText: {
    color: 'inherit',
  },
  listIcon: {
    color: 'inherit',
    minWidth: '32px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inherit',
    },
  },
  sectionMobile: {
    display: 'inherit',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const mapState = ({ merchant, auth, wallet, profile }) => ({
  isLoggedIn: auth.isLoggedIn,
  token: auth.token,
  profile: auth.profile,
  storeListedProducts: merchant.storeListedProducts,
  storeListedProductsLoading: merchant.storeListedProductsLoading,
  storeListedProductsErr: merchant.storeListedProductsErr,

  storeSoldProducts: merchant.storeSoldProducts,
  storeSoldProductsLoading: merchant.storeSoldProductsLoading,
  storeSoldProductsErr: merchant.storeSoldProductsErr,

  storeDraftProducts: merchant.storeDraftProducts,
  storeDraftProductsLoading: merchant.storeDraftProductsLoading,
  storeDraftProductsErr: merchant.storeDraftProductsErr,

  storeDetailsPrivate: merchant.storeDetailsPrivate,
  storeDetailsPublic: merchant.storeDetailsPublic,

  walletLoading: wallet.loading,
  walletBalance: wallet.balance,
  walletPendingBalance: wallet.pendingBalance,
  walletErr: wallet.fetchErr,

  walletTransactions: wallet.transactions,
  walletTransactionsLoading: wallet.transactionsLoading,
  walletTransactionsErr: wallet.transactionsErr,
});

const AdminNav = ({ handleClick }) => {
  const classes = useStyles();
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const { profile, token } = useSelector(mapState);

  const navLinks = [
    {
      linkIcon: <AccountCircle />,
      linkTitle: 'My Profile',
      linkRoute: `${url}`,
    },
    {
      linkIcon: <Storefront />,
      linkTitle: 'My Shop',
      linkRoute: `${url}/listed-items`,
    },
    {
      linkIcon: <Description />,
      linkTitle: 'Sold Items',
      linkRoute: `${url}/sold-items`,
    },
    {
      linkIcon: <AccountBalanceWallet />,
      linkTitle: 'My Wallet',
      linkRoute: `${url}/wallet`,
    },
    {
      linkIcon: <Assessment />,
      linkTitle: 'Shop Stats',
      linkRoute: `${url}/statistics`,
    },
    {
      linkIcon: <Feedback />,
      linkTitle: 'Feedback',
      linkRoute: `${url}/feedback`,
    },
  ];

  // const handleSellNow = () => {
  //   history.push('/list-product');
  // };
  const handleSellNow = () => {


    // const loggedIn = isLoggedIn;

    if (token && profile.hasStore) {
      // TODO: push to upload products
      history.push('/list-product');
    } else if (token && !profile.hasStore) {
      history.push('/setup-store');
    } else {
      history.push('/auth');
    }
  };

  const [selectedIndex, setSelectedIndex] = useState(url);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <List component="nav" aria-label="merchant admin panel pages">
      {navLinks.map((item, i) => (
        <ListItem
          button
          component={Link}
          to={item.linkRoute}
          key={i}
          classes={{
            root: classes.notselectedList,
            selected: classes.selectedList,
          }}
          onClick={(event) => handleListItemClick(event, item.linkRoute)}
          selected={selectedIndex === item.linkRoute}
        >
          <ListItemIcon classes={{ root: classes.listIcon }}>
            {item.linkIcon}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={item.linkTitle}
            classes={{ root: classes.listText }}
          />
        </ListItem>
      ))}
      <ListItem
        component="a"
        href="https://lukhu.helpscoutdocs.com/"
        target="_blank"
        classes={{
          root: classes.notselectedList,
          selected: classes.selectedList,
        }}
        // onClick={(event) => handleListItemClick(event, item.linkRoute)}
        // selected={selectedIndex === item.linkRoute}
      >
        <ListItemIcon classes={{ root: classes.listIcon }}>
          <Help />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary="Help center"
          classes={{ root: classes.listText }}
        />
      </ListItem>
      <div className="flex-center mt-24 mb-24">
        <BaseBtnFilled
          style={{ width: '150px', padding: '4px 16px' }}
          onClick={handleSellNow}
        >
          Sell Now
        </BaseBtnFilled>
      </div>
    </List>
  );
};

AdminNav.propTypes = {
  handleClick: PropTypes.func,
};

const MerchantAdminPanel = () => {
  const classes = useStyles();
  const history = useHistory();
  const[isVerified,setVerified] = useState(false)

  const dispatch = useDispatch();
  const {
    isLoggedIn,
    storeListedProducts,
    storeSoldProducts,
    storeDraftProducts,
    storeDetailsPrivate,
    storeDetailsPublic,
    walletLoading,
    walletBalance,
    walletErr,
    walletPendingBalance,
    walletTransactions,
    walletTransactionsErr,
    walletTransactionsLoading,
    token,
    profile
  } = useSelector(mapState);

  useEffect(() => {
    dispatch(fetchWallet());
    return () => {};
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchWalletTransactions());
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMerchantStoreProducts());
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMerchantSoldProducts());
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMerchantDraftProducts());
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMerchantStoreDetails());
    return () => {};
  }, [dispatch]);

  useEffect(()=>{
    setVerified(profile.isPhoneVerified);
  },[profile]
  )

  // console.log("store details",profile)
  const { url, path } = useRouteMatch();
  const navLinks = [
    {
      linkIcon: <AccountCircle />,
      linkTitle: 'My Profile',
      linkRoute: `${url}`,
    },
    {
      linkIcon: <Storefront />,
      linkTitle: 'My Shop',
      linkRoute: `${url}/listed-items`,
    },
    {
      linkIcon: <Description />,
      linkTitle: 'Sold Items',
      linkRoute: `${url}/sold-items`,
    },
    {
      linkIcon: <AccountBalanceWallet />,
      linkTitle: 'My Wallet',
      linkRoute: `${url}/wallet`,
    },
    {
      linkIcon: <Assessment />,
      linkTitle: 'Shop Stats',
      linkRoute: `${url}/statistics`,
    },
    {
      linkIcon: <Feedback />,
      linkTitle: 'Feedback',
      linkRoute: `${url}/feedback`,
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(url);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleEditProfile = () => {
    // history.push(`${shopPathname}/profile`);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    handleMobileMenuClose();
  };
  const mobileMenuId = 'merchant-admin-menu-mobile';

  const renderMobileNavMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {navLinks.map((item, i) => (
        <MenuItem
          button
          component={Link}
          to={item.linkRoute}
          key={i}
          classes={{
            root: classes.notselectedList,
            selected: classes.selectedList,
          }}
          // onClick={handleMobileMenuClose}
          onClick={(event) => handleListItemClick(event, item.linkRoute)}
          selected={selectedIndex === item.linkRoute}
        >
          <ListItemIcon classes={{ root: classes.listIcon }}>
            {item.linkIcon}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={item.linkTitle}
            classes={{ root: classes.listText }}
          />
        </MenuItem>
      ))}
    </Menu>
  );

  if (!token) return <Redirect to="/auth" />;

  return (
    <section>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <div className={classes.bg}>
              <div className={classes.sectionDesktop}>
                <AdminNav />
              </div>

              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show menu"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Switch>
              <Route exact path={path}>
                <UserProfile />
              </Route>
              <Route path={`${path}/listed-items`}>
                <MerchantAdminPanelSelling products={storeListedProducts} />
              </Route>
              <Route path={`${path}/sold-items`}>
                <MerchantAdminPanelSold products={storeSoldProducts} />
              </Route>
              <Route path={`${path}/feedback`}>
                <MerchantAdminPanelFeedback />
              </Route>
              <Route path={`${path}/wallet`}>
                <MerchantAdminPanelWallet
                  loading={walletLoading}
                  availableBalance={walletBalance}
                  pendingBalance={walletPendingBalance}
                  fetchErr={walletErr}
                  transactions={walletTransactions}
                  transactionsErr={walletTransactionsErr}
                  transactionsLoading={walletTransactionsLoading}
                />
              </Route>
              <Route path={`${path}/wallet/withdraw`}>
                <MerchantAdminPanelWithdraw />
              </Route>

              <Route path={`${path}/statistics`}>
                <MerchantAdminPanelStatistics />
              </Route>

              <Route path={`${path}/edit-store`}>
                <MerchantEditProfile />
              </Route>
            </Switch>
          </Grid>
          <Grid item xs={12} md={4}>
            {storeDetailsPrivate && storeDetailsPublic && (
              <MerchantProfileCard
                verified={isVerified}
                name={storeDetailsPrivate.businessName}
                joinDate={getYear(storeDetailsPrivate.createdOn)}
                description={storeDetailsPrivate.description}
                image={storeDetailsPrivate.image}
                location={storeDetailsPrivate.location}
                followers={storeDetailsPublic.numberOfFollowers}
                following={storeDetailsPublic.numberFollowing}
                reviews={storeDetailsPublic.numberOfRatings}
                transactions={storeDetailsPublic.numberOfTransactions}
                rating={storeDetailsPublic.rating}
                handleEditProfile={handleEditProfile}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      {renderMobileNavMenu}
    </section>
  );
};

export default MerchantAdminPanel;
