import React from 'react'
import YourBag from '../components/headers/YourBag'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CartItems from '../../components/CartItems';
import { useDispatch, useSelector } from 'react-redux';



const useStyles = makeStyles((theme) => ({
  ctn: {
    paddingTop: '32px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  section1: {
    padding: '16px 0 0 32px',
  },
  section2: {
    padding: '24px 8px',
  },
  pageTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '1.5rem',
    textTransform: 'capitalize',
    fontWeight: 700,
  },

  stepContent: {
    borderLeft: '2px solid #E8E8E8',
    marginTop: 0,
    paddingRight: 0,
  },

  iconSpace: {
    marginRight: '4px',
  },
  stepComplete: {
    background: '#4A4062',
    color: '#FFFFFF',
    padding: '16px ',
    borderRadius: '4px',
  },
  stepIncomplete: {
    background: '#EBEBEB',
    color: '#525252',
    padding: '16px ',
    borderRadius: '4px',
  },
  customStepIcon: {
    color: '#E8E8E8',
    '&.MuiStepIcon-active': {
      color: '#3EA636',
    },
    '&.MuiStepIcon-completed': {
      color: '#3EA636',
    },
  },
  stepperRoot: {
    paddingTop: 0,
  },
  stepLabel: {
    // color: '#525252',
    background: '#F8F8F8',
    color: '#707071',
    padding: '16px ',
    borderRadius: '4px',
    '&.MuiStepLabel-active': {
      color: '#525252',
      background: '#EBEBEB',
    },
    '&.MuiStepLabel-completed': {
      background: '#4A4062',
      color: '#FFFFFF',
    },
  },
  contentCollapse: {
    background: '#FAF8FF',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  // temp
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },

  mobileRoot:{
    width:"100%",
    height:"55px",
    background:"#D81E5B",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    borderRadius:"0px"
  },
  mobileBox1:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    fontSize:"16px",
    width:"60%"
  },
  mobileBox2:{
    width:"40%"
  },
  cancelIcon:{
    color:"#fff",
    marginLeft:"10px"
  },
  bagText:{
    color:"#fff",
    font: "normal normal bold 16px/19px Montserrat",
    padding:"15px"

  },
  displayF:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    borderRadius:"0px"
   
    
    
  },
  displayF1:{
    display:"flex",
    alignItems:"right",

      
  },
  displayF12:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    marginTop:"10px",
    marginBottom:"15px"
  },
  tterte:{
    fontSize:"13px",
    color:"#111111",
    marginLeft:"10px",
    font:"Lato",
    padding:"15px"

  },
  ttrw:{
    color:"#491DFF",
    fontSize:"12px",
    marginRight:"10px",
    padding:"15px"
  },
  yyy:{
    width:"40px",
    height:"40px",
    borderRadius:"100%",
    marginLeft:"10px",
    marginTop:"10px",
    marginBottom:"10px"
  },
  rrwww:{
    marginLeft:"10px",
    color:"#4A4062",
    fontSize:"14px",
    padding:"15px",
    textAlign:"center"
  },
  rrwww1:{
    color:"4A4062",
    marginTop:"10px"
    
  },
  rqqt:{
    width:"150px",
    height:"150px",
  
  },
  v1:{
    fontSize:"12px",
    color:"#111111",
    font:"normal normal bold 9px/19px Lato",
    marginLeft:"13px",
    
  },

  v3:{
    fontSize:"12px",
    color:"#111111",
    font:"normal normal bold 9px/19px Lato",
    marginLeft:"13px",
    
  },

  v4:{
    fontSize:"12px",
    color:"#111111",
    font:"normal normal bold 9px/19px Lato",
    marginRight:"13px",
    
  },
  b1:{
    width:"150px"
  },
  v2:{
    fontSize:"12px",
    color:"#707070",
    marginRight:"10px",
    font:"normal normal normal 9px/19px Lato",
   
  },
  divHl:{
    width:"100%",
    height:"2px",
    marginTop:"10px",
    background:"#F5F5F5",
    marginLeft:"5px",
    marginRight:"5px"
  },
  btnCheckout:{
    width:"90%",
    padding:"15px",
    color:"#fff",
    background:" #4900FF",
    marginTop:"15px",
    marginLeft:"15px",
    marginRight:"15px",
    marginBottom:"18px",
    textTransform:"none",
    font:"bold normal normal 19px Lato",
  },
  ppclas:{
    width:"150px",
    margin:"10px",
    borderRadius:"5px"
  }
}));

const mapState = ({ checkout , cart }) => ({
  delDetailsLoading: checkout.delDetailsLoading,
  delDetails: checkout.delDetails,
  delDetailsErr: checkout.delDetailsErr,
  cartItemCheckout : cart.cartItems,
  shoppingBagItems: cart.shoppingBagItems,
});

function CheckOutPageMobile() {
  const classes = useStyles();
  const {  cartItemCheckout } = useSelector(mapState);

 
    return(
      <section>
        <YourBag name={"YOUR BAG"}></YourBag>

        <Paper className={classes.displayF}>
          <Typography className={classes.tterte} variant='h6'><strong>3</strong> items from <strong>2</strong> shop</Typography>

          <Button className={classes.ttrw} variant="text" >Edit your Bag</Button>

        </Paper>


        <div className={classes.vogery}>

          {cartItemCheckout.map((car)=>(
            <CartItems cart_total = {car.cart_total} items = {car.items}  ></CartItems>
          ))}
        
       
        </div>

        
      </section>
    )
  
}

export default CheckOutPageMobile