import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';

import MerchantListedProducts from './MerchantListedProducts';
import MerchantLikes from './MerchantLikes';
import MerchantFeeback from './MerchantFeeback';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box p={3}>
        //   <Typography>{children}</Typography>
        // </Box>
        <div>{children}</div>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '90%',
    margin: '0 auto',
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // },
  },
  appBar: {
    color: '#707070',
    boxShadow: 'none',
  },
  label: {
    textTransform: 'capitalize',
    fontFamily: ['Lato', 'sans-serif'].join(', '),
    fontSize: '0.875rem',
  },
  panel: {
    paddingTop: '32px',
    paddingBottom: '24px',
  },
}));
const MerchantProfileTabs = ({ merchantSelling, merchantName }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    // <div className={classes.root}>
    <Container maxWidth="lg">
      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Merchant's products, likes and feedback"
        >
          <Tab
            value="one"
            label="Selling"
            wrapped
            {...a11yProps('one')}
            className={classes.label}
          />
          <Tab
            value="two"
            label="Likes"
            {...a11yProps('two')}
            className={classes.label}
          />
          <Tab
            value="three"
            label="Feedback"
            {...a11yProps('three')}
            className={classes.label}
          />
        </Tabs>
      </AppBar>
      <Divider />
      <TabPanel value={value} index="one" className={classes.panel}>
        <MerchantListedProducts
          listedProducts={merchantSelling}
          merchantName={merchantName}
        />
      </TabPanel>
      <TabPanel value={value} index="two" className={classes.panel}>
        <MerchantLikes merchantName={merchantName} />
      </TabPanel>
      <TabPanel value={value} index="three" className={classes.panel}>
        <MerchantFeeback merchantName={merchantName} />
      </TabPanel>
    </Container>
    // </div>
  );
};

MerchantProfileTabs.propTypes = {
  merchantSelling: PropTypes.array.isRequired,
  merchantName: PropTypes.string.isRequired,
};
export default MerchantProfileTabs;
