import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

import { TiSocialLinkedin } from 'react-icons/ti';
import { AiOutlineInstagram } from 'react-icons/ai';
import { TiSocialTwitter } from 'react-icons/ti';
import { TiSocialFacebook } from 'react-icons/ti';

import lukhu_logo from '../../assets/images/footer_logo.svg';
import appstore_badge from '../../assets/images/app-store-badge.png';
import googleplay_badge from '../../assets/images/google-play-badge.png';

const useStyles = makeStyles({
  linkGrids: {
    width: '90%',
    margin: '0 auto',
  },
  divider: {
    backgroundColor: '#ffffff',
  },
  fhead: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5%',
    paddingBottom: '32px',
  },
  flogo: {
    width: '20%',
  },
  hr: {
    width: '100%',
    border: 'none',
    borderBottom: '1px solid #ffffff',
  },
  icons: {
    color: '#ffffff',
    fontSize: '24px',
  },
  spacing: {
    lineHeight: 2,
  },
});
const FooterLinks = ({ title, children }) => {
  return (
    <>
      <div>
        <h4 className="txt-uppercase f-14 mb-12">{title}</h4>
        {children}
      </div>
    </>
  );
};

FooterLinks.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className="footer">
      <div className={classes.fhead}>
        <div className={classes.flogo}>
          <img src={lukhu_logo} alt="Lukhu app logo" />
        </div>
        <hr className={classes.hr} />
      </div>
      <Grid container spacing={2} className={classes.linkGrids}>
        <Grid item xs={6} sm={4} md={2}>
          <FooterLinks title="About us">
            <Link to="/">
              <p className={classes.spacing}>About Lukhu</p>
            </Link>
            <a
              href="https://lukhu.helpscoutdocs.com/"
              target="_blank"
              rel="noreferrer"
            >
              <p className={classes.spacing}> Our Blog</p>
            </a>
            <a
              href="https://lukhu.medium.com/"
              target="_blank"
              rel="noreferrer"
            >
              <p className={classes.spacing}>Help Center</p>
            </a>
            <a
              href=" https://lukhu.helpscoutdocs.com/article/7-terms-and-conditions"
              target="_blank"
              rel="noreferrer"
            >
              <p className={classes.spacing}>Terms & Conditions</p>
            </a>
            <a
              href="https://lukhu.helpscoutdocs.com/article/5-privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              <p className={classes.spacing}>Privacy Policy</p>
            </a>
          </FooterLinks>
        </Grid>

        <Grid item xs={6} sm={4} md={2}>
          <FooterLinks title="CONTACT US">
            <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Lukhu LTD.,</p>
            <p className={classes.spacing}>+254 721 515 912</p>
            <p className={classes.spacing}>+254 726 885 929</p>
            <p
              className={classes.spacing}
              style={{ textTransform: 'lowercase' }}
            >
              info@lukhu.co.ke
            </p>
          </FooterLinks>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="space-between"
            height="100%"
          >
            <div>
              <a
                href="https://linkedin.com/company/lukhu"
                target="_blank"
                rel="noreferrer"
              >
                <TiSocialLinkedin className={classes.icons} />
              </a>
              <a
                href="https://instagram.com/lukhu.app"
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineInstagram className={classes.icons} />
              </a>
              <a
                href="https://fb.me/teamlukhu"
                target="_blank"
                rel="noreferrer"
              >
                <TiSocialFacebook className={classes.icons} />
              </a>
              <a
                href="https://twitter.com/LukhuHQ"
                target="_blank"
                rel="noreferrer"
              >
                <TiSocialTwitter className={classes.icons} />
              </a>
            </div>
            <div className="d-flex mb-8">
              <a
                href="https://play.google.com/store/apps/details?id=com.kalu.lukhu"
                target="_blank"
                rel="noreferrer"
                className="app-logo"
              >
                <img
                  src={googleplay_badge}
                  alt="download from google play store"
                />
              </a>
              <img
                src={appstore_badge}
                alt="download from app store"
                className="app-logo"
              />
            </div>
            <div>
              <Link to="/">
                <span className="f-12 txt-lato txt-bold">www.lukhu.co.ke</span>
              </Link>
            </div>
          </Box>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
