import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MerchantProductCard from './MerchantProductCard';
import EmptyDisplay from './EmptyDisplay';
import { sampleListing, sampleEmpty } from '../Home/dummyData';

const displayMessage = 'hasn’t liked anything yet.';

const MerchantLikes = ({ merchantName }) => {
  const [likedProducts, setLikedProducts] = useState([]);
  useEffect(() => {
    const fetchedProducts = [...sampleEmpty];
    setLikedProducts(fetchedProducts);
  }, []);
  return (
    <>
      {likedProducts.length ? (
        <div className="c5-grid">
          {sampleListing.map((item, i) => (
            <MerchantProductCard
              key={i}
              picture={item.productImg}
              price={item.productPrice}
              size={item.productSize}
            />
          ))}
        </div>
      ) : (
        <EmptyDisplay
          merchantName={merchantName}
          displayMessage={displayMessage}
        />
      )}
    </>
  );
};

MerchantLikes.propTypes = {
  merchantName: PropTypes.string.isRequired,
};

export default MerchantLikes;
