import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '16px',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    color: '#4822FB',
    textDecoration: 'underline',
    fontSize: '0.875rem',
  },
}));
const GoBackLink = () => {
  const classes = useStyles();
  return (
    <Typography className={classes.root}>
      <Link to="/">Go Back</Link>
    </Typography>
  );
};

export default GoBackLink;
