import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';

import BaseInput2 from '../../components/BaseInput2';
import BaseBtn from '../../components/BaseBtn';
import BaseCard from '../../components/BaseCard';
import { formatPhoneNumber } from '../../_helpers/format-phone';
import { requestPwdReset } from '../../redux/auth/authActions';
import flag_icon from '../../assets/images/flag_icon.jpg';

const useStyles = makeStyles({
  disabledBtn: {
    backgroundColor: '#E2E2E2',
    '&:hover': {
      backgroundColor: '#E2E2E2',
    },
  },
  activeBtn: {
    backgroundColor: '#4900ff',
    border: ' 2px solid #4900ff',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  disabledLink: {
    color: '#717171',
  },
  activeLink: {
    color: '#fff',
  },
  content: {
    height: '50vh',
  },
  select: {
    maxHeight: '40px',
  },
  avatar: {
    width: '16px',
    height: '16px',
    marginRight: '8px',
  },
});
const validationSchema = yup.object({
  phoneNumber: yup
    .string()
    .matches(
      /^-?\d+\.?\d*$/,
      'invalid format! Phone number cannot contain letters, spaces or special characters'
    )
    .max(10, 'too long!')
    .required('Please enter your phone number'),
});

const mapState = ({ auth }) => ({
  pwdResetCode: auth.pwdResetCode,
  pwdResetRequestLoading: auth.pwdResetRequestLoading,
  passwordResetPhonenumber: auth.passwordResetPhonenumber,
});

const RequestPasswordReset = () => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const { pwdResetCode, pwdResetRequestLoading } = useSelector(mapState);

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const phone = {
        PhoneNumber: formatPhoneNumber('254', values.phoneNumber),
      };
      dispatch(requestPwdReset(phone));
      history.push('/auth/verify_password_reset_code');

      // if (!pwdResetRequestLoading) {
      //   formik.resetForm({});
      // }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <BaseCard>
          <CardContent className={classes.content}>
            <h2
              className="txt-ruby h1x-large-bold"
              style={{ marginBottom: '16px' }}
            >
              Request Password Reset
            </h2>
            <p className="f-14 txt-violet" style={{ marginBottom: '48px' }}>
              Please enter your phone number to receive a password reset code
            </p>
            <BaseInput2
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              placeholder="e.g 0712345678 "
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              startAdornment={
                <Avatar src={flag_icon} className={classes.avatar} />
              }
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber && (
              <p className="input-error">
                <small>{formik.errors.phoneNumber}</small>
              </p>
            )}
          </CardContent>
          <CardActions className={classes.actions}>
            <BaseBtn
              fullWidth
              type="submit"
              disabled={
                !formik.values.phoneNumber ||
                formik.isSubmitting ||
                pwdResetRequestLoading
              }
              className={
                formik.values.phoneNumber
                  ? classes.activeBtn
                  : classes.disabledBtn
              }
            >
              <span
                className={
                  formik.values.phoneNumber
                    ? classes.activeLink
                    : classes.disabledLink
                }
              >
                done
              </span>
            </BaseBtn>
          </CardActions>
        </BaseCard>
      </form>
    </>
  );
};

export default RequestPasswordReset;
