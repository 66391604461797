import axios from 'axios';

import {
  FETCH_CART_ITEMS_START,
  FETCH_CART_ITEMS_SUCCESS,
  FETCH_CART_ITEMS_FAIL,
  REMOVE_CART_ITEM,
  ADD_CART_ITEM_SUCCESS,
  ADD_CART_ITEM_FAIL,
  FETCH_SHOPPING_BAG_FAIL,
  FETCH_SHOPPING_BAG_SUCCESS,
  FETCH_SHOPPING_BAG_START,
  CART_NEW_ITEM,
  CART_VOUCHER,
  CART_TOTAL,
  CART_SUBTOTAL,
  CART_SHIPPING_TOTAL
} from './cartTypes';

import { url } from '../../api';
import { LOGIN_SUCCESS } from '../auth/authTypes';

export const setCartTotal = (cartTotall) =>{
  return (dispatch,getState)=>{
    dispatch({
      type:CART_TOTAL,
      payload:cartTotall
    })
  }
}

export const setCartSubtotal = (subtotall) =>{
  return (dispatch,getState) =>{
    dispatch({
      type:CART_SUBTOTAL,
      payload:subtotall
    })
  }
}

export const setCartShippingTotal = (shippingTotall) =>{
  return(dispatch,getState)=>{
    dispatch({
      type:CART_SHIPPING_TOTAL,
      payload:shippingTotall
    })
  }
}

export const cart_voucher = (voucher) =>{
  return (dispatch,getState)=>{
    dispatch({
      type: CART_VOUCHER,
      payload :voucher
    });
  }
}

export const fetchShoppingBag = () => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_SHOPPING_BAG_START,
    });
    const token = getState().auth.token;
    axios
      .get(`${url}/cart/`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        let subtotal = response.data.cart.total;
        let carts = response.data.cart.carts;
        let itemsArray = carts.map((item) => item.items);
        let combinedItemsArray = itemsArray.flat();
        let count = combinedItemsArray.length;
        console.log('carts', carts);
        console.log('itemsArray', itemsArray);
        console.log('combinedItemsArray', combinedItemsArray);
        console.log('count', count);

        dispatch({
          type: FETCH_SHOPPING_BAG_SUCCESS,
          payload: {
            items: combinedItemsArray,
            count: count,
            total: subtotal,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SHOPPING_BAG_FAIL,
          payload: error,
        });
      });
  };
};



export const fetchCartItems = () => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_CART_ITEMS_START,
    });

    const token = getState().auth.token;
    
    axios
      .get(`${url}/cart/`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        const cart = response.data.cart;
        const cartTotal = cart.total;
        const itemsInCart = cart.carts;
        const cartItemsCount = itemsInCart.length;

        dispatch({
          type: FETCH_CART_ITEMS_SUCCESS,
          payload: {
            total: cartTotal,
            items: itemsInCart,
            count: cartItemsCount,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_CART_ITEMS_FAIL,
          payload: error,
        });
      });
  };
};

export const addNewCart = (state) =>{
  return (dispatch,getState) =>{
    dispatch({
      type:state.type,
      payload:state.payload

    });
  }
}

export const addCartItem = (productId) => {

  console.log("product_id",productId)
  return (dispatch, getState) => {
    
    // if(productId.loggedIn){
    //   var data = JSON.stringify({
    //     "productId": productId.productId
    //   });
      const token = getState().auth.token;
      console.log("bearer token",token)
      

    //   // gga
    //   axios.post(`https://api.lukhu.co.ke/api/v1/cart/`, data, {
    //     headers: {
    //       'Content-Type': 'application/json', 
    //       Authorization: `Bearer ${token} ` ,
    //     },
    //   })
    //   .then((response) => {
    //     console.log("response",response.data)
    //     dispatch({
    //       type: ADD_CART_ITEM_SUCCESS,
    //     });
    //     // reload cart
    //     dispatch(fetchShoppingBag());
    //     dispatch(fetchCartItems());
    //   })
    //   .catch((error) => {
    //     dispatch({
    //       type: ADD_CART_ITEM_FAIL,
    //       payload: error,
    //     });

    //   });
    // }else if(productId.loggedIn === false){
    
      var data = JSON.stringify({
      "productId": productId.productId
    });

    const getT =  localStorage.getItem("_REG_TK")
   
    
    const configg = getT ?(

      {
        method: 'post',
        url: 'https://api.lukhu.co.ke/api/v1/cart/',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${getT}`
        },
        data : data
      }
    ):(
      
      {
        method: 'post',
        url: 'https://api.lukhu.co.ke/api/v1/cart/',
        headers: { 
          'Content-Type': 'application/json',
          
     
        },
        data : data
      }
    );

    

    console.log("conf",configg)
    axios(configg)
    .then(function (response) {
      // const user = JSON.stringify(response.data);
      const statuss =  response.status;
      if(statuss === 201){
        const user = response.data;
      console.log("tokem",user,response.status)
        localStorage.setItem('_REG_TK', user.token);
        localStorage.setItem('loggedIn',true)
        localStorage.setItem('_PRF', JSON.stringify(user.profile));
  
      dispatch({
              type: LOGIN_SUCCESS,
              payload: {
                profile: user.profile,
                token: user.token,
                username: user.profile.username,
              },
        });

         dispatch({
          type: ADD_CART_ITEM_SUCCESS,
        });
        dispatch(fetchShoppingBag());
        dispatch(fetchCartItems());


      }else if(statuss === 200){
        dispatch({
          type: ADD_CART_ITEM_SUCCESS,
        });
        dispatch(fetchShoppingBag());
        dispatch(fetchCartItems());

      }
      
      console.log("bearer token",token)
     
     

     
    })
    .catch(function (error) {
      console.log("error",error.message)
          dispatch({
          type: ADD_CART_ITEM_FAIL,
          payload: error.message,
        });
    });

      // axios.post(`${url}/cart/`, productId.productId)
      // .then((response) => {
      //   const response_d =  response.data;
      //   console.log("response_d",response_d)
      //   dispatch({
      //     type: ADD_CART_ITEM_SUCCESS,
      //   });
      //   // reload cart
      //   dispatch(fetchShoppingBag());
      //   dispatch(fetchCartItems());
      // })
      // .catch((error) => {
      //   dispatch({
      //     type: ADD_CART_ITEM_FAIL,
      //     payload: error,
      //   });
      // });
    // }
    
    
  
  };
};

export const removeCartItem = (productId) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    axios
      .delete(`${url}/cart/`, {
        data: productId,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        dispatch({
          type: REMOVE_CART_ITEM,
        });
        // reload cart
        dispatch(fetchShoppingBag());
        dispatch(fetchCartItems());
      })
      .catch((error) => {});
  };
};
