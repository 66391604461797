import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import mIcon from '../../assets/images/micon.png';

import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/Error';

import BaseBtn from '../../components/BaseBtn';

import {
  Divider,
  List,
  ListItemSecondaryAction,
  ListItemText,
  ListItem,
} from '@material-ui/core';

import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import WalletTopup from '../../parts/Shops/WalletTopup';
import MerchantAdminPanelWithdraw from './MerchantAdminPanelWithdraw';
import ComingSoon from '../../pages/ComingSoon';
import WithdrawalPopUp from '../../parts/Shops/WithdrawalPopUp';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
    borderRadius: '5px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.07)',
  },
  section1: {
    padding: '16px',
    marginTop: '16px',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '5px',
    border: ' 1px solid #efefeF',

    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.07)',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-end',
    },
  },
  section2: {
    background: '#F3F3F3',
    padding: '8px',
    marginTop: '16px',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '5px',
  },
  font1: {
    color: '#1E802C',
    fontWeight: 700,
    fontSize: '1.5rem',
  },

  font2: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '0.625rem',
    color: '#111111',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  font3: {
    color: '#898989',
    fontSize: '0.875rem',
  },
  font4: {
    color: '#8E8E8E',
    fontSize: '0.625rem',
    textTransform: 'capitalize',
  },
  font5: {
    color: '#111111',
    fontSize: '0.875rem',
  },
  font6: {
    color: '#898989',
    fontSize: '0.75rem',
    fontWeight: 600,
  },
  btn: {
    background: '#1E802C',
    color: '#ffffff',
    padding: '4px 24px',
    fontSize: '0.75rem',
    '&:hover': {
      background: '#1E802C',
      color: '#ffffff',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '4px 12px',
    },
  },
  btn2: {
    padding: '4px 24px',
    fontSize: '0.75rem',
  },
  section3: {
    padding: '16px',
  },
  flexlayout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spacing: {
    marginTop: '16px',
    marginBottom: '16px',
  },
}));

const MerchantAdminPanelWallet = ({
  loading,
  pendingBalance,
  availableBalance,
  transactions,
  transactionsErr,
  transactionsLoading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const tryDate = format(new Date('2021-11-22T14:49:12.000Z'), 'd MMM ,yyyy');

  const [openTopup, setOpenTopup] = useState(false);
  const [clickWithdraw, setclickWithdraw] = useState(false);
  const [isWithdrawCliked, setisWithdrawCliked] = useState(false);

  const handleClickopenTopup = () => {
    setOpenTopup(true);
  };

  const handleCloseTopup = () => {
    setOpenTopup(false);
  };

  const handleWithdraw = () => {
    setclickWithdraw(true);
  };

  const backToWallet = () => {
    setclickWithdraw(false);
  };

  if (loading) {
    return (
      <Paper elevation={0} className={classes.root}>
        <div className="txt-center">
          <p className={classes.font2}> Loading ...</p>
        </div>
      </Paper>
    );
  }
  return (
    <>
      {clickWithdraw ? (
        // <ComingSoon />
          <MerchantAdminPanelWithdraw
          availableBalance={availableBalance}
          pendingBalance={pendingBalance}
          handleBack={backToWallet}
        />
      ) : (
        // <MerchantAdminPanelWithdraw
        //   availableBalance={availableBalance}
        //   pendingBalance={pendingBalance}
        //   handleBack={backToWallet}
        // />
        <Paper elevation={0} className={classes.root}>
          <h3>Your Wallet</h3>
          <Paper className={classes.section1}>
            <div>
              <p className={classes.font1}>Ksh {availableBalance}</p>
              <p className={classes.font2}> AVAILABLE BALANCE</p>
            </div>
            <BaseBtn className={classes.btn} onClick={handleClickopenTopup}>
              WITHDRAW TO MPESA
            </BaseBtn>
          </Paper>
          <Paper elevation={0} className={classes.section2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p className={classes.font3}>Your Pending Balance</p>
              <ErrorIcon
                className={classes.font3}
                style={{ marginLeft: '4px' }}
              />
            </div>
            <div>
              <p className={classes.font3}>Ksh {pendingBalance}</p>
            </div>
          </Paper>
          <div className={classes.flexlayout}>
            <h4 className={classes.font6}>TRANSACTIONS</h4>
            <h4 className={classes.font6}>See All</h4>
          </div>
          <Paper variant="outlined">
            {transactionsLoading && (
              <div className="pb-16 pt-16">
                <p className="f-14 txt-center">Loading...</p>
              </div>
            )}
            {transactionsErr && (
              <div className="pb-16 pt-16">
                <p className="f-14 txt-ruby txt-center">
                  {' '}
                  No transactions to show
                </p>
              </div>
            )}
            {transactions && transactions.length === 0 && (
              <div className="pb-16 pt-16">
                <p className="f-14 txt-violet txt-center">
                  No transactions to show
                </p>
              </div>
            )}
            {transactions &&
              transactions.map((item, i) => (
                <>
                  <div className={classes.section3}>
                    <div className={classes.flexlayout}>
                      <p className={classes.font4}>{item.status}</p>
                      <p className={classes.font4}>
                        {format(new Date(item.created_on), 'd MMM, yyyy')}
                      </p>
                    </div>
                    <div className={classes.flexlayout}>
                      <p className={classes.font5}>{item.item}</p>
                      <p className={classes.font5} style={{ fontWeight: 700 }}>
                        KSh {item.price}
                      </p>
                    </div>
                  </div>
                  <Divider />
                </>
              ))}
          </Paper>
          <div className={classes.spacing}>
            <div className={classes.flexlayout}>
              <BaseBtnOutlined
                className={classes.btn2}
                onClick={handleWithdraw}
              >
                Edit my details
              </BaseBtnOutlined>
              {/* <BaseBtnOutlined className={classes.btn2}>
              Edit My Details
            </BaseBtnOutlined> */}
            </div>
          </div>
        </Paper>
      )}

        <WithdrawalPopUp open={openTopup} handleClose={handleCloseTopup} />

      {/* <WalletTopup open={openTopup} handleClose={handleCloseTopup} /> */}
    </>
  );
};

MerchantAdminPanelWallet.propTypes = {
  loading: PropTypes.bool.isRequired,
  availableBalance: PropTypes.string.isRequired,
  pendingBalance: PropTypes.number.isRequired,
  transactionsErr: PropTypes.object.isRequired,
  transactionsLoading: PropTypes.bool.isRequired,
  transactions: PropTypes.array.isRequired,
};

export default MerchantAdminPanelWallet;
