/*
  This function ormats a phone number and  prepends a calling code
  e.g  from 0712345678 to +254712345678
 */
export const formatPhoneNumber = (code, num) => {
  const trimmed = num.split(' ').join('');
  return trimmed.charAt(0) === '0'
    ? code + trimmed.substring(1)
    : code + trimmed;
};

export const displayPhoneNumber = (phoneNum) => {
  const removedCode = phoneNum.substring(3);
  const firstChar = '0';
  return firstChar.concat(removedCode);
};

export const formatPhoneNumberNew = (num) => {
  let numArr = num.split('');
  const firstChar = numArr[0];
  if (firstChar == '0') {
    return num;
  } else {
    numArr.unshift('0');
    // console.log(typeof numArr.join(''));
    return numArr.join('');
  }
};

export const removePrefix = (num) => {
  const removedCode = num.substring(3);
  return removedCode;
};
