import React from 'react'
import YourBag from '../components/headers/YourBag'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { FormControl,FormControlLabel,FormLabel,RadioGroup,Radio, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  ctn: {
    paddingTop: '32px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  section1: {
    padding: '16px 0 0 32px',
  },
  section2: {
    padding: '24px 8px',
  },
  pageTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '1.5rem',
    textTransform: 'capitalize',
    fontWeight: 700,
  },

  stepContent: {
    borderLeft: '2px solid #E8E8E8',
    marginTop: 0,
    paddingRight: 0,
  },

  iconSpace: {
    marginRight: '4px',
  },
  stepComplete: {
    background: '#4A4062',
    color: '#FFFFFF',
    padding: '16px ',
    borderRadius: '4px',
  },
  stepIncomplete: {
    background: '#EBEBEB',
    color: '#525252',
    padding: '16px ',
    borderRadius: '4px',
  },
  customStepIcon: {
    color: '#E8E8E8',
    '&.MuiStepIcon-active': {
      color: '#3EA636',
    },
    '&.MuiStepIcon-completed': {
      color: '#3EA636',
    },
  },
  stepperRoot: {
    paddingTop: 0,
  },
  stepLabel: {
    // color: '#525252',
    background: '#F8F8F8',
    color: '#707071',
    padding: '16px ',
    borderRadius: '4px',
    '&.MuiStepLabel-active': {
      color: '#525252',
      background: '#EBEBEB',
    },
    '&.MuiStepLabel-completed': {
      background: '#4A4062',
      color: '#FFFFFF',
    },
  },
  contentCollapse: {
    background: '#FAF8FF',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  // temp
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },

  mobileRoot:{
    width:"100%",
    height:"55px",
    background:"#D81E5B",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    borderRadius:"0px"
  },
  mobileBox1:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    fontSize:"16px",
    width:"60%"
  },
  mobileBox2:{
    width:"40%"
  },
  cancelIcon:{
    color:"#fff",
    marginLeft:"10px"
  },
  bagText:{
    color:"#491DFF",
    font: "normal normal  16px/19px Montserrat",
    padding:"15px"

  },
  displayF:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    borderRadius:"0px"
   
    
    
  },
  displayF1:{
    display:"flex",
    alignItems:"right",

      
  },
  displayF12:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    marginTop:"10px",
    marginBottom:"15px"
  },
  tterte:{
    fontSize:"13px",
    color:"#111111",
    marginLeft:"10px",
    font:"Lato",
    padding:"15px"

  },
  ttrw:{
    color:"#491DFF",
    fontSize:"12px",
    marginRight:"10px",
    padding:"15px"
  },
  yyy:{
    width:"40px",
    height:"40px",
    borderRadius:"100%",
    marginLeft:"10px",
    marginTop:"10px",
    marginBottom:"10px"
  },
  rrwww:{
    marginLeft:"10px",
    color:"#4A4062",
    fontSize:"14px",
    padding:"15px",
    textAlign:"center"
  },
  rrwww1:{
    color:"4A4062",
    marginTop:"10px"
    
  },
  rqqt:{
    width:"150px",
    height:"150px",
  
  },
  v1:{
    fontSize:"12px",
    color:"#111111",
    font:"normal normal bold 9px/19px Lato",
    marginLeft:"13px",
    
  },

  v3:{
    fontSize:"12px",
    color:"#111111",
    font:"normal normal bold 9px/19px Lato",
    marginLeft:"13px",
    
  },

  v4:{
    fontSize:"12px",
    color:"#111111",
    font:"normal normal bold 9px/19px Lato",
    marginRight:"13px",
    
  },
  b1:{
    width:"150px"
  },
  v2:{
    fontSize:"12px",
    color:"#707070",
    marginRight:"10px",
    font:"normal normal normal 9px/19px Lato",
   
  },
  divHl:{
    width:"100%",
    height:"2px",
    marginTop:"10px",
    background:"#F5F5F5",
    marginLeft:"5px",
    marginRight:"5px"
  },
  btnCheckout:{
    width:"90%",
    padding:"15px",
    color:"#fff",
    background:" #4900FF",
    marginTop:"15px",
    marginLeft:"15px",
    marginRight:"15px",
    marginBottom:"18px",
    textTransform:"none",
    font:"bold normal normal 19px Lato",
  },
  ppclas:{
    width:"150px",
    margin:"10px",
    borderRadius:"5px"
  },
  bx1:{
    display:"flex",
    alignItems:"stretch",
    justifyContent:"space-around"
  },
  label1:{
    color: "#111111",
  
    font:"normal normal 600 14px/19px Montserrat",
    letterSpacing:"0px",
    opacity:1,
    fontSize:"19px",

    flexGrow:12,
    marginTop:"10px",
    marginLeft:"1px",
    marginBottom:"2px",
    padding:0
  },
  rd1:{
    marginLeft:"4px",
    flexGrow:1
  },
  mainr:{
    backgroundColor:"white",
    height:"100vh",
    marginTop:"15px"
  },
  ssd3:{
    marginTop:"10px",
    color:"#D81E5B",
    fontSize:"16px",
    marginBottom:"5px",
    font: "normal normal 600 14px/18px Montserrat",
    textAlign:"left",
    marginLeft:"7px",
    
  },
  lines:{
    height:"1px",
    backgroundColor:"#C5C5C5",
    width:"95vw",
    marginBottom:"5px",
    marginTop:"10px"
  },
  bbf:{
    margin:"10px"
  },
  hhr:{
    width:"100"
  },
  rd11:{
    transform:"scale(1.2)",
    marginRight:"1px",
    margin:0
  },
  ju11:{
    marginLeft:"45px",
    

  },
  p12:{
    color:"#111111",
    font:"normal normal medium 12px Lato",
    
    fontSize:"15px"
  },
  p13:{
    marginTop:"5px",
    color:"#111111",
    fontSize:"15px",
    font:"normal normal medium 12px Lato",
    

  },
  b2:{
    background:"#0000000D",
    display:"flex",
    alignItems:"stretch",
    marginLeft:"25px",
    marginRight:"10px",
    marginTop:"5px",
    borderRadius:"5px"
  },
  b3x:{
    display:"flex",
    padding:"8px"
    
  },
  p3:{
    fontSize:"15px",
    padding:"5px",
    font:"normal normal medium 12px Lato",
    fontWeight:600,

  },
  p4:{
    fontSize:"15px",
    padding:"5px",

  }
}));


function DeliveryAndPaymentPageMobile() {
  const classes = useStyles();
  return (
    <div >
      <YourBag name={"Delivery And Payment"}></YourBag>
      <Paper className={classes.displayF}>
          <Typography className={classes.tterte} variant='h6'>Total <strong>Ksh 5,300</strong> </Typography>

          <Button className={classes.ttrw} variant="text" >Edit your Bag</Button>

        </Paper>

       <Paper className={classes.mainr}>
       <FormControl>
      
      <FormLabel  className={classes.ssd3}>DELIVERY OPTION</FormLabel>
      
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        className={classes.bbf}
      >
       <Box  className={classes.hhr}>
        <div className={classes.lines}></div>
          <Box className={classes.bx1}>
              <FormControlLabel className={classes.rd1} value="female" control={<Radio className={classes.rd11} />}  ></FormControlLabel>
              <p className={classes.label1}>Lukhu Express</p>
          </Box>  

          <Box className={classes.ju11}>
            <p className={classes.p12}>Get the order delivered within 1 day of your prefferred delivery purchase</p>
            <p className={classes.p13}><strong>Delivery cost</strong> : Ksh 200</p>
          </Box>


          <Box className={classes.b2}>
            
              <Box>
                <Box className={classes.b3x}>
                  <p className={classes.p3}>John Doe</p>
                  <p className={classes.p4}>Nairobi,Langata, Parklands </p>

                </Box>

                

              </Box>
              <Typography className={classes.bagText} variant='h6'>Edit Address</Typography>

            

          </Box>

       
          <div className={classes.lines}></div>
       </Box>
        
        <FormControlLabel value="male" control={<Radio />} label="Male" />
        <FormControlLabel value="other" control={<Radio />} label="Other" />
      </RadioGroup>
    </FormControl>

       </Paper>
    </div>
  )
}

export default DeliveryAndPaymentPageMobile