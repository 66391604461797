// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const BaseBtnFilled = withStyles({
  root: {
    borderRadius: 24,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    backgroundColor: '#4900ff',
    border: '2px solid #4900ff',
    color: '#FFF',
    maxWidth: '100%',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
    '&:disabled': {
      backgroundColor: '#E2E2E2',
      borderColor: '#E2E2E2',
      color: '#9e9e9e',
    },
  },
})(Button);

export default BaseBtnFilled;
