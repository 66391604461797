import { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import useSocket from './hooks/useSocket';
import './assets/styles/App.css';
// import ClearCache from 'react-clear-cache';

import Home from './pages/Home/Home';
import LoginOptions from './pages/Login/LoginOptions';
import LoginForm from './pages/Login/LoginForm';
import SelectCategory from './pages/Onboarding/SelectCategory';
import Signup from './pages/Signup/Signup';
import SignupForm from './pages/Signup/SignupForm';
import SignupLogin from './pages/Onboarding/SignupLogin';
import VerifyPhoneNumber from './pages/Signup/VerifyPhoneNumber';
import ResetPassword from './pages/Login/ResetPassword';
import ResetPasswordFeedback from './pages/Login/ResetPasswordFeedback';
import ListProducts from './pages/Shops/ListProducts';

import { loadUser } from './redux/auth/authActions';
import { fetchUnread } from './redux/message/messageActions';
import { fetchShoppingBag } from './redux/cart/cartActions';
import { deleteListing } from './redux/products/productsActions';
import VerifyPasswordCode from './pages/Login/VerifyPasswordCode';
import RequestPasswordReset from './pages/Login/RequestPasswordReset';
import SetupStore from './pages/Shops/SetupStore';
import SingleProductView from './pages/Products/SingleProductView';
import MerchantProfile from './pages/Shops/MerchantProfile';
import CheckoutPage from './pages/Checkout/CheckoutPage';
import MerchantAdminPanel from './pages/Shops/MerchantAdminPanel';
import HomeLayout from './components/layouts/HomeLayout';
import AuthLayout from './components/layouts/AuthLayout';
import StoreActionsLayout from './components/layouts/StoreActionsLayout';
import CheckoutLayout from './components/layouts/CheckoutLayout';
import AllHeadersLayout from './components/layouts/AllHeadersLayout';
import ComingSoon from './pages/ComingSoon';
import MessagingHome from './pages/Messaging/MessagingHome';
import CategorySearchPage from './pages/Search/CategorySearchPage';
import GeneralSearchPage from './pages/Search/GeneralSearchPage';
import CustomSearchPage from './pages/Search/CustomSearchPage';
import Category2SearchPage from './pages/Search/Category2SearchPage';
import EditProducts from './pages/Shops/EditProducts';
import SameSellerPage from './pages/Search/SameSellerPage';
import SimilarItemsPage from './pages/Search/SimilarItemsPage';
import CategoryPageResults from './pages/Results/CategoryPageResults';
import DealsPageResults from './pages/Results/DealsPageResults';
import SameSellerPageResults from './pages/Results/SameSellerPageResults';
import SearchPageResults from './pages/Results/SearchPageResults';
import L2CategoryPageResults from './pages/Results/L2CategoryPageResults';
import BetaHome from './pages/Beta/BetaHome';
import BetaWelcome from './pages/Beta/BetaWelcome';
import BetaConfirmAccess from './pages/Beta/BetaConfirmAccess';
import SaveSeat from './pages/Beta/SaveSeat';
import ConfirmSaveSeat from './pages/Beta/ConfirmSaveSeat';
import RedirectSaveSeat from './pages/Beta/RedirectSaveSeat';
import About from './pages/About';
import NotFound from './pages/NotFound';
import { Edit } from '@material-ui/icons';
import DealsPageResultsOver from './pages/Results/DealsPageResultsOver';
import HomeMobile from './mobile/pages/HomeMobile';
import { fetchCartItems } from './redux/cart/cartActions';
import DeliveryAndPaymentPageMobile from './mobile/pages/DeliveryAndPaymentPageMobile';
import { ADD_CART_ITEM_SUCCESS } from './redux/cart/cartTypes';

const mapState = ({ auth, message }) => ({
  token: auth.token,
  sentMessage: message.sentMessage,
  profile: auth.profile,
});
function App() {
  const dispatch = useDispatch();
  const { token, sentMessage } = useSelector(mapState);
  const { socket, disconnectSocket, profile } = useSocket();
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  useEffect(() => {
    dispatch(loadUser());
    dispatch(fetchCartItems());
    dispatch(fetchShoppingBag());
    
  }, [dispatch]);

  useEffect(() => {
    if (token && profile && profile.isPhoneVerified) {
      dispatch(fetchShoppingBag());
    }
  }, [dispatch]);

  useEffect(() => {
    if (token && profile && profile.isPhoneVerified) {
      dispatch(fetchUnread());
    }
  }, [dispatch]);

  useEffect(() => {
    if (token && profile && profile.isPhoneVerified && socket) {
      socket.on('message', () => {
        dispatch(fetchUnread());
      });
    }
    return () => {
      disconnectSocket();
    };
  }, [socket]);


  // return (
  //   <>
  
  //     <Switch>
  //       <Route
  //         exact
  //         path="/"
  //         render={() => (
  //           <HomeLayout>
  //             <Home />
  //           </HomeLayout>
  //         )}
  //       />

  //       <Route
  //         path="/shop/:storeName"
  //         render={() => (
  //           <HomeLayout>
  //             <MerchantProfile />
  //           </HomeLayout>
  //         )}
  //       />
  //       <Route
  //         path="/about-us"
  //         render={() => (
  //           <HomeLayout>
  //             <About />
  //           </HomeLayout>
  //         )}
  //       />
  //       <Route
  //         path="/daily-deals"
  //         render={() => (
  //           <AllHeadersLayout>
  //             <ComingSoon />
  //           </AllHeadersLayout>
  //         )}
  //       />
  //       <Route
  //         path="/express-delivery"
  //         render={() => (
  //           <AllHeadersLayout>
  //             <ComingSoon />
  //           </AllHeadersLayout>
  //         )}
  //       />
  //       <Route
  //         path="/top-sellers"
  //         render={() => (
  //           <AllHeadersLayout>
  //             <ComingSoon />
  //           </AllHeadersLayout>
  //         )}
  //       />
  //       <Route
  //         path="/lukhu-picks"
  //         render={() => (
  //           <AllHeadersLayout>
  //             <ComingSoon />
  //           </AllHeadersLayout>
  //         )}
  //       />
  //       <Route
  //         path="/new-arrivals"
  //         render={() => (
  //           <AllHeadersLayout>
  //             <ComingSoon />
  //           </AllHeadersLayout>
  //         )}
  //       />
  //       <Route
  //         path="/shop-categories/:category"
  //         render={() => (
  //           <HomeLayout>
  //             <CategoryPageResults />
  //           </HomeLayout>
  //         )}
  //       />
  //       <Route
  //         path="/Women"
  //         render={() => (
  //           <HomeLayout>
  //             <L2CategoryPageResults />
  //           </HomeLayout>
  //         )}
  //       />
  //       <Route
  //         path="/Men"
  //         render={() => (
  //           <HomeLayout>
  //             <L2CategoryPageResults />
  //           </HomeLayout>
  //         )}
  //       />
  //       <Route
  //         path="/Kids"
  //         render={() => (
  //           <HomeLayout>
  //             <L2CategoryPageResults />
  //           </HomeLayout>
  //         )}
  //       />
  //       <Route
  //         path="/search"
  //         render={() => (
  //           <HomeLayout>
  //             <SearchPageResults />
  //           </HomeLayout>
  //         )}
  //       />
  //       <Route
  //         path="/under-1000"
  //         render={() => (
  //           <HomeLayout>
  //             <DealsPageResults />
  //           </HomeLayout>
  //         )}
  //       />

  //     <Route
  //         path="/over-1000"
  //         render={() => (
  //           <HomeLayout>
  //             <DealsPageResultsOver />
  //           </HomeLayout>
  //         )}
  //       />
  //       <Route
  //         path="/store/:store"
  //         render={() => (
  //           <HomeLayout>
  //             <SameSellerPage />
  //           </HomeLayout>
  //         )}
  //       />
  //       <Route
  //         path="/similar-items/:cat"
  //         render={() => (
  //           <HomeLayout>
  //             <SimilarItemsPage />
  //           </HomeLayout>
  //         )}
  //       />
  //       <Route
  //         exact
  //         path="/auth"
  //         render={() => (
  //           <AuthLayout>
  //             <SignupLogin />
  //           </AuthLayout>
  //         )}
  //       />
  //       <Route
  //         exact
  //         path="/auth/signup"
  //         render={() => (
  //           <AuthLayout>
  //             <Signup />
  //           </AuthLayout>
  //         )}
  //       />
  //       <Route
  //         path="/auth/signup-form"
  //         render={() => (
  //           <AuthLayout>
  //             <SignupForm />
  //           </AuthLayout>
  //         )}
  //       />
  //       <Route
  //         path="/auth/verify-phone-number"
  //         render={() => (
  //           <AuthLayout>
  //             <VerifyPhoneNumber />
  //           </AuthLayout>
  //         )}
  //       />
  //       <Route
  //         path="/auth/login"
  //         render={() => (
  //           <AuthLayout>
  //             <LoginOptions />
  //           </AuthLayout>
  //         )}
  //       />
  //       <Route
  //         path="/auth/login-form"
  //         render={() => (
  //           <AuthLayout>
  //             <LoginForm />
  //           </AuthLayout>
  //         )}
  //       />
  //       <Route
  //         path="/auth/request_password_reset"
  //         render={() => (
  //           <AuthLayout>
  //             <RequestPasswordReset />
  //           </AuthLayout>
  //         )}
  //       />
  //       <Route
  //         path="/auth/verify_password_reset_code"
  //         render={() => (
  //           <AuthLayout>
  //             <VerifyPasswordCode />
  //           </AuthLayout>
  //         )}
  //       />
  //       <Route
  //         path="/auth/reset_password"
  //         render={() => (
  //           <AuthLayout>
  //             <ResetPassword />
  //           </AuthLayout>
  //         )}
  //       />
  //       <Route
  //         path="/auth/reset-password-feedback"
  //         render={() => (
  //           <AuthLayout>
  //             <ResetPasswordFeedback />
  //           </AuthLayout>
  //         )}
  //       />
  //       <Route
  //         path="/select-preference"
  //         render={() => (
  //           <AuthLayout>
  //             <SelectCategory />
  //           </AuthLayout>
  //         )}
  //       />
  //       <Route
  //         path="/setup-store"
  //         render={() => (
  //           <StoreActionsLayout>
  //             <SetupStore />
  //           </StoreActionsLayout>
  //         )}
  //       />
  //       <Route
  //         path="/list-product"
  //         render={() => (
  //           <StoreActionsLayout>
  //             <ListProducts />
  //           </StoreActionsLayout>
  //         )}
  //       />
  //       <Route
  //         path="/edit-product"
  //         render={() => (
  //           <StoreActionsLayout>
  //             <EditProducts />
  //           </StoreActionsLayout>
  //         )}
  //       />
  //       <Route
  //         path="/single-product"
  //         render={() => (
  //           <StoreActionsLayout>
  //             <SingleProductView />
  //           </StoreActionsLayout>
  //         )}
  //       />
  //       <Route
  //         path="/catalog/:id"
  //         render={() => (
  //           <StoreActionsLayout>
  //             <SingleProductView />
  //           </StoreActionsLayout>
  //         )}
  //       />

  //       <Route
  //         path="/checkout"
  //         render={() => (
  //           <CheckoutLayout>
  //             <CheckoutPage />
  //           </CheckoutLayout>
  //         )}
  //       />
  //       <Route
  //         path="/my-profile"
  //         render={() => (
  //           <AllHeadersLayout>
  //             <MerchantAdminPanel />
  //           </AllHeadersLayout>
  //         )}
  //       />

  //       <Route
  //         path="/inbox"
  //         render={() => (
  //           <AllHeadersLayout>
  //             <MessagingHome />
  //           </AllHeadersLayout>
  //         )}
  //       />

  //       {/* PATH NOT FOUND */}
  //       {/* <Route path="/404" component={NotFound} />
  //       <Redirect to="/404" /> */}
  //     </Switch>
    
  //   </>
  // );

  if(width <= 768 ){
    return (
      <>
        <Switch>
        <Route
            exact
            path="/"
            render={() => (
              <HomeLayout>
                <Home />
              </HomeLayout>
            )}
          />

<Route
            exact
            path="/delveryAndPaymentPageMobile"
            render={() => (
              // <HomeLayout>
                <DeliveryAndPaymentPageMobile />
           
            )}
          />
          {/* <Route
            exact
            path="/"
            render={() => (
             <HomeMobile />
            )}
          />
   */}
        <Route
            path="/shop/:storeName"
            render={() => (
              <HomeLayout>
                <MerchantProfile />
              </HomeLayout>
            )}
          />
          <Route
            path="/about-us"
            render={() => (
              <HomeLayout>
                <About />
              </HomeLayout>
            )}
          />
          <Route
            path="/daily-deals"
            render={() => (
              <AllHeadersLayout>
                <ComingSoon />
              </AllHeadersLayout>
            )}
          />
          <Route
            path="/express-delivery"
            render={() => (
              <AllHeadersLayout>
                <ComingSoon />
              </AllHeadersLayout>
            )}
          />
          <Route
            path="/top-sellers"
            render={() => (
              <AllHeadersLayout>
                <ComingSoon />
              </AllHeadersLayout>
            )}
          />
          <Route
            path="/lukhu-picks"
            render={() => (
              <AllHeadersLayout>
                <ComingSoon />
              </AllHeadersLayout>
            )}
          />
        <Route
            path="/new-arrivals"
            render={() => (
              <AllHeadersLayout>
                <ComingSoon />
              </AllHeadersLayout>
            )}
          />
          <Route
            path="/shop-categories/:category"
            render={() => (
              <HomeLayout>
                <CategoryPageResults />
              </HomeLayout>
            )}
          />
          <Route
            path="/Women"
            render={() => (
              <HomeLayout>
                <L2CategoryPageResults />
              </HomeLayout>
            )}
          />
          <Route
            path="/Men"
            render={() => (
              <HomeLayout>
                <L2CategoryPageResults />
              </HomeLayout>
            )}
          />
          <Route
            path="/Kids"
            render={() => (
              <HomeLayout>
                <L2CategoryPageResults />
              </HomeLayout>
            )}
          />
          <Route
            path="/search"
            render={() => (
              <HomeLayout>
                <SearchPageResults />
              </HomeLayout>
            )}
          />
          <Route
            path="/under-1000"
            render={() => (
              <HomeLayout>
                <DealsPageResults />
              </HomeLayout>
            )}
          />
  
        <Route
            path="/over-1000"
            render={() => (
              <HomeLayout>
                <DealsPageResultsOver />
              </HomeLayout>
            )}
          />
          <Route
            path="/store/:store"
            render={() => (
              <HomeLayout>
                <SameSellerPage />
              </HomeLayout>
            )}
          />
          <Route
            path="/similar-items/:cat"
            render={() => (
              <HomeLayout>
                <SimilarItemsPage />
              </HomeLayout>
            )}
          />
          <Route
            exact
            path="/auth"
            render={() => (
              <AuthLayout>
                <SignupLogin />
              </AuthLayout>
            )}
          />
          <Route
            exact
            path="/auth/signup"
            render={() => (
              <AuthLayout>
                <Signup />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/signup-form"
            render={() => (
              <AuthLayout>
                <SignupForm />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/verify-phone-number"
            render={() => (
              <AuthLayout>
                <VerifyPhoneNumber />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/login"
            render={() => (
              <AuthLayout>
                <LoginOptions />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/login-form"
            render={() => (
              <AuthLayout>
                <LoginForm />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/request_password_reset"
            render={() => (
              <AuthLayout>
                <RequestPasswordReset />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/verify_password_reset_code"
            render={() => (
              <AuthLayout>
                <VerifyPasswordCode />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/reset_password"
            render={() => (
              <AuthLayout>
                <ResetPassword />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/reset-password-feedback"
            render={() => (
              <AuthLayout>
                <ResetPasswordFeedback />
              </AuthLayout>
            )}
          />
          <Route
            path="/select-preference"
            render={() => (
              <AuthLayout>
                <SelectCategory />
              </AuthLayout>
            )}
          />
          <Route
            path="/setup-store"
            render={() => (
              <StoreActionsLayout>
                <SetupStore />
              </StoreActionsLayout>
            )}
          />
          <Route
            path="/list-product"
            render={() => (
              <StoreActionsLayout>
                <ListProducts />
              </StoreActionsLayout>
            )}
          />
          <Route
            path="/edit-product"
            render={() => (
              <StoreActionsLayout>
                <EditProducts />
              </StoreActionsLayout>
            )}
          />
          <Route
            path="/single-product"
            render={() => (
              <StoreActionsLayout>
                <SingleProductView />
              </StoreActionsLayout>
            )}
          />
          <Route
            path="/catalog/:id"
            render={() => (
              <StoreActionsLayout>
                <SingleProductView />
              </StoreActionsLayout>
            )}
          />
  
  
          <Route
            path="/checkout"
            render={() => (
              <CheckoutLayout>
                <CheckoutPage />
              </CheckoutLayout>
            )}
          />
          <Route
            path="/my-profile"
            render={() => (
              <AllHeadersLayout>
                <MerchantAdminPanel />
              </AllHeadersLayout>
            )}
          />
  
          <Route
            path="/inbox"
            render={() => (
              <AllHeadersLayout>
                <MessagingHome />
              </AllHeadersLayout>
            )}
          />
  
          {/* PATH NOT FOUND */}
          {/* <Route path="/404" component={NotFound} />
          <Redirect to="/404" /> */}
        </Switch>
      </>
    );
  }else{
    return (
      <>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <HomeLayout>
                <Home />
              </HomeLayout>
            )}
          />
  
          <Route
            path="/shop/:storeName"
            render={() => (
              <HomeLayout>
                <MerchantProfile />
              </HomeLayout>
            )}
          />
          <Route
            path="/about-us"
            render={() => (
              <HomeLayout>
                <About />
              </HomeLayout>
            )}
          />
          <Route
            path="/daily-deals"
            render={() => (
              <AllHeadersLayout>
                <ComingSoon />
              </AllHeadersLayout>
            )}
          />
          <Route
            path="/express-delivery"
            render={() => (
              <AllHeadersLayout>
                <ComingSoon />
              </AllHeadersLayout>
            )}
          />
          <Route
            path="/top-sellers"
            render={() => (
              <AllHeadersLayout>
                <ComingSoon />
              </AllHeadersLayout>
            )}
          />
          <Route
            path="/lukhu-picks"
            render={() => (
              <AllHeadersLayout>
                <ComingSoon />
              </AllHeadersLayout>
            )}
          />
          <Route
            path="/new-arrivals"
            render={() => (
              <AllHeadersLayout>
                <ComingSoon />
              </AllHeadersLayout>
            )}
          />
          <Route
            path="/shop-categories/:category"
            render={() => (
              <HomeLayout>
                <CategoryPageResults />
              </HomeLayout>
            )}
          />
          <Route
            path="/Women"
            render={() => (
              <HomeLayout>
                <L2CategoryPageResults />
              </HomeLayout>
            )}
          />
          <Route
            path="/Men"
            render={() => (
              <HomeLayout>
                <L2CategoryPageResults />
              </HomeLayout>
            )}
          />
          <Route
            path="/Kids"
            render={() => (
              <HomeLayout>
                <L2CategoryPageResults />
              </HomeLayout>
            )}
          />
          <Route
            path="/search"
            render={() => (
              <HomeLayout>
                <SearchPageResults />
              </HomeLayout>
            )}
          />
          <Route
            path="/under-1000"
            render={() => (
              <HomeLayout>
                <DealsPageResults />
              </HomeLayout>
            )}
          />
  
        <Route
            path="/over-1000"
            render={() => (
              <HomeLayout>
                <DealsPageResultsOver />
              </HomeLayout>
            )}
          />
          <Route
            path="/store/:store"
            render={() => (
              <HomeLayout>
                <SameSellerPage />
              </HomeLayout>
            )}
          />
          <Route
            path="/similar-items/:cat"
            render={() => (
              <HomeLayout>
                <SimilarItemsPage />
              </HomeLayout>
            )}
          />
          <Route
            exact
            path="/auth"
            render={() => (
              <AuthLayout>
                <SignupLogin />
              </AuthLayout>
            )}
          />
          <Route
            exact
            path="/auth/signup"
            render={() => (
              <AuthLayout>
                <Signup />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/signup-form"
            render={() => (
              <AuthLayout>
                <SignupForm />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/verify-phone-number"
            render={() => (
              <AuthLayout>
                <VerifyPhoneNumber />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/login"
            render={() => (
              <AuthLayout>
                <LoginOptions />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/login-form"
            render={() => (
              <AuthLayout>
                <LoginForm />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/request_password_reset"
            render={() => (
              <AuthLayout>
                <RequestPasswordReset />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/verify_password_reset_code"
            render={() => (
              <AuthLayout>
                <VerifyPasswordCode />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/reset_password"
            render={() => (
              <AuthLayout>
                <ResetPassword />
              </AuthLayout>
            )}
          />
          <Route
            path="/auth/reset-password-feedback"
            render={() => (
              <AuthLayout>
                <ResetPasswordFeedback />
              </AuthLayout>
            )}
          />
          <Route
            path="/select-preference"
            render={() => (
              <AuthLayout>
                <SelectCategory />
              </AuthLayout>
            )}
          />
          <Route
            path="/setup-store"
            render={() => (
              <StoreActionsLayout>
                <SetupStore />
              </StoreActionsLayout>
            )}
          />
          <Route
            path="/list-product"
            render={() => (
              <StoreActionsLayout>
                <ListProducts />
              </StoreActionsLayout>
            )}
          />
          <Route
            path="/edit-product"
            render={() => (
              <StoreActionsLayout>
                <EditProducts />
              </StoreActionsLayout>
            )}
          />
          <Route
            path="/single-product"
            render={() => (
              <StoreActionsLayout>
                <SingleProductView />
              </StoreActionsLayout>
            )}
          />
          <Route
            path="/catalog/:id"
            render={() => (
              <StoreActionsLayout>
                <SingleProductView />
              </StoreActionsLayout>
            )}
          />
  
          <Route
            path="/checkout"
            render={() => (
              <CheckoutLayout>
                <CheckoutPage />
              </CheckoutLayout>
            )}
          />
          <Route
            path="/my-profile"
            render={() => (
              <AllHeadersLayout>
                <MerchantAdminPanel />
              </AllHeadersLayout>
            )}
          />
  
          <Route
            path="/inbox"
            render={() => (
              <AllHeadersLayout>
                <MessagingHome />
              </AllHeadersLayout>
            )}
          />
  
          {/* PATH NOT FOUND */}
          {/* <Route path="/404" component={NotFound} />
          <Redirect to="/404" /> */}
        </Switch>
      </>
    );
  }

  
}

export default App;
