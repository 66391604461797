import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const NoResults = ({ msg }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <p>{msg}</p>
    </Box>
  );
};

NoResults.propTypes = {
  msg: PropTypes.string.isRequired,
};

export default NoResults;
