import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

import SavedAddressCard from './SavedAddressCard';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import { selectSavedDeliveryAddress } from '../../redux/checkout/checkoutActions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  label: {
    marginBottom: '16px',
  },
  btn: {
    width: '150px',
  },
}));

// const mapState = ({ checkout }) => ({
//   savedDeliveryAddressLoading: checkout.savedDeliveryAddressLoading,
//   savedDeliveryAddress: checkout.savedDeliveryAddress,
// });

const SelectSavedDeliveryAddress = ({
  closeDialog,
  handleAddNew,
  savedDeliveryAddress,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const { savedDeliveryAddress } = useSelector(mapState);

  const [selectedAddress, setSelectedAddress] = useState(null);

  const handleChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  const handleUseAddress = () => {
    dispatch(selectSavedDeliveryAddress(selectedAddress));
    closeDialog();
  };

  // const handleAddNew = () => {
  //   //open new address form. Forward as prop maybe?
  // };

  return (
    <div>
      {/* <FormControl component="fieldset" fullWidth>
        <RadioGroup
          aria-label="delivery-options"
          name="delivery-options"
          value={selectedAddress}
          onChange={handleChange}
        >
          {savedDeliveryAddress &&
            savedDeliveryAddress.map((addr) => (
              <FormControlLabel
                key={addr.id}
                value={String(addr.id)}
                control={<Radio />}
                label={
                  <SavedAddressCard
                    customerName={`${addr.firstName} ${addr.lastName}`}
                    phoneNo={addr.phoneNumber}
                    location={`${addr.buildingName}, ${addr.city} `}
                    otherDetails={addr.additionalInfo}
                  />
                }
                classes={{
                  label: classes.label,
                }}
              />
            ))}
        </RadioGroup>
      </FormControl> */}
      {/* <div>{selectedAddress}</div> */}
      <div>
        {savedDeliveryAddress &&
          savedDeliveryAddress.length > 0 &&
          savedDeliveryAddress.map((addr) => (
            <div className={classes.root} key={addr.id}>
              <Radio
                checked={selectedAddress === String(addr.id)}
                onChange={handleChange}
                value={addr.id}
                name={`address-${addr.id}`}
              />
              <SavedAddressCard
                customerName={`${addr.firstName} ${addr.lastName}`}
                phoneNo={addr.phoneNumber}
                location={`${addr.buildingName}, ${addr.city}`}
                otherDetails={addr.additionalInfo}
              />
            </div>
          ))}
      </div>
      <Box display="flex" justifyContent="space-between">
        <BaseBtnFilled
          className={classes.btn}
          onClick={handleUseAddress}
          disabled={!selectedAddress}
        >
          Use this Address
        </BaseBtnFilled>
        <BaseBtnOutlined className={classes.btn} onClick={handleAddNew}>
          Add New Address
        </BaseBtnOutlined>
      </Box>
    </div>
  );
};

SelectSavedDeliveryAddress.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  handleAddNew: PropTypes.func.isRequired,
  savedDeliveryAddress: PropTypes.array.isRequired,
};

export default SelectSavedDeliveryAddress;
