import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import BaseBtn from '../../components/BaseBtn';
import BaseCard from '../../components/BaseCard';
import BaseInput2 from '../../components/BaseInput2';
import { resetPwd } from '../../redux/auth/authActions';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  
  disabledBtn: {
    backgroundColor: '#E2E2E2',
    color: '#717171',
    '&:hover': {
      backgroundColor: '#E2E2E2',
    },
  },
  activeBtn: {
    backgroundColor: '#4900ff',
    border: ' 2px solid #4900ff',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  btn: {
    backgroundColor: '#4900ff',
    border: ' 2px solid #4900ff',
    color: '#fff',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  content: {
    height: '50vh',
  },
});

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Please enter password')
    .matches(
      /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password  must contain at least 6 characters, one uppercase, one number and one special case character'
    ),
  verifyPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], "Passwords don't match!")
    .required('Please verify password'),
});

const mapState = ({ auth }) => ({
  userPhoneNumber: auth.passwordResetPhonenumber,
  isPasswordReset: auth.isPasswordReset,
  passwordResetError: auth.passwordResetError,
});

const ResetPassword = () => {
  const classes = useStyles();
  const [showPassword, setshowPassword] = useState(false);
  const dispatch = useDispatch();
  const { userPhoneNumber, isPasswordReset, passwordResetError } = useSelector(
    mapState
  );

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const formik = useFormik({
    initialValues: {
      password: '',
      verifyPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const updatedPassword = {
        Password: values.password,
      };

      // console.log(updatedPassword);

      dispatch(resetPwd(updatedPassword));
      formik.resetForm({});
    },
  });

  //
  if (!userPhoneNumber) return <Redirect to="/auth/request_password_reset" />;
  if (isPasswordReset) return <Redirect to="/auth/reset-password-feedback" />;

  return (
    <BaseCard>
      <form onSubmit={formik.handleSubmit}>
        <CardContent className={classes.content}>
          <h2
            className="txt-ruby h2x-large-bold"
            style={{ marginBottom: '16px' }}
          >
            Reset Password
          </h2>
          <p className="p-14 txt-violet" style={{ marginBottom: '48px' }}>
            Enter a new password and verify it.
          </p>
          <BaseInput2
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            placeholder=" new password *"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? (
                    <MdVisibility fontSize="small" />
                  ) : (
                    <MdVisibilityOff fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            }
            fullWidth
            className="mb-16"
          />
          {formik.errors.password && formik.touched.password && (
            <div className="mb-16">
              <p className="input-error">
                <small>{formik.errors.password}</small>
              </p>
            </div>
          )}

          <BaseInput2
            id="verifyPassword"
            name="verifyPassword"
            type={showPassword ? 'text' : 'password'}
            placeholder="verify new password *"
            value={formik.values.verifyPassword}
            onChange={formik.handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? (
                    <MdVisibility fontSize="small" />
                  ) : (
                    <MdVisibilityOff fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            }
            fullWidth
          />

          {formik.errors.verifyPassword && formik.touched.verifyPassword && (
            <div className="mb-16">
              <p className="input-error">
                <small>{formik.errors.verifyPassword}</small>
              </p>
            </div>
          )}
        </CardContent>

        <CardActions>
          <BaseBtn
            type="submit"
            fullWidth
            disabled={
              (!formik.values.password && !formik.values.verifyPassword) ||
              formik.isSubmitting
            }
            className={
              formik.values.password && formik.values.verifyPassword
                ? classes.activeBtn
                : classes.disabledBtn
            }
          >
            reset password
          </BaseBtn>
        </CardActions>
        {passwordResetError && (
          <div className="mt-8 mb-8">
            <p
              style={{
                fontSize: '0.875rem',
                color: 'red',
                textAlign: 'center',
              }}
            >
              An error occurred!{' '}
              <span style={{ color: 'blue', textDecoration: 'underline' }}>
                <Link to="/auth/request_password_reset">Try Again </Link>
              </span>
            </p>
          </div>
        )}
      </form>
    </BaseCard>
  );
};

export default ResetPassword;
