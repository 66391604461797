/* 
 This component renders when see all items from seller is cliked
*/

import { useEffect, useState } from 'react';
import {
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { Container, makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import '../../assets/styles/Display.css';
import SetPriceInput from '../../parts/Home/SetPriceInput';
import { fetchAllCategories } from '../../redux/search/searchActions';
import {
  fetchProductConditions,
  fetchProductColorsOptions,
} from '../../redux/products/productsActions';
import WelcomeBanner from '../../parts/Home/WelcomeBanner';
import promo1 from '../../assets/images/black-hat-model.jpg';
import ListingCard from '../../parts/Products/ListingCard';
import BaseCollapse from '../../components/BaseCollapse';
import { api_url } from '../../api';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  sizeBtn: {
    padding: '4px',
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-4px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  listItem: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  nestedListItem: {
    padding: '4px 0px',
  },
  listText: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  listTextSmall: {
    fontSize: '1rem',
  },
  categoryTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  attrTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontSize: '0.875rem',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  nestedListItemText: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 400,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
    color: '#111111',
  },
  listActionPosition: {
    right: 0,
  },
  setPriceButton: {
    color: '#471CF9',
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 700,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
  },
  grid: {
    // width: '92vw',
    margin: '0 auto',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  colorCircle: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  selectedColorCircle: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  filterNav: {
    width: '100%',
    marginRight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  navList: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  categoryTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  listTextSmall: {
    fontSize: '1rem',
  },
  attrTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontSize: '0.875rem',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  setPriceButton: {
    color: '#471CF9',
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 700,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
  },
  nestedListItem: {
    padding: '4px 0px',
  },
  nestedListItemText: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 400,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
    color: '#111111',
  },
  colorCircle: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  sizeBtn: {
    padding: '4px',
  },
}));

const mapState = ({ search, product }) => ({
  allCategories: search.allCategories,
  productConditions: product.productConditions,
  productColors: product.productColors,
  productStoreId: product.productStoreId,
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SameSellerPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { store } = useParams();
  const dispatch = useDispatch();
  const {
    allCategories,
    productColors,
    productConditions,
    productStoreId,
  } = useSelector(mapState);
  const { search } = useLocation();
  const { url } = useRouteMatch();
  let query = useQuery();

  const [allProducts, setallProducts] = useState([]);

  const [maxPrice, setmaxPrice] = useState('');
  const [minPrice, setminPrice] = useState('');
  const [categoryFilterVal, setcategoryFilterVal] = useState();
  const [colorFilterVal, setcolorFilterVal] = useState();
  const [conditionFilterVal, setconditionFilterVal] = useState();
  const [priceFilterVal, setpriceFilterVal] = useState();

  const setFilter = (key, value) => {
    if (query.has(key)) {
      query.delete(key);
    }
    return query.append(key, value);
  };
  const setCategoryFilter = (val) => {
    setFilter('category', val);
    history.push(`${url}?${query}`);
    setcategoryFilterVal(query.get('category'));
  };
  const setCondtionFilter = (val) => {
    setFilter('condition', val);
    history.push(`${url}?${query}`);
    setconditionFilterVal(query.get('condition'));
  };

  const setColorFilter = (val) => {
    setFilter('color', val);
    history.push(`${url}?${query}`);
    setcolorFilterVal(query.get('color'));
  };
  const setPriceFilter = (min, max) => {
    const priceString = `${min},${max}`;
    setFilter('price', priceString);
    history.push(`${url}?${query}`);
    setpriceFilterVal(query.get('price'));
    // console.log(query.get('price'));
  };
  const handleSetPrice = () => {
    let max = maxPrice;
    let min = minPrice;
    if (Number(min) > Number(max)) {
      setminPrice('0');
      min = '0';
    }

    const priceString = `${min},${max}`;
    setFilter('price', priceString);
    history.push(`${url}?${query}`);
    setpriceFilterVal(query.get('price'));
    // console.log(query.get('price'));
  };

  useEffect(() => {
    dispatch(fetchProductConditions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductColorsOptions());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchAllCategories());
  }, [dispatch]);

  useEffect(() => {
    let isCancelled = false;
    const fetchSimilarProducts = async () => {
      const filterQuery = search.substring(1);

      try {
        const response = await axios.get(
          `${api_url}/listing/?store=${productStoreId}&page=1&limit=100&${filterQuery}`
        );
        const products = response.data.data;
        if (!isCancelled) {
          setallProducts(products);
        }
      } catch (error) {
        // console.log(error);
      }
    };

    fetchSimilarProducts();
    return () => (isCancelled = true);
  }, [colorFilterVal, conditionFilterVal, categoryFilterVal, priceFilterVal]);

  const [sortBy, setSortBy] = useState('on sale');
  const handleSortBy = (event) => {
    setSortBy(event.target.value);
  };

  return (
    <div className="bg-home">
      <WelcomeBanner
        bannerBackgroundColor="#FFF5F6"
        themeColor="#D81E5B"
        promoImg={promo1}
        promoTitle="WELCOME TO LUKHU"
      />

      <Container maxWidth="lg" classes={{ root: classes.root }}>
        <Grid container className={classes.grid}>
          <Grid item xs={12}>
            <div className="d-flex d-flex-space pb-16">
              {allProducts && allProducts.length > 1 && (
                <h4 className="f-16 txt-semi-bold">
                  {allProducts.length} listings from &apos;{store}&apos;
                </h4>
              )}
              {allProducts && allProducts.length === 1 && (
                <h4 className="f-16 txt-semi-bold">
                  {allProducts.length} listing from &apos;{store}&apos;
                </h4>
              )}
              {/* <div className="d-flex">
              <h4 className="f-16 txt-medium">Sort by : </h4>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={sortBy}
                onChange={handleSortBy}
                input={<CustomSelect />}
              >
                <MenuItem value="other value">other values</MenuItem>
              </Select>
            </div> */}
            </div>
            <Divider />
          </Grid>
        </Grid>
        <Grid container className={classes.grid}>
          <Grid container item xs={12} md={2}>
            <div className={classes.filterNav}>
              <div className="pt-16 pb-16">
                {allCategories && (
                  <List component="nav">
                    {allCategories
                      .filter((cat) => cat.parent_id === 0)
                      .map((item) => (
                        <BaseCollapse
                          key={item.id}
                          title={item.name}
                          titleStyles={classes.categoryTitle}
                        >
                          {allCategories
                            .filter((cat) => cat.parent_id === item.id)
                            .map((subCat) => (
                              <ListItem
                                button
                                key={subCat.id}
                                alignItems="flex-start"
                                onClick={() => setCategoryFilter(subCat.name)}

                                // onClick={() => handleSetNewCat(subCat.name)}
                              >
                                <ListItemText
                                  primary={subCat.name}
                                  disableTypography
                                  className={classes.listTextSmall}
                                />
                              </ListItem>
                            ))}
                        </BaseCollapse>
                      ))}
                  </List>
                )}
              </div>
              <Divider />
              <List component="nav" className={classes.navList}>
                <BaseCollapse title="price" titleStyles={classes.attrTitle}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      margin: '8px 0',
                    }}
                  >
                    <Box>
                      <SetPriceInput
                        placeholder="min"
                        value={minPrice}
                        onChange={(e) => setminPrice(e.target.value)}
                      />
                    </Box>
                    <div>
                      {' '}
                      <pre> — </pre>
                    </div>
                    <Box>
                      <SetPriceInput
                        placeholder="max"
                        value={maxPrice}
                        onChange={(e) => setmaxPrice(e.target.value)}
                      />
                    </Box>

                    <div>
                      {' '}
                      <Button
                        color="primary"
                        className={classes.setPriceButton}
                        onClick={handleSetPrice}
                      >
                        Set
                      </Button>
                    </div>
                  </div>

                  <ListItem
                    button
                    alignItems="flex-start"
                    className={classes.nestedListItem}
                    onClick={() => setPriceFilter('0', '1000')}
                  >
                    <ListItemText
                      primary="Under KSh 1,000"
                      disableTypography
                      className={classes.nestedListItemText}
                    />
                  </ListItem>
                  <ListItem
                    button
                    alignItems="flex-start"
                    className={classes.nestedListItem}
                    onClick={() => setPriceFilter('1000', '2000')}
                  >
                    <ListItemText
                      primary="KSh 1,000 - KSh 2,000"
                      disableTypography
                      className={classes.nestedListItemText}
                    />
                  </ListItem>
                  <ListItem
                    button
                    alignItems="flex-start"
                    className={classes.nestedListItem}
                    onClick={() => setPriceFilter('2000', '3000')}
                  >
                    <ListItemText
                      primary="KSh 2,000 - KSh 3,000"
                      disableTypography
                      className={classes.nestedListItemText}
                    />
                  </ListItem>
                  <ListItem
                    button
                    alignItems="flex-start"
                    className={classes.nestedListItem}
                    onClick={(e) => setPriceFilter('3000', '4000')}
                  >
                    <ListItemText
                      primary="KSh 3,000 - KSh 4,000"
                      disableTypography
                      className={classes.nestedListItemText}
                    />
                  </ListItem>
                  <ListItem
                    button
                    alignItems="flex-start"
                    className={classes.nestedListItem}
                    onClick={() => setPriceFilter('4000', '10000')}
                  >
                    <ListItemText
                      primary="KSh 4,000+"
                      disableTypography
                      className={classes.nestedListItemText}
                    />
                  </ListItem>
                </BaseCollapse>
                <Divider />
                <BaseCollapse title="condition" titleStyles={classes.attrTitle}>
                  {productConditions &&
                    productConditions.map((item) => (
                      <ListItem
                        button
                        key={item.id}
                        alignItems="flex-start"
                        className={classes.nestedListItem}
                        onClick={() => setCondtionFilter(item.name)}
                      >
                        <ListItemText
                          disableTypography
                          primary={item.name}
                          className={classes.nestedListItemText}
                        />
                      </ListItem>
                    ))}
                </BaseCollapse>
                <BaseCollapse title="color" titleStyles={classes.attrTitle}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(4,1fr)',
                      gridRowGap: '12px',
                    }}
                  >
                    {productColors &&
                      productColors.map((color) => (
                        <div
                          key={color.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                          onClick={() => setColorFilter(color.id)}
                        >
                          <div
                            className={classes.colorCircle}
                            style={{
                              // backgroundColor: `${color.hex}`,
                              backgroundColor: `#eeeeee`,
                            }}
                          ></div>
                          <p className="f-12">{color.name}</p>
                        </div>
                      ))}
                  </div>
                </BaseCollapse>
                <Divider />
              </List>
            </div>
          </Grid>
          <Grid container item xs={12} md={10} spacing={1}>
            {allProducts &&
              allProducts.map((item) => (
                <Grid item xs={6} sm={4} md={3} key={item.id}>
                  <ListingCard product={item} />{' '}
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SameSellerPage;
