import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const CustomInput = withStyles({
  root: {
    maxHeight: '40px',
    backgroundColor: '#F5F2FE',
    padding: '4px 8px',
    fontSize: '0.875rem',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
  input: {
    fontWeight: 600,

    '&::placeholder': {
      fontWeight: 'normal',
      color: '#717171',
    },
  },
})(InputBase);
export default CustomInput;
