import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const BaseInput = withStyles({
  root: {
    '& label': {
      fontFamily: 'Lato',
      fontSize: '12px',
      textTransform: 'capitalize',
      color: '#707070',
    },
    '& label.Mui-focused': {
      fontSize: '12px',
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        fontSize: '16px',
        height: '20px',
      },
      '& fieldset': {
        borderColor: '#0000001F',
      },
      '&:hover fieldset': {
        borderColor: '#4800FB',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4800FB',
      },
    },

    backgroundColor: '#F5F2FE',
    maxHeight: '40px',
  },

  // '&.MuiOutlinedInput-input': {
  //   fontSize: '12px',
  //   fontFamily: 'Lato',
  // },
})(TextField);
export default BaseInput;
