import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import '../../assets/styles/Display.css';
import ListingCard from '../Products/ListingCard';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 56px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
  
}));

const SelectDisplayOptions = ({
  productArr,
  title,
  link,
  linkName,
  emptyMessage,
}) => {
  const classes = useStyles();

  const slicedProductArr =
    productArr.length > 10 ? productArr.slice(0, 10) : productArr;

  const history = useHistory();

  const viewProductDetails = (id) => {
    history.push(`/catalog/${id}`);
  };

  return (
    <section>
      <Container className={classes.container}>
        <header className="d-opt-header mb-24">
          <h3 className="section-title f-18 txt-uppercase">{title}</h3>
          <Link to={link}>
            <span
              style={{
                color: '#481CFB',
                textTransform: 'capitalize',
                textDecoration: 'underline',
              }}
            >
              {productArr.length > 1 && linkName}
            </span>
          </Link>
        </header>

        <div className="c5-grid">
          {slicedProductArr.length > 0 &&
            slicedProductArr.map((item) => (
              <div key={item.id}>
                <ListingCard product={item} />
              </div>
            ))}
        </div>
        {!productArr.length && (
          <div className="txt-center  pb-16">
            <p className="txt-ruby">{emptyMessage}</p>
          </div>
        )}
      </Container>
    </section>
  );
};

SelectDisplayOptions.propTypes = {
  productArr: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkName: PropTypes.string.isRequired,
  emptyMessage: PropTypes.string,
};

export default SelectDisplayOptions;
