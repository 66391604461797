import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'timeago.js';

import Divider from '@material-ui/core/Divider';

import ChatMessageSnippet from './ChatMessageSnippet';
import {
  fetchCurrentChat,
  fetchUserMessages,
  setCurrentChatId,
} from '../../redux/message/messageActions';
import NotificationSnippet from './NotificationSnippet';

const MessagingHistory = ({ messagesLoading, messages, messagesErr }) => {
  const dispatch = useDispatch();
  const [isClicked, setisClicked] = useState(false);
  const [activeChat, setactiveChat] = useState(null);

  const [chatMessages, setChatMessages] = useState([]);
  const [otherMessages, setOtherMessages] = useState([]);

  const clickedStyles = 'white';

  useEffect(() => {
    if (messages) {
      let chatTypeMsgs = messages.filter((msg) => msg.type === 'CHAT');
      setChatMessages(chatTypeMsgs);
    }

    return () => {};
  }, [messages]);
  useEffect(() => {
    if (messages) {
      let otherTypeMsgs = messages.filter((msg) => msg.type !== 'CHAT');
      setOtherMessages(otherTypeMsgs);
    }

    return () => {};
  }, [messages]);

  const openChat = (id) => {
    setactiveChat(id);
    dispatch(setCurrentChatId(id));
  };

  return (
    <div>
      <div className="history-title-spacing">
        <h3 className="txt-black f-20">Messages</h3>
      </div>
      <Divider />
      {messagesLoading && (
        <div className="history-component-spacing">
          <p className="txt-violet f-14 ">Checking for messages...</p>
        </div>
      )}
      {!messagesLoading && messages && messages.length === 0 && (
        <div className=" history-component-spacing ">
          <p className="txt-violet f-14">You have no messages in your inbox</p>
        </div>
      )}
      {messagesErr && (
        <div className=" history-component-spacing ">
          <p className="txt-violet f-14">You have no messages in your inbox</p>
        </div>
      )}

      {chatMessages &&
        chatMessages.map((item) => (
          <div key={item.id}>
            <div
              className={
                activeChat === item.userId
                  ? 'history-component-spacing-active'
                  : 'history-component-spacing'
              }
            >
              <ChatMessageSnippet
                senderImg={item.image}
                senderName={item.user}
                message={item.last_message}
                handleClick={() => openChat(item.userId)}
                // handleClick={handleOpenChat}
              />
            </div>
            <Divider />
          </div>
        ))}
      {otherMessages &&
        otherMessages.map((item) => (
          <div key={item.id}>
            <div
              className={
                activeChat === item.userId
                  ? 'history-component-spacing-active'
                  : 'history-component-spacing'
              }
            >
              <NotificationSnippet
                senderImg={item.image}
                senderName={item.user}
                sentTime={format(item.created_on)}
                handleClick={() => openChat(item.userId)}
                isRead={item.messageStatus === 'READ'}
                status={item.status}
              />
            </div>
            <Divider />
          </div>
        ))}
    </div>
  );
};
MessagingHistory.propTypes = {
  messagesLoading: PropTypes.bool.isRequired,
  messages: PropTypes.array.isRequired,
  messagesErr: PropTypes.object,
};
export default MessagingHistory;
