import {
  FETCH_CART_ITEMS_START,
  FETCH_CART_ITEMS_SUCCESS,
  FETCH_CART_ITEMS_FAIL,
  REMOVE_CART_ITEM,
  ADD_CART_ITEM_SUCCESS,
  ADD_CART_ITEM_FAIL,
  FETCH_SHOPPING_BAG_FAIL,
  FETCH_SHOPPING_BAG_START,
  FETCH_SHOPPING_BAG_SUCCESS,
  CART_NEW_ITEM,
  CART_VOUCHER,
  CART_TOTAL,
  CART_SUBTOTAL,
  CART_SHIPPING_TOTAL
} from './cartTypes';

import { ADD_TO_CART_FIREBASE, REMOVE_CART_ITEM_FIREBASE } from '../../common/firebaseConstants';
import { log_firebase_event } from '../../common/FirebaseCommon';
import { addToCartFailPixel, addToCartPixel, removeFromCardPixel } from '../../_helpers/facebookpixel/PixelEvents';

const initialState = {
  cartLoading: false,
  cartItems: null,
  combinedCartTotal: null,
  cartItemsCount: null,
  checkout: false, //may store in web storage
  cartLoadingErr: null,
  addItemErr: null,
  carVoucher:"",
  cartTotal:0,
  cartSubtotal:0,
  cartShippingTotal:0,

  cartItems : [],

  shoppingBagItems: null,
  shoppingBagItemsCount: null,
  shoppingBagItemsLoading: false,
  shoppingBagItemsErr: null,
};

// add cart items 


const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHOPPING_BAG_START: {
      return {
        ...state,
        shoppingBagItemsLoading: true,
      };
    }
    case CART_VOUCHER: {
      return {
        ...state,
        carVoucher:action.payload

      }
    }

    case CART_TOTAL: {
  
      return {
        ...state,
        cartTotal:action.payload

      };
    }

    case CART_SUBTOTAL: {
      return {
        ...state,
        cartSubtotal:action.payload

      }
    }

    case CART_SHIPPING_TOTAL: {
      return {
        ...state,
        cartShippingTotal:action.payload

      }
    }


    case FETCH_SHOPPING_BAG_SUCCESS: {
      return {
        ...state,
        shoppingBagItemsLoading: false,
        shoppingBagItems: action.payload.items,
        shoppingBagItemsCount: action.payload.count,
        combinedCartTotal: action.payload.total,
      };
    }
    case FETCH_SHOPPING_BAG_FAIL: {
      return {
        ...state,
        shoppingBagItemsLoading: false,
        shoppingBagItemsErr: action.payload,
      };
    }
    
    case CART_NEW_ITEM:
      // console.log("payload",action.payload)
      return {
        ...state,
        cartItems:[...state.cartItems,action.payload]

      }
    case FETCH_CART_ITEMS_START: {
      return {
        ...state,
        cartLoading: true,
      };
    }
    case FETCH_CART_ITEMS_SUCCESS: {
      return {
        ...state,
        cartLoading: false,
        cartItems: action.payload.items,
        combinedCartTotal: action.payload.total,
        cartItemsCount: action.payload.count,
      };
    }
    case FETCH_CART_ITEMS_FAIL: {
      return {
        ...state,
        cartLoading: false,
        cartLoadingErr: action.payload,
      };
    }
    case ADD_CART_ITEM_SUCCESS: {
      addToCartPixel();
      // log_firebase_event(
      //   ADD_TO_CART_FIREBASE,
      //   {
      //     ...state
      //   }
      // )
      return {
        ...state,
      };
    }
    case ADD_CART_ITEM_FAIL: {
      addToCartFailPixel();
      return {
        ...state,
        addItemErr: action.payload,
      };
    }
    case REMOVE_CART_ITEM: {
      removeFromCardPixel();
      // log_firebase_event(
      //   REMOVE_CART_ITEM_FIREBASE,
      //   {
      //     ...state,
      //   }
      // )
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default cartReducer;
