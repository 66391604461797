import { useHistory } from 'react-router-dom';

import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';

import BaseBtn from '../../components/BaseBtn';
import BaseCard from '../../components/BaseCard';
import success_illustration from '../../assets/images/man-trophy-illustration.png';
// src/assets/images/man-trophy-illustration.png
// import success-illustration from ""

const useStyles = makeStyles({
  btn: {
    backgroundColor: '#4900ff',
    border: ' 2px solid #4900ff',
    color: '#fff',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
});

const ResetPasswordFeedback = () => {
  const history = useHistory();
  const classes = useStyles();

  const handleLogin = () => {
    history.push('/auth/login/login-form');
  };

  return (
    <BaseCard>
      <CardContent>
        <div className="flex-center mt-24 mb-16">
          <img src={success_illustration} />
        </div>
        <p className="f-14 txt-violet txt-center">
          You have successfully reset your password. Log in to continue enjoying
          your buying and selling experience.
        </p>
      </CardContent>
      <CardActions>
        <BaseBtn fullWidth className={classes.btn} onClick={handleLogin}>
          Log in
        </BaseBtn>
      </CardActions>
    </BaseCard>
  );
};

export default ResetPasswordFeedback;
