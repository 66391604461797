import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CardActionArea from '@material-ui/core/CardActionArea';
// import Chip from '@material-ui/core/Chip';
// import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
// import WhatshotIcon from '@material-ui/icons/Whatshot';
const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
  },
  media: {
    // position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '100%',
  },
  // root: {
  //   width: '232px',
  //   maxWidth: '100%',

  //   // minWidth: '320px',
  // },
  avatar: {
    backgroundColor: '#d81e5b',
  },

  content: {
    padding: 0,
    lineHeight: 1.5,
  },

  p1: {
    // lineHeight: 1.5,
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 16,
    color: '#111111',
    marginBottom: '8px',
    fontWeight: 600,
  },
  p2: {
    fontSize: 16,
    fontFamily: 'Montserrat, sans-serif',
    color: '#525252',
    fontWeight: 400,
    marginBottom: '8px',
  },
  p3: {
    color: '#111111',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    fontSize: '1rem',
  },
  // media: {
  //   height: '224px',
  // },
  chip: {
    padding: '14px 16px',
    color: '#D80000',
    backgroundColor: '#ffd8d8',
    fontSize: 14,
    fontFamily: 'Lato, sans-serif',
  },
  tag: {
    color: '#D80000',
    fontSize: 14,
  },
  locationIcon: {
    color: '#d81e5b',
    fontSize: 16,
  },
  location: {
    color: '#d81e5b',
    fontSize: 12,
    textTransform: 'capitalize',
    fontFamily: 'Montserrat, sans-serif',
  },
  circle: {
    height: '48px',
    width: '48px',
    backgroundColor: '#d81e5b',
    borderRadius: '50%',
    position: 'relative',
  },
  smallCircle: {
    height: '16px',
    width: '16px',
    backgroundColor: '#4900ff',
    borderRadius: '50%',
    position: 'absolute',
    top: '32px',
    left: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleIcon: {
    color: '#fff',
    fontSize: '12px',
  },
  locationContent: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '8px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px',
  },
  headerIcon: {
    fontSize: '16px',
  },

  grid: {
    display: 'grid',
  },
  actions: {
    display: 'flex',

    // TODO: change flex positions to accomodate tags
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: 0,
  },
});
const StoreProductCard = ({
  productName,
  productImg,
  productSize,
  productCondtion,
  productLocation,
  productPrice,
  productLikes,
  productId,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const viewProductDetails = (id) => {
    history.push(`/catalog/${id}`);
  };
  return (
    <Card
      className={classes.root}
      onClick={() => viewProductDetails(productId)}
    >
      <CardActionArea>
        <CardMedia
          // component="img"
          image={productImg}
          className={classes.media}
        />
        <CardContent className={classes.locationContent}>
          <LocationOnIcon className={classes.locationIcon} />
          <p className={classes.location}>{productLocation}</p>
        </CardContent>
        <CardContent>
          <p className={classes.p1}>{productName}</p>
          <p className={classes.p2}>
            Size: {productSize} . {productCondtion}
          </p>
          <p className={classes.p3}>KSh {productPrice}</p>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <div>
            <p className="f-12 txt-black">{productLikes}</p>
          </div>

          <IconButton className={classes.likesIcon} disabled>
            <FavoriteBorderIcon
              style={{
                fontSize: '1.25rem',
                color: '#111111',
                fontWeight: 'lighter',
              }}
            />
          </IconButton>
        </div>
        {/* <Chip
          size="small"
          label={productTag}
          icon={
            productTag.toLowerCase() === 'popular' ? (
              <WhatshotIcon className={classes.tag} />
            ) : (
              <LocalOfferOutlinedIcon className={classes.tag} />
            )
          }
          className={classes.chip}
        /> */}
      </CardActions>
    </Card>
  );
};
StoreProductCard.propTypes = {
  storeName: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productSize: PropTypes.string.isRequired,
  productCondtion: PropTypes.string.isRequired,
  productLocation: PropTypes.string.isRequired,
  productImg: PropTypes.string.isRequired,
  productPrice: PropTypes.string.isRequired,
  productLikes: PropTypes.number.isRequired,
  productId: PropTypes.string.isRequired,
  // productTag: PropTypes.string.isRequired,
};
export default StoreProductCard;
