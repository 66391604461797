import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { FiPackage } from 'react-icons/fi';

import BaseBtnFilled from '../../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../../components/BaseBtnOutlined';

const BuyerNotifyArrivalMtaani = ({ timestamp, orderID }) => {
  const sampleNot = {
    seller: '@jersey254',
    order: '#L08534847',
    amt: '1,500',
    shipping: '2 Days',
    time: '9.08 AM',

    storeName: 'Store Name',
    contact: 'store contact',
    location: 'store location',
  };
  return (
    <Box display="flex">
      <Avatar style={{ background: '#45BC56', marginRight: '8px' }}>
        <FiPackage style={{ color: '#ffffff' }} />
      </Avatar>
      <div className="notification-box">
        <p className="txt-time f-10">{timestamp}</p>
        <p className="txt-bold txt-blackTrue">Your item has arrived!</p>
        <p className="txt-gray5 f-14">
          Order {orderID} has arrived at your selected pickup location. Kindly
          pick up your item in the next 2 days.
        </p>
        <Box mt={2}>
          <p className="f-14 txt-gray5">Pick up store:</p>
          <p className="f-14 txt-gray5 txt-bold">{sampleNot.storeName}</p>
          <p className="f-14 txt-gray5">{sampleNot.contact}</p>
          <p className="f-14 txt-gray5">{sampleNot.location}</p>
        </Box>
        <Box mt={2}>
          <BaseBtnFilled fullWidth>Directions to Pickup Location</BaseBtnFilled>
        </Box>
        <Box mt={1}>
          <BaseBtnOutlined fullWidth>I have received my item</BaseBtnOutlined>
        </Box>
      </div>
    </Box>
  );
};

BuyerNotifyArrivalMtaani.propTypes = {
  orderID: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default BuyerNotifyArrivalMtaani;
