import PropTypes from 'prop-types';

import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';

const ProductImageInput = ({
  onChange,
  isImageSelected,
  image,
  inputId,
  inputName,
}) => {
  return (
    <div className="image-upload-wrap">
      <input
        id={inputId}
        name={inputName}
        className="image-upload-input"
        type="file"
        accept="image/*"
        onChange={onChange}
      />

      {isImageSelected ? (
        <img
          src={image}
          style={{ maxHeight: '100%', width: '100%', objectFit: 'cover' }}
          alt="uploaded product"
        />
      ) : (
        <div className="image-upload-text">
          <PhotoCameraOutlinedIcon style={{ color: '#666666' }} />
          <p>Add a photo</p>
        </div>
      )}
    </div>
  );
};
ProductImageInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  isImageSelected: PropTypes.bool.isRequired,
  image: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
};
export default ProductImageInput;
