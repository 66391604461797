import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import {
  Link,

  useHistory,
 
} from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
  },
  '&:hover': {
    borderColor: '#4800FB',
  }
});

const MerchantProductCard2 = ({ picture, price, size,product_id }) => {
  // 
  const history = useHistory();
  const viewListedProduct = () => {
    console.log(product_id)
    localStorage.setItem("product_id",product_id)
    history.push('/edit-product');
    // console.log("clicked!!")
  };
  const classes = useStyles();
  return (
    <Card className={classes.root} 
    onClick={viewListedProduct}>
      <CardMedia component="img" image={picture} />
      <CardContent className={classes.content}>
        <p className="txt-bold f-12">KSh {price}</p>
        <p className="txt-light f-12">Size: {size}</p>
      </CardContent>
    </Card>
  );
};

MerchantProductCard2.propTypes = {
  picture: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export default MerchantProductCard2;
