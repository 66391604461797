import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import success from '../../assets/images/ask-seller-success.svg';

const ListProductsSuccess = ({
  open,
  continueListing,
  handleClose,
  viewProduct,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="product-listing-success-dialog-description"
      >
        <DialogContent>
          <Box display="flex" justifyContent="center" mb={2}>
            <img src={success} alt="check mark illustration" width="40%" />
          </Box>
          <DialogContentText id="product-listing-success-dialog-description">
            Your item has been listed successfully!
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button onClick={viewProduct}>View Item</Button>
          <Button onClick={continueListing}>Continue listing</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ListProductsSuccess.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  continueListing: PropTypes.func.isRequired,
  viewProduct: PropTypes.func.isRequired,
};
export default ListProductsSuccess;
