import React from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { FiPackage } from 'react-icons/fi';

import BaseBtnFilled from '../../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../../components/BaseBtnOutlined';

const URL = process.env.REACT_APP_SERVER;

const mapState = ({ auth }) => ({
  token: auth.token,
});
const BuyerNotifyArrivalExpress = ({
  timestamp,
  orderID,
  riderName,
  riderContact,
}) => {
  const { token } = useSelector(mapState);
  const handleReceivedItem = (id) => {
    axios
      .put(
        `${URL}/orders/${id}/delivery`,
        { status: 'DELIVERED' },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Box display="flex">
      <Avatar style={{ background: '#45BC56', marginRight: '8px' }}>
        <FiPackage style={{ color: '#ffffff' }} />
      </Avatar>
      <div className="notification-box">
        <p className="txt-time f-10">{timestamp}</p>
        <p className="txt-bold txt-blackTrue">Your item has arrived!</p>
        <p className="txt-gray5 f-14">
          Order #{orderID} is on its way to you. Kindly contact the rider to
          arrange delivery.
        </p>
        <Box mt={2}>
          <p className="f-14 txt-gray5">Rider Name:</p>
          <p className="f-14 txt-gray5 txt-bold">{riderName}</p>
          <p className="f-14 txt-gray5">{riderContact}</p>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mt={2}>
            <BaseBtnFilled onClick={() => handleReceivedItem(orderID)}>
              I have received my item
            </BaseBtnFilled>
          </Box>
          <Box mt={1}>
            <BaseBtnOutlined>Call Rider</BaseBtnOutlined>
          </Box>
        </Box>
      </div>
    </Box>
  );
};

BuyerNotifyArrivalExpress.propTypes = {
  orderID: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  riderName: PropTypes.string.isRequired,
  riderContact: PropTypes.string.isRequired,
};

export default BuyerNotifyArrivalExpress;
