import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EmptyDisplay from './EmptyDisplay';
import FeedbackCard from './FeedbackCard';
import { sampleFeedback, sampleEmpty } from '../Home/dummyData';

const displayMessage = 'has no feedback yet.';

const MerchantFeeback = ({ merchantName }) => {
  const [feedBack, setFeedBack] = useState([]);
  useEffect(() => {
    const fetchedFeeback = [...sampleEmpty];
    setFeedBack(fetchedFeeback);
  }, []);
  return (
    <>
      {feedBack.length ? (
        <div>
          {sampleFeedback.map((item, i) => (
            <FeedbackCard
              key={i}
              feedbackBy={item.name}
              ratingValue={item.rating}
              comment={item.comment}
              datePosted={item.datePosted}
            />
          ))}
        </div>
      ) : (
        <EmptyDisplay
          merchantName={merchantName}
          displayMessage={displayMessage}
        />
      )}
    </>
  );
};

MerchantFeeback.propTypes = {
  merchantName: PropTypes.string.isRequired,
};
export default MerchantFeeback;
