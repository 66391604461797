import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import { MdSearch } from 'react-icons/md';
import ClearIcon from '@material-ui/icons/Clear';

import BaseInput2 from '../../components/BaseInput2';
import { fetchSearchResults } from '../../redux/search/searchActions';

const SearchField = withStyles({
  root: {
    backgroundColor: '#F5F6F7',
    borderRadius: '28px',
    padding: '4px 16px',
  },
  input: {
    maxHeight: '40px',
    fontSize: '0.875rem',
    fontFamily: 'Montserrat',
  },
  focused: {
    border: '1px solid #111111',
  },
})(InputBase);

const useStyles = makeStyles((theme) => ({
  searchBar: {
    borderRadius: 25,
    // width: '40%',
  },

  startIcon: {},
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SearchInput = () => {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const currUrl = 'q?';
  let query = useQuery();
  const [searchString, setSearchString] = useState('');

  const handleChange = (e) => {
    setSearchString(e.target.value);
  };
  const setFilter = (key, value) => {
    if (query.has(key)) {
      query.delete(key);
    }
    return query.append(key, value);
  };
  const handleSearch = (e) => {
    if (e.keyCode === 13) {
      setFilter('name', searchString);
      // dispatch(fetchSearchResults(searchString));
      history.push(`/search/${currUrl}${query}`);
      // setSearchString(null);
    }
  };

  const clearSearch = () => {
    setSearchString('');
  };

  return (
    <SearchField
      variant="outlined"
      fullWidth
      placeholder="Search"
      value={searchString}
      startAdornment={
        <InputAdornment position="end">
          {/* <IconButton style={{ padding: '6px 0 6px 6px' }}> */}
          <MdSearch fontSize="large" style={{ color: '#A6A7A8' }} />
          {/* </IconButton> */}
        </InputAdornment>
      }
      endAdornment={
        searchString ? (
          <InputAdornment position="start">
            <IconButton style={{ padding: '6px' }} onClick={clearSearch}>
              <ClearIcon style={{ fontSize: '1rem' }} />
            </IconButton>
          </InputAdornment>
        ) : null
      }
      onChange={handleChange}
      onKeyDown={handleSearch}
    />
  );
};

export default SearchInput;
