import axios from 'axios';

import { url } from '../../api';
import {
  FETCH_SINGLE_PRODUCT_FAIL,
  FETCH_SINGLE_PRODUCT_SUCCESS,
  FETCH_SINGLE_PRODUCT_START,
  FETCH_SELLER_PRODUCTS_START,
  FETCH_SELLER_PRODUCTS_SUCCESS,
  FETCH_SELLER_PRODUCTS_FAIL,
  FETCH_SIMILAR_PRODUCTS_SUCCESS,
  FETCH_SIMILAR_PRODUCTS_FAIL,
  FETCH_SIMILAR_PRODUCTS_START,
  CHANGE_DISPLAY_IMAGE,
  FECTH_PRODUCT_STORE_DETAILS_START,
  FECTH_PRODUCT_STORE_DETAILS_SUCCESS,
  FECTH_PRODUCT_STORE_DETAILS_FAIL,
  FETCH_PRODUCT_CONDITIONS_SUCCESS,
  FETCH_PRODUCT_CONDITIONS_FAIL,
  FETCH_PRODUCT_DELIVERY_OPTIONS_FAIL,
  FETCH_PRODUCT_DELIVERY_OPTIONS_SUCCESS,
  FETCH_PRODUCT_COLORS_SUCCESS,
  FETCH_PRODUCT_COLORS_FAIL,
} from './productsTypes';

export const deleteListing = (id) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    axios
      .delete(`${url}/products/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log('success');
      })
      .catch((error) => console.log(error));
    console.log('fail');
  };
};

export const fetchSingleProduct = (id) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SINGLE_PRODUCT_START,
    });
    axios
      .get(`${url}/products/${id}/public`)
      .then((response) => {
        // console.log('RES', response.data);
        const productDetails = response.data.data;
        const productId = response.data.data.id;
        const productStore = response.data.data.storeId;
        const productCat = response.data.data.category;
        const displayImage = productDetails.images[0].secure_url;

        dispatch({
          type: FETCH_SINGLE_PRODUCT_SUCCESS,
          payload: {
            details: productDetails,
            category: productCat,
            storeId: productStore,
            displayImage: displayImage,
          },
        });
        dispatch(fetchSellerProducts(productStore, productId));
        dispatch(fetchSimilarProducts(productCat, productId));
        dispatch(fetchProductStoreDetails(productStore));
      })
      .catch((error) => {
        // console.log('ERROR', error);
        dispatch({
          type: FETCH_SINGLE_PRODUCT_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchSellerProducts = (storeId, productId) => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_SELLER_PRODUCTS_START,
    });
    axios
      .get(`${url}/listing/?page=1&limit=50`)
      .then((response) => {
        const products = response.data.data;

        // get product from same store
        const sellerProducts = products.filter(
          (product) => product.storeId === storeId
        );

        // exclude currently viewed product

        const otherSellerProducts = sellerProducts.filter(
          (product) => product.id !== productId
        );
        dispatch({
          type: FETCH_SELLER_PRODUCTS_SUCCESS,
          payload: otherSellerProducts,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SELLER_PRODUCTS_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchSimilarProducts = (productCategory, productId) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SIMILAR_PRODUCTS_START,
    });
    axios
      .get(`${url}/listing/?category=${productCategory}&page=1&limit=50`)
      .then((response) => {
        const allCategoryProducts = response.data.data;

        // exclude currently viewed product
        const similarProducts = allCategoryProducts.filter(
          (product) => product.id !== productId
        );
        dispatch({
          type: FETCH_SIMILAR_PRODUCTS_SUCCESS,
          payload: similarProducts,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SIMILAR_PRODUCTS_FAIL,
          payload: error,
        });
      });
  };
};

export const changeDisplayImage = (imageUrl) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_DISPLAY_IMAGE,
      payload: imageUrl,
    });
  };
};

export const fetchProductStoreDetails = (storeId) => {
  return (dispatch) => {
    dispatch({
      type: FECTH_PRODUCT_STORE_DETAILS_START,
    });
    axios
      .get(`${url}/store?id=${storeId}`)
      .then((response) => {
        const details = response.data.store[0];
        dispatch({
          type: FECTH_PRODUCT_STORE_DETAILS_SUCCESS,
          payload: details,
        });
      })
      .catch((error) => {
        dispatch({
          type: FECTH_PRODUCT_STORE_DETAILS_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchProductConditions = () => {
  return (dispatch) => {
    axios
      .get(`${url}/products/attributes/CONDITIONS`)
      .then((response) => {
        const conditions = response.data.options;
        dispatch({
          type: FETCH_PRODUCT_CONDITIONS_SUCCESS,
          payload: conditions,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PRODUCT_CONDITIONS_FAIL,
          payload: error,
        });
      });
  };
};
export const fetchProductDeliveryOptions = () => {
  return (dispatch) => {
    axios
      .get(`${url}/products/attributes/DELIVERY`)
      .then((response) => {
        const deliveryOptions = response.data.options;
        dispatch({
          type: FETCH_PRODUCT_DELIVERY_OPTIONS_SUCCESS,
          payload: deliveryOptions,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PRODUCT_DELIVERY_OPTIONS_FAIL,
          payload: error,
        });
      });
  };
};
export const fetchProductColorsOptions = () => {
  return (dispatch) => {
    axios
      .get(`${url}/products/attributes/COLORS`)
      .then((response) => {
        const colors = response.data.options;
        dispatch({
          type: FETCH_PRODUCT_COLORS_SUCCESS,
          payload: colors,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PRODUCT_COLORS_FAIL,
          payload: error,
        });
      });
  };
};
