export const FETCH_CART_ITEMS_START = 'FETCH_CART_ITEMS_START';
export const FETCH_CART_ITEMS_SUCCESS = 'FETCH_CART_ITEMS_SUCCESS';
export const FETCH_CART_ITEMS_FAIL = 'FETCH_CART_ITEMS_FAIL';

export const CART_NEW_ITEM = 'CART_NEW_ITEM'

export const CART_VOUCHER = 'CART_VOUCHER';

export const CART_TOTAL = "CART_TOTAL";
export const CART_SUBTOTAL = "CART_SUBTOTAL";
export const CART_SHIPPING_TOTAL = "CART_SHIPPING_TOTAL";

export const COUNT_CART_ITEMS = 'COUNT_CART_ITEMS';

export const ADD_CART_ITEM = 'ADD_CART_ITEM';

export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';

export const ADD_CART_ITEM_SUCCESS = 'ADD_CART_ITEM_SUCCESS';
export const ADD_CART_ITEM_FAIL = 'ADD_CART_ITEM_FAIL';

export const FETCH_SHOPPING_BAG_START = 'FETCH_SHOPPING_BAG_START';
export const FETCH_SHOPPING_BAG_SUCCESS = 'FETCH_SHOPPING_BAG_SUCCESS';
export const FETCH_SHOPPING_BAG_FAIL = 'FETCH_SHOPPING_BAG_FAIL';
