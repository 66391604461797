import axios from 'axios';
import { url } from '../../api';
import {
  GET_WALLET_START,
  GET_WALLET_SUCCESS,
  GET_WALLET_FAIL,
  GET_TRANSACTIONS_FAIL,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_START,
  DEPOSIT_SUCCESS,
  DEPOSIT_FAIL,
} from './walletTypes';

export const fetchWallet = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_WALLET_START });
    const authToken = getState().auth.token;

    axios
      .get(`${url}/wallets`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        const balance = res.data.wallet.currentBalance;
        const pending = res.data.wallet.pendingAmount;
        dispatch({
          type: GET_WALLET_SUCCESS,
          payload: {
            balance: balance,
            pending: pending,
          },
        });
      })
      .catch((err) => {
        // console.log(err);
        dispatch({ type: GET_WALLET_FAIL, payload: err.response });
      });
  };
};

export const fetchWalletTransactions = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_TRANSACTIONS_START });
    const authToken = getState().auth.token;
    axios
      .get(`${url}/wallets/transactions`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        const transactions = res.data.transactions;

        dispatch({
          type: GET_TRANSACTIONS_SUCCESS,
          payload: transactions,
        });
      })
      .catch((err) => {
        // console.log(err);
        dispatch({ type: GET_TRANSACTIONS_FAIL, payload: err });
      });
  };
};

export const depositToWallet = (payDetails) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    axios
      .post(`${url}/wallets/deposit`, payDetails, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        const results = response.data.message;
        dispatch({ type: DEPOSIT_SUCCESS, payload: results });
      })
      .catch((error) => {
        dispatch({ type: DEPOSIT_FAIL, payload: error });
      });
  };
};
