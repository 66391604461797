import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import FeedbackCard from '../../parts/Shops/FeedbackCard';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {
  fetchMerchantStoreSentFeedbacK,
  fetchMerchantStoreReceivedFeedbacK,
} from '../../redux/merchant/merchantActions';

const useStyles2 = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    borderRadius: '5px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.07)',
  },
  spacing: {
    padding: '16px 8px',
  },

  btnCustom: {
    borderRadius: 0,
    border: '1px solid #7E7E7E',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    color: '#7E7E7E',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'inherit',
      color: '#7E7E7E',
    },
  },
  btnCustomSelected: {
    borderRadius: 0,
    fontFamily: ['Lato', 'sans-serif'].join(','),
    background: '#000000',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#000000',
      color: '#FFFFFF',
    },
  },
  flexLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const ReceivedFeedback = ({ feedback, err, loading }) => {
  const avClasses = useStyles2();
  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <p className="txt-violet">Loading ...</p>
        </Box>
      )}
      {err && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <p className="txt-ruby">You have not received any feedback</p>
        </Box>
      )}
      {feedback &&
        feedback.map((item, i) => (
          <FeedbackCard
            key={i}
            feedbackBy={item.name}
            ratingValue={item.rating}
            comment={item.comment}
            datePosted={item.datePosted}
            avatarStyles={avClasses.avatar}
          />
        ))}
    </>
  );
};

ReceivedFeedback.propTypes = {
  feedback: PropTypes.array.isRequired,
  err: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const SentFeedback = ({ feedback, err, loading }) => {
  const avClasses = useStyles2();

  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <p className="txt-violet">Loading ...</p>
        </Box>
      )}
      {err && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <p className="txt-ruby">You have not sent any feedback</p>
        </Box>
      )}
      {feedback &&
        feedback.map((item, i) => (
          <FeedbackCard
            key={i}
            feedbackBy={item.name}
            ratingValue={item.rating}
            comment={item.comment}
            datePosted={item.datePosted}
            avatarStyles={avClasses.avatar}
          />
        ))}
    </>
  );
};

SentFeedback.propTypes = {
  feedback: PropTypes.array.isRequired,
  err: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapState = ({ merchant }) => ({
  receivedFeedbackLoading: merchant.receivedFeedbackLoading,
  receivedFeedback: merchant.receivedFeedback,
  receivedFeedbackErr: merchant.receivedFeedbackErr,

  sentFeedbackLoading: merchant.sentFeedbackLoading,
  sentFeedback: merchant.sentFeedback,
  sentFeedbackErr: merchant.sentFeedbackErr,
});
const MerchantAdminPanelFeedback = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    receivedFeedback,
    receivedFeedbackErr,
    receivedFeedbackLoading,
    sentFeedbackLoading,
    sentFeedback,
    sentFeedbackErr,
  } = useSelector(mapState);

  const [showReceived, setshowReceived] = useState(true);
  const [showSent, setshowSent] = useState(false);
  const [isBtn1Selected, setisBtn1Selected] = useState(true);
  const [isBtn2Selected, setisBtn2Selected] = useState(false);

  useEffect(() => {
    dispatch(fetchMerchantStoreSentFeedbacK());
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMerchantStoreReceivedFeedbacK());
    return () => {};
  }, [dispatch]);

  const handleClickIncomplete = () => {
    setshowReceived(false);
    setshowSent(true);
    setisBtn1Selected(false);
    setisBtn2Selected(true);
  };

  const handleClickComplete = () => {
    setshowSent(false);
    setshowReceived(true);
    setisBtn1Selected(true);
    setisBtn2Selected(false);
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <div className={`${classes.spacing} ${classes.flexLayout}`}>
        <h3 className="txt-capitalize">Feedback</h3>
        <div style={{ display: 'flex' }}>
          <Button
            variant="outlined"
            size="small"
            className={
              isBtn1Selected ? classes.btnCustomSelected : classes.btnCustom
            }
            onClick={handleClickComplete}
          >
            Received
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={
              isBtn2Selected ? classes.btnCustomSelected : classes.btnCustom
            }
            onClick={handleClickIncomplete}
          >
            Sent
          </Button>
        </div>
      </div>
      <Divider />
      <div className={classes.spacing}>
        {showReceived && (
          <ReceivedFeedback
            feedback={receivedFeedback}
            err={receivedFeedbackErr}
            loading={receivedFeedbackLoading}
          />
        )}
        {showSent && (
          <SentFeedback
            feedback={sentFeedback}
            err={sentFeedbackErr}
            loading={sentFeedbackLoading}
          />
        )}
      </div>
    </Paper>
  );
};

export default MerchantAdminPanelFeedback;
