import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import BaseBtn from '../../components/BaseBtn';

const useStyles = makeStyles((theme) => ({
  btn: {
    // backgroundColor: '#D81E5B',
    color: 'white',
    width: '96px',
    marginTop: '0.35rem',
  },
  htext: {
    fontFamily: ['Syncopate', 'sans-serif'].join(','),
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  ptext: {
    color: '#525252',
    marginBottom: '16px',
  },
  img: {
    height: '100%',
    width: ' 100%',
    objectFit: 'cover',
  },
  textBox: {
    paddingLeft: '120px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
    },
  },
}));

const PromotionBanner = ({
  promoImg,
  promoPrice,
  promoTitle,
  bannerBackgroundColor,
  themeColor,
}) => {
  const classes = useStyles();
  return (
    <div
      className="prom-banner-wrapper"
      style={{ backgroundColor: `${bannerBackgroundColor}` }}
    >
      <div className="prom-banner-title">
        <Container maxWidth="sm" className={classes.textBox}>
          <Typography
            variant="h4"
            className={classes.htext}
            style={{ color: `${themeColor}` }}
          >
            {promoTitle}
          </Typography>
          <Typography variant="subtitle1" className={classes.ptext}>
            Look Good. Feel Good.{' '}
            <span style={{ color: `${themeColor}` }}>
              From KSh {promoPrice}
            </span>
          </Typography>

          <BaseBtn
            className={classes.btn}
            style={{ backgroundColor: `${themeColor}` }}
          >
            Shop Now
          </BaseBtn>
        </Container>
      </div>
      <div className="prom-banner-media">
        <img src={promoImg} alt="promotion banner " className={classes.img} />
      </div>
    </div>
  );
};
PromotionBanner.propTypes = {
  promoImg: PropTypes.string.isRequired,
  promoPrice: PropTypes.string.isRequired,
  promoTitle: PropTypes.string.isRequired,
  bannerBackgroundColor: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
};

export default PromotionBanner;
