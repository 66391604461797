import React from 'react'
import ForYou from './ForYou'
import { Paper } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DailyDeals from '../../../../assets/images/daily_deals.png';
import ExpressDeliver from '../../../../assets/images/express_delivery.png';
import LukhuPicksClothe from '../../../../assets/images/lukhu_picks.png';
import TopSeller from '../../../../assets/images/top_seller.png';

const useStyles = makeStyles((theme) => ({
    root:{
      display:"flex",
      flexWrap:"nowrap",
      overflow:"scroll",
      alignItems:"center",
      justifyContent:"space-between",
      marginTop:"10px",
      background:"#F5F5F5"
      
    }
}))
const MainForYou = () => {
    const classes = useStyles();
  return (
    <Paper className={classes.root}>
        <ForYou image={DailyDeals} name={"Daily Deals"} />
        <ForYou image={ExpressDeliver} name ={"Express Delivery"} />
        {/* <ForYou image={TopSeller} name={"Top Seller"} /> */}
        <ForYou iamge={LukhuPicksClothe} name={"Lukhu Picks"} />

    </Paper>
  )
}

export default MainForYou