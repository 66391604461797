/* 
 This component renders if a search query is made from  specific category (women, men, kidsware) homepage
*/

import { useEffect, useState } from 'react';
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Container from '@material-ui/core/Container';

import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseCollapse from '../../components/BaseCollapse';
import SetPriceInput from '../../parts/Home/SetPriceInput';
import CustomSelect from '../../components/NoBorderInput';
import SearchProductResults from '../../parts/Search/SearchProductResults';
import WelcomeBanner from '../../parts/Home/WelcomeBanner';

import promo1 from '../../assets/images/black-hat-model.jpg';
import {
  fetchCategoryProductsResults,
  fetchMainCategoriesList,
  fetchAllCategories,
} from '../../redux/search/searchActions';
import {
  fetchProductConditions,
  fetchProductColorsOptions,
} from '../../redux/products/productsActions';
import { fetchFilterResults } from '../../redux/search/searchActions';
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  sizeBtn: {
    padding: '4px',
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-4px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  listItem: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  nestedListItem: {
    padding: '4px 0px',
  },
  listText: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  listTextSmall: {
    fontSize: '1rem',
  },
  categoryTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  attrTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontSize: '0.875rem',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  nestedListItemText: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 400,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
    color: '#111111',
  },
  listActionPosition: {
    right: 0,
  },
  setPriceButton: {
    color: '#471CF9',
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 700,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
  },
  grid: {
    margin: '0 auto',
    paddingTop: '24px',
    paddingBottom: '16px',
  },
  colorCircle: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  selectedColorCircle: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  filterNav: {
    width: '100%',
    marginRight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  navList: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
}));

const mapState = ({ search, product }) => ({
  categoryProductsResults: search.categoryProductsResults,
  categoryProductsResultsError: search.categoryProductsResultsError,
  loadingcategoryProductsResults: search.loadingcategoryProductsResults,
  listingCount: search.categoryProductsResultsLength,
  loadingAllCategories: search.loadingAllCategories,
  allCategories: search.allCategories,
  productConditions: product.productConditions,
  productColors: product.productColors,
  searchQuery: search.searchQuery,
  searchQueryResults: search.searchQueryResults,
  loadingSearchQuery: search.loadingSearchQuery,
  searchQueryResultsCount: search.searchQueryResultsCount,
  filterResults: search.filterResults,
  filterResultsError: search.filterResultsError,
  filterResultsCount: search.filterResultsCount,
  loadingFilterResults: search.loadingFilterResults,
});
const priceRange = [
  'Under KSh 1,000',
  'KSh 1,000 - KSh 2,000',
  'KSh 1,000 - KSh 2,000',
  'KSh 4,000+',
];
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const GeneralSearchPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { category } = useParams();
  const { pathname, search } = useLocation();
  const { url } = useRouteMatch();
  let query = useQuery();
  const dispatch = useDispatch();

  const {
    categoryProductsResults,
    loadingcategoryProductsResults,
    loadingAllCategories,
    allCategories,
    productColors,
    productConditions,
    listingCount,
    searchQuery,
    searchQueryResults,
    searchQueryResultsCount,
    loadingSearchQuery,
    filterResults,
    filterResultsCount,
    filterResultsError,
    loadingFilterResults,
  } = useSelector(mapState);

  useEffect(() => {
    dispatch(fetchProductConditions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductColorsOptions());
  }, [dispatch]);

  // dispatch(fetchSearchResults(searchString));

  const [maxPrice, setmaxPrice] = useState('');
  const [minPrice, setminPrice] = useState('');

  const [categoryFilterVal, setcategoryFilterVal] = useState();
  const [colorFilterVal, setcolorFilterVal] = useState();
  const [priceFilterVal, setpriceFilterVal] = useState();
  const [conditionFilterVal, setconditionFilterVal] = useState();
  const [searchStringVal, setsearchStringVal] = useState();

  useEffect(() => {
    const filterQuery = search.substring(1);
    dispatch(fetchFilterResults(filterQuery));

    // console.log(filterQuery);
    return () => {};
  }, [categoryFilterVal, colorFilterVal, priceFilterVal, conditionFilterVal]);

  const setFilter = (key, value) => {
    // check key exists and remove it
    if (query.has(key)) {
      query.delete(key);
    }
    return query.append(key, value);
  };

  // setSearchStringFilter = () => {
  //   // if it exists, use it
  //   const searchString = query.get('name');
  //   if (searchString) {
  //     setsearchStringVal(searchString)
  //   } else {
  //     setFilter('name', )
  //   }

  // };

  const setCategoryFilter = (val) => {
    setFilter('category', val);
    history.push(`${url}?${query}`);
    setcategoryFilterVal(query.get('category'));
  };
  const setCondtionFilter = (val) => {
    setFilter('condition', val);
    history.push(`${url}?${query}`);
    setconditionFilterVal(query.get('condition'));
  };

  const setPriceFilter = (min, max) => {
    const priceString = `${min},${max}`;
    setFilter('price', priceString);
    history.push(`${url}?${query}`);
    setpriceFilterVal(query.get('price'));
    // console.log(query.get('price'));
  };
  const handleSetPrice = () => {
    let max = maxPrice;
    let min = minPrice;
    if (Number(min) > Number(max)) {
      setminPrice('0');
      min = '0';
    }

    const priceString = `${min},${max}`;
    setFilter('price', priceString);
    history.push(`${url}?${query}`);
    setpriceFilterVal(query.get('price'));
    // console.log(query.get('price'));
  };

  const setColorFilter = (val) => {
    setFilter('color', val);
    history.push(`${url}?${query}`);
    setcolorFilterVal(query.get('color'));
  };

  const viewProductDetails = (id) => {
    history.push(`/catalog/${id}`);
  };
  const [sortBy, setSortBy] = useState('on sale');
  const handleSortBy = (event) => {
    setSortBy(event.target.value);
  };

  return (
    <div className="bg-home">
      <Grid container>
        <Grid item xs={12}>
          <WelcomeBanner
            bannerBackgroundColor="#FFF5F6"
            themeColor="#D81E5B"
            promoImg={promo1}
            promoTitle="WELCOME TO LUKHU"
          />
        </Grid>
      </Grid>

      <Container maxWidth="lg" classes={{ root: classes.root }}>
        <Grid container className={classes.grid}>
          <Grid item xs={12}>
            <div className="d-flex d-flex-space pb-16">
              <h4 className="f-16 txt-semi-bold">
                {searchQueryResultsCount
                  ? searchQueryResultsCount
                  : 'No available'}{' '}
                &#39;{searchQuery}&#39; listings
              </h4>
              {/* <div className="d-flex">
              <h4 className="f-16 txt-medium">Sort by : </h4>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={sortBy}
                onChange={handleSortBy}
                input={<CustomSelect />}
              >
                <MenuItem value="other value">other values</MenuItem>
              </Select>
            </div> */}
            </div>
            <Divider />
          </Grid>
        </Grid>
        <Grid container className={classes.grid}>
          <Grid item md={2} xs={12}>
            <div className={classes.filterNav}>
              {/* <div className="pb-16">
              <h4 className="f-16 pb-8 txt-semi-bold">Size</h4>
              <p>
                Sign up or log in to add your sizes and we’ll customise your
                feed to better fit your needs
              </p>
              <div className="pb-8 pt-16">
                <BaseBtnFilled fullWidth className={classes.sizeBtn}>
                  Add My Sizes
                </BaseBtnFilled>
              </div>
              <div></div>
            </div> */}

              {/* <Divider /> */}
              {/* <div className="pt-16 pb-16">
              {allCategories && (
                <List component="nav">
                  {allCategories
                    .filter((cat) => cat.parent_id === 0)
                    .map((item) => (
                      <BaseCollapse
                        key={item.id}
                        title={item.name}
                        titleStyles={classes.categoryTitle}
                      >
                        {allCategories
                          .filter((cat) => cat.parent_id === item.id)
                          .map((subCat) => (
                            <ListItem
                              button
                              key={subCat.id}
                              alignItems="flex-start"
                              // onClick={() => handleSetNewCat(subCat.name)}
                            >
                              <ListItemText
                                primary={subCat.name}
                                disableTypography
                                className={classes.listTextSmall}
                              />
                            </ListItem>
                          ))}
                      </BaseCollapse>
                    ))}
                </List>
              )}
            </div> */}

              <List className={classes.navList}>
                {/* <Divider /> */}

                <BaseCollapse title="price" titleStyles={classes.attrTitle}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      margin: '8px 0',
                    }}
                  >
                    <Box>
                      <SetPriceInput
                        placeholder="min"
                        value={minPrice}
                        onChange={(e) => setminPrice(e.target.value)}
                      />
                    </Box>
                    <div>
                      {' '}
                      <pre> — </pre>
                    </div>
                    <Box>
                      <SetPriceInput
                        placeholder="max"
                        value={maxPrice}
                        onChange={(e) => setmaxPrice(e.target.value)}
                      />
                    </Box>

                    <div>
                      {' '}
                      <Button
                        color="primary"
                        className={classes.setPriceButton}
                        // onClick={setPriceFilter(minPrice, maxPrice)}
                        onClick={handleSetPrice}
                      >
                        Set
                      </Button>
                    </div>
                  </div>

                  <ListItem
                    button
                    alignItems="flex-start"
                    className={classes.nestedListItem}
                    onClick={() => setPriceFilter('0', '1000')}
                  >
                    <ListItemText
                      primary="Under KSh 1,000"
                      disableTypography
                      className={classes.nestedListItemText}
                    />
                  </ListItem>
                  <ListItem
                    button
                    alignItems="flex-start"
                    className={classes.nestedListItem}
                    onClick={() => setPriceFilter('1000', '2000')}
                  >
                    <ListItemText
                      primary="KSh 1,000 - KSh 2,000"
                      disableTypography
                      className={classes.nestedListItemText}
                    />
                  </ListItem>
                  <ListItem
                    button
                    alignItems="flex-start"
                    className={classes.nestedListItem}
                    onClick={() => setPriceFilter('2000', '3000')}
                  >
                    <ListItemText
                      primary="KSh 2,000 - KSh 3,000"
                      disableTypography
                      className={classes.nestedListItemText}
                    />
                  </ListItem>
                  <ListItem
                    button
                    alignItems="flex-start"
                    className={classes.nestedListItem}
                    onClick={(e) => setPriceFilter('3000', '4000')}
                  >
                    <ListItemText
                      primary="KSh 3,000 - KSh 4,000"
                      disableTypography
                      className={classes.nestedListItemText}
                    />
                  </ListItem>
                  <ListItem
                    button
                    alignItems="flex-start"
                    className={classes.nestedListItem}
                    onClick={() => setPriceFilter('4000', '10000')}
                  >
                    <ListItemText
                      primary="KSh 4,000+"
                      disableTypography
                      className={classes.nestedListItemText}
                    />
                  </ListItem>
                </BaseCollapse>
                <Divider />
                <BaseCollapse title="condition" titleStyles={classes.attrTitle}>
                  {productConditions &&
                    productConditions.map((item) => (
                      <ListItem
                        button
                        key={item.id}
                        alignItems="flex-start"
                        className={classes.nestedListItem}
                        onClick={() => setCondtionFilter(item.name)}
                      >
                        <ListItemText
                          disableTypography
                          primary={item.name}
                          className={classes.nestedListItemText}
                        />
                      </ListItem>
                    ))}
                </BaseCollapse>
                <Divider />
                <BaseCollapse title="color" titleStyles={classes.attrTitle}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(4,1fr)',
                      gridRowGap: '12px',
                    }}
                  >
                    {productColors &&
                      productColors.map((color) => (
                        <div
                          key={color.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                          onClick={() => setColorFilter(color.id)}
                        >
                          <div
                            className={classes.colorCircle}
                            style={{
                              backgroundColor: `#eeeeee`,
                            }}
                          ></div>
                          <p className="f-12">{color.name}</p>
                        </div>
                      ))}
                  </div>
                </BaseCollapse>

                <Divider />
              </List>
            </div>
          </Grid>
          <Grid item md={10} xs={12}>
            {loadingSearchQuery ||
              (loadingFilterResults && (
                <div className=" disp-empty">
                  <p className="txt-violet"> Loading ... </p>
                </div>
              ))}
            {searchQueryResults && searchQueryResultsCount < 1 && (
              <div className=" disp-empty">
                <p className="txt-violet">
                  {' '}
                  Oops! No search results for &#39;{searchQuery}&#39;
                </p>
              </div>
            )}
            {filterResults && filterResultsCount < 1 && (
              <div className=" disp-empty">
                <p className="txt-violet"> Oops! No search results!</p>
              </div>
            )}
            <div className={classes.itemContainer}>
              {!filterResults &&
                searchQueryResults &&
                searchQueryResults.map((item) => (
                  <SearchProductResults
                    key={item.id}
                    handleClick={() => viewProductDetails(item.id)}
                    itemCondition={item.condition}
                    itemId={item.id}
                    itemImg={item.images[0].secure_url}
                    itemLocation={item.location}
                    itemName={item.productName}
                    itemPrice={item.price}
                    itemSize={item.size}
                    itemStoreName={item.storeName}
                  />
                ))}
              {filterResults &&
                filterResults.map((item) => (
                  <SearchProductResults
                    key={item.id}
                    handleClick={() => viewProductDetails(item.id)}
                    itemCondition={item.condition}
                    itemId={item.id}
                    itemImg={item.images[0].secure_url}
                    itemLocation={item.location}
                    itemName={item.productName}
                    itemPrice={item.price}
                    itemSize={item.size}
                    itemStoreName={item.storeName}
                  />
                ))}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default GeneralSearchPage;
