import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';

import CustomSelect from '../../components/NoBorderInput';
import {
  fetchAvgDailyIncome,
  fetchMerchantStoreStats,
} from '../../redux/merchant/merchantActions';
// DUMMY DATA
const dummyAverageDailyIncome = '1,276';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
    boxShadow: ' 0 3px 6px 0 rgba(0, 0, 0, 0.07)',
    borderRadius: '5px',
  },
  summaryCard: {},
  summaryCardSec2: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  dayAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  section1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '5px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #efefef',
    padding: '16px',
  },
  spacing: {
    marginBottom: '16px',
  },
  spacing2: {
    marginBottom: '32px',
  },
  chartSection: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  menuItem: {
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontFamily: ['Lato', 'sans-serif'].join(', '),
  },
  menuItemSelected: {
    fontWeight: 600,
  },
  fullHeight: {
    height: '100%',
  },
}));
const daysDisplayStyles = makeStyles((theme) => ({
  inactive: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.75rem',
  },
  active: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.75rem',
    backgroundColor: '#481CFB',
  },
  btn: {
    padding: 0,
  },
}));

const days = [
  { name: 'Sunday', abbr: 'S' },
  { name: 'Monday', abbr: 'M' },
  { name: 'Tuesday', abbr: 'T' },
  { name: 'Wednesday', abbr: 'W' },
  { name: 'Thursday', abbr: 'T' },
  { name: 'Friday', abbr: 'F' },
  { name: 'Saturday', abbr: 'S' },
];

// const profileViews = [
//   {
//     day: 'M',
//     numberOfViews: 10,
//   },
//   {
//     day: 'T',
//     numberOfViews: 10,
//   },
//   {
//     day: 'W',
//     numberOfViews: 10,
//   },
//   {
//     day: 'T',
//     numberOfViews: 10,
//   },
//   {
//     day: 'F',
//     numberOfViews: 10,
//   },
//   {
//     day: 'S',
//     numberOfViews: 10,
//   },
//   {
//     day: 'S',
//     numberOfViews: 10,
//   },
// ];

// const itemsSold = [
//   {
//     day: 'Monday',
//     numberOfItems: 10,
//   },
//   {
//     day: 'Tuesday',
//     numberOfItems: 10,
//   },
//   {
//     day: 'Wednesday',
//     numberOfItems: 20,
//   },
//   {
//     day: 'Thursday',
//     numberOfItems: 10,
//   },
//   {
//     day: 'Friday',
//     numberOfItems: 70,
//   },
//   {
//     day: 'Saturday',
//     numberOfItems: 10,
//   },
//   {
//     day: 'Sunday',
//     numberOfItems: 10,
//   },
// ];
// end of dummy data

// const itemsWithAbbr = itemsSold.map((item) => {
//   const container = {}

//   container.abbr = item.day.substring(0,1)
//   container.day = item.day
//   container.numberOfItems = item.numberOfItems

//   return container

// })

function DisplayDays({ day, dayAbbr, handleClick, selectedDay }) {
  const classes = daysDisplayStyles();

  return (
    <IconButton onClick={handleClick} className={classes.btn}>
      <Avatar
        className={selectedDay === day ? classes.active : classes.inactive}
      >
        {dayAbbr}
      </Avatar>
    </IconButton>
  );
}

DisplayDays.propTypes = {
  day: PropTypes.string.isRequired,
  dayAbbr: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  selectedDay: PropTypes.string.isRequired,
};

const mapState = ({ merchant }) => ({
  statLoading: merchant.statLoading,
  profileViews: merchant.statProfileViews,
  itemsSold: merchant.statItemsSold,
  statFetchErr: merchant.statFetchErr,
  avgDailyIncomeLoading: merchant.avgDailyIncomeLoading,
  avgDailyIncome: merchant.avgDailyIncome,
  avgDailyIncomeErr: merchant.avgDailyIncomeErr,
});

const MerchantAdminPanelStatistics = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    avgDailyIncome,
    avgDailyIncomeErr,
    avgDailyIncomeLoading,
    statLoading,
    profileViews,
    itemsSold,
    statFetchErr,
  } = useSelector(mapState);

  const modItemsSold =
    itemsSold &&
    itemsSold.map((item) => {
      const container = {};

      container.abbr = item.day.substring(0, 1);
      container.day = item.day;
      container.numberOfItems = item.numberOfItems;

      return container;
    });

  const modProfileViews =
    profileViews &&
    profileViews.map((item) => {
      const container = {};

      container.abbr = item.day.substring(0, 1);
      container.day = item.day;
      container.numberOfViews = item.numberOfViews;

      return container;
    });
  const getDayName = () => {
    let today = new Date();
    let todayIdx = today.getDay();
    if (todayIdx === 0) {
      return 'Sunday';
    } else if (todayIdx === 1) {
      return 'Monday';
    } else if (todayIdx === 2) {
      return 'Tuesday';
    } else if (todayIdx === 3) {
      return 'Wednesday';
    } else if (todayIdx === 4) {
      return 'Thursday';
    } else if (todayIdx === 5) {
      return 'Friday';
    } else {
      return 'Saturday';
    }
  };

  const [selectedDay, setselectedDay] = useState(getDayName());
  // const [selectedDayIncome, setselectedDayIncome] = useState('');

  const handleSelectDay = (val) => {
    setselectedDay(val);
  };

  useEffect(() => {
    dispatch(fetchAvgDailyIncome(selectedDay));
    return () => {};
  }, [dispatch, selectedDay]);
  // const getAvgDailyIncome = (day) => {
  //   if (avgDailyIncome) {
  //     // get corresponding day name then get the value of that day
  //     // avgDailyIncome.filter(item => item)
  //   }
  // };
  // useEffect(() => {
  //   getAvgDailyIncome();
  //   return () => {};
  // }, [selectedDay]);

  const [period, setPeriod] = useState('last 7 days');
  const handleSelectPeriod = (event) => {
    setPeriod(event.target.value);
  };
  return (
    <Paper elevation={0} className={classes.root}>
      <div className="mb-16 d-flex  d-flex-space d-flex-align-center">
        <h3 className="txt-capitalize"> Your Shop Stats</h3>
        <Select
          labelId="select-period-label"
          id="select-period"
          value={period}
          onChange={handleSelectPeriod}
          input={<CustomSelect />}
        >
          <MenuItem
            value="last 7 days"
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
          >
            last 7 days
          </MenuItem>
          {/* <MenuItem
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
            value="last week"
          >
            last week
          </MenuItem> */}
          {/* <MenuItem
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
            value="last month"
          >
            last month
          </MenuItem> */}
          {/* <MenuItem
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
            value="last year"
          >
            last year
          </MenuItem> */}
          {/* <MenuItem
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
            value="all time"
          >
            all time
          </MenuItem> */}
        </Select>
      </div>
      <Paper className={classes.section1}>
        <div>
          <p className="txt-green txt-bold f-20">Ksh {avgDailyIncome}</p>
          <p className="txt-gray3 f-12">Average Daily Income (KSh)</p>
        </div>
        <div className={classes.summaryCardSec2}>
          {days.map((item, i) => (
            <DisplayDays
              key={i}
              day={item.name}
              dayAbbr={item.abbr}
              selectedDay={selectedDay}
              handleClick={() => handleSelectDay(item.name)}
            />
          ))}
        </div>
      </Paper>
      <div className={classes.spacing}></div>

      <div className="d-flex">
        {/* #481CFB */}
        <Box width="50%" mr={1}>
          <Card>
            <CardContent>
              <div>
                <h4 className="f-14 txt-capitalize">Profile Views</h4>
                {/* <p className="f-12 txt-gray3">from - to</p> */}
              </div>
              {statLoading && (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <p className="txt-violet"> loading ... </p>
                </Box>
              )}

              {statFetchErr && (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <p className="txt-ruby"> error while loading data!</p>
                </Box>
              )}

              {profileViews && (
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={modProfileViews}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <XAxis dataKey="abbr" fontSize={12} />
                    <YAxis hide />
                    <Bar dataKey="numberOfViews" fill="#481CFB" barSize={5}>
                      <LabelList
                        dataKey="views"
                        position="top"
                        fill="#481CFB"
                        fontSize={12}
                        fontWeight={700}
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </CardContent>
          </Card>
        </Box>

        <Box width="50%">
          <Card>
            <CardContent>
              <h4 className="f-14 txt-capitalize">Items sold</h4>
              {/* <p className="f-12 txt-gray3">from - to</p> */}
              {statLoading && (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <p className="txt-violet"> loading ... </p>
                </Box>
              )}

              {statFetchErr && (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <p className="txt-ruby"> error while loading data!</p>
                </Box>
              )}

              {itemsSold && (
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={modItemsSold}
                    margin={{
                      top: 5,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <XAxis dataKey="abbr" fontSize={12} />
                    {/* <XAxis 
           dataKey="Text"
           fontFamily="sans-serif"
           tickSize
                  dy='25 */}
                    <YAxis hide />
                    <Bar dataKey="numberOfItems" fill="#481CFB" barSize={5}>
                      <LabelList
                        dataKey="items"
                        position="top"
                        fill="#481CFB"
                        fontSize={12}
                        fontWeight={700}
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </CardContent>
          </Card>
        </Box>
      </div>
      <div className={classes.spacing2}></div>
      <div>
        <p className="txt-gray3 f-14">
          <span className="txt-black txt-bold">Profile Views:</span> This
          section displays the total number of profile views per day
        </p>
        <p className="txt-gray3 f-14">
          <span className="txt-black txt-bold">Items Sold:</span> This section
          displays your total sold items over a period of time
        </p>
        <p className="txt-gray3 f-14">
          <span className="txt-black txt-bold">Average Daily Income:</span> This
          section displays your average income
        </p>
      </div>
    </Paper>
  );
};

export default MerchantAdminPanelStatistics;
