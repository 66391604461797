import React from 'react';

import ComingSoon from '../pages/ComingSoon';

const About = () => {
  
  
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default About;
