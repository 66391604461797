import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: 'green',
    position: 'absolute',
    bottom: '10%',
    left: '50%',
    // marginTop: -12,
    // marginLeft: -12,
  },
}));
const BaseLoadingProgress = () => {
  const classes = useStyles();
  return <CircularProgress size={24} className={classes.buttonProgress} />;
};

export default BaseLoadingProgress;
