import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import BaseBtn from '../../components/BaseBtn';

const useStyles = makeStyles({
  root: {
    width: '696px',
  },

  layout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '64px 0',
  },
  title: {
    color: '#4a4062',
    marginBottom: '24px',
  },
  btnFilled: {
    marginTop: '24px',
    backgroundColor: '#4900ff',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFFFFF',
    },
    width: '240px',
  },
  btnTxt: {
    marginTop: '16px',
    textTransform: 'capitalize',
    color: '#111111',
    textDecoration: 'underline',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    fontSize: '16px',
  },
});

const DialogStoreSetupFail = ({ handleClose, open, title, btnTxt }) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
    >
      <div className={classes.layout}>
        <h3 className={classes.title}>{title}</h3>
        <ErrorOutlineIcon fontSize="large" style={{ color: '#d81e5b' }} />
        <BaseBtn className={classes.btnFilled} onClick={handleClose}>
          <span style={{ color: '#ffffff' }}>{btnTxt}</span>
        </BaseBtn>
      </div>
    </Dialog>
  );
};

DialogStoreSetupFail.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  btnTxt: PropTypes.string.isRequired,
};

export default DialogStoreSetupFail;
