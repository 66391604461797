import { useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';

import BetaLayout from './BetaLayout';
import CustomCard from './CustomCard';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import { Box, CardActions } from '@material-ui/core';
import CustomContainer from './CustomContainer';
const useStyles = makeStyles((theme) => ({
  content: {
    textAlign: 'center',
  },
  actions: {
    flexDirection: 'column',
  },
  btn: {
    width: '100%',
    maxWidth: '360px',
    textTransform: 'inherit',
    // [theme.breakpoints.down('xs')]: {
    //   height: '100vh',
    // },
  },
  ctn: {
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
  },
}));

const mapState = ({ auth }) => ({
  token: auth.token,
  profile: auth.profile,
});

const BetaHome = () => {
  const classes = useStyles();

  const history = useHistory();
  const { token, profile } = useSelector(mapState);

  const handleLetIn = () => {
    history.push('/beta-welcome');
  };

  const handleSaveSeat = () => {
    history.push('/beta-save-seat');
  };

  if (token && profile) {
    return <Redirect to="/home" />;
  }

  return (
    <BetaLayout>
      <CustomContainer>
        <CustomCard>
          <h1 className=" txt-ruby">
            Hello there, <br /> we are in Beta!
          </h1>
          <div className="mb-24" />
          <p className="txt-violet mb-16 ">
            {' '}
            Thank you for showing interest in Lukhu! 🎉
          </p>
          <p className="txt-violet mb-16">
            We are currently putting the finishing touches on the app so we are
            only opening up access to a few peeps at a time to make sure nothing
            breaks! 😉
          </p>
          <p className="txt-violet mb-16">
            If you had already signed up for early access then good for you!
            Just tap on the Let me in button below to set up your Lukhu account!
          </p>
          <p className="txt-violet mb-16">
            If not, don’t worry! Just tap on the Save me a seat button below and
            we will add you to the waitlist. Once done, you will get access to
            the Lukhu app sooner than you think.
          </p>
          <p className="txt-violet mb-16">
            We are grateful you’re here and can’t wait to have you experience
            the magic we’re making at Lukhu! 🙏🏾
          </p>

          <CardActions className={classes.actions}>
            <BaseBtnFilled className={classes.btn} onClick={handleLetIn}>
              Let me in
            </BaseBtnFilled>
            <div className="mb-16" />
            <BaseBtnOutlined className={classes.btn} onClick={handleSaveSeat}>
              Save me a seat
            </BaseBtnOutlined>
          </CardActions>
        </CustomCard>
      </CustomContainer>
    </BetaLayout>
  );
};

export default BetaHome;
