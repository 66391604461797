import { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import BaseBtnFilled from '../../components/BaseBtnFilled';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
    height: '100%',
  },
  formArea: {
    padding: '12px',
    marginTop: '16px',
    backgroundColor: '#F5F6F8',
    boxShadow: 'none',
    borderRadius: '5px',
  },
  noInput: {
    '&. input': {
      backgroundColor: 'red',
    },
  },
  label: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),

    color: '#000000',
    fontSize: '0.675rem',
    fontWeight: 600,
  },
  btn1: {
    padding: '4px 24px',
    fontSize: '0.75rem',
  },
  btn2: {
    marginTop: '16px',
    padding: '4px 24px',
    fontSize: '0.75rem',
    width: '120px',
  },
  adornment: {
    color: '#717171',
    fontSize: '0.75rem',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
}));

const CustomInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
      fontSize: '0.75rem',
    },
    backgroundColor: '#FFFFFF',
    padding: '4px 16px',
    borderRadius: '5px',
  },
  input: {
    fontSize: '0.75rem',
  },
}))(InputBase);
const MerchantAdminPanelAddNo = ({ handleBack }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <IconButton className={classes.icnBtn} onClick={handleBack}>
            <ArrowBackIosIcon style={{ fontSize: '1rem', color: '#111111' }} />
          </IconButton>
          <h3>Add New Number</h3>
        </Box>
        <BaseBtnOutlined className={classes.btn1} onClick={handleBack}>
          Cancel
        </BaseBtnOutlined>
      </Box>
      <Paper className={classes.formArea}>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="mpesa-no" className={classes.label}>
            ENTER YOUR NUMBER BELOW
          </InputLabel>
          <CustomInput
            className={classes.noInput}
            id="mpesa-no"
            startAdornment={
              <InputAdornment position="start">
                <p className={classes.adornment}>+254</p>
              </InputAdornment>
            }
          />
        </FormControl>
        <BaseBtnFilled className={classes.btn2}>Save </BaseBtnFilled>
      </Paper>
    </Paper>
  );
};

MerchantAdminPanelAddNo.propTypes = {
  handleBack: PropTypes.func.isRequired,
};
export default MerchantAdminPanelAddNo;
