import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CardActionArea from '@material-ui/core/CardActionArea';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MerchantAvatar from '../Shops/MerchantAvatar';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    [theme.breakpoints.down("xs")]: {
      maxWidth: "170px"
    }
    
    // height: '100%',
    // maxHeight: '391px',
  },
  media: {
    width: '100%',
  
    height: 0,
    paddingBottom: '100%',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },

  p1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    color: '#111111',
    marginBottom: '8px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  p2: {
    fontSize: '0.875rem',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    color: '#525252',
    fontWeight: 400,
    marginBottom: '8px',
  },
  p3: {
    color: '#111111',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontWeight: 700,
    fontSize: '1rem',
  },

  chip: {
    padding: '14px 16px',
    color: '#D80000',
    backgroundColor: '#ffd8d8',
    fontSize: 14,
    fontFamily: 'Lato, sans-serif',
  },
  tag: {
    color: '#D80000',
    fontSize: 14,
  },
  locationIcon: {
    color: '#d81e5b',
    fontSize: '1rem',
  },
  location: {
    color: '#d81e5b',
    fontSize: '0.75rem',
    textTransform: 'capitalize',
    fontFamily: 'Montserrat, sans-serif',
  },

  locationContent: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: '16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px',
    [theme.breakpoints.down('xs')]: {
      // flexDirection: 'column',
      padding: '8px 2px',
      flexDirection: 'column',
      alignItems: 'normal',
    },
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
    color: '#525252',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  likesIcon: {
    padding: '0.25rem',
    // fontSize: '1.25rem',
    color: '#111111',
    fontWeight: 'lighter',
  },
  likesCtn: {},
}));

const ListingCard = ({ product }) => {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const history = useHistory();

  // console.log("product merchant",product)

  const viewProductDetails = (id) => {
    history.push(`/catalog/${id}`);
  };

  return (
    <Card
      className={classes.root}
      onClick={() => viewProductDetails(product?.id)}
    >
      <CardActionArea>
        <div className={classes.header}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '8px' }}>
              <MerchantAvatar
                image={product?.storeImage}
                avatarStyles={classes.avatar}
                verified = {product?.storeVerified}
              />
            </div>

            <div>
              <p className="f-14" style={{ color: '#898989' }}>
                {product?.storeName}
              </p>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <div>
              <p className="f-12 txt-black">{product?.likes}</p>
            </div>

            <FavoriteBorderIcon className={classes.likesIcon} />
          </div>
        </div>

        <CardMedia
         sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          image={product?.images.length > 0 ? product.images[0].secure_url : ''}
          className={classes.media}
        />
        <CardContent className={classes.content}>
          <div className={classes.locationContent}>
            <LocationOnIcon className={classes.locationIcon} />
            <p className={classes.location}>{product?.location}</p>
          </div>
          <p className={classes.p1}>{product?.name}</p>
          <p className={classes.p2}>
            Size : {product?.size} {bull} {product?.condition}
          </p>
          <p className={classes.p3}>KSh {product?.price}</p>
        </CardContent>
      </CardActionArea>

      {/* <CardActions>
        {productTag && (
          <Chip
            size="small"
            label={productTag}
            icon={
              productTag.toLowerCase() === 'popular' ? (
                <WhatshotIcon className={classes.tag} />
              ) : (
                <LocalOfferOutlinedIcon className={classes.tag} />
              )
            }
            className={classes.chip}
          />
        )}
      </CardActions> */}
    </Card>
  );
};
ListingCard.propTypes = {
  product: PropTypes.object.isRequired,
};
export default ListingCard;
