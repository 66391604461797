import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import BuyerNotifyGiveFeedback from './BuyerNotifyGiveFeedback';
import NotificationSeparator from './NotificationSeparator';
const BuyerNotifyItemOkay = ({ timestamp, orderID }) => {
  return (
    <Box>
      <Box display="flex">
        <Avatar style={{ background: '#707070', marginRight: '8px' }}>
          <DoneIcon style={{ color: '#ffffff' }} />
        </Avatar>
        <div className="notification-box">
          <p className="txt-time f-10">{timestamp}</p>
          <p className="txt-bold txt-blackTrue">You marked your items as OK!</p>
          <p className="txt-gray5 f-14">
            And we are more than happy that you did{' '}
          </p>
        </div>
      </Box>
      <BuyerNotifyGiveFeedback timestamp={timestamp} orderID={orderID} />
    </Box>
  );
};

BuyerNotifyItemOkay.propTypes = {
  orderID: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};
export default BuyerNotifyItemOkay;
