import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';

import BetaLayout from './BetaLayout';
import CustomCard from './CustomCard';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import BaseInput2 from '../../components/BaseInput2';
import CustomInput from './CustomInput';

import flagIcon from '../../assets/images/flag_icon_2.jpg';
import GoBackLink from './GoBackLink';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    boxShadow: '0px 6px 13px #4242421A',
    maxWidth: '450px',
  },
  content: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 32px 32px 32px',
  },
  actions: {
    flexDirection: 'column',
  },
  btn: {
    width: '360px',
    textTransform: 'inherit',
    fontSize: '0.875rem',
  },
  ctn: {
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ConfirmSaveSeat = () => {
  const classes = useStyles();

  return (
    <BetaLayout>
      <Container maxWidth="sm" className={classes.ctn}>
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <h2 className="txt-ruby">Your seat is saved!</h2>
            <div className="mb-24" />
            <p className="txt-violet mb-16">
              Your username and phone number are now on our waitlist. You will
              receive a text as soon as your account is ready! ⏰
            </p>
            <GoBackLink />
          </CardContent>
        </Card>
      </Container>
    </BetaLayout>
  );
};

export default ConfirmSaveSeat;
