import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';

import DialogHeading from './DialogHeading';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import BaseInput2 from '../../components/BaseInput2';
import {
  getMtaaniZones,
  getMtaaniStores,
  setPickupStore,
  getMtaaniRegions,
} from '../../redux/checkout/checkoutActions';

const URL = process.env.REACT_APP_SERVER;
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  paper: {
    padding: '8px',
    borderColor: '#601DFF',
    borderWidth: '0.5px',
    width: 'fit-content',
    maxWidth: '375px',
  },
  label: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  gridLayout: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gridRowGap: '8px',
    gridColumnGap: '8px',
  },
  btn: {
    width: '150px',
  },
  actions: {
    justifyContent: 'space-between',
    padding: '24px',
  },
  select: {
    backgroundColor: '#F5F2FE',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  selectLabel: {
    color: 'red',
  },
}));

const title = 'Select Dropoff Store';
const titleId = 'select-dropoff-store-title';
const subtitle = 'Set up your preferred Lukhu dropoff store below';
const subtitleId = 'select-dropoff-store-subtitle';

const mapState = ({ checkout, auth }) => ({
  mtaaniError: checkout.mtaaniError,
  mtaaniLoading: checkout.mtaaniLoading,
  mtaaniRegions: checkout.mtaaniRegions,
  mtaaniZones: checkout.mtaaniZones,
  mtaaniStores: checkout.mtaaniStores,
  authToken: auth.token,
});
const SelectDropoffStore = ({ handleClose, open, orderId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    mtaaniLoading,
    mtaaniRegions,
    mtaaniZones,
    mtaaniStores,
    mtaaniError,
    authToken,
  } = useSelector(mapState);

  useEffect(() => {
    dispatch(getMtaaniRegions());
    return () => {};
  }, [dispatch]);

  const [selected, setSelected] = useState('');
  const handleChange = (event) => {
    // console.log('store', selected);

    setSelected(event.target.value);
  };

  const [selectedRegion, setselectedRegion] = useState('');
  const handleChangeRegion = (event) => {
    const regionID = event.target.value;
    // console.log(regionID);
    setselectedRegion(regionID);
    dispatch(getMtaaniZones(regionID));
  };

  const [selectedZone, setselectedZone] = useState('');
  const handleChangeZone = (event) => {
    const zoneID = event.target.value;
    // console.log(zoneID);
    setselectedZone(zoneID);
    dispatch(getMtaaniStores(zoneID));
  };
  // TODO: Record selected option
  const [selectedStore, setselectedStore] = useState('');
  const handleSelectMtaaniStore = (event) => {
    const storeID = event.target.value;
    setselectedStore(storeID);
  };

  const [resError, setresError] = useState(null);
  const [resSuccess, setresSuccess] = useState(null);

  const saveStore = (orderId) => {
    const store = mtaaniStores.find((item) => item.id == selectedStore);
    // dispatch(setPickupStore(store));
    // 185.141.63.56:3051/orders/:orderId/mtaani
    const body = { mtaaniId: store.id };
    axios
      .post(`${URL}/orders/${orderId}/mtaani`, body, {
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
      })
      .then((res) => {
        const result = res.data;
        setresSuccess('Done!');
        console.log('Drop', result);
        handleClose();
      })
      .catch((error) => {
        // console.log('drop  ERR', error);
        setresError(error);
      });
    // handleClose();
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="select-dropoff-store"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogHeading
        subtitle={subtitle}
        subtitleId={subtitleId}
        title={title}
        titleId={titleId}
        onClose={handleClose}
      />
      <DialogContent>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Box width="50%" mb={2}>
            <Select
              native
              classes={{ root: classes.select }}
              fullWidth
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              variant="outlined"
              size="small"
              value={selectedRegion}
              onChange={handleChangeRegion}
            >
              <option value="" disabled selected hidden>
                Region *
              </option>

              {mtaaniLoading && (
                <option value="" disabled>
                  Loading ...
                </option>
              )}

              {mtaaniRegions &&
                mtaaniRegions.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.region}
                  </option>
                ))}
            </Select>
          </Box>
          <Box width="50%" mb={2}>
            <Select
              native
              classes={{ root: classes.select }}
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              fullWidth
              variant="outlined"
              size="small"
              value={selectedZone}
              onChange={handleChangeZone}
            >
              <option value="" disabled selected hidden>
                Main Road *
              </option>
              {!mtaaniZones && (
                <option value="" disabled>
                  Please select region first
                </option>
              )}
              {mtaaniLoading && (
                <option value="" disabled>
                  Loading ...
                </option>
              )}

              {mtaaniZones &&
                mtaaniZones.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.street}
                  </option>
                ))}
            </Select>
          </Box>
        </Box>
        <Box>
          {mtaaniLoading && <LinearProgress />}
          {mtaaniStores && mtaaniStores.length === 0 && (
            <p className="txt-center txt-violet txt-bold">
              No pickup stores available here
            </p>
          )}
          {mtaaniStores && (
            <FormControl
              component="fieldset"
              classes={{ root: classes.formControl }}
            >
              <RadioGroup
                aria-label="delivery-options"
                name="delivery-options"
                value={selectedStore}
                onChange={handleSelectMtaaniStore}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {mtaaniStores.map((item) => (
                    <Paper
                      variant="outlined"
                      className={classes.paper}
                      key={item.id}
                    >
                      <FormControlLabel
                        value={String(item.id)}
                        control={<Radio />}
                        label={item.storeName}
                        classes={{
                          label: classes.label,
                        }}
                      />

                      <div className={classes.gridLayout}>
                        <div>
                          <p className="txt-bold f-14">Address</p>
                          <p className="txt-lightColor f-14">{item.address}</p>
                        </div>
                        <div>
                          <p className="txt-bold f-14">Opening Hours</p>
                          <p className="txt-lightColor f-14">
                            {item.operatingDays}
                            <br />
                            {item.operatingHours}
                          </p>
                        </div>
                        <div>
                          <p className="txt-bold f-14">Contact Number</p>
                          <p className="txt-lightColor f-14">{item.contacts}</p>
                        </div>

                        <div>
                          <p className="txt-bold f-14 ">Delivery Cost</p>
                          <p className="txt-lightColor f-14 f-14">
                            KSh {item.deliveryCost}
                          </p>
                        </div>
                      </div>
                    </Paper>
                  ))}
                </div>
              </RadioGroup>
            </FormControl>
          )}
        </Box>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        {mtaaniStores && mtaaniStores.length > 0 && (
          <BaseBtnFilled
            className={classes.btn}
            onClick={() => saveStore(orderId)}
            disabled={!selectedStore}
          >
            Select Dropoff Store
          </BaseBtnFilled>
        )}

        <BaseBtnOutlined className={classes.btn} onClick={handleClose}>
          Cancel
        </BaseBtnOutlined>
      </DialogActions>
      {resError && (
        <Box mb={2} display="flex" justifyContent="center" alignItems="center">
          {' '}
          <p className="txt-ruby f-14 txt-bold">
            An error occurred. Please try again.
          </p>
        </Box>
      )}
    </Dialog>
  );
};

SelectDropoffStore.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
};
export default SelectDropoffStore;
