import React from 'react';

const NotificationSeparator = () => {
  return (
    <div
      style={{
        height: '40px',
        borderLeft: '1px solid #707070',
        marginLeft: '20px',
        marginBottom: '4px',
      }}
    />
  );
};

export default NotificationSeparator;
