import { Paper } from '@material-ui/core'
import React from 'react'
import PersonImage from '../../../../assets/images/blue-hoodie-model.jpg';
import { makeStyles, withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root:{
     background:"#F5F5F5",
      display:"flex",
   
      flexDirection:"column",
      
      textAlign:"center",
      

    },
    img:{
        height:"70px",
        width:"70px",
        borderRadius:"100%"
    },
    txt:{
        font:"normal normal medium 5px Lato",
        color:"#111111",
       
        fontSize:"4px"
    }
}))

function ForYou({image,name}) {

    const classes = useStyles();
  return (
    <div>
        <div classes={classes.root}>
            <img className={classes.img} src={image}></img>
            <h5 className={classes.txt} >{name}</h5>
        </div>
    </div>
  )
}

export default ForYou