import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { FiPackage } from 'react-icons/fi';

import BaseBtnFilled from '../../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../../components/BaseBtnOutlined';

const URL = process.env.REACT_APP_SERVER;

const mapState = ({ auth }) => ({
  token: auth.token,
});
const BuyerConfirmItemStatus = ({ timestamp, orderID }) => {
  const { token } = useSelector(mapState);

  const handleItemOk = (id) => {
    axios
      .post(
        `${URL}/orders/${id}/feedback/confirmation`,
        { status: 'GOOD' },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleItemIssue = () => {};
  return (
    <Box display="flex">
      <Avatar style={{ background: '#45BC56', marginRight: '8px' }}>
        <FiPackage style={{ color: '#ffffff' }} />
      </Avatar>
      <div className="notification-box">
        <p className="txt-time f-10">{timestamp}</p>
        <p className="txt-bold txt-blackTrue">Is your item okay!</p>
        <p className="txt-gray5 f-14">
          Let us know if the item is as described.
        </p>

        <Box mt={2}>
          <BaseBtnFilled fullWidth onClick={() => handleItemOk(orderID)}>
            Yes, my item is OK!
          </BaseBtnFilled>
        </Box>
        <Box mt={2}>
          <BaseBtnOutlined fullWidth onClick={handleItemIssue}>
            No, i have a problem
          </BaseBtnOutlined>
        </Box>
      </div>
    </Box>
  );
};

BuyerConfirmItemStatus.propTypes = {
  orderID: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default BuyerConfirmItemStatus;
