import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import DialogHeading from './DialogHeading';
import BaseBtnFilled from '../../components/BaseBtnFilled';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  btn: {
    width: '240px',
  },
  actions: {
    justifyContent: 'center',
    paddingTop: '32px',
    paddingBottom: '24px',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const title = 'Order Successful!';
const titleId = 'successful-order-feedback-heading';

const OrderSuccessfulModal = ({ handleClose, open }) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="select-pickup-store"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogHeading title={title} titleId={titleId} />

      <DialogContent>
        <Box width="80%" style={{ margin: '0 auto' }}>
          <div className="flex-center mb-24">
            <div className="bg-order-success"></div>
          </div>
          <div className="txt-center mb-24">
            <p className="txt-violet">
              Your order has been completed successfully. <pre></pre> Check on
              your delivery via your your inbox or click below.
            </p>
          </div>
          <Box mb={2} className={classes.center}>
            <BaseBtnFilled className={classes.btn}>
              Delivery Status
            </BaseBtnFilled>
          </Box>
          <Box mb={2} className={classes.center}>
            <Link to="/">
              {' '}
              <p className="txt-elecViolet">continue shopping</p>
            </Link>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
OrderSuccessfulModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default OrderSuccessfulModal;
