import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    boxShadow: '0px 6px 13px #4242421A',
    maxWidth: '520px',
  },
  content: {
    textAlign: 'center',
    paddingTop: '32px',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
}));

const CustomCard = ({ children }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>{children}</CardContent>
    </Card>
  );
};

CustomCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomCard;
