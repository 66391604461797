
import axios from 'axios';
import { url } from '../api';


export const getProductDetails  = (id) =>{
    var qs = require('qs');
    var data = qs.stringify({
    
    });
    var config = {
    method: 'get',
    url: `${url}/products/${id}`,
    headers: { },
    data : data
    };

    const res = axios(config)
    .then(function (response) {
    // console.log(JSON.stringify(response.data));
    return response.data;
    })
    .catch(function (error) {
    // console.log(error);
    return {"success":"error occured"}
    });

    return res;
}