import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import io from 'socket.io-client';

const SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET_SERVER;

// const msgEvent = 'message';

const mapState = ({ auth, message }) => ({
  token: auth.token,
});
const useSocket = () => {
  const { token } = useSelector(mapState);

  const [socket, setSocket] = useState(null);
  const [testConnection, settestConnection] = useState('');

  useEffect(() => {
    if (token) {
      const socketConn = io(`${SOCKET_ENDPOINT}`, {
        query: { auth_token: token },
      });
      setSocket(socketConn);
      //   socket.on('test', (msg) => {
      //     console.log('TEST CONNECTION', msg);
      //   });
    }
  }, []);

  const disconnectSocket = () => {
    // console.log('Disconnecting socket...');
    if (socket) socket.disconnect();
  };

  return { socket, disconnectSocket };
};

export default useSocket;
