import PropTypes from 'prop-types';

import AppHeader from '../../parts/Shared/AppHeader';
import StickyBanner from '../StickyBanner';

const AuthLayout = ({ children }) => {
  return (
    <>
      <AppHeader />
      <main>
        <div className="bg-main ctn-main wrapper-main ">
          <div className="bg-content">{children}</div>
        </div>
      </main>

      {/* <StickyBanner /> */}

    </>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
};
export default AuthLayout;
