import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  center: {
    textAlign: 'center',
  },
});

const BaseFailDialog = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="process-fail-title"
        aria-describedby="process-fail-description"
      >
        <DialogContent>
          <DialogContentText id="process-fail-description">
            Oops! An error occurred. Please try again later.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
BaseFailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default BaseFailDialog;
