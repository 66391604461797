import {
  CREATE_STORE_FAIL,
  CREATE_STORE_SUCCESS,
  VERIFY_MOBILE_MONEY_NUMBER_SUCCESS,
  PROVIDE_MOBILE_MONEY_NUMBER_SUCCESS,
  FETCH_STORE_DETAILS_PUBLIC_START,
  FETCH_STORE_DETAILS_PUBLIC_SUCCESS,
  FETCH_STORE_DETAILS_PUBLIC_FAIL,
  FETCH_STORE_DETAILS_PRIVATE_START,
  FETCH_STORE_DETAILS_PRIVATE_SUCCESS,
  FETCH_STORE_DETAILS_PRIVATE_FAIL,
  FETCH_STORE_LISTED_PRODUCTS_START,
  FETCH_STORE_LISTED_PRODUCTS_SUCCESS,
  FETCH_STORE_LISTED_PRODUCTS_FAIL,
  FETCH_STORE_SOLD_PRODUCTS_START,
  FETCH_STORE_SOLD_PRODUCTS_SUCCESS,
  FETCH_STORE_SOLD_PRODUCTS_FAIL,
  FETCH_STORE_DRAFT_PRODUCTS_START,
  FETCH_STORE_DRAFT_PRODUCTS_SUCCESS,
  FETCH_STORE_DRAFT_PRODUCTS_FAIL,
  FETCH_STORE_LISTED_PRODUCTS_PUBLIC_START,
  FETCH_STORE_LISTED_PRODUCTS_PUBLIC_SUCCESS,
  FETCH_STORE_LISTED_PRODUCTS_PUBLIC_FAIL,
  FETCH_STORE_STAT_START,
  FETCH_STORE_STAT_SUCCESS,
  FETCH_STORE_STAT_FAIL,
  FETCH_AVG_DAILY_INCOME_FAIL,
  FETCH_AVG_DAILY_INCOME_START,
  FETCH_AVG_DAILY_INCOME_SUCCESS,
  FETCH_STORE_SENT_FEEDBACK_START,
  FETCH_STORE_SENT_FEEDBACK_SUCCESS,
  FETCH_STORE_SENT_FEEDBACK_FAIL,
  FETCH_STORE_RECEIVED_FEEDBACK_START,
  FETCH_STORE_RECEIVED_FEEDBACK_SUCCESS,
  FETCH_STORE_RECEIVED_FEEDBACK_FAIL,
} from './merchantTypes';

const initialState = {
  isPhoneVerified: sessionStorage.getItem('provided_pay_no'),
  isPhoneProvided: sessionStorage.getItem('verified_pay_no'),
  phoneVal: null,
  isStoreCreated: false,
  errorMessage: null,
  createdStore: localStorage.getItem('store'),
  verMessage: null,
  loading: false,

  storeDetailsPublicLoading: false,
  storeDetailsPublic: null,
  storeDetailsPublicErr: null,

  storeDetailsPrivate: null,
  storeDetailsPrivateLoading: false,
  storeDetailsPrivateErr: null,

  storeListedProducts: null,
  storeListedProductsLoading: false,
  storeListedProductsErr: null,

  storeListedProductsPublic: null,
  storeListedProductsLoadingPublic: false,
  storeListedProductsErrPublic: null,

  storeSoldProducts: null,
  storeSoldProductsLoading: false,
  storeSoldProductsErr: null,

  storeDraftProducts: null,
  storeDraftProductsLoading: false,
  storeDraftProductsErr: null,

  statLoading: false,
  statItemsSold: null,
  statProfileViews: null,
  statFetchErr: null,

  avgDailyIncomeLoading: false,
  avgDailyIncome: null,
  avgDailyIncomeErr: null,

  receivedFeedbackLoading: false,
  receivedFeedback: null,
  receivedFeedbackErr: null,

  sentFeedbackLoading: false,
  sentFeedback: null,
  sentFeedbackErr: null,

  // hasStore: userProfile.hasStore,
};

const merchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STORE_SUCCESS: {
      return {
        ...state,
        createdStore: action.payload.storeId,
        isStoreCreated: true,
      };
    }
    case CREATE_STORE_FAIL: {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }
    case PROVIDE_MOBILE_MONEY_NUMBER_SUCCESS: {
      return {
        ...state,
        isPhoneProvided: sessionStorage.getItem('provided_pay_no'),
        phoneVal: action.payload,
      };
    }
    case VERIFY_MOBILE_MONEY_NUMBER_SUCCESS: {
      return {
        ...state,
        isPhoneVerified: sessionStorage.getItem('verified_pay_no'),
        verMessage: action.payload.msg,
      };
    }
    case FETCH_STORE_DETAILS_PRIVATE_START: {
      return {
        ...state,
        storeDetailsPrivateLoading: true,
      };
    }
    case FETCH_STORE_DETAILS_PRIVATE_SUCCESS: {
      return {
        ...state,
        storeDetailsPrivateLoading: false,
        storeDetailsPrivate: action.payload,
      };
    }
    case FETCH_STORE_DETAILS_PRIVATE_FAIL: {
      return {
        ...state,
        storeDetailsPrivateLoading: false,
        storeDetailsPrivateErr: action.payload,
      };
    }
    case FETCH_STORE_DETAILS_PUBLIC_START: {
      return {
        ...state,
        storeDetailsPublicLoading: true,
      };
    }
    case FETCH_STORE_DETAILS_PUBLIC_SUCCESS: {
      return {
        ...state,
        storeDetailsPublicLoading: false,
        storeDetailsPublic: action.payload,
      };
    }
    case FETCH_STORE_DETAILS_PUBLIC_FAIL: {
      return {
        ...state,
        storeDetailsPublicLoading: false,
        storeDetailsPublicErr: action.payload,
      };
    }
    case FETCH_STORE_LISTED_PRODUCTS_START: {
      return {
        ...state,
        storeListedProductsLoading: true,
      };
    }
    case FETCH_STORE_LISTED_PRODUCTS_SUCCESS: {
      return {
        ...state,
        storeListedProductsLoading: false,
        storeListedProducts: action.payload,
      };
    }
    case FETCH_STORE_LISTED_PRODUCTS_FAIL: {
      return {
        ...state,
        storeListedProductsLoading: false,
        storeListedProductsErr: action.payload,
      };
    }
    case FETCH_STORE_SOLD_PRODUCTS_START: {
      return {
        ...state,
        storeSoldProductsLoading: true,
      };
    }
    case FETCH_STORE_SOLD_PRODUCTS_SUCCESS: {
      return {
        ...state,
        storeSoldProductsLoading: false,
        storeSoldProducts: action.payload,
      };
    }
    case FETCH_STORE_SOLD_PRODUCTS_FAIL: {
      return {
        ...state,
        storeSoldProductsLoading: false,
        storeSoldProductsErr: action.payload,
      };
    }
    case FETCH_STORE_DRAFT_PRODUCTS_START: {
      return {
        ...state,
        storeDraftProductsLoading: true,
      };
    }
    case FETCH_STORE_DRAFT_PRODUCTS_SUCCESS: {
      return {
        ...state,
        storeDraftProductsLoading: false,
        storeDraftProducts: action.payload,
      };
    }
    case FETCH_STORE_DRAFT_PRODUCTS_FAIL: {
      return {
        ...state,
        storeDraftProductsLoading: false,
        storeDraftProductsErr: action.payload,
      };
    }
    case FETCH_STORE_LISTED_PRODUCTS_PUBLIC_START: {
      return {
        ...state,
        storeListedProductsLoadingPublic: true,
      };
    }
    case FETCH_STORE_LISTED_PRODUCTS_PUBLIC_SUCCESS: {
      return {
        ...state,
        storeListedProductsLoadingPublic: false,
        storeListedProductsPublic: action.payload,
      };
    }
    case FETCH_STORE_LISTED_PRODUCTS_PUBLIC_FAIL: {
      return {
        ...state,
        storeListedProductsLoadingPublic: false,
        storeListedProductsErrPublic: action.payload,
      };
    }
    case FETCH_STORE_STAT_START: {
      return {
        ...state,
        statLoading: true,
      };
    }
    case FETCH_STORE_STAT_SUCCESS: {
      return {
        ...state,
        statLoading: false,
        statItemsSold: action.itemSold,
        statProfileViews: action.profileViews,
      };
    }
    case FETCH_STORE_STAT_FAIL: {
      return {
        ...state,
        statLoading: false,
        statFetchErr: action.payload,
      };
    }
    case FETCH_AVG_DAILY_INCOME_START: {
      return {
        ...state,
        avgDailyIncomeLoading: true,
      };
    }
    case FETCH_AVG_DAILY_INCOME_SUCCESS: {
      return {
        ...state,
        avgDailyIncomeLoading: false,
        avgDailyIncome: action.payload,
      };
    }
    case FETCH_AVG_DAILY_INCOME_FAIL: {
      return {
        ...state,
        avgDailyIncomeLoading: false,
        avgDailyIncomeErr: action.payload,
      };
    }
    case FETCH_STORE_SENT_FEEDBACK_START: {
      return {
        ...state,
        sentFeedbackLoading: true,
      };
    }
    case FETCH_STORE_SENT_FEEDBACK_SUCCESS: {
      return {
        ...state,
        sentFeedbackLoading: false,
        sentFeedback: action.payload,
      };
    }
    case FETCH_STORE_SENT_FEEDBACK_FAIL: {
      return {
        ...state,
        sentFeedbackLoading: false,
        sentFeedbackErr: action.payload,
      };
    }
    case FETCH_STORE_RECEIVED_FEEDBACK_START: {
      return {
        ...state,
        receivedFeedbackLoading: true,
      };
    }
    case FETCH_STORE_RECEIVED_FEEDBACK_SUCCESS: {
      return {
        ...state,
        receivedFeedbackLoading: false,
        receivedFeedback: action.payload,
      };
    }
    case FETCH_STORE_RECEIVED_FEEDBACK_FAIL: {
      return {
        ...state,
        receivedFeedbackLoading: false,
        receivedFeedbackErr: action.payload,
      };
    }
    default:
      return state;
  }
};

export default merchantReducer;
