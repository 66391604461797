export const SIGN_UP_START = ' SIGN_UP_START';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = ' SIGN_UP_FAILURE';

export const USER_LOADING = 'USER_LOADING';
export const CHECK_LOGGED_IN = 'CHECK_LOGGED_IN';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAILURE';



export const VERIFY_PHONE_START = 'VERIFY_PHONE_START';
export const VERIFY_PHONE_SUCCESS = 'VERIFY_PHONE_SUCCESS';
export const VERIFY_PHONE_FAIL = 'VERIFY_PHONE_FAIL';

export const SIGN_OUT = 'SIGN_OUT';

export const PASSWORD_RESET_REQUEST = 'REQUEST_PASSWORD_RESET';
export const PASSWORD_RESET_REQUEST_SUCCESS = 'PASSWORD_RESET_REQUEST_SUCCESS';
export const PASSWORD_RESET_REQUEST_FAIL = 'PASSWORD_RESET_REQUEST_FAIL';
export const VERIFY_PASSWORD_RESET_CODE_SUCCESS =
  'VERIFY_PASSWORD_RESET_CODE_SUCCESS';
export const VERIFY_PASSWORD_RESET_CODE_FAIL =
  'VERIFY_PASSWORD_RESET_CODE_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const PASSWORD_RESET_CODE = 'PASSWORD_RESET_CODE';

export const GET_USER_DETAILS_START = 'GET_USER_DETAILS_START';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';

// WAILTIST

export const CHECK_WAITLIST_START = 'CHECK_WAITLIST_START';
export const CHECK_WAITLIST_SUCCESS = 'CHECK_WAITLIST_SUCCESS';
export const CHECK_WAITLIST_FAIL = 'CHECK_WAITLIST_FAIL';

export const ADD_WAITLIST_SUCCESS = 'ADD_WAITLIST_SUCCESS';
export const ADD_WAITLIST_FAIL = 'ADD_WAITLIST_FAIL';

export const RESEND_VER_OTP_SUCCESS = 'RESEND_VER_OTP_SUCCESS';
export const RESEND_VER_OTP_FAIL = 'RESEND_VER_OTP_FAIL';
export const RESEND_VER_OTP_START = 'RESEND_VER_OTP_START';
