import { initializeApp } from "firebase/app";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyCOJ3pRT4lxrKPhY65msAVnS25-dd4nhtk",
    authDomain: "lukhu-app.firebaseapp.com",
    projectId: "lukhu-app",
    storageBucket: "lukhu-app.appspot.com",
    messagingSenderId: "1048850599976",
    appId: "1:1048850599976:web:fde426a66a08551f79251d",
    measurementId: "G-PE2WT94ZYS"
};

// Initialize Firebase
const firebase_app = initializeApp(firebaseConfig);

export default  firebase_app