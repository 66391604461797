import axios from 'axios';
import { url } from '../../api';

import {
  CREATE_STORE_SUCCESS,
  CREATE_STORE_FAIL,
  PROVIDE_MOBILE_MONEY_NUMBER_SUCCESS,
  PROVIDE_MOBILE_MONEY_NUMBER_FAIL,
  VERIFY_MOBILE_MONEY_NUMBER_SUCCESS,
  VERIFY_MOBILE_MONEY_NUMBER_FAIL,
  FETCH_STORE_DETAILS_PRIVATE_START,
  FETCH_STORE_DETAILS_PRIVATE_SUCCESS,
  FETCH_STORE_DETAILS_PRIVATE_FAIL,
  FETCH_STORE_DETAILS_PUBLIC_START,
  FETCH_STORE_DETAILS_PUBLIC_SUCCESS,
  FETCH_STORE_DETAILS_PUBLIC_FAIL,
  FETCH_STORE_LISTED_PRODUCTS_START,
  FETCH_STORE_LISTED_PRODUCTS_SUCCESS,
  FETCH_STORE_LISTED_PRODUCTS_FAIL,
  FETCH_STORE_SOLD_PRODUCTS_START,
  FETCH_STORE_SOLD_PRODUCTS_SUCCESS,
  FETCH_STORE_SOLD_PRODUCTS_FAIL,
  FETCH_STORE_DRAFT_PRODUCTS_START,
  FETCH_STORE_DRAFT_PRODUCTS_SUCCESS,
  FETCH_STORE_DRAFT_PRODUCTS_FAIL,
  FETCH_STORE_LISTED_PRODUCTS_PUBLIC_START,
  FETCH_STORE_LISTED_PRODUCTS_PUBLIC_SUCCESS,
  FETCH_STORE_LISTED_PRODUCTS_PUBLIC_FAIL,
  FETCH_STORE_STAT_START,
  FETCH_STORE_STAT_SUCCESS,
  FETCH_STORE_STAT_FAIL,
  FETCH_AVG_DAILY_INCOME_START,
  FETCH_AVG_DAILY_INCOME_SUCCESS,
  FETCH_AVG_DAILY_INCOME_FAIL,
  FETCH_STORE_SENT_FEEDBACK_START,
  FETCH_STORE_SENT_FEEDBACK_SUCCESS,
  FETCH_STORE_SENT_FEEDBACK_FAIL,
  FETCH_STORE_RECEIVED_FEEDBACK_START,
  FETCH_STORE_RECEIVED_FEEDBACK_SUCCESS,
  FETCH_STORE_RECEIVED_FEEDBACK_FAIL,
} from './merchantTypes';

export const createStore = (storeDetails) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;

    axios
      .post(`${url}/store/`, storeDetails, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        localStorage.setItem('store', response.data.id);
        dispatch({
          type: CREATE_STORE_SUCCESS,
          payload: {
            storeId: response.data.id,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_STORE_FAIL,
          payload: error,
        });
      });
  };
};

export const providePhoneNo = (merchantPhone, authToken) => {
  return (dispatch) => {
    sessionStorage.setItem('provided_pay_no', JSON.stringify(true));
    const store_id = localStorage.getItem('store');

    axios
      .patch(`${url}/store/${store_id}/phone`, merchantPhone, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        dispatch({
          type: PROVIDE_MOBILE_MONEY_NUMBER_SUCCESS,
          payload: merchantPhone.paymentNumber,
        });
      })
      .catch((error) => {
        // console.log(error);
        dispatch({
          type: PROVIDE_MOBILE_MONEY_NUMBER_FAIL,
          payload: error,
        });
      });
  };
};
export const verifyVerificationCode = (code, authToken) => {
  return (dispatch) => {
    const store_id = localStorage.getItem('store');

    axios
      .patch(`${url}/store/${store_id}/phone/verify`, code, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        sessionStorage.setItem('verified_pay_no', JSON.stringify(true));
        dispatch({
          type: VERIFY_MOBILE_MONEY_NUMBER_SUCCESS,
          payload: {
            msg: response.data.message,
          },
        });
      })
      .catch((error) => {
        // console.log(error.response);
        dispatch({
          type: VERIFY_MOBILE_MONEY_NUMBER_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchPublicStoreDetails = (name) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_STORE_DETAILS_PUBLIC_START,
    });
    axios
      .get(`${url}/store?id=${name}`)
      .then((response) => {
        const details = response.data.store[0];
        dispatch({
          type: FETCH_STORE_DETAILS_PUBLIC_SUCCESS,
          payload: details,
        });
        dispatch(fetchMerchantListedProductsPublic(details.id));
      })
      .catch((error) => {
        // console.log(error);
        dispatch({
          type: FETCH_STORE_DETAILS_PUBLIC_FAIL,
          payload: error,
        });
      });
  };
};
export const fetchMerchantStoreDetails = () => {
  return (dispatch, getState) => {
    const merchProfile = getState().auth.profile;
    const merchantId = merchProfile.id;
    const authToken = getState().auth.token;

    dispatch({
      type: FETCH_STORE_DETAILS_PRIVATE_START,
    });
    axios
      .get(`${url}/store/${merchantId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        const details = response.data.store;
        const storeId = details.id;
        dispatch({
          type: FETCH_STORE_DETAILS_PRIVATE_SUCCESS,
          payload: details,
        });
        dispatch(fetchPublicStoreDetails(storeId));
      })
      .catch((error) => {
        // console.log(error);
        dispatch({
          type: FETCH_STORE_DETAILS_PRIVATE_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchMerchantStoreProducts = () => {
  return (dispatch, getState) => {
    const merchProfile = getState().auth.profile;
    const merchantId = merchProfile.id;
    const authToken = getState().auth.token;

    dispatch({
      type: FETCH_STORE_LISTED_PRODUCTS_START,
    });
    axios
      .get(
        `${url}/store/${merchantId}?productStatus=listed&page=1&pageLimit=100`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        const products = res.data.store.products;
        dispatch({
          type: FETCH_STORE_LISTED_PRODUCTS_SUCCESS,
          payload: products,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_STORE_LISTED_PRODUCTS_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchMerchantSoldProducts = () => {
  return (dispatch, getState) => {
    const merchProfile = getState().auth.profile;

    const merchantId = merchProfile.id;
    const authToken = getState().auth.token;
    dispatch({
      type: FETCH_STORE_SOLD_PRODUCTS_START,
    });

    axios
      .get(
        `${url}/store/${merchantId}?productStatus=sold&page=1&pageLimit=100`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        const products = res.data.store.products;
        dispatch({
          type: FETCH_STORE_SOLD_PRODUCTS_SUCCESS,
          payload: products,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_STORE_SOLD_PRODUCTS_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchMerchantDraftProducts = () => {
  return (dispatch, getState) => {
    const merchProfile = getState().auth.profile;
    const merchantId = merchProfile.id;
    const authToken = getState().auth.token;
    dispatch({
      type: FETCH_STORE_DRAFT_PRODUCTS_START,
    });

    axios
      .get(
        `${url}/store/${merchantId}?productStatus=draft&page=1&pageLimit=100`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        const products = res.data.store.products;
        dispatch({
          type: FETCH_STORE_DRAFT_PRODUCTS_SUCCESS,
          payload: products,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_STORE_DRAFT_PRODUCTS_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchMerchantListedProductsPublic = (storeId) => {
  return (dispatch, getState) => {
    // const storeId = getState().product.productStoreId;
    dispatch({
      type: FETCH_STORE_LISTED_PRODUCTS_PUBLIC_START,
    });
    axios
      .get(`${url}/listing/?page=1&limit=50&store=${storeId}`)
      .then((response) => {
        const products = response.data.data;

        // get product from same store
        // const sellerProducts = products.filter(
        //   (product) => product.storeId === storeId
        // );

        // exclude currently viewed product

        dispatch({
          type: FETCH_STORE_LISTED_PRODUCTS_PUBLIC_SUCCESS,
          payload: products,
        });
      })
      .catch((error) => {
        // console.log(error);
        dispatch({
          type: FETCH_STORE_LISTED_PRODUCTS_PUBLIC_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchMerchantStoreLikes = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
  };
};

export const fetchMerchantStoreReceivedFeedbacK = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;

    dispatch({
      type: FETCH_STORE_RECEIVED_FEEDBACK_START,
    });

    axios
      .get(`${url}/store/feedback/received`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        // console.log('FEED', response.data);
        const results = response.data;
        dispatch({
          type: FETCH_STORE_RECEIVED_FEEDBACK_SUCCESS,
          payload: results,
        });
      })
      .catch((error) => {
        // console.log('FEED ERR', error);
        dispatch({
          type: FETCH_STORE_RECEIVED_FEEDBACK_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchMerchantStoreSentFeedbacK = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch({
      type: FETCH_STORE_SENT_FEEDBACK_START,
    });

    axios
      .get(`${url}/store/feedback/sent`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        // console.log('FEED', response.data);
        const results = response.data;
        dispatch({
          type: FETCH_STORE_SENT_FEEDBACK_SUCCESS,
          payload: results,
        });
      })
      .catch((error) => {
        // console.log('FEED ERR', error);
        dispatch({
          type: FETCH_STORE_SENT_FEEDBACK_FAIL,
          payload: error,
        });
      });
  };
};
export const fetchAvgDailyIncome = (day) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;

    dispatch({
      type: FETCH_AVG_DAILY_INCOME_START,
    });

    axios
      .get(`${url}/store/statistics?period=week&day=${day}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        const results = response.data.statistics.averageDailyIncome; //array of all days in week
        const dayIncome = results.find((stat) => stat.day === day); //object {day:'Monday' , amount:0}
        const dayIncomeAmount = dayIncome.amount;

        dispatch({
          type: FETCH_AVG_DAILY_INCOME_SUCCESS,
          payload: dayIncomeAmount,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_AVG_DAILY_INCOME_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchMerchantStoreStats = (period) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    dispatch({
      type: FETCH_STORE_STAT_START,
    });
    axios
      .get(`${url}/store/statistics?period=${period}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        const results = response.data.statistics;
        const itemSold = results.itemSold;
        const profileViews = results.profileViews;

        dispatch({
          type: FETCH_STORE_STAT_SUCCESS,
          payload: {
            itemSold,
            profileViews,
          },
        });
      })
      .catch((error) => {
        // console.log(error);
        dispatch({
          type: FETCH_STORE_STAT_FAIL,
          payload: error,
        });
      });
  };
};
