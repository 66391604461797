import CardContent from '@material-ui/core/CardContent';

import logo from '../../assets/images/logo-holder.png';

const OnboardingCardContent = () => {
  return (
    <CardContent>
      <img
        src={logo}
        alt="lukhu app logo"
        style={{ width: '35%', marginBottom: '32px' }}
      ></img>
      <h2 className="txt-ruby h2x-large-bold" style={{ marginBottom: '32px' }}>
        Buy. Sell. Discover
      </h2>
      <p className="txt-violet p-14" style={{ marginBottom: '80px' }}>
        Your one-stop shop for buying and selling <br />
        second-hand clothing online.
      </p>
    </CardContent>
  );
};

export default OnboardingCardContent;
