import React,{useEffect,useState} from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { Button } from 'react-bootstrap';
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles({
    root: {
        position:"fixed" ,
       
          bottom:"70px",
          width:"100%",
         background:"#DC0E51",
        
    },
    innerB:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        position:"absolute",
        background:"#DC0E51",
        overflowY:"hidden"
    },
    typoc:{
        color:"#fff",
        padding:"10px",
        fontSize:"14px",
        font:'Inter',
        fontWeight:600,
        lineHeight:"17px",
        fontStyle:"normal"
    },
    btn:{
        minWidth:"150px",
        padding:"10px",
        borderRadius:"8px",
        marginRight:"10px",
        color:"#003CFF",
        border:"none",
        fontSize:"14px",
        font:"Inter",
        fontWeight:600,
        lineHeight:"20px",
        background:"#fff"

    }
  });

function StickyBanner() {
    const classes = useStyles();


    const onClickFunction = ()=>{
        window.location.href = "https://linktr.ee/lukhu"

    }


  
    if(isMobile){
        return(
        // <Box className={classes.root} >
        //         <Box className={classes.innerB}>
        //             <Typography className={classes.typoc} variant='h6' >
        //                     Use code <strong>KARIBU</strong> on the app to get <strong>KSh 100</strong>  off your next order
                      
        //             </Typography>

        //             <Button onClick={onClickFunction} variant="contained" className={classes.btn}>
        //                 Get the App
        //             </Button>
        //         </Box>
        //     </Box>

        <></>
        )
    }else{
        return (
            <></>
        )
    }



}

export default StickyBanner