/* 
  This component renders the secondary header
 */
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
import axios from 'axios';

import BaseMenu from '../../components/BaseMenu';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { url } from '../../api';
import {
  fetchLevel2Search,
  setSubCatId,
} from '../../redux/search/searchActions';
// import bgImage from '../../assets/images/menu-bg-2.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff',
    color: '#111111',
    boxShadow: 'none',
    borderBottom: '1px solid #D8D8D8',
    // marginBottom: '2px',
  },
  menuItemsLayout: {
    display: 'flex',
    // justifyContent: 'space-around',
    margin: '2px',
    flexWrap: 'wrap',
    // width: '60%',
  },

  textStyleTitle: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    textTransform: 'uppercase',
  },
  textStyleNormal: {
    fontSize: '0.875rem',
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    color: '#111111',
  },
  textStyleBold: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },

  menuImgContainer: {
    position: 'relative',
    margin: '32px 0',
  },
  menuImg: {
    height: '288px',
    width: '432px',
  },
  menuImgText: {
    position: 'absolute',
    top: '16px',
    left: '16px',
  },
  imgTxtStyle1: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    fontFamily: ['Lato', 'sans-serif'].join(', '),
  },
  imgTxtStyle2: {
    fontSize: '2rem',
    fontFamily: ['Syncopate', 'sans-serif'].join(', '),
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  textStyleLink: {
    fontSize: '0.875rem',
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    textTransform: 'capitalize',
    color: '#481CFB',
    fontWeight: 'bold',
  },
  offset: theme.mixins.toolbar,
  btn: {
    textTransform: 'capitalize',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    color: '#111111',
    fontSize: '1rem',
    fontWeight: 600,
  },
  btnClicked: {
    color: '#d81e5b',
    textTransform: 'capitalize',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '1rem',
    fontWeight: 600,
  },

  // --font-family-syncopate: 'Syncopate', sans-serif;
}));

// const MenuImage = ({ title, offer, titleColor, offerColor }) => {
//   const classes = useStyles();

//   return (
//     <div className={classes.menuImgContainer}>
//       <img src={bgImage} className={classes.menuImg} />
//       <div className={classes.menuImgText}>
//         <p className={classes.imgTxtStyle1} style={{ color: `${titleColor}` }}>
//           {title}
//         </p>
//         <p className={classes.imgTxtStyle2} style={{ color: `${offerColor}` }}>
//           {offer}
//         </p>
//       </div>
//     </div>
//   );
// };

// MenuImage.propTypes = {
//   titleColor: PropTypes.string.isRequired,
//   offerColor: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired,
//   offer: PropTypes.string.isRequired,
// };
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const MainNavigation = () => {
  const classes = useStyles();

  const [womenCategories, setWomenCategories] = useState([]);
  const [menCategories, setMenCategories] = useState([]);
  const [kidsCategories, setKidsCategories] = useState([]);
  const [isAboutClicked, setisAboutClicked] = useState(false);

  let query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    let isCancelled = false;

    const getWomenCategories = async () => {
      const response = await axios(`${url}/products/attributes/category?id=2`);
      const categories = response.data.options;
      if (!isCancelled) {
        setWomenCategories(categories);
      }
    };
    getWomenCategories();
    return () => (isCancelled = true);
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const getMenCategories = async () => {
      const response = await axios(`${url}/products/attributes/category?id=1`);
      const categories = response.data.options;
      if (!isCancelled) {
        setMenCategories(categories);
      }
    };
    getMenCategories();
    return () => (isCancelled = true);
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const getKidsCategories = async () => {
      const response = await axios(`${url}/products/attributes/category?id=3`);
      const categories = response.data.options;
      if (!isCancelled) {
        setKidsCategories(categories);
      }
    };

    getKidsCategories();
    return () => (isCancelled = true);
  }, []);

  const setFilter = (key, value) => {
    if (query.has(key)) {
      query.delete(key);
    }
    return query.append(key, value);
  };

  const handleWomenSearch = (subCat, subCatId) => {
    setFilter('category', `Womenswear.${subCat}`);
    // dispatch(fetchLevel2Search(query, subCatId));
    history.push(`/Women?${query}`);
  };
  const handleMenSearch = (subCat, subCatId) => {
    setFilter('category', `Menswear.${subCat}`);
    // dispatch(fetchLevel2Search(query, subCatId));
    history.push(`/Men?${query}`);
  };
  const handleKidsSearch = (subCat, subCatId) => {
    setFilter('category', `Kidswear.${subCat}`);
    // dispatch(fetchLevel2Search(query, subCatId));
    history.push(`/Kids?${query}`);
  };
  const handleClickAbout = () => {
    // setisAboutClicked(true);
    window.location.href = "https://lukhu.co/"
    // history.push('/about-us');
  };
  return (
    <section>
      <div>
        <AppBar className={classes.root} position="static">
          <Toolbar>
            <BaseMenu menuTitle="women">
              <div className={classes.menuItemsLayout}>
                {womenCategories.map((category) => (
                  <MenuItem
                    key={category.id}
                    className={classes.textStyleNormal}
                    onClick={() =>
                      handleWomenSearch(category.name, category.id)
                    }
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </div>
            </BaseMenu>
            <BaseMenu menuTitle="men">
              <div className={classes.menuItemsLayout}>
                {menCategories.map((category) => (
                  <MenuItem
                    key={category.id}
                    className={classes.textStyleNormal}
                    onClick={() => handleMenSearch(category.name, category.id)}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </div>
            </BaseMenu>
            <BaseMenu menuTitle="kids">
              <div className={classes.menuItemsLayout}>
                {kidsCategories.map((category) => (
                  <MenuItem
                    key={category.id}
                    className={classes.textStyleNormal}
                    onClick={() => handleKidsSearch(category.name, category.id)}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </div>
            </BaseMenu>
            {/* <BaseMenu menuTitle="our picks">
              <MenuItem>Coming soon!</MenuItem>
            </BaseMenu> */}
            <Button
              aria-controls="main-navigation-menu"
              aria-haspopup="true"
              onClick={handleClickAbout}
              variant="text"
              className={isAboutClicked ? classes.btnClicked : classes.btn}
            >
              About Us
            </Button>
          </Toolbar>
        </AppBar>
        {/* <div className={classes.offset} /> */}
      </div>
    </section>
  );
};

export default MainNavigation;
