import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SecurityIcon from '@material-ui/icons/Security';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Rating from '@material-ui/lab/Rating';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { FaMoneyBillWave } from 'react-icons/fa';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import MerchantAvatar from '../Shops/MerchantAvatar';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';

import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: '8px',
    width:"100%",
   
  },
  media: {
    width: 110,
    height:90,
    marginTop:10,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
  },
  content: {
    padding: '8px',
    width:"100%",
    background:"#F8F8F8",
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  flex: {
    display: 'flex',
    width:"100%",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  font1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  font2: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    color: '#707070',
  },
  font3: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontWeight: 700,
    fontSize: '1rem',
  },
  icon: {
    fontSize: '0.875rem',
    color: '#707070',
  },
  iconText: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    width: '2px',
    height:"2px"
  },
  cardHeader: {
    padding: '8px',
    '& action': {
      alignSelf: 'center',
    },
  },
  accSummary: {
    margin: 0,
  },
  accDetails: {
    marginLeft: '48px',
  },
  accContent: {
    width: '90%',
    display: 'flex',
    // justifyContent: 'space-between',
    // margin: theme.spacing(1, 0),
    alignItems: 'center',
  },
  iconSmall: {
    fontSize: '1rem',
    margin: theme.spacing(0, 0.5, 0, 0),
    color: '#707070',
  },
  accContentSpacing: {
    marginRight: '8px',
    marginLeft: '8px',
  },
}));
const CheckoutVoucherProductDisplay = ({
  condition,
  image,
  // location,
  price,
  size,
  title,
  handleRemoveFromCart,
  // items,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <div className={classes.flex}>
        <CardMedia className={classes.media} image={image} title="product" />
        <CardContent>
          <Typography component="p" variant="body1" className={classes.font1}>
            {title}
          </Typography>

          <div>
            <Typography component="p" variant="body1" className={classes.font2}>
              <span style={{ fontWeight: 700 }}>Condition: </span>
              {condition}
            </Typography>
            <Typography component="p" variant="body1" className={classes.font2}>
              <span style={{ fontWeight: 700 }}>Size: </span>
              {size}
            </Typography>
          </div>

          <Typography component="p" variant="body1" className={classes.font3}>
            KSh {price}
          </Typography>
        </CardContent>
      </div>
      <CardActions>
        <IconButton className={classes.btn} onClick={handleRemoveFromCart}>
      <ClearIcon />
        </IconButton>
       
      </CardActions>
      
    </div>
  );
};

CheckoutVoucherProductDisplay.propTypes = {
  condition: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  // location: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleRemoveFromCart: PropTypes.func.isRequired,
};

export default CheckoutVoucherProductDisplay;
