import { useEffect, useState } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import BaseCollapse from '../../components/BaseCollapse';
import BaseCollapseCategory from '../../components/BaseCollapseCategory';
import { fetchUnder1kResults } from '../../redux/search/searchActions';

const useStyles = makeStyles((theme) => ({
  filterNav: {
    width: '100%',
    marginRight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },

  categoryTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  listTextSmall: {
    fontSize: '1rem',
  },
  attrTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontSize: '0.875rem',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  setPriceButton: {
    color: '#471CF9',
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 700,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
  },
  nestedListItem: {
    padding: '4px 0px',
  },
  nestedListItemText: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 400,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
    color: '#111111',
  },
  colorCircle: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  sizeBtn: {
    padding: '4px',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const DealsPageNav = ({
  allCategories,
  displayCategories,
  productColors,
  productConditions,
}) => {
  const classes = useStyles();

  const history = useHistory();
  const { search } = useLocation();
  const { category } = useParams();
  const { url } = useRouteMatch();
  let query = useQuery();
  const dispatch = useDispatch();

  const [maxPrice, setmaxPrice] = useState('');
  const [minPrice, setminPrice] = useState('');

  const [categoryFilterVal, setcategoryFilterVal] = useState();
  const [colorFilterVal, setcolorFilterVal] = useState();
  const [conditionFilterVal, setconditionFilterVal] = useState();

  useEffect(() => {
    if (categoryFilterVal || colorFilterVal || conditionFilterVal) {
      const filterQuery = search.substring(1);
      dispatch(fetchUnder1kResults(filterQuery));
    }
  }, [dispatch, search, categoryFilterVal, colorFilterVal, conditionFilterVal]);

  const setFilter = (key, value) => {
    if (query.has(key)) {
      query.delete(key);
    }
    return query.append(key, value);
  };

  const formatCategoryFilter = (cat) => {
    let catArr = cat.split('.');
    if (catArr.length === 2) {
      catArr.pop();
      return catArr.join('.');
    } else {
      return cat;
    }
  };
  const setCategoryFilter = (mainCat, subCat1) => {
    let val = [mainCat, subCat1].join('.');
    setFilter('category', val);
    history.push(`${url}?${query}`);
    setcategoryFilterVal(query.get('category'));
  };

  const setCondtionFilter = (val) => {
    setFilter('condition', val);
    history.push(`${url}?${query}`);
    setconditionFilterVal(query.get('condition'));
  };

  const setColorFilter = (val) => {
    setFilter('color', val);
    history.push(`${url}?${query}`);
    setcolorFilterVal(query.get('color'));
  };

  return (
    <div className={classes.filterNav}>
      {/* <div className="pb-16">
        <h4 className="f-16 pb-8 txt-semi-bold">Size</h4>
        <p>
          Sign up or log in to add your sizes and we’ll customise your feed to
          better fit your needs
        </p>
        <div className="pb-8 pt-16">
          <BaseBtnFilled fullWidth className={classes.sizeBtn}>
            Add My Sizes
          </BaseBtnFilled>
        </div>
        <div></div>
      </div> */}

      <div className="pt-16  ">
        {allCategories && (
          <List component="nav">
            {allCategories
              .filter((cat) => cat.parent_id === 0)
              .map((item) => (
                <BaseCollapseCategory
                  key={item.id}
                  title={item.name}
                  titleStyles={classes.categoryTitle}
                >
                  {allCategories
                    .filter((cat) => cat.parent_id === item.id)
                    .map((subCat) => (
                      <ListItem
                        button
                        key={subCat.id}
                        alignItems="flex-start"
                        onClick={() =>
                          setCategoryFilter(item.name, subCat.name)
                        }

                        // onClick={() => handleSetNewCat(subCat.name)}
                      >
                        <ListItemText
                          primary={subCat.name}
                          disableTypography
                          className={classes.listTextSmall}
                        />
                      </ListItem>
                    ))}
                </BaseCollapseCategory>
              ))}
          </List>
        )}
      </div>

      <List component="nav">
        <Divider />
        <BaseCollapse title="condition" titleStyles={classes.attrTitle}>
          {productConditions &&
            productConditions.map((item) => (
              <ListItem
                button
                key={item.id}
                alignItems="flex-start"
                className={classes.nestedListItem}
                onClick={() => setCondtionFilter(item.name)}
              >
                <ListItemText
                  disableTypography
                  primary={item.name}
                  className={classes.nestedListItemText}
                />
              </ListItem>
            ))}
        </BaseCollapse>

        <Divider />

        <BaseCollapse title="color" titleStyles={classes.attrTitle}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4,1fr)',
              gridRowGap: '12px',
            }}
          >
            {productColors &&
              productColors.map((color) => (
                <div
                  key={color.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  onClick={() => setColorFilter(color.id)}
                >
                  <div
                    className={classes.colorCircle}
                    style={{
                      // backgroundColor: `${color.hex}`,
                      backgroundColor: `#eeeeee`,
                    }}
                  ></div>
                  <p className="f-12">{color.name}</p>
                </div>
              ))}
          </div>
        </BaseCollapse>
        <Divider />
      </List>
    </div>
  );
};

DealsPageNav.propTypes = {
  displayCategories: PropTypes.array.isRequired,
  allCategories: PropTypes.array.isRequired,
  productConditions: PropTypes.array.isRequired,
  productColors: PropTypes.array.isRequired,
};

export default DealsPageNav;
