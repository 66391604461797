import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';

import { FiPackage } from 'react-icons/fi';
import { FaShoppingBag } from 'react-icons/fa';
import OrderImage from './OrderImage';
import NotificationSeparator from './NotificationSeparator';
const BuyerConfirmOrder = ({
  orderNo,
  orderTotal,
  sellerName,
  shippingTimeframe,
  timestamp,
  prodName,
  prodSize,
  prodCondition,
  prodPrice,
  prodImage,
}) => {
  return (
    <Box>
      <OrderImage
        prodName={prodName}
        prodSize={prodSize}
        prodCondition={prodCondition}
        prodPrice={prodPrice}
        prodImage={prodImage}
      />
      <NotificationSeparator />
      <Box display="flex">
        <Avatar style={{ background: '#45BC56', marginRight: '8px' }}>
          <FaShoppingBag style={{ color: '#ffffff' }} />
        </Avatar>
        <div className="notification-box">
          <p className="txt-time f-10">{timestamp}</p>
          <p className="txt-bold txt-blackTrue">Order Confirmed!</p>
          <p className="txt-gray5 f-14">
            Hang tight! Your order has been confirmed. You will be notified once{' '}
            <span className="txt-bold ">{sellerName}</span> has shipped your
            item.
          </p>
          <Box mt={2}>
            <p className="f-14 txt-gray5">
              Order Number: <span className="txt-bold">{orderNo}</span>{' '}
            </p>
            <p className="f-14 txt-gray5">
              Amount Paid: <span className="txt-bold"> KSh {orderTotal}</span>
            </p>
            <p className="f-14 txt-gray5">
              Shipping Timeframe: <span className="txt-bold">2 days</span>
            </p>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

BuyerConfirmOrder.propTypes = {
  sellerName: PropTypes.string.isRequired,
  orderTotal: PropTypes.string.isRequired,
  orderNo: PropTypes.string.isRequired,
  shippingTimeframe: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  prodName: PropTypes.string.isRequired,
  prodSize: PropTypes.string.isRequired,
  prodPrice: PropTypes.string.isRequired,
  prodCondition: PropTypes.string.isRequired,
  prodImage: PropTypes.string.isRequired,
};

export default BuyerConfirmOrder;
