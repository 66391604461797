import { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import MoreIcon from '@material-ui/icons/MoreVert';
import { MdSearch, MdEmail } from 'react-icons/md';
import { FaShoppingBag } from 'react-icons/fa';
import EmailIcon from '@material-ui/icons/Email';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Inbox from '@material-ui/icons/Inbox';
import StorefrontIcon from '@material-ui/icons/Storefront';
import StoreIcon from '@material-ui/icons/Store';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import BaseInput2 from '../../components/BaseInput2';
import logo from '../../assets/images/logo-holder.png';
import BaseBtn from '../../components/BaseBtn';
import CartDropDown from '../Cart/CartDropDown';

import { getUserDetails, signOut } from '../../redux/auth/authActions';
import { fetchCartItems } from '../../redux/cart/cartActions';
import { url } from '../../api';
import UserAvatar from '../Shared/UserAvatar';
import SearchInput from '../Search/SearchInput';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  inputRoot: {
    color: 'inherit',
  },

  // search: {
  //   position: 'relative',

  //   marginLeft: 0,
  //   width: '100%',
  //   [theme.breakpoints.up('sm')]: {
  //     marginLeft: theme.spacing(3),
  //     width: 'auto',
  //   },
  // },
  search: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: '40%',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  divBtn: {
    display: 'flex',
    justifyContent: 'space-beween',
  },
  btnFilled: {
    padding: '0 32px',
    height: '32px',
    backgroundColor: '#4900ff',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  btnText: {
    padding: '4px 16px',
    color: '#4900ff',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    textTransform: 'capitalize',
    fontSize: '16px',
    '&:hover': {
      borderRadius: 25,
      color: '#4900ff',
    },
  },
  searchBar: {
    borderRadius: 25,
  },
  bgBar: {
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.08)',
  },
  badge: {
    backgroundColor: '#AA2222',
    color: 'white',
    fontWeight: 'bold',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    border: '1px solid #ffffff',
  },
  badgeNotify: {
    color: '#AA2222',
  },
  offset: theme.mixins.toolbar,
}));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: '#4a4062',
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: '#4a4062',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
  },
}))(MenuItem);

const mapState = ({ auth, cart, message }) => ({
  isLoggedIn: auth.isLoggedIn,
  userDetails: auth.userDetails,
  cartLoading: cart.cartLoading,
  cartItems: cart.cartItems,
  cartTotal: cart.cartTotal,
  shoppingBagItemsCount: cart.shoppingBagItemsCount,
  cartLoadingErr: cart.cartLoadingErr,
  profile: auth.profile,
  token: auth.token,
  userName: auth.username,
  unreadMessages: message.unread,
});
export default function AppHeader() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [nadgeM,setBadgeM] = useState();

  const {
    isLoggedIn,
    userDetails,
    cartLoading,
    cartLoadingErr,
    cartItems,
    shoppingBagItemsCount,
    cartTotal,
    profile,
    token,
    userName,
    unreadMessages,
  } = useSelector(mapState);

  /*
   * Necessary because the user profile local storage item doesn't update hasStore details
   * until the user re-logs into the system
   */

  const [hasStoreWebStorage, sethasStoreWebStorage] = useState('');
  const handleResize = () => {
    if (window.innerWidth < 950) {
        setIsMobile(true)
        setBadgeM( <StyledMenuItem onClick={handleShoppingBagOpen}>
          <ListItemIcon>
          <Badge badgeContent={shoppingBagItemsCount}
                      classes={{
                        badge: classes.badge,
                      }}>
          <FaShoppingBag fontSize="small" />
           
          </Badge>
          </ListItemIcon>
         
        </StyledMenuItem>)
    } else {
        setIsMobile(false)
    }
  }

  // console.log("mobile ",isMobile)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  useEffect(()=>{
    handleResize()
  },[])
  useEffect(() => {
    const storeDetails = localStorage.getItem('store');
    sethasStoreWebStorage(storeDetails);
    return () => {};
  }, []);
  /* */

  const handleSignIn = () => {
    history.push('/auth/login-form');
  };
  const handleSignUp = () => {
    history.push('/auth/signup-form');
  };

  const handleSellNow = () => {
    // const loggedIn = isLoggedIn;

    if ((token && profile.hasStore) || (token && hasStoreWebStorage)) {
      // TODO: push to upload products
      history.push('/list-product');
    } else if (token && !profile.hasStore) {
      history.push('/setup-store');
    } else {
      history.push('/auth');
    }
  };

  const handleSignOut = () => {
    dispatch(signOut());
    history.push('/auth/login');
  };

  const handleMyShop = () => {
    if ((token && profile.hasStore) || (token && hasStoreWebStorage)) {
      history.push('/my-profile/listed-items');
    } else {
      history.push('/setup-store');
    }
  };

  const handleMyProfile = () => {
    if (token) {
      history.push('/my-profile');
    } else {
      history.push('/auth');
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [shoppingBagAnchorEl, setShoppingBagAnchorEl] = useState(null);


  const isProfileMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isShoppingBagOpen = Boolean(shoppingBagAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleShoppingBagOpen = (event) => {
    setShoppingBagAnchorEl(event.currentTarget);
  };
  const handleShoppingBagClose = () => {
    setShoppingBagAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleClickCheckout = () => {
    setShoppingBagAnchorEl(null);
    history.push('/checkout');
  };

  const handleNotifications = () => {
    history.push('/inbox');
  };

  const setUserNameInitials = (userName) => {
    const userInitials = userName.toUpperCase().charAt(0);
    return userInitials;
  };

  const shoppingBagMenu = 'my-shopping-bag';
  const renderShoppingBagMenu = (
    <Menu
      anchorEl={shoppingBagAnchorEl}
      id={shoppingBagMenu}
      keepMounted={false}
      open={isShoppingBagOpen}
      onClose={handleShoppingBagClose}
      PaperProps={{
        style: {
          transform: 'translateX(0px) translateY(50px)',
        },
      }}
    >
      <CartDropDown handleClick={handleClickCheckout} />
    </Menu>
  );

  const profileMenu = 'my-account-menu';
  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={profileMenu}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      getContentAnchorEl={null}
      open={isProfileMenuOpen}
      onClose={handleMenuClose}
    >
      <StyledMenuItem onClick={handleMyShop}>
        <ListItemIcon>
          <StorefrontIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary=" My Shop" />
      </StyledMenuItem>
      <StyledMenuItem onClick={handleMyProfile}>
        <ListItemIcon>
          <AccountCircleIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary=" My Profile" />
      </StyledMenuItem>
      <StyledMenuItem onClick={handleSignOut}>
        <ListItemIcon>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Sign out" />
      </StyledMenuItem>
    </Menu>

  );

  const mobileMenuId = 'app-menu-mobile';
  const mobileMenuAuthenticated = (
    
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <StyledMenuItem onClick={handleShoppingBagOpen}>
        <ListItemIcon>
        <Badge badgeContent={shoppingBagItemsCount}
                    classes={{
                      badge: classes.badge,
                    }}>
        <FaShoppingBag fontSize="small" />
         
        </Badge>
        </ListItemIcon>
        <ListItemText primary="My Cart" />
      </StyledMenuItem>
      <StyledMenuItem onClick={handleNotifications}>
        <ListItemIcon>
          <EmailIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Notifications" />
      </StyledMenuItem>
      <StyledMenuItem onClick={handleSellNow}>
        <ListItemIcon>
          <LocalOfferIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Sell Now" />
      </StyledMenuItem>

      <StyledMenuItem onClick={handleMyShop}>
        <ListItemIcon>
          <StorefrontIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary=" My Shop" />
      </StyledMenuItem>
      <StyledMenuItem onClick={handleMyProfile}>
        <ListItemIcon>
          <AccountCircleIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary=" My Profile" />
      </StyledMenuItem>
      <StyledMenuItem onClick={handleSignOut}>
        <ListItemIcon>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Sign out" />
      </StyledMenuItem>
    </Menu>
  );

  const mobileMenuNotAuthenticated = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      
      <MenuItem>
        <Button className={classes.btnText} onClick={handleSellNow}>
          Sell Now
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          variant="text"
          className={classes.btnText}
          onClick={handleSignIn}
        >
          sign in
        </Button>
      </MenuItem>
      <MenuItem>
        <Button className={classes.btnText} onClick={handleSignUp}>
          Sign up
        </Button>
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      // transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {token && profile && profile.isPhoneVerified
        ? mobileMenuAuthenticated
        : mobileMenuNotAuthenticated}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar className={classes.bgBar} position="fixed">
        <Toolbar>
          <Link to="/" replace>
            <img src={logo} alt="Lukhu app logo" className="app-logo" />
          </Link>

        {isMobile ? <>
              <div className={classes.search}>
            <SearchInput />
            
            
          </div>
      
          
          </> :      <div className={classes.search}>
            <SearchInput />
            
            
          </div> 
        }

  


          {token && profile  && isMobile === true?
          <StyledMenuItem onClick={handleShoppingBagOpen}>
          <ListItemIcon>
          <Badge badgeContent={shoppingBagItemsCount}
                      classes={{
                        badge: classes.badge,
                      }}>
          <FaShoppingBag fontSize="small" />
           
          </Badge>
          </ListItemIcon>
         
        </StyledMenuItem>
        
  
        : ""}
        
          {/* <div className={classes.grow} >

      
          </div> */}
          <div className={classes.sectionDesktop}>
            {/* <div className={classes.divBtn}></div> */}
            <div style={{ marginRight: '24px' }}>
              <BaseBtn
                className={classes.btnFilled}
                size="small"
                onClick={handleSellNow}
              >
                Sell now
              </BaseBtn>
            </div>

            {token && profile && profile.isPhoneVerified ? (
              <Box display="flex" alignItems="center">
                <IconButton onClick={handleNotifications}>
                  <Badge
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={unreadMessages}
                    classes={{
                      badge: classes.badge,
                    }}
                  >
                    <MdEmail style={{ color: '#000', fontSize: '1.75rem' }} />
                  </Badge>
                </IconButton>
                <IconButton onClick={handleShoppingBagOpen}>
                  <Badge
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={shoppingBagItemsCount}
                    classes={{
                      badge: classes.badge,
                    }}
                  >
                    <FaShoppingBag
                      style={{ color: '#000', fontSize: '1.75rem' }}
                    />
                  </Badge>
                </IconButton>

                {userName && (
                  <IconButton style={{ paddingRight: 0 }}>
                    <UserAvatar initials={setUserNameInitials(userName)} />
                  </IconButton>
                )}

                <IconButton
                  onClick={handleProfileMenuOpen}
                  style={{ padding: 0 }}
                >
                  <KeyboardArrowDownIcon style={{ color: '#000000' }} />
                </IconButton>
              </Box>
            ) : (
              <>
               <IconButton onClick={handleShoppingBagOpen}>
                  <Badge
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={shoppingBagItemsCount}
                    classes={{
                      badge: classes.badge,
                    }}
                  >
                    <FaShoppingBag
                      style={{ color: '#000', fontSize: '1.75rem' }}
                    />
                  </Badge>
                </IconButton>

                <Button
                  variant="text"
                  className={classes.btnText}
                  onClick={handleSignIn}
                >
                  Sign in
                </Button>
                <Button className={classes.btnText} onClick={handleSignUp}>
                  Sign up
                </Button>
              </>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show menu"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />

       {renderMobileMenu}
      {renderProfileMenu} 
      {renderShoppingBagMenu}
    </div>
  );

  // if(width <= 768){
  //   return(
  //     <></>
  //   )
  // }else{
  //   return (
  //     <div className={classes.grow}>
  //       <AppBar className={classes.bgBar} position="fixed">
  //         <Toolbar>
  //           <Link to="/" replace>
  //             <img src={logo} alt="Lukhu app logo" className="app-logo" />
  //           </Link>
  
  //         {isMobile ? <h1>Hello there</h1> :   <div className={classes.search}>
  //             <SearchInput />
  //           </div>}
  
  //           {token && profile  && isMobile?
  //         // ?  <StyledMenuItem onClick={handleShoppingBagOpen}>
  //         //   <ListItemIcon>
  //         //   <Badge badgeContent={shoppingBagItemsCount}
  //         //               classes={{
  //         //                 badge: classes.badge,
  //         //               }}>
  //         //   <FaShoppingBag fontSize="small" />
             
  //         //   </Badge>
  //         //   </ListItemIcon>
           
  //         // </StyledMenuItem>
  //         <></>
    
  //         : ""}
          
  //           <div className={classes.grow} />
  //           <div className={classes.sectionDesktop}>
  //             {/* <div className={classes.divBtn}></div> */}
  //             <div style={{ marginRight: '24px' }}>
  //               <BaseBtn
  //                 className={classes.btnFilled}
  //                 size="small"
  //                 onClick={handleSellNow}
  //               >
  //                 Sell now
  //               </BaseBtn>
  //             </div>
  
  //             {token && profile && profile.isPhoneVerified ? (
  //               <Box display="flex" alignItems="center">
  //                 <IconButton onClick={handleNotifications}>
  //                   <Badge
  //                     anchorOrigin={{
  //                       vertical: 'bottom',
  //                       horizontal: 'right',
  //                     }}
  //                     badgeContent={unreadMessages}
  //                     classes={{
  //                       badge: classes.badge,
  //                     }}
  //                   >
  //                     <MdEmail style={{ color: '#000', fontSize: '1.75rem' }} />
  //                   </Badge>
  //                 </IconButton>
  //                 <IconButton onClick={handleShoppingBagOpen}>
  //                   <Badge
  //                     anchorOrigin={{
  //                       vertical: 'bottom',
  //                       horizontal: 'right',
  //                     }}
  //                     badgeContent={shoppingBagItemsCount}
  //                     classes={{
  //                       badge: classes.badge,
  //                     }}
  //                   >
  //                     <FaShoppingBag
  //                       style={{ color: '#000', fontSize: '1.75rem' }}
  //                     />
  //                   </Badge>
  //                 </IconButton>
  
  //                 {userName && (
  //                   <IconButton style={{ paddingRight: 0 }}>
  //                     <UserAvatar initials={setUserNameInitials(userName)} />
  //                   </IconButton>
  //                 )}
  
  //                 <IconButton
  //                   onClick={handleProfileMenuOpen}
  //                   style={{ padding: 0 }}
  //                 >
  //                   <KeyboardArrowDownIcon style={{ color: '#000000' }} />
  //                 </IconButton>
  //               </Box>
  //             ) : (
  //               <>
  //                <IconButton onClick={handleShoppingBagOpen}>
  //                   <Badge
  //                     anchorOrigin={{
  //                       vertical: 'bottom',
  //                       horizontal: 'right',
  //                     }}
  //                     badgeContent={shoppingBagItemsCount}
  //                     classes={{
  //                       badge: classes.badge,
  //                     }}
  //                   >
  //                     <FaShoppingBag
  //                       style={{ color: '#000', fontSize: '1.75rem' }}
  //                     />
  //                   </Badge>
  //                 </IconButton>
  
  //                 <Button
  //                   variant="text"
  //                   className={classes.btnText}
  //                   onClick={handleSignIn}
  //                 >
  //                   Sign in
  //                 </Button>
  //                 <Button className={classes.btnText} onClick={handleSignUp}>
  //                   Sign up
  //                 </Button>
  //               </>
  //             )}
  //           </div>
  //           <div className={classes.sectionMobile}>
  //             <IconButton
  //               aria-label="show menu"
  //               aria-controls={mobileMenuId}
  //               aria-haspopup="true"
  //               onClick={handleMobileMenuOpen}
  //             >
  //               <MoreIcon />
  //             </IconButton>
  //           </div>
  //         </Toolbar>
  //       </AppBar>
  //       <div className={classes.offset} />
  
  //       {/* {renderMobileMenu} */}
  //       {/* {renderProfileMenu} */}
  //       {renderShoppingBagMenu}
  //     </div>
  //   );
  // }
 
}
