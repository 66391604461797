import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Link, useHistory } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import AddressFormIndividual from './AddressFormIndividual';
import AddressFormBusiness from './AddressFormBusiness';

import LukhupayForm from './LukhupayForm';
import DialogSuccess from '../../components/DialogSuccess';
import DialogStoreSetupFail from './DialogStoreSetupFail';
import happyfaceImg from '../../assets/images/happy-face-illustration1.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepLabel: {
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },
  stepIcon: {
    color: '#EA0053',
  },

  // for tabs
  card: {
    padding: '16px 16px',
    borderRadius: '16px',
    // width: '424px',
  },
  customTab: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: ['Lato', 'sans-serif'].join(','),
    '&:hover': {
      color: '#4800FB',
      opacity: 1,
    },
    '&selected': {
      color: '#4800FB',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#4800FB',
    },
  },

  disabledBtn: {
    backgroundColor: '#E2E2E2',
    color: '#717171',
    '&:hover': {
      backgroundColor: '#E2E2E2',
    },
  },
  activeBtn: {
    backgroundColor: '#4900ff',
    border: ' 2px solid #4900ff',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  smallIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  mbCtnt: {
    marginBottom: '16px',
  },
  tabPanel: {
    width: '100%',
  },
  mbSpacing: {
    marginBottom: theme.spacing(2),
  },
  indicatorColor: {
    backgroundColor: '#4800FB',
  },
}));

const CustomTabs = withStyles({
  indicator: {
    backgroundColor: '#4800FB',
  },
})(Tabs);
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box>{children}</Box>}</div>;
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function getSteps() {
  return [
    'Setup your shop details',
    'Link your mobile money number to LukhuPay',
  ];
}

const mapState = ({ merchant }) => ({
  store: merchant.createdStore,
  errMsg: merchant.errorMessage,
});

const SetupStore = () => {
  const classes = useStyles();
  const history = useHistory();
  const { store, errMsg } = useSelector(mapState);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const [tabValue, setTabValue] = useState(0);
  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setopenFail] = useState(false);

  const handleOpenDialogSuccess = () => {
    setOpenSuccess(true);
  };

  const handleCloseDialog = () => {
    setOpenSuccess(false);
  };
  const handleMaybeLater = () => {
    setOpenSuccess(false);
    history.push('/');
  };

  const handleOpenDialogFail = () => {
    setopenFail(true);
  };

  const handleCloseDialogFail = () => {
    setopenFail(false);
  };
  const handleOpenFeedback = () => {
    if (errMsg) {
      handleOpenDialogFail();
    } else {
      handleOpenDialogSuccess();
    }
  };

  return (
    <section className="sctn-centered">
      <header className="sctn-centered-header">
        <h2 style={{ color: '#011111' }}>Store Setup</h2>
      </header>
      <Divider />

      <Card className={classes.card}>
        <div>
          <CustomTabs value={tabValue} onChange={handleChangeTabs}>
            <Tab label="Selling Individually" className={classes.customTab} />
            <Tab label="Selling as a Business" className={classes.customTab} />
          </CustomTabs>
        </div>
        <CardContent>
          <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
            <AddressFormIndividual
              openNextStep={handleNext}
              openSuccessDialog={handleOpenDialogSuccess}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <AddressFormBusiness
              openNextStep={handleNext}
              openSuccessDialog={handleOpenDialogSuccess}
              openFeedback={handleOpenFeedback}
            />
          </TabPanel>
        </CardContent>
      </Card>
      <DialogSuccess
        handleClose={handleCloseDialog}
        open={openSuccess}
        title="You’re all set!"
        img={happyfaceImg}
        btnTxt="I want to list!"
        btnLinkTo="/list-product"
        txtLinkTo="/"
        handleMaybeLater={handleMaybeLater}
      />

      <DialogStoreSetupFail
        handleClose={handleCloseDialogFail}
        open={openFail}
        title="Unsuccessful!"
        img={happyfaceImg}
        btnTxt="try again"
        // btnLinkTo="/list-product"
        // txtLinkTo="/"
      />
    </section>
  );
};

export default SetupStore;
