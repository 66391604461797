import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { spacing, flexbox } from '@material-ui/system';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import mpesaLogo from '../../assets/images/mpesa-logo.svg';
import visaLogo from '../../assets/images/visa-logo.svg';
import mastercardLogo from '../../assets/images/mastercard-logo.svg';
import RenderCartItems from './RenderCartItems';
import CartVoucher from './CartVoucher';
import VoucherCodeCheck from './VoucherCodeCheck';
import { cart_voucher, setCartSubtotal, setCartTotal } from '../../redux/cart/cartActions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F8F8F8',
    padding: '2px',
    marginBottom:"30px"
  },
  boxx:{
    background:"#F8F8F8"
  },
  heading: {
    textTransform: 'uppercase',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    fontWeight: 600,
    color: '#4A4062',
  },
  txt:{
    fontSize:"16px",
    color:"#929292"
  },
  heading2: {
    textTransform: 'uppercase',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '0.75rem',
    fontWeight: 600,
    color: '#D81E5B',
  },
  content: {
    color: '#707071',
    fontSize: '0.75rem',
    fontFamily: ['Lato', 'sans-serif'].join(','),
  },
  logoCtn: {
    marginRight: '8px',
    width: '25%',
  },
  shippingT:{
    display:"flex",
    width:"100%",
    alignItems:"center",
    justifyContent:"space-between"

  },
  mainDiv:{
    width:"100%",
    marginTop:"6px",
    marginBottom:"10px"
  }
}));

const mapState = ({ checkout, cart }) => ({
  isCartCheckedout: checkout.isCartCheckedout,
  checkedOutCart: checkout.checkedOutCart,
  cartItems: cart.cartItems,
  shoppingBagItems: cart.shoppingBagItems,
  cartTotal:cart.cartTotal,
  cartSubtotal :cart.cartSubtotal,
  cartShippingTotal:cart.cartShippingTotal,
  cart:cart,
  combinedCartTota:cart.combinedCartTotal


});


const VoucherCode = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
 
  const { isCartCheckedout, cartItems, checkedOutCart,cartTotal,cartSubtotal,cartShippingTotal,cart,combinedCartTotal } = useSelector(mapState);



  console.log("the cart items",cart.combinedCartTotal)
  console.log("the cart total is",combinedCartTotal)

  const saveTotal = () =>{
    // set cart items to react hook total
    const c  = cartItems[0].cart_total
    dispatch(setCartTotal(c))
    dispatch(setCartSubtotal(c))
  }
  
  useEffect(() => {
    saveTotal();
    
    
  
  }, [])
  


  console.log("cart_items_voucher",cartItems)
  return (
    <Paper elevation={0} className={classes.root}>
      <Box  elevation={0}  className={classes.boxx}>
        <Box width="100%" p={1} mb={3}>
          <Typography variant="h4" gutterBottom className={classes.heading}>
            ORDER SUMMARY
          </Typography>
          <CartVoucher />
       
          <VoucherCodeCheck />
         <div className={classes.mainDiv}>
         <div className={classes.shippingT}>
            <p className={classes.txt}>SubTotal</p>
            <p>Ksh {cart.combinedCartTotal}</p>
          </div>

          {
            cartShippingTotal !== 0 ? (
              <div  className={classes.shippingT}>
            <p className={classes.txt}>Shipping Cost</p>
            <p>Ksh {cartShippingTotal}</p>
          </div>
            ) : ""
          }

          {/*  */}
         </div>
          <hr></hr>

          <div className={classes.mainDiv}>
          <div className={classes.shippingT}>
            <p>Total</p>
            <p>Ksh {cart.combinedCartTotal}</p>
          </div>

    
         </div>
        </Box>
        
       
        
      
       
      </Box>
    </Paper>
  );
};

export default VoucherCode;
