/* 
 This component renders if a search query is made from anywhere in the app 
 except from a specific category (women, men, kidsware) homepage 
*/

import { useEffect, useState } from 'react';
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Container, makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CheckIcon from '@material-ui/icons/Check';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import PromotionBanner from '../../parts/Home/PromotionBanner';
import promoimg from '../../assets/images/pink-hoodie-model.jpg';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseCollapse from '../../components/BaseCollapse';
import SetPriceInput from '../../parts/Home/SetPriceInput';
import CustomSelect from '../../components/NoBorderInput';
import SearchProductResults from '../../parts/Search/SearchProductResults';

import '../../assets/styles/Display.css';
import {
  fetchCategoryProductsResults,
  fetchMainCategoriesList,
  fetchFilterResults,
} from '../../redux/search/searchActions';
import {
  fetchProductConditions,
  fetchProductColorsOptions,
} from '../../redux/products/productsActions';
import FilterNav from '../../parts/Search/FilterNav';
import WelcomeBanner from '../../parts/Home/WelcomeBanner';
import promo1 from '../../assets/images/black-hat-model.jpg';

const useStyles = makeStyles((theme) => ({
  sizeBtn: {
    padding: '4px',
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-4px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  listItem: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  nestedListItem: {
    padding: '4px 0px',
  },
  listText: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  listTextSmall: {
    fontSize: '1rem',
  },
  categoryTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  attrTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontSize: '0.875rem',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  nestedListItemText: {
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 400,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
    color: '#111111',
  },
  listActionPosition: {
    right: 0,
  },
  setPriceButton: {
    color: '#471CF9',
    fontFamily: ['Montserrat', 'sans-serif'].join(', '),
    fontWeight: 700,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
  },
  grid: {
    // width: '92vw',
    margin: '0 auto',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  colorCircle: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  selectedColorCircle: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  filterNav: {
    paddingRight: '24px',
    width: '100%',
  },
}));

const mapState = ({ search, product }) => ({
  categoryProductsResults: search.categoryProductsResults,
  categoryProductsResultsError: search.categoryProductsResultsError,
  loadingcategoryProductsResults: search.loadingcategoryProductsResults,
  listingCount: search.categoryProductsResultsLength,
  mainCategoriesList: search.mainCategoriesList,
  allCategories: search.allCategories,
  productConditions: product.productConditions,
  productDeliveryOptions: product.productDeliveryOptions,
  productColors: product.productColors,
  loadingFilterResults: search.loadingFilterResults,
  filterResults: search.filterResults,
  filterResultsError: search.filterResultsError,
  filterResultsCount: search.filterResultsCount,
  searchQueryResultsCount: search.searchQueryResultsCount,
  categoryProductsResultsCount: search.categoryProductsResultsCount,
});
// const priceRange = [
//   'Under KSh 1,000',
//   'KSh 1,000 - KSh 2,000',
//   'KSh 2,000 - KSh 3,000',
//   'KSh 3,000 - KSh 4,000',
//   'KSh 4,000+',
// ];
const priceRange = [
  { id: 0, title: 'Under KSh 1,000', max: 1000, min: 0 },
  { id: 1, title: 'KSh 1,000 - KSh 2,000', max: 2000, min: 1000 },
  { id: 2, title: 'KSh 2,000 - KSh 3,000', max: 3000, min: 2000 },
  { id: 3, title: 'KSh 3,000 - KSh 4,000', max: 4000, min: 3000 },
  { id: 4, title: 'KSh 4,000+', max: 100000, min: 4000 },
];
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CategorySearchPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { category } = useParams();
  const { pathname, search } = useLocation();
  const { url } = useRouteMatch();
  let query = useQuery();
  const dispatch = useDispatch();
  const {
    categoryProductsResults,
    loadingcategoryProductsResults,
    mainCategoriesList,
    allCategories,
    productDeliveryOptions,
    productColors,
    productConditions,
    listingCount,
    loadingFilterResults,
    filterResults,
    filterResultsError,
    filterResultsCount,
    searchQueryResultsCount,
    categoryProductsResultsCount,
  } = useSelector(mapState);

  useEffect(() => {
    dispatch(fetchMainCategoriesList(category));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCategoryProductsResults(category));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductConditions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductColorsOptions());
  }, [dispatch]);

  const viewProductDetails = (id) => {
    history.push(`/catalog/${id}`);
  };

  const [sortBy, setSortBy] = useState('on sale');
  const handleSortBy = (event) => {
    setSortBy(event.target.value);
  };

  const getDisplayImage = (arr) => {
    if (categoryProductsResults || filterResults) {
      // console.log('IMAGESs', arr[0]);
      return arr[0].secure_url;
    }
  };

  // collapsible lists
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const [openConditionFilter, setOpenConditionFilter] = useState(false);

  const handleClickConditionFilter = () => {
    setOpenConditionFilter(!openConditionFilter);
  };

  return (
    <div className="bg-home">
      <WelcomeBanner
        bannerBackgroundColor="#FFF5F6"
        themeColor="#D81E5B"
        promoImg={promo1}
        promoTitle="WELCOME TO LUKHU"
      />

      <Container maxWidth="lg">
        <Grid container className={classes.grid}>
          <Grid item xs={12}>
            <div className="d-flex d-flex-space pb-16">
              <h4 className="f-16 txt-semi-bold">
                {!filterResultsCount && categoryProductsResultsCount
                  ? categoryProductsResultsCount
                  : categoryProductsResultsCount && filterResultsCount
                  ? filterResultsCount
                  : 'no available'}{' '}
                {''} listings
              </h4>
              {/* <div className="d-flex">
              <h4 className="f-16 txt-medium">Sort by : </h4>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={sortBy}
                onChange={handleSortBy}
                input={<CustomSelect />}
              >
                <MenuItem value="other value">other values</MenuItem>
              </Select>
            </div> */}
            </div>
            <Divider />
          </Grid>
        </Grid>
        <Grid container className={classes.grid}>
          <Grid container item xs={12} md={2}>
            <FilterNav
              mainCategoriesList={mainCategoriesList}
              allCategories={allCategories}
              productColors={productColors}
              productConditions={productConditions}
            />
          </Grid>

          <SearchProductResults
            filterResults={filterResults}
            categoryProductsResults={categoryProductsResults}
            viewProductDetails={viewProductDetails}
            // filterResultsCount={filterResultsCount}
          />
        </Grid>
      </Container>
    </div>
  );
};

export default CategorySearchPage;
