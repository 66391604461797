export const FOLLOW_MERCHANT = 'FOLLOW_MERCHANT';
export const UNFOLLOW_MERCHANT = 'UNFOLLOW_MERCHANT';

export const GET_FOLLOWING_START = 'GET_FOLLOWING_START';
export const GET_FOLLOWING_SUCCESS = 'GET_FOLLOWING_SUCCESS';
export const GET_FOLLOWING_FAIL = 'GET_FOLLOWING_FAIL';

export const FETCH_USER_LIKED_PRODUCTS_START =
  'FETCH_USER_LIKED_PRODUCTS_START';
export const FETCH_USER_LIKED_PRODUCTS_SUCESS =
  'FETCH_USER_LIKED_PRODUCTS_SUCESS';
export const FETCH_USER_LIKED_PRODUCTS_FAIL = 'FETCH_USER_LIKED_PRODUCTS_FAIL';
export const LIKE_PRODUCT = 'LIKE_PRODUCT';
