export const FETCH_USER_MESSAGES_START = 'FETCH_MESSAGES_START';
export const FETCH_USER_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_USER_MESSAGES_FAIL = ' FETCH_MESSAGES_FAIL';

export const FETCH_CURRENT_CHAT_START = 'FETCH_CURRENT_CHAT_START';
export const FETCH_CURRENT_CHAT_SUCCESS = 'FETCH_CURRENT_CHAT_SUCCESS';
export const FETCH_CURRENT_CHAT_FAIL = ' FETCH_CURRENT_CHAT_FAIL';

export const SEND_CHAT_MSG = 'SEND_CHAT_MSG';
export const SEND_CHAT_MSG_FAIL = 'SEND_CHAT_MSG_FAIL';

export const SET_CURRENT_CHATID = 'SET_CURRENT_CHATID';

export const FETCH_UNREAD_START = 'FETCH_UNREAD_START';
export const FETCH_UNREAD_SUCCESS = 'FETCH_UNREAD_SUCCESS';
export const FETCH_UNREAD_FAIL = 'FETCH_UNREAD_FAIL';
