export const CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS';
export const CREATE_STORE_FAIL = 'CREATE_STORE_FAIL';

export const VERIFY_MOBILE_MONEY_NUMBER_SUCCESS =
  'VERIFY_MOBILE_MONEY_NUMBER_SUCCESS';
export const VERIFY_MOBILE_MONEY_NUMBER_FAIL =
  'VERIFY_MOBILE_MONEY_NUMBER_FAIL';

export const PROVIDE_MOBILE_MONEY_NUMBER_SUCCESS =
  'PROVIDE_MOBILE_MONEY_NUMBER_SUCCESS';
export const PROVIDE_MOBILE_MONEY_NUMBER_FAIL =
  'PROVIDE_MOBILE_MONEY_NUMBER_FAIL';

export const CHECK_HAS_STORE = 'CHECK_HAS_STORE';

export const FETCH_STORE_DETAILS_PUBLIC_START =
  'FETCH_STORE_DETAILS_PUBLIC_START';
export const FETCH_STORE_DETAILS_PUBLIC_SUCCESS =
  'FETCH_STORE_DETAILS_PUBLIC_SUCCESS';
export const FETCH_STORE_DETAILS_PUBLIC_FAIL =
  'FETCH_STORE_DETAILS_PUBLIC_FAIL';

export const FETCH_STORE_DETAILS_PRIVATE_START =
  'FETCH_STORE_DETAILS_PRIVATE_START';
export const FETCH_STORE_DETAILS_PRIVATE_SUCCESS =
  'FETCH_STORE_DETAILS_PRIVATE_SUCCESS';
export const FETCH_STORE_DETAILS_PRIVATE_FAIL =
  'FETCH_STORE_DETAILS_PRIVATE_FAIL';

export const FETCH_STORE_LISTED_PRODUCTS_START =
  'FETCH_STORE_LISTED_PRODUCTS_START';
export const FETCH_STORE_LISTED_PRODUCTS_SUCCESS =
  'FETCH_STORE_LISTED_PRODUCTS_SUCCESS';
export const FETCH_STORE_LISTED_PRODUCTS_FAIL =
  'FETCH_STORE_LISTED_PRODUCTS_FAIL';

export const FETCH_STORE_SOLD_PRODUCTS_START =
  'FETCH_STORE_SOLD_PRODUCTS_START';
export const FETCH_STORE_SOLD_PRODUCTS_SUCCESS =
  ' FETCH_STORE_SOLD_PRODUCTS_SUCCESS';
export const FETCH_STORE_SOLD_PRODUCTS_FAIL = 'FETCH_STORE_SOLD_PRODUCTS_FAIL';

export const FETCH_STORE_DRAFT_PRODUCTS_START =
  'FETCH_STORE_DRAFT_PRODUCTS_START';
export const FETCH_STORE_DRAFT_PRODUCTS_SUCCESS =
  ' FETCH_STORE_DRAFT_PRODUCTS_SUCCESS';
export const FETCH_STORE_DRAFT_PRODUCTS_FAIL =
  'FETCH_STORE_DRAFT_PRODUCTS_FAIL';

export const FETCH_STORE_LISTED_PRODUCTS_PUBLIC_START =
  'FETCH_STORE_LISTED_PRODUCTS_PUBLIC_START';
export const FETCH_STORE_LISTED_PRODUCTS_PUBLIC_SUCCESS =
  'FETCH_STORE_LISTED_PRODUCTS_PUBLIC_SUCCESS';
export const FETCH_STORE_LISTED_PRODUCTS_PUBLIC_FAIL =
  'FETCH_STORE_LISTED_PRODUCTS_PUBLIC_FAIL';

export const FETCH_STORE_STAT_START = 'FETCH_STORE_STAT_START';
export const FETCH_STORE_STAT_SUCCESS = 'FETCH_STORE_STAT_SUCCESS';
export const FETCH_STORE_STAT_FAIL = 'FETCH_STORE_STAT_FAIL';

export const FETCH_AVG_DAILY_INCOME_START = 'FETCH_AVG_DAILY_INCOME_START';
export const FETCH_AVG_DAILY_INCOME_SUCCESS = 'FETCH_AVG_DAILY_INCOME_SUCCESS';
export const FETCH_AVG_DAILY_INCOME_FAIL = 'FETCH_AVG_DAILY_INCOME_FAIL';

// export const EDIT_PROFILE_START = 'EDIT_PROFILE_START';
// export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
// export const EDIT_PROFILE_FAIL = 'EDIT_PROFILE_FAIL';

export const FETCH_STORE_RECEIVED_FEEDBACK_START =
  'FETCH_STORE_RECEIVED_FEEDBACK_START';
export const FETCH_STORE_RECEIVED_FEEDBACK_SUCCESS =
  'FETCH_STORE_RECEIVED_FEEDBACK_SUCCESS';
export const FETCH_STORE_RECEIVED_FEEDBACK_FAIL =
  'FETCH_STORE_RECEIVED_FEEDBACK_FAIL';

export const FETCH_STORE_SENT_FEEDBACK_START =
  'FETCH_STORE_SENT_FEEDBACK_START';
export const FETCH_STORE_SENT_FEEDBACK_SUCCESS =
  'FETCH_STORE_SENT_FEEDBACK_SUCCESS';
export const FETCH_STORE_SENT_FEEDBACK_FAIL = 'FETCH_STORE_SENT_FEEDBACK_FAIL';
