import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import happyFace from '../assets/images/happy-face-illustration1.png';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '32px 0',
  },
});

const ComingSoon = () => {
  const classes = useStyles();
  return (
    <section>
      <Container className={classes.root}>
        <h2 className="txt-capitalize txt-ruby ">coming soon!</h2>
        <p className="txt-violet">
          Stay tuned for this and more awesome features from Lukhu.
        </p>
        <Link to="/">Home</Link>
        <div>
          <img src={happyFace} alt="Happy face illustration" />
        </div>
      </Container>
    </section>
  );
};

export default ComingSoon;
