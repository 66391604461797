import { useState, useEffect } from 'react';
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import ResultsLayout from './ResultsLayout';
import WelcomeBanner from '../../parts/Home/WelcomeBanner';
import ListingCard from '../../parts/Products/ListingCard';
import L2CategoryNav from '../../parts/Search/L2CategoryNav';
import NoResults from '../../parts/Search/NoResults';
import {
  fetchCategoryProductsResults,
  fetchLevel2Search,
  fetchLevel2Categories,
} from '../../redux/search/searchActions';
import {
  fetchProductConditions,
  fetchProductColorsOptions,
} from '../../redux/products/productsActions';

import promo1 from '../../assets/images/black-hat-model.jpg';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const mapState = ({ search, product }) => ({
  //   allCategories: search.allCategories,
  //   displayCategories: search.l2CategoriesList,
  //   productConditions: product.productConditions,
  //   productColors: product.productColors,
  //   l2Results: null,
  //   l2Count: null,
  //   l2Loading: null,
  //   l2Error: null,
  //   l2Id: null,
  //   l2CategoryChildren: null,
  //   l2ChildrenLoading: false,
  //   l2Children: null,
  //   l2ChildrenError: null,

  results: search.l2Results,
  resultsCount: search.l2Count,
  resultsError: search.l2Error,
  loading: search.l2Loading,
});

const L2CategoryPageResults = () => {
  // const classes = useStyles();
  //   const history = useHistory();
  const { category } = useParams();
  const { pathname, search } = useLocation();
  //   const { url } = useRouteMatch();
  let query = useQuery();

  const dispatch = useDispatch();
  const {
    // allCategories,
    // displayCategories,
    productColors,
    productConditions,
    loading,
    results,
    resultsCount,
    resultsError,
  } = useSelector(mapState);

  useEffect(() => {
    /**
     * Fetch results on load
     * If no query string , set the category filter on the API url
     */
    query = `category=${category}`;
    const filterQuery = search ? search.slice(1) : query;
    // console.log('FILE', filterQuery);
    dispatch(fetchLevel2Search(filterQuery));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchLevel2Categories(category));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductConditions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductColorsOptions());
  }, [dispatch]);

  return (
    <section>
      <div className="bg-home" style={{ minHeight: '80vh' }}>
        <ResultsLayout
          content={
            <>
              {loading && <NoResults msg="Loading ..." />}
              {!loading && resultsError && <NoResults msg="Error!" />}
              {results && resultsCount < 1 && <NoResults msg="No listings" />}
              {results &&
                results.map((item) => (
                  <Grid item xs={6} sm={4} md={3} key={item.id}>
                    <ListingCard product={item} />
                  </Grid>
                ))}
            </>
          }
          menu={
            <L2CategoryNav
              productColors={productColors}
              productConditions={productConditions}
            />
          }
          title={
            resultsCount === 0
              ? 'No available listings'
              : resultsCount > 1
              ? `${resultsCount} listings`
              : `${resultsCount} listing`
          }
        />
      </div>
    </section>
  );
};

export default L2CategoryPageResults;
