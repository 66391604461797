import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';

import DialogHeading from './DialogHeading';

import BaseBtnFilled from '../../components/BaseBtnFilled';
import { completePayment } from '../../redux/checkout/checkoutActions';

import mpesaLogo from '../../assets/images/mpesa-logo.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '16px',
  },
  paper2: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },

  dflex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  btn: {
    width: '240px',
  },
  actions: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '32px',
    paddingBottom: '24px',
    flexDirection: 'column',
  },
}));

const title = 'Complete Payment';
const subtitle = 'Pay securely with Lukhu Pay';

const mapState = ({ checkout }) => ({
  orderId: checkout.orderId,
  orderItemsTotal: checkout.orderItemsTotal,
  deliveryFee: checkout.deliveryFee,
  orderSubTotal: checkout.orderSubTotal,
  orderGrandTotal: checkout.orderGrandTotal,
  phoneNumber: checkout.phoneNumber,
  paymentType: checkout.paymentType,
  cardNumber: checkout.cardNumber,
  cvv: checkout.cvv,
  cardExpiry: checkout.cardExpiry,
  firstName: checkout.fName,
  middleName: checkout.mName,
  lastName: checkout.lName,
  orderCreated: checkout.orderCreated,
  stkPushSuccess: checkout.completePaymentSucess,
  stkPushFail: checkout.completePaymentErr,
});
const ConfirmPaymentCard = ({ handleClose, open,firstName,lastName,email,phoneNumber }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const {
    orderGrandTotal,
    orderId,
    orderItemsTotal,
    orderSubTotal,
    deliveryFee,
 
    paymentType,
    cardNumber,
    cvv,
    cardExpiry,
  
    middleName,
  
    orderCreated,
    stkPushFail,
    stkPushSuccess,
  } = useSelector(mapState);

  const formatCcNum = (num) => {
    if (num) {
      let hideNum = [];
      for (let i = 0; i < num.length; i++) {
        if (i < num.length - 4) {
          hideNum.push('*');
        } else {
          hideNum.push(num[i]);
        }
      }
      let formatNum = hideNum
        .join('')
        .match(/.{1,4}/g)
        .join(' ');
      return formatNum;
    }
  };

  const handlePayNow = () => {
    const body = {
      orderId: orderId,
      phoneNumber: phoneNumber,
      email:email,
      paymentType: paymentType,
      cardNumber: cardNumber,
      cardExpiry: cardExpiry,
      cvv: cvv,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
    };
    // console.log(body);
    dispatch(completePayment(body));
  };


  // const placeOrder = async (firstName,lastName,phoneNumber,email,OrderId)=>{
    

  //   var data = qs.stringify({
  //     'paymentType': 'CARD',
  //     'orderId': OrderId,
  //     'phoneNumber': phoneNumber,
  //     'firstName': firstName,
  //     'lastName': lastName,
  //    'email': email
  //   });
  //   var config = {
  //     method: 'post',
  //     url: `${url}/orders/payment/`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //      },
  //     data : data
  //   };
    
  //   axios(config)
  //   .then(function (response) {
  //     console.log(JSON.stringify(response.data));
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  //     }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="select-pickup-store"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogHeading title={title} subtitle={subtitle} onClose={handleClose} />
      {orderCreated ? (
        <>
          <DialogContent>
            <div className="mb-16">
              <div className="mb-16">
                <h4 className="f-14 txt-semi-bold txt-gray32">ORDER SUMMARY</h4>
              </div>

              <Paper className={classes.paper} elevation={3}>
                <div className={classes.dflex}>
                  <div>
                    <p className="f-14">Items total</p>
                  </div>
                  <div>
                    <p className="f-14 txt-bold">Ksh {orderItemsTotal}</p>
                  </div>
                </div>
                <div className={classes.dflex}>
                  <div>
                    <p className="f-14">Delivery Fee</p>
                  </div>
                  <div>
                    <p className="f-14 txt-bold">Ksh {deliveryFee}</p>
                  </div>
                </div>
                <div className={classes.dflex}>
                  <div>
                    <p className="f-14 txt-bold">Subtotal</p>
                  </div>
                  <div>
                    <p className="f-14 txt-bold">Ksh {orderSubTotal}</p>
                  </div>
                </div>
                <div className={classes.dflex}>
                  <div>
                    <p className="txt-hanPurple txt-bold">Grand Total</p>
                  </div>
                  <div>
                    <p className="txt-hanPurple txt-bold">
                      Ksh {orderGrandTotal}
                    </p>
                  </div>
                </div>
              </Paper>
            </div>
            <div>
              <div className="mb-16">
                <h4 className="f-14 txt-semi-bold txt-gray32">
                  PAYMENT DETAILS
                </h4>
              </div>

              {paymentType === 'MPESA' ? (
                <Paper className={classes.paper2} elevation={3}>
                  <div style={{ width: '15%' }}>
                    <img src={mpesaLogo} alt="M-Pesa Logo" />
                  </div>
                  <div>
                    <p>{phoneNumber}</p>
                  </div>
                </Paper>
              ) : (
                <Paper className={classes.paper2} elevation={3}>
                  {/* <div style={{ width: '15%' }}>
                    <img src={mpesaLogo} alt="M-Pesa Logo" />
                  </div> */}
                  <div>
                    <p className="f-14">{formatCcNum(cardNumber)}</p>
                    <p className="f-14">Expiry: {cardExpiry}</p>
                  </div>
                </Paper>
              )}
            </div>
          </DialogContent>
          <DialogActions classes={{ root: classes.actions }}>
            <BaseBtnFilled
              className={classes.btn}
              onClick={handlePayNow}
              disabled={stkPushSuccess}
            >
              Pay Now
            </BaseBtnFilled>
            <div className="mb-16" />
            {stkPushSuccess && (
              <Alert severity="info">
                Please visit the redirected page to complete the payment !
              </Alert>
            )}
          </DialogActions>
        </>
      ) : (
        <DialogContent>
          <div>loading ...</div>
        </DialogContent>
      )}

      <div className="txt-center">
        <p className="f-14" style={{ padding: '16px 48px' }}>
          All in-app purchases are covered by Lukhu Buyer Protection. By tapping
          “Pay Now” you accept Lukhu´s{' '}
          <a
            href="https://lukhu.helpscoutdocs.com/article/7-terms-and-conditions"
            target="_blank"
          >
            Terms & Conditions
          </a>
        </p>
      </div>
    </Dialog>
  );
};

ConfirmPaymentCard.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default ConfirmPaymentCard;
