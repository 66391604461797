import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

const BaseSuccessDisplay = ({ message }) => {
  return (
    <Box
      height="10vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={2}
    >
      <p style={{
        color:"green"
      }} className="txt-center">{message}</p>
    </Box>
  );
};
BaseSuccessDisplay.propTypes = {
  message: PropTypes.string.isRequired,
};
export default BaseSuccessDisplay;
