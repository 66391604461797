import {
  GET_WALLET_START,
  GET_WALLET_SUCCESS,
  GET_WALLET_FAIL,
  GET_TRANSACTIONS_FAIL,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_START,
  DEPOSIT_SUCCESS,
  DEPOSIT_FAIL,
} from './walletTypes';

const initialState = {
  loading: false,
  balance: null,
  pendingBalance: null,
  fetchErr: null,

  transactions: null,
  transactionsLoading: false,
  transactionsErr: null,

  depositInitiated: false,
  depositInitiatedErr: null,
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WALLET_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_WALLET_SUCCESS: {
      return {
        ...state,
        loading: false,
        pendingBalance: action.payload.pending,
        balance: action.payload.balance,
      };
    }
    case GET_WALLET_FAIL: {
      return {
        ...state,
        loading: false,
        fetchErr: action.payload,
      };
    }
    case GET_TRANSACTIONS_START: {
      return {
        ...state,
        transactionsLoading: true,
      };
    }
    case GET_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        transactionsLoading: false,
        transactions: action.payload,
      };
    }
    case GET_TRANSACTIONS_FAIL: {
      return {
        ...state,
        transactionsLoading: false,
        transactionsErr: action.payload,
      };
    }
    case DEPOSIT_SUCCESS: {
      return {
        ...state,
        depositInitiated: true,
      };
    }
    case DEPOSIT_FAIL: {
      return {
        ...state,
        depositInitiatedErr: action.payload,
      };
    }

    default:
      return state;
  }
};

export default walletReducer;
