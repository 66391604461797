import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@material-ui/core/Avatar';

import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseInput2 from '../../components/BaseInput2';
import flagIcon from '../../assets/images/flag_icon.jpg';

import mpesaLogo from '../../assets/images/mpesa-logo.svg';
import visaLogo from '../../assets/images/visa-logo.svg';
import mastercardLogo from '../../assets/images/mastercard-logo.svg';
import { depositToWallet } from '../../redux/wallet/walletActions';

const mobileValidationSchema = yup.object({
  phoneNumber: yup
    .string()
    .required('Please fill out this field')
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number'),

  amount: yup
    .string()
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .required('Please fill out this field'),
});
const cardValidationSchema = yup.object({
  cardAmount: yup
    .string()
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .required('Please fill out this field'),
  cardNumber: yup
    .string()
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .required('Please fill out this field'),
  cardCvv: yup
    .string()
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .required('Please fill out this field'),
  cardExpiry: yup.string().required('Please fill out this field'),
  firstName: yup.string().required('Please fill out this field'),
  lastName: yup.string().required('Please fill out this field'),
  middleName: yup.string().required('Please fill out this field'),
});

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },

  layout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  paper: {
    padding: '16px',
    height: 'fit-content',
    width: '100%',
    // maxWidth: '375px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
    margin: '8px 0',
  },
  btn: { width: '150px', fontWeight: 700, padding: 4 },
  active: {},
  inactive: {},
  flagIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  title: {
    paddingBottom: 0,
    margin: '0 auto',
  },
  subtitle: {
    paddingTop: 0,
    margin: '0 auto',
    textTransform: 'capitalize',
  },
}));

const mapState = ({ wallet }) => ({
  depositInitiated: wallet.depositInitiated,
  depositInitiatedErr: wallet.depositInitiatedErr,
});

const WalletTopup = ({ open, handleClose }) => {
  const [mpesaConfirm, setMpesaConfirm] = useState(false);
  const [cardConfirm, setCardConfirm] = useState(false);

  const dispatch = useDispatch();

  const { depositInitiated, depositInitiatedErr } = useSelector(mapState);
  const formik1 = useFormik({
    initialValues: {
      phoneNumber: '',
      amount: '',
    },
    validationSchema: mobileValidationSchema,
    onSubmit: (values) => {
      const details = {
        paymentType: 'MPESA',
        amount: values.amount,
        phoneNumber: values.phoneNumber,
        cardNumber: null,
        cvv: null,
        cardExpiry: null,
        firstName: null,
        middleName: null,
        lastName: null,
      };

      dispatch(depositToWallet(details));

      setMpesaConfirm(true);
    },
  });
  const formik2 = useFormik({
    initialValues: {
      cardAmount: '',
      cardNumber: '',
      cardCvv: '',
      cardExpiry: '',
      firstName: '',
      middleName: '',
      lastName: '',
    },
    validationSchema: cardValidationSchema,
    onSubmit: (values) => {
      const details = {
        paymentType: 'CARD',
        phoneNumber: null,
        amount: values.cardAmount,
        cardNumber: values.cardNumber,
        cvv: values.cardCvv,
        cardExpiry: values.cardExpiry,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
      };

      // console.log('Deposit', details);
      dispatch(depositToWallet(details));

      setCardConfirm(true);
    },
  });
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState('mobile');
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle
        disableTypography
        id="wallet-topup-title"
        classes={{ root: classes.title }}
      >
        <h2 className="txt-violet">Top-up Wallet</h2>
      </DialogTitle>
      <DialogTitle
        disableTypography
        id="wallet-topup-subtitle"
        classes={{ root: classes.subtitle }}
      >
        <p>select topup option</p>
      </DialogTitle>
      <DialogContent>
        <div>
          <RadioGroup
            aria-label="payment-options"
            name="payment"
            value={selectedOption}
            onChange={handleChange}
          >
            <div className={classes.layout}>
              <Paper className={classes.paper}>
                {' '}
                <div className="d-flex d-flex-space d-flex-align-center">
                  <FormControlLabel
                    value="mobile"
                    control={<Radio />}
                    label="Mobile Money"
                    classes={{
                      label: classes.label,
                    }}
                  />
                  {selectedOption === 'mobile' && (
                    <div className="brand-logo">
                      <img src={mpesaLogo} alt="M-Pesa Logo" />
                    </div>
                  )}
                </div>
                {selectedOption === 'mobile' && (
                  <>
                    <form onSubmit={formik1.handleSubmit} noValidate>
                      <BaseInput2
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone Number *"
                        startAdornment={
                          <InputAdornment position="start">
                            {' '}
                            <Avatar
                              alt="flag of Kenya"
                              src={flagIcon}
                              className={classes.flagIcon}
                            />
                          </InputAdornment>
                        }
                        value={formik1.values.phoneNumber}
                        onChange={formik1.handleChange}
                        fullWidth
                      />
                      {formik1.errors.phoneNumber &&
                        formik1.touched.phoneNumber && (
                          <p className="input-error">
                            <small>{formik1.errors.phoneNumber}</small>
                          </p>
                        )}
                      <div className="mb-8"></div>
                      <BaseInput2
                        id="amount"
                        name="amount"
                        type="text"
                        placeholder="Amount *"
                        value={formik1.values.amount}
                        onChange={formik1.handleChange}
                        fullWidth
                      />
                      {formik1.errors.amount && formik1.touched.amount && (
                        <p className="input-error">
                          <small>{formik1.errors.amount}</small>
                        </p>
                      )}
                      <div className="mb-8"></div>
                      <BaseBtnFilled className={classes.btn} type="submit">
                        Confirm
                      </BaseBtnFilled>

                      {depositInitiated && (
                        <p className="f-14 txt-center txt-elecViolet">
                          Check your mobile phone to enter your M-pesa pin{' '}
                        </p>
                      )}
                      {depositInitiatedErr && (
                        <p className="inputError">
                          An error occured. Please try again.
                        </p>
                      )}
                    </form>
                  </>
                )}
              </Paper>
              <Paper className={classes.paper}>
                <div className="d-flex d-flex-space d-flex-align-center">
                  <FormControlLabel
                    value="card"
                    control={<Radio />}
                    label="Debit or Credit Card"
                    classes={{
                      label: classes.label,
                    }}
                  />
                  {selectedOption === 'card' && (
                    <div
                      className="d-flex  d-flex-align-center"
                      style={{ justifyContent: 'flex-end' }}
                    >
                      <div className="brand-logo">
                        {' '}
                        <img src={mastercardLogo} alt="Mastercard Logo" />
                      </div>
                      <div className="brand-logo">
                        <img src={visaLogo} alt="Visa Logo" />
                      </div>
                    </div>
                  )}
                </div>
                {selectedOption === 'card' && (
                  <>
                    <form onSubmit={formik2.handleSubmit} noValidate>
                      <div
                        style={{
                          display: 'flex',
                          marginBottom: '16px',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ width: '40%' }}>
                          <BaseInput2
                            id="cardNumber"
                            name="cardNumber"
                            type="text"
                            placeholder="Card Number *"
                            value={formik2.values.cardNumber}
                            onChange={formik2.handleChange}
                            fullWidth
                          />
                          {formik2.errors.cardNumber &&
                            formik2.touched.cardNumber && (
                              <p className="input-error">
                                <small>{formik2.errors.cardNumber}</small>
                              </p>
                            )}
                        </div>
                        <div style={{ width: '38%' }}>
                          <BaseInput2
                            id="cardExpiry"
                            name="cardExpiry"
                            type="text"
                            placeholder="Expiry Date e.g 08/22 *"
                            value={formik2.values.cardExpiry}
                            onChange={formik2.handleChange}
                            fullWidth
                            autoComplete="off"
                          />

                          {formik2.errors.cardExpiry &&
                            formik2.touched.cardExpiry && (
                              <p className="input-error">
                                <small>{formik2.errors.cardExpiry}</small>
                              </p>
                            )}
                        </div>
                        <div style={{ width: '20%' }}>
                          <BaseInput2
                            id="cardCvv"
                            name="cardCvv"
                            type="text"
                            placeholder=" cvv *"
                            value={formik2.values.cardCvv}
                            onChange={formik2.handleChange}
                            fullWidth
                          />
                          {formik2.errors.cardCvv && formik2.touched.cardCvv && (
                            <p className="input-error">
                              <small>{formik2.errors.cardCvv}</small>
                            </p>
                          )}
                        </div>
                      </div>

                      <div>
                        <BaseInput2
                          id="firstName"
                          name="firstName"
                          type="text"
                          placeholder=" First Name *"
                          value={formik2.values.firstName}
                          onChange={formik2.handleChange}
                          fullWidth
                        />
                        {formik2.errors.firstName && formik2.touched.firstName && (
                          <p className="input-error">
                            <small>{formik2.errors.firstName}</small>
                          </p>
                        )}
                        <div className="mb-8"></div>
                        <BaseInput2
                          id="middleName"
                          name="middleName"
                          type="text"
                          placeholder=" Middle Name *"
                          value={formik2.values.middleName}
                          onChange={formik2.handleChange}
                          fullWidth
                        />
                        {formik2.errors.middleName &&
                          formik2.touched.middleName && (
                            <p className="input-error">
                              <small>{formik2.errors.middleName}</small>
                            </p>
                          )}
                        <div className="mb-8"></div>
                        <BaseInput2
                          id="lastName"
                          name="lastName"
                          type="text"
                          placeholder="Last Name *"
                          value={formik2.values.lastName}
                          onChange={formik2.handleChange}
                          fullWidth
                        />
                        {formik2.errors.lastName && formik2.touched.lastName && (
                          <p className="input-error">
                            <small>{formik2.errors.lastName}</small>
                          </p>
                        )}
                        <div className="mb-8"></div>
                        <BaseInput2
                          id="cardAmount"
                          name="cardAmount"
                          type="text"
                          placeholder=" Amount *"
                          value={formik2.values.cardAmount}
                          onChange={formik2.handleChange}
                          fullWidth
                        />
                        {formik2.errors.cardAmount &&
                          formik2.touched.cardAmount && (
                            <p className="input-error">
                              <small>{formik2.errors.cardAmount}</small>
                            </p>
                          )}
                      </div>
                      <div className="mb-8"></div>

                      <BaseBtnFilled className={classes.btn} type="submit">
                        Confirm
                      </BaseBtnFilled>
                    </form>
                  </>
                )}
              </Paper>
            </div>
          </RadioGroup>
        </div>
      </DialogContent>
    </Dialog>
  );
};

WalletTopup.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default WalletTopup;
