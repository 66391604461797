import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';

import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import AskSellerDialogTemplate from './AskSellerDialogTemplate';
import { sendChatMsg } from '../../redux/message/messageActions';
import { s_server } from '../../api';

const useStyles = makeStyles((theme) => ({
  btn: {
    padding: '4px',
  },
  btnSection: {
    margin: '0 auto',
    marginBottom: '48px',
    textAlign: 'center',
    width: '60%',
  },
  txtArea: {
    '&input': {
      fontSize: '0.875rem',
    },
  },
  txtAreaSection: {
    width: '80%',
    margin: '0 auto',
    marginBottom: '32px',
  },
}));
const mapState = ({ auth }) => ({
  token: auth.token,
});
const SendMessage = ({ open, handleClose, sellerID, openFeedback }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token } = useSelector(mapState);
  const [message, setMessage] = useState('');
  const [emptyMsgErr, setemptyMsgErr] = useState('');

  const { id } = useParams();

  // const socket = io(`${s_server}`, {
  //   extraHeaders: {
  //     auth_token: token,
  //   },
  // });

  const handleSendMessage = () => {
    if (!message) {
      setemptyMsgErr('Cannot send empty message!');
      return;
    }

    const body = {
      message: message,
      to: sellerID,
      type: 'CHAT',
      productReference: id,
    };

    dispatch(sendChatMsg(body));
    // console.log('MSG', body);
    setMessage('');
    openFeedback();
  };

  return (
    <AskSellerDialogTemplate open={open} handleClose={handleClose}>
      <DialogContentText disableTypography>
        <p className="txt-black txt-center f-14">
          Whether it’s a clarification or any other question, ask!
        </p>
      </DialogContentText>
      <div className={classes.txtAreaSection}>
        <TextField
          classes={{
            root: classes.txtArea,
          }}
          id="message"
          fullWidth
          multiline
          rows={4}
          placeholder="Type your message…"
          variant="outlined"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onFocus={(e) => setemptyMsgErr('')}
        />
        <div>
          <p className="input-error">{emptyMsgErr}</p>
        </div>
      </div>

      <div className={classes.btnSection}>
        <BaseBtnFilled
          fullWidth
          className={classes.btn}
          onClick={handleSendMessage}
        >
          Send Message
        </BaseBtnFilled>
      </div>
    </AskSellerDialogTemplate>
  );
};

SendMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  openFeedback: PropTypes.func.isRequired,
  sellerID: PropTypes.string.isRequired,
};

const SentMessageFeedback = ({ open, handleClose }) => {
  const classes = useStyles();
  return (
    <AskSellerDialogTemplate open={open} handleClose={handleClose}>
      <DialogContentText disableTypography>
        <p className="txt-black txt-center f-14">
          Your message has been sent! <br /> You will be notified once the
          seller responds
        </p>
      </DialogContentText>

      <div className={classes.btnSection}>
        <BaseBtnOutlined
          fullWidth
          className={classes.btn}
          onClick={handleClose}
        >
          Continue Shopping
        </BaseBtnOutlined>
      </div>
    </AskSellerDialogTemplate>
  );
};
SentMessageFeedback.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const AskSellerDialog = ({ open, handleClose, sellerID }) => {
  const [isMessageSent, setisMessageSent] = useState(false);

  const openFeedback = () => {
    // TODO: Itegrate Send message api
    setisMessageSent(true);
  };

  return (
    <>
      {!isMessageSent ? (
        <SendMessage
          open={open}
          handleClose={handleClose}
          openFeedback={openFeedback}
          sellerID={sellerID}
        />
      ) : (
        <SentMessageFeedback open={open} handleClose={handleClose} />
      )}
    </>
  );
};

AskSellerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sellerID: PropTypes.string.isRequired,
};
export default AskSellerDialog;
