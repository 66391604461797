import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { formatPhoneNumber } from '../../_helpers/format-phone';
import BaseInput2 from '../../components/BaseInput2';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import flagIcon from '../../assets/images/flag_icon.jpg';
import PlacesAutocomplete from '../../components/PlacesAutocomplete';
import { addDeliveryAddress } from '../../redux/checkout/checkoutActions';

const validationSchema = yup.object({
  fname: yup.string().required('Please fill out this field'),
  lname: yup.string().required('Please fill out this field'),
  phoneNumber: yup.string().required('Please fill out this field'),
  secondaryPhoneNumber: yup.string(),
  buildingName: yup.string().required('Please fill out this field'),
  additionalInfo: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '24px',
    borderColor: '#601DFF',
    borderWidth: '0.5px',
  },
  label: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  gridLayout: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gridRowGap: '8px',
    gridColumnGap: '8px',
  },
  btn: {
    width: '150px',
    padding: '4px 16px',
    fontWeight: 700,
  },
  actions: {
    justifyContent: 'space-between',
    padding: '24px',
  },
  flagIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  font1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '0.75rem',
  },
  iconBtn: {
    padding: '8px',
  },
  flexSpace: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const mapState = ({ checkout }) => ({
  deliveryId: checkout.deliveryId,
  loading: checkout.deliveryIdLoading,
  err: checkout.deliveryIdErr,
});

const DeliveryAddressForm = ({ closeDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { deliveryId, loading, err } = useSelector(mapState);

  const [locationErr, setlocationErr] = useState(null);
  const [location, setlocation] = useState(null);

  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [phone, setphone] = useState('');
  const [selectedLocation, setselectedLocation] = useState('');
  const [other, setother] = useState('');
  const [building, setbuilding] = useState('');
  const [otherPhone, setotherPhone] = useState('');

  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      phoneNumber: '',
      buildingName: '',
      additionalInfo: '',
      secondaryPhoneNumber: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!location) {
        setlocationErr('Please fill out this field');
        return;
      }
      if (location) {
        setlocationErr('');
      }

      localStorage.setItem("first_name",values.fname)
      localStorage.setItem("last_name",values.fname)
      localStorage.setItem("phone",values.phoneNumber)
      setfirstName(values.fname);
      setlastName(values.lname);
      setphone(values.phoneNumber);
      setselectedLocation(location.description);
      setother(values.additionalInfo);
      setbuilding(values.buildingName);
      setotherPhone(values.secondaryPhoneNumber);

      const deliveryAddress = {
        firstName: values.fname,
        lastName: values.lname,
        phoneNumber: formatPhoneNumber('254', values.phoneNumber),
        otherNumber: values.secondaryPhoneNumber
          ? formatPhoneNumber('254', values.secondaryPhoneNumber)
          : '',
        placeId: location.place_id,
        buildingName: values.buildingName,
        additionalInfo: values.additionalInfo,
      };
      console.log(deliveryAddress);
      dispatch(addDeliveryAddress(deliveryAddress));
      closeDialog();

      formik.resetForm();
      setlocation('');
    },
  });

  const [addSecondPhoneNo, setAddSecondPhoneNo] = useState(false);
  const handleAddSecondPhoneNo = (event) => {
    setAddSecondPhoneNo(true);
  };
  return (
    <Paper className={classes.paper} variant="outlined">
      <div className="mb-8">
        <h3 className="f-16" style={{ color: '#491DFF' }}>
          Add New Address
        </h3>
      </div>
      <Divider />
      <div className="mt-16"></div>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <BaseInput2
              id="fname"
              name="fname"
              type="text"
              placeholder="First Name *"
              value={formik.values.fname}
              onChange={formik.handleChange}
              fullWidth
            />

            {formik.errors.fname && formik.touched.fname && (
              <p className="input-error">
                <small>{formik.errors.fname}</small>
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <BaseInput2
              id="lname"
              name="lname"
              type="text"
              placeholder="Last Name *"
              value={formik.values.lname}
              onChange={formik.handleChange}
              fullWidth
            />
            {formik.errors.lname && formik.touched.lname && (
              <p className="input-error">
                <small>{formik.errors.lname}</small>
              </p>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            // style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <BaseInput2
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              placeholder="Phone Number *"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              startAdornment={
                <InputAdornment position="start">
                  {' '}
                  <Avatar
                    alt="flag of Kenya"
                    src={flagIcon}
                    className={classes.flagIcon}
                  />
                </InputAdornment>
              }
              fullWidth
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber && (
              <p className="input-error">
                <small>{formik.errors.phoneNumber}</small>
              </p>
            )}
            {/* </div> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {addSecondPhoneNo ? (
              <>
                <BaseInput2
                  id="secondaryPhoneNumber"
                  name="secondaryPhoneNumber"
                  type="text"
                  placeholder="Second Phone Number *"
                  value={formik.values.secondaryPhoneNumber}
                  onChange={formik.handleChange}
                  fullWidth
                />
                {formik.errors.phoneNumber &&
                  formik.touched.secondaryPhoneNumber && (
                    <p className="input-error">
                      <small>{formik.errors.secondaryPhoneNumber}</small>
                    </p>
                  )}
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div>
                  <IconButton
                    classes={{ root: classes.iconBtn }}
                    size="small"
                    onClick={handleAddSecondPhoneNo}
                  >
                    <AddCircleOutlineIcon fontSize="small" />
                  </IconButton>
                </div>
                <div style={{ padding: '8px' }}>
                  <Typography variant="body2" className={classes.font1}>
                    Add Second Phone Number
                  </Typography>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <PlacesAutocomplete value={location} setValue={setlocation} />
            <p className="f-14">
              <small>* Start typing to select your location</small>
            </p>
            {locationErr && (
              <p className="input-error">
                <small>{locationErr}</small>
              </p>
            )}
          </Grid>

          <Grid item xs={12}>
            <BaseInput2
              id="buildingName"
              name="buildingName"
              type="text"
              placeholder="Building *"
              value={formik.values.buildingName}
              onChange={formik.handleChange}
              fullWidth
            />
            {formik.errors.buildingName && formik.touched.buildingName && (
              <p className="input-error">
                <small>{formik.errors.buildingName}</small>
              </p>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <BaseInput2
              id="additionalInfo"
              name="additionalInfo"
              type="text"
              placeholder="Add More Details"
              value={formik.values.additionalInfo}
              onChange={formik.handleChange}
              fullWidth
            />
            {formik.errors.additionalInfo && formik.touched.additionalInfo && (
              <p className="input-error">
                <small>{formik.errors.additionalInfo}</small>
              </p>
            )}
          </Grid>
          <Grid item xs={12}>
            <div>
              <BaseBtnFilled
                type="submit"
                className={classes.btn}
                disabled={loading}
              >
                Save Address
              </BaseBtnFilled>
            </div>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

DeliveryAddressForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};
export default DeliveryAddressForm;
