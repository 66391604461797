import React from 'react';
import PropTypes from 'prop-types';

import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import MerchantAvatar from '../Shops/MerchantAvatar';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  spacing: {
    marginRight: '8px',
  },
  badge: {
    backgroundColor: '#35c43a',
  },
  chip: {
    fontSize: '0.75rem',
    backgroundColor: '#d1eaff',
  },
}));

const NotificationSnippet = ({
  senderImg,
  senderName,
  handleClick,
  sentTime,
  isRead,
  status,
}) => {
  const classes = useStyles();

  return (
    <div className="d-flex d-flex-align-center" onClick={handleClick}>
      {!isRead && (
        <Box mr={1}>
          <Badge variant="dot" classes={{ dot: classes.badge }}></Badge>
        </Box>
      )}

      <Box mr={1}>
        <MerchantAvatar avatarStyles={classes.avatar} image={senderImg} />
      </Box>

      <div style={{ width: 'calc(100% - 64px)' }}>
        <p className="txt-black txt-bold ">{senderName}</p>

        <p className=" f-14">{sentTime}</p>
        <Box>
          <Chip
            // size="small"
            label={`ORDER ${status}`}
            className={classes.chip}
          />
        </Box>
      </div>
    </div>
  );
};

NotificationSnippet.propTypes = {
  senderImg: PropTypes.string.isRequired,
  senderName: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  sentTime: PropTypes.string.isRequired,
  isRead: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};
export default NotificationSnippet;
