import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';

import { FiPackage } from 'react-icons/fi';
import BaseBtnFilled from '../../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../../components/BaseBtnOutlined';

const MerchantNotifyShipping = ({ buyerName, timestamp }) => {
  {
    /* Head to your selected drop-off agent store to initiate the delivery process. Download and print the Shipping Label and attach it to the package. Kindly note that this order should be delivered within 2 days and this affects your ratings. Order Number: #L08534847 Drop-off Agent Store: Westlands LMO1 Zenith Store, Woodvale Grove +254 712 345 678 Shipping Timeframe: 2 Days */
  }
  const sampleNot = {
    buyer: 'peekaboo_77',
    amt: '1,500',
    shipping: '2 Days',
    time: '9.08 AM',
    order: '#L08534847',
    storeName: 'Store Name',
    contact: 'store contact',
    location: 'store location',
  };
  return (
    <Box display="flex">
      <Avatar style={{ background: '#45BC56', marginRight: '8px' }}>
        <FiPackage style={{ color: '#ffffff' }} />
      </Avatar>
      <div className="notification-box">
        <p className="txt-time f-10">{timestamp}</p>
        <p className="txt-bold txt-blackTrue">Your item has been shipped</p>
        <p className="txt-gray5 f-14">
          Hold on tight! Your item is on the way to its new home! We are sure
          that <span className="txt-bold">{buyerName}</span> is eagerly awaiting
          its arrival.
        </p>
        {/* <Box mt={2}>
          <BaseBtnFilled fullWidth>Track Order</BaseBtnFilled>
        </Box> */}
      </div>
    </Box>
  );
};

MerchantNotifyShipping.propTypes = {
  buyerName: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default MerchantNotifyShipping;
