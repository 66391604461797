import React from 'react';
import PropTypes from 'prop-types';

import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  closeButton: {
    padding: '4px',
  },
});

const DialogHeading = ({ title, subtitle, onClose }) => {
  const classes = useStyles();
  return (
    <Box p={1}>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <h2 className="txt-violet f-24">{title}</h2>
        <p className="txt-black f-14">{subtitle}</p>
      </Box>
    </Box>
  );
};

DialogHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
export default DialogHeading;
