import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import logo from '../../assets/images/logo-holder.png';
import bg from '../../assets/images/clothe_rack_2.jpg';

const useStyles = makeStyles((theme) => ({
  bgBar: {
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.08)',
  },
  bgContent: {
    position: 'relative',
    top: '48px',
  },
}));
const BetaLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <section>
      <AppBar className={classes.bgBar} position="fixed">
        <Toolbar>
          <Link to="/">
            <img src={logo} alt="Lukhu app logo" className="app-logo" />
          </Link>
        </Toolbar>
      </AppBar>
      <main>
        <div className="bg-beta">
          <div className="beta-content">{children}</div>
        </div>
      </main>
    </section>
  );
};

BetaLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BetaLayout;
