import axios from 'axios';

import {
  FETCH_MAIN_CATEGORIES_LIST_START,
  FETCH_MAIN_CATEGORIES_LIST_SUCCESS,
  FETCH_MAIN_CATEGORIES_LIST_FAIL,
  FETCH_SEARCH_RESULTS_START,
  FETCH_SEARCH_RESULTS_SUCCESS,
  FETCH_SEARCH_RESULTS_FAIL,
  FETCH_FITLER_RESULTS_START,
  FETCH_FITLER_RESULTS_FAIL,
  FETCH_FITLER_RESULTS_SUCCESS,
  FETCH_CATEGORY_PRODUCTS_RESULTS_START,
  FETCH_CATEGORY_PRODUCTS_RESULTS_SUCCESS,
  FETCH_CATEGORY_PRODUCTS_RESULTS_FAIL,
  FETCH_ALL_CATEGORIES_FAIL,
  FETCH_ALL_CATEGORIES_START,
  FETCH_ALL_CATEGORIES_SUCCESS,
  FETCH_L2_START,
  FETCH_L2_SUCCESS,
  FETCH_L2_FAIL,
  FETCH_L2_CAT_CHILDREN_START,
  FETCH_L2_CAT_CHILDREN_SUCCESS,
  FETCH_L2_CAT_CHILDREN_FAIL,
  SET_SUBCAT_ID,
  SET_L2_CATEGORIES,
  SET_L3_CATEGORIES,
  FETCH_OVER1K_FAIL,
  FETCH_OVER1K_SUCCESS,
  FETCH_OVER1K_START,
  FETCH_UNDER1K_START,
  FETCH_UNDER1K_SUCCESS,
  FETCH_UNDER1K_FAIL,
  FETCH_SAME_SELLER_FAIL,
  FETCH_SAME_SELLER_START,
  FETCH_SAME_SELLER_SUCCESS,
} from './searchTypes';

import { url } from '../../api';

// Load Menswear, Womenswear, kidswear
export const fetchCategoryProductsResults1 = (categoryString) => {
  return (dispatch) => {
    // const categoryId =
    //   categoryString === 'Menswear'
    //     ? 'Mensware'
    //     : categoryString === 'Womenswear'
    //     ? 'Womensware'
    //     : categoryString == 'Kidswear'
    //     ? 'Kidsware'
    //     : '';

    dispatch({
      type: FETCH_CATEGORY_PRODUCTS_RESULTS_START,
    });

    axios
      .get(`${url}/listing/?page=1&limit=100&category=${categoryString}`)
      .then((response) => {
        const allProducts = response.data.data;

        dispatch({
          type: FETCH_CATEGORY_PRODUCTS_RESULTS_SUCCESS,
          payload: { results: allProducts, count: allProducts.length },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_CATEGORY_PRODUCTS_RESULTS_FAIL,
          payload: error,
        });
      });
  };
};
export const fetchMainCategoriesList = (categoryString) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_MAIN_CATEGORIES_LIST_START,
    });

    const categoryId =
      categoryString === 'Menswear'
        ? 1
        : categoryString === 'Womenswear'
        ? 2
        : categoryString === 'Kidswear'
        ? 3
        : null;

    axios
      .get(`${url}/products/attributes/category?id=0`)
      .then((response) => {
        const categories = response.data.options;
        const level2CategoriesList = categories.filter(
          (cat) => cat.parent_id === categoryId
        );

        dispatch({
          type: FETCH_MAIN_CATEGORIES_LIST_SUCCESS,
          // payload: categories,
          payload: {
            mainCategories: level2CategoriesList,
            allCategories: categories,
            // subCategories: subCategories,
          },
        });
        dispatch({
          type: SET_L2_CATEGORIES,
          payload: level2CategoriesList,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_MAIN_CATEGORIES_LIST_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchLevel2Categories = (categoryString) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_MAIN_CATEGORIES_LIST_START,
    });

    const categoryId =
      categoryString === 'Menswear'
        ? 1
        : categoryString === 'Womenswear'
        ? 2
        : categoryString === 'Kidswear'
        ? 3
        : null;

    axios
      .get(`${url}/products/attributes/category?id=0`)
      .then((response) => {
        const categories = response.data.options;
        const level2CategoriesList = categories.filter(
          (cat) => cat.parent_id === categoryId
        );

        dispatch({
          type: FETCH_MAIN_CATEGORIES_LIST_SUCCESS,
          payload: {
            mainCategories: level2CategoriesList,
            allCategories: categories,
          },
        });
        dispatch({
          type: SET_L2_CATEGORIES,
          payload: level2CategoriesList,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_MAIN_CATEGORIES_LIST_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchAllCategories = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ALL_CATEGORIES_START,
    });
    axios
      .get(`${url}/products/attributes/category?id=0`)
      .then((response) => {
        const categories = response.data.options;
        dispatch({
          type: FETCH_ALL_CATEGORIES_SUCCESS,
          payload: categories,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ALL_CATEGORIES_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchSearchResults = (searchQueryString) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SEARCH_RESULTS_START,
      payload: searchQueryString,
    });
    axios
      .get(`${url}/listing/?name=${searchQueryString}&page=1&limit=100`)
      .then((response) => {
        const results = response.data.data;
        dispatch({
          type: FETCH_SEARCH_RESULTS_SUCCESS,
          payload: {
            results: results,
            count: results.length,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SEARCH_RESULTS_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchFilterResults = (filterQuery) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_FITLER_RESULTS_START,
    });

    axios
      .get(`${url}/listing/?page=1&limit=100&${filterQuery}`)
      .then((res) => {
        const results = res.data.data;
        dispatch({
          type: FETCH_FITLER_RESULTS_SUCCESS,
          payload: {
            results: results,
            count: results.length,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_FITLER_RESULTS_FAIL,
          payload: err,
        });
      });
  };
};
export const fetchLevel2CatgoriesChildren = (id) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_L2_CAT_CHILDREN_START,
    });
    axios
      .get(`${url}/products/attributes/category?id=${id}`)
      .then((response) => {
        const categories = response.data.options;
        dispatch({
          type: FETCH_L2_CAT_CHILDREN_SUCCESS,
          payload: categories,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_L2_CAT_CHILDREN_FAIL,
          payload: error,
        });
      });
  };
};
export const fetchLevel2Search = (filterQuery) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_L2_START,
    });

    axios
      .get(`${url}/listing/?page=1&limit=100&${filterQuery}`)
      .then((res) => {
        const results = res.data.data;
        dispatch({
          type: FETCH_L2_SUCCESS,
          payload: {
            results: results,
            count: results.length,
            // subCatId,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_L2_FAIL,
          payload: err,
        });
      });
  };
};

export const setSubCatId = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_SUBCAT_ID,
      payload: id,
    });
  };
};

export const fetchCategoryProductsResults = (filterQuery) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_CATEGORY_PRODUCTS_RESULTS_START,
    });

    axios
      .get(`${url}/listing/?page=1&limit=100&${filterQuery}`)
      .then((response) => {
        const products = response.data.data;

        dispatch({
          type: FETCH_CATEGORY_PRODUCTS_RESULTS_SUCCESS,
          payload: { results: products, count: products.length },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_CATEGORY_PRODUCTS_RESULTS_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchOver1kResults = (filterQuery) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_OVER1K_START,
    });
    axios
      .get(`${url}/listing/?page=1&price=1000,10000000&limit=100&${filterQuery}`)
      .then((response) => {
        const results = response.data.data;

        dispatch({
          type: FETCH_OVER1K_SUCCESS,
          payload: { results: results, count: results.length },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_OVER1K_FAIL,
          payload: error,
        });
      });
  };
};


export const fetchUnder1kResults = (filterQuery) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_UNDER1K_START,
    });
    axios
      .get(`${url}/listing/?page=1&price=1,1000&limit=100&${filterQuery}`)
      .then((response) => {
        const results = response.data.data;

        dispatch({
          type: FETCH_UNDER1K_SUCCESS,
          payload: { results: results, count: results.length },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_UNDER1K_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchSellerProductsResults = (seller, filterQuery) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SAME_SELLER_START,
    });
    axios
      .get(`${url}/listing/?store=${seller}&page=1&limit=100&${filterQuery}`)
      .then((response) => {
        const results = response.data.data;

        dispatch({
          type: FETCH_SAME_SELLER_SUCCESS,
          payload: { results: results, count: results.length },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SAME_SELLER_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchSearchProductResults = (filterQuery) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SEARCH_RESULTS_START,
      // payload: searchQueryString,
    });
    axios
      .get(`${url}/listing/?page=1&limit=100&${filterQuery}`)
      .then((response) => {
        const results = response.data.data;
        dispatch({
          type: FETCH_SEARCH_RESULTS_SUCCESS,
          payload: {
            results: results,
            count: results.length,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SEARCH_RESULTS_FAIL,
          payload: error,
        });
      });
  };
};
