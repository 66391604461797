import axios from 'axios';
import { url } from '../../api';
import {
  GET_FOLLOWING_START,
  GET_FOLLOWING_SUCCESS,
  GET_FOLLOWING_FAIL,
  FOLLOW_MERCHANT,
  FETCH_USER_LIKED_PRODUCTS_FAIL,
  FETCH_USER_LIKED_PRODUCTS_SUCESS,
  FETCH_USER_LIKED_PRODUCTS_START,
  LIKE_PRODUCT,
} from './socialsTypes';

export const fetchFollowedMerchants = () => {
  return (dispatch, getState) => {
    const userProfile = getState().auth.profile;
    const userId = userProfile.id;
    const authToken = getState().auth.token;

    dispatch({
      type: GET_FOLLOWING_START,
    });
    axios
      .get(
        `${url}/users/${userId}/socials?page=1&pageLimit=100&status=following`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        const results = res.data.data;
        const following = results.following.users; //array

        dispatch({
          type: GET_FOLLOWING_SUCCESS,
          payload: {
            following: following,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_FOLLOWING_FAIL,
          payload: error.response,
        });
      });
  };
};

export const followMerchant = (merchId) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;

    const body = { status: 'follow' };
    axios
      .post(`${url}/users/${merchId}/socials`, body, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        // console.log('I FOLLOWED', res.data);
        dispatch({
          type: FOLLOW_MERCHANT,
        });
        dispatch(fetchFollowedMerchants());
      });
  };
};

export const unfollowMerchant = () => {
  return (dispatch, getState) => {
    const userProfile = getState().auth.profile;
    const userId = userProfile.id;
    const authToken = getState().auth.token;

    const body = { status: 'unfollow' };
    axios
      .post(`${url}/users/${userId}/socials`, body, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        // reload following
        // console.log(res);
      });
  };
};

export const blockMerchant = () => {
  return (dispatch, getState) => {
    const userProfile = getState().auth.profile;
    const userId = userProfile.id;
    const authToken = getState().auth.token;

    const body = { status: 'block' };
    axios
      .post(`${url}/users/${userId}/socials`, body, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        // reload following
        // console.log(res);
      });
  };
};

export const unblockMerchant = () => {
  return (dispatch, getState) => {
    const userProfile = getState().auth.profile;
    const userId = userProfile.id;
    const authToken = getState().auth.token;

    const body = { status: 'unblock' };
    axios
      .post(`${url}/users/${userId}/socials`, body, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        // console.log(res);
      });
  };
};

export const likeProduct = (productId) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;
    const body = {
      type: 'LIKE',
    };

    axios
      .post(`${url}/products/${productId}/analytics`, body, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        dispatch({
          type: LIKE_PRODUCT,
        });
      });
  };
};

export const fetchUserLikedProducts = () => {
  return (dispatch, getState) => {
    const authToken = getState().auth.token;

    dispatch({
      type: FETCH_USER_LIKED_PRODUCTS_START,
    });

    axios
      .get(`${url}/products/analytics/liked?page=1&pageSize=100`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        const results = res.data;
        const likedProducts = results.products; //array
        dispatch({
          type: FETCH_USER_LIKED_PRODUCTS_SUCESS,
          payload: {
            products: likedProducts,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_USER_LIKED_PRODUCTS_FAIL,
          payload: error.response,
        });
      });
  };
};
