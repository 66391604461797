
import ReactPixel from 'react-facebook-pixel';

const initFB = () =>{
    const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
    };
    ReactPixel.init('402159492056859', advancedMatching, options);

}

export const trackPageViewPixel = () =>{
    initFB();
    ReactPixel.pageView()
}


export const addToCartPixel = () =>{
    initFB();
    ReactPixel.track('track', 'AddToCart');
}

export const completeRegistrationPixel = () =>{
    initFB();
    ReactPixel.track('track', 'CompleteRegistration');
  
}


export const customizerProductPixel =  () =>{
    initFB();
    ReactPixel.track('track', 'CustomizeProduct');

}

export const initiateCheckoutPixel = () =>{
    initFB();
    ReactPixel.track('track', 'InitiateCheckout');

}

export const searchPixel = (key_name) =>{
    initFB();
    ReactPixel.track('track', `Search ${key_name}`);

}


export const viewContent  = (content_name) =>{

    initFB();
    ReactPixel.track('track', `PageInteraction ${content_name}`);


}

export const LoginInitiatedPixel = () =>{
    initFB();
    ReactPixel.track('track', `LoginInitiated`);

}

export const LoginSuccessPixel = () =>{
    initFB();
    ReactPixel.track('track', `LoginSuccess`);

}

export const LoginFailurePixel = () =>{
    initFB();
    ReactPixel.track('track', `LoginSuccess`);

}



export const SignUpInitiatedPixel = () =>{
    initFB();
    ReactPixel.track('track', `SignUpInitiated`);

}

export const SignUpSuccessPixel = () =>{
    initFB();
    ReactPixel.track('track', `SignUpSuccess`);

}

export const SignUpFailurePixel = () =>{
    initFB();
    ReactPixel.track('track', `SignUpSuccess`);

}


export const removeFromCardPixel = () =>{
    initFB();
    ReactPixel.track('track', `RemoveFromCart`);

}

export const addToCartFailPixel = () =>{
    initFB();
    ReactPixel.track('track', `AddToCartFail`);

}
