import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import ArrowForward from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';

import MerchantAvatar from '../Shops/MerchantAvatar';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import { followMerchant } from '../../redux/socials/socialsActions';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    // margin: '16px 0',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  fontStyle1: {
    fontFamily: ['Lato', 'sans-serif'].join(', '),
    fontWeight: 700,
    fontSize: '1.125rem',
    color: '#111',
  },
  fontStyle2: {
    fontFamily: ['Lato', 'sans-serif'].join(', '),
    color: '#707070',
    fontWeight: 400,
    fontSize: '1rem',
  },
  fontStyle3: {
    fontFamily: ['Lato', 'sans-serif'].join(', '),
    color: '#707070',
    fontWeight: 400,
    fontSize: '0.875rem',
  },
  btn: {
    width: '104px',
    padding: '2px',
  },
  section1: {
    display: 'flex',
    // width: '40%',
  },

  section2: {
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-end',
      marginTop: '8px',
    },
  },

  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const AboutSeller = ({
  storeId,
  storeLocation,
  storeName,
  storeAvatar,
  storeReviews,
  action,
  actionText,
  actionIcon,
  followingInfo,
  storeRating,
  handleFollow,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isFollowClicked, setIsFollowClicked] = useState(false);
  const viewSeller = (id) => {
    history.push(`/shop/${id}`);
  };

  const handleFollowMerchant = (id) => {
    dispatch(followMerchant(id));
    setIsFollowClicked(true);
  };

  return (
    <Box p={1} style={{ background: '#F5F6F7' }}>
      <div className="d-flex d-flex-align-center d-flex-space">
        <h4 className="f-14 txt-violet">ABOUT THE SELLER</h4>
        <IconButton onClick={() => viewSeller(storeName)}>
          <ArrowForward
            style={{ fontSize: '20px', color: '#4a4062', fontWeight: 'bold' }}
          />
        </IconButton>
      </div>

      <div className={classes.content}>
        <div>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <MerchantAvatar
                image={storeAvatar}
                avatarStyles={classes.avatar}
              />
            </ListItemAvatar>
            <ListItemText
              classes={{
                primary: classes.fontStyle1,
              }}
              primary={storeName}
              secondary={
                <>
                  <Typography variant="body2" className={classes.fontStyle2}>
                    {storeLocation}
                  </Typography>

                  <Box display="flex" alignItems="center" mt={1}>
                    <Rating name="read-only" value={storeRating} readOnly />
                    <div style={{ marginRight: '8px' }} />
                    <Typography variant="body2" className={classes.fontStyle3}>
                      {storeReviews} Reviews
                    </Typography>
                  </Box>
                </>
              }
            />
          </ListItem>
        </div>

        <div className={classes.section2}>
          {!isFollowClicked && (
            <BaseBtnOutlined
              startIcon={<AddIcon fontSize="small" />}
              className={classes.btn}
              onClick={() => handleFollowMerchant(storeId)}
            >
              follow
            </BaseBtnOutlined>
          )}
        </div>
      </div>
    </Box>
  );
};

AboutSeller.propTypes = {
  storeId: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  storeLocation: PropTypes.string.isRequired,
  storeReviews: PropTypes.number.isRequired,
  actionText: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  actionIcon: PropTypes.element,
  followingInfo: PropTypes.array.isRequired,
  storeRating: PropTypes.any,
  handleFollow: PropTypes.func.isRequired,
  storeAvatar: PropTypes.string.isRequired,
};
export default AboutSeller;
