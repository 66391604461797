import {
  FETCH_SINGLE_PRODUCT_START,
  FETCH_SINGLE_PRODUCT_SUCCESS,
  FETCH_SINGLE_PRODUCT_FAIL,
  FETCH_SELLER_PRODUCTS_FAIL,
  FETCH_SELLER_PRODUCTS_SUCCESS,
  FETCH_SELLER_PRODUCTS_START,
  FETCH_SIMILAR_PRODUCTS_START,
  FETCH_SIMILAR_PRODUCTS_SUCCESS,
  FETCH_SIMILAR_PRODUCTS_FAIL,
  CHANGE_DISPLAY_IMAGE,
  FECTH_PRODUCT_STORE_DETAILS_START,
  FECTH_PRODUCT_STORE_DETAILS_SUCCESS,
  FECTH_PRODUCT_STORE_DETAILS_FAIL,
  FETCH_PRODUCT_CONDITIONS_SUCCESS,
  FETCH_PRODUCT_CONDITIONS_FAIL,
  FETCH_PRODUCT_DELIVERY_OPTIONS_FAIL,
  FETCH_PRODUCT_DELIVERY_OPTIONS_SUCCESS,
  FETCH_PRODUCT_COLORS_SUCCESS,
  FETCH_PRODUCT_COLORS_FAIL,
} from './productsTypes';

const initialState = {
  loading: false,
  products: null,
  productDetails: null,
  fetchError: null,
  similarProducts: null,
  sellerProducts: null,
  productStoreId: null,
  productCategory: null,
  displayImage: null,
  productStoreDetails: null,
  productConditions: null,
  productConditionsFetchError: null,
  productDeliveryOptions: null,
  productDeliveryOptionsError: null,
  productColors: null,
  productColorsError: null,
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SINGLE_PRODUCT_START: {
      return {
        loading: true,
      };
    }
    case FETCH_SINGLE_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        productDetails: action.payload.details,
        productCategory: action.payload.category,
        productStoreId: action.payload.storeId,
        displayImage: action.payload.displayImage,
      };
    }
    case FETCH_SINGLE_PRODUCT_FAIL: {
      return {
        ...state,
        loading: false,
        fetchError: action.payload,
      };
    }
    case FETCH_SELLER_PRODUCTS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_SELLER_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        sellerProducts: action.payload,
      };
    }
    case FETCH_SELLER_PRODUCTS_FAIL: {
      return {
        ...state,
        loading: false,
        fetchError: action.payload,
      };
    }
    case FETCH_SIMILAR_PRODUCTS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_SIMILAR_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        similarProducts: action.payload,
      };
    }
    case FETCH_SIMILAR_PRODUCTS_FAIL: {
      return {
        ...state,
        loading: false,
        fetchError: action.payload,
      };
    }
    case CHANGE_DISPLAY_IMAGE: {
      return {
        ...state,
        displayImage: action.payload,
      };
    }
    case FECTH_PRODUCT_STORE_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FECTH_PRODUCT_STORE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        productStoreDetails: action.payload,
      };
    }
    case FECTH_PRODUCT_STORE_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        fetchError: action.payload,
      };
    }
    case FETCH_PRODUCT_CONDITIONS_SUCCESS: {
      return {
        ...state,
        productConditions: action.payload,
      };
    }
    case FETCH_PRODUCT_CONDITIONS_FAIL: {
      return {
        ...state,
        productConditionsFetchError: action.payload,
      };
    }
    case FETCH_PRODUCT_DELIVERY_OPTIONS_SUCCESS: {
      return {
        ...state,
        productDeliveryOptions: action.payload,
      };
    }
    case FETCH_PRODUCT_DELIVERY_OPTIONS_FAIL: {
      return {
        ...state,
        productDeliveryOptionsError: action.payload,
      };
    }
    case FETCH_PRODUCT_COLORS_SUCCESS: {
      return {
        ...state,
        productColors: action.payload,
      };
    }
    case FETCH_PRODUCT_COLORS_FAIL: {
      return {
        ...state,
        productColorsError: action.payload,
      };
    }

    default:
      return state;
  }
};

export default productsReducer;
