export const FETCH_ALL_CATEGORIES_START = 'FETCH_ALL_CATEGORIES_START';
export const FETCH_ALL_CATEGORIES_SUCCESS = 'FETCH_ALL_CATEGORIES_SUCCESS';
export const FETCH_ALL_CATEGORIES_FAIL = 'FETCH_ALL_CATEGORIES_FAIL';

export const FETCH_MAIN_CATEGORIES_LIST_START =
  'FETCH_MAIN_CATEGORIES_LIST_START';
export const FETCH_MAIN_CATEGORIES_LIST_SUCCESS =
  'FETCH_MAIN_CATEGORIES_LIST_SUCCESS';
export const FETCH_MAIN_CATEGORIES_LIST_FAIL =
  'FETCH_MAIN_CATEGORIES_LIST_FAIL';

export const FETCH_SUB_CATEGORIES_LIST_START =
  'FETCH_SUB_CATEGORIES_LIST_START';
export const FETCH_SUB_CATEGORIES_LIST_SUCCESS =
  'FETCH_SUB_CATEGORIES_LIST_SUCCESS';
export const FETCH_SUB_CATEGORIES_LIST_FAIL = 'FETCH_SUB_CATEGORIES_LIST_FAIL';

export const FETCH_FITLER_RESULTS_START = 'FETCH_FITLER_RESULTS_START';
export const FETCH_FITLER_RESULTS_SUCCESS = 'FETCH_FITLER_RESULTS_SUCCESS';
export const FETCH_FITLER_RESULTS_FAIL = 'FETCH_FITLER_RESULTS_FAIL';

export const FETCH_SEARCH_RESULTS_START = 'FETCH_SEARCH_RESULTS_START';
export const FETCH_SEARCH_RESULTS_SUCCESS = 'FETCH_SEARCH_RESULTS_SUCCESS';
export const FETCH_SEARCH_RESULTS_FAIL = 'FETCH_SEARCH_RESULTS_FAIL';

export const FETCH_CATEGORY_PRODUCTS_RESULTS_START =
  'FETCH_CATEGORY_PRODUCTS_RESULTS_START';

export const FETCH_CATEGORY_PRODUCTS_RESULTS_SUCCESS =
  'FETCH_CATEGORY_PRODUCTS_RESULTS_SUCCESS';

export const FETCH_CATEGORY_PRODUCTS_RESULTS_FAIL =
  'FETCH_CATEGORY_PRODUCTS_RESULTS_FAIL';

export const FETCH_L2_START = 'FETCH_L2_START';
export const FETCH_L2_SUCCESS = 'FETCH_L2_SUCCESS';
export const FETCH_L2_FAIL = 'FETCH_L2_FAIL';
export const FETCH_L2_CAT_CHILDREN_START = 'FETCH_L2_CAT_CHILDREN_START';
export const FETCH_L2_CAT_CHILDREN_SUCCESS = 'FETCH_L2_CAT_CHILDREN_SUCCESS';
export const FETCH_L2_CAT_CHILDREN_FAIL = 'FETCH_L2_CAT_CHILDREN_FAIL';

export const SET_SUBCAT_ID = 'SET_SUBCAT_ID';

/**
 * Level 1: Womenwear, Menswear, Kidswear
 * Level 2: Tops, Bottoms, etc
 * Level 3: Blouses,Leggings etc
 */
export const SET_L2_CATEGORIES = 'SET_L2_CATEGORIES';
export const SET_L3_CATEGORIES = 'SET_L3_CATEGORIES';

export const FETCH_UNDER1K_START = 'FETCH_UNDER1K_START ';
export const FETCH_UNDER1K_SUCCESS = 'FETCH_UNDER1K_SUCCESS';
export const FETCH_UNDER1K_FAIL = 'FETCH_UNDER1K_FAIL';

export const FETCH_OVER1K_START = 'FETCH_OVER1K_START ';
export const FETCH_OVER1K_SUCCESS = 'FETCH_OVER1K_SUCCESS';
export const FETCH_OVER1K_FAIL = 'FETCH_OVER1K_FAIL';


export const FETCH_SAME_SELLER_START = 'FETCH_SAME_SELLER_START';
export const FETCH_SAME_SELLER_SUCCESS = 'FETCH_SAME_SELLER_SUCCESS';
export const FETCH_SAME_SELLER_FAIL = 'FETCH_SAME_SELLER_FAIL';
