/*
  This component displays the three main categories on the homepage:
  Mensware, womenswear and kidswear.
 */

import { Link, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import BaseBtn from '../../components/BaseBtn';
import '../../assets/styles/Home.css';

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: '#FFFFFF',
    width: '280px',
    color: '#481CFB',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  btnText: {
    color: '#481CFB',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  container: {
    padding: '0 56px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
}));

const womensWear = { title: 'womenswear', link: '/shop-categories/Womenswear' };
const mensWear = { title: 'menswear', link: '/shop-categories/Menswear' };
const kidsWear = { title: 'kidswear', link: '/shop-categories/Kidswear' };

const sectionTitle = 'SHOP CATEGORIES';

const ShopCategoriesSection = () => {
  const classes = useStyles();

  return (
    <section className="bg-white pb-16 pt-24 mb-24">
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <header className="mb-24">
              <h1 className="section-title f-18 txt-uppercase">
                {sectionTitle}
              </h1>
            </header>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="bg-women">
              <div className="link-cat">
                <BaseBtn
                  className={classes.btn}
                  component={Link}
                  to={womensWear.link}
                >
                  <span className={classes.btnText}>{womensWear.title}</span>
                </BaseBtn>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="bg-men">
              <div className="link-cat">
                <BaseBtn
                  className={classes.btn}
                  component={Link}
                  to={mensWear.link}
                >
                  <span className={classes.btnText}>{mensWear.title}</span>
                </BaseBtn>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="bg-kids">
              <div className="link-cat">
                <BaseBtn
                  className={classes.btn}
                  component={Link}
                  to={kidsWear.link}
                >
                  <span className={classes.btnText}>{kidsWear.title}</span>
                </BaseBtn>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default ShopCategoriesSection;
