/* eslint-disable react/prop-types */
import AppHeader from '../../parts/Shared/AppHeader';
import Footer from '../../parts/Shared/Footer';
import StickyBanner from '../StickyBanner';

const StoreActionsLayout = ({ children }) => {
  return (
    <>
      <AppHeader />
      <main>
        <div className="bg-white mt-4">{children}</div>
      </main>
     
      <Footer />
      <StickyBanner />

    </>
  );
};

export default StoreActionsLayout;
