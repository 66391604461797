import * as yup from 'yup';

export const cardValidationSchema = yup.object({
  cardNumber: yup
    .number()
    .required('Please fill out this field')
    .typeError('Please enter a digit')
    // .matches(/^((4)|(5))[0-9]+$/, 'Invalid card format!')
    .min(13, 'Invalid card number!')
    .max(16, 'Invalid card number!'),
  cardCvv: yup
    .string()
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .required('Please fill out this field'),
  cardExpiry: yup.string().required('Please fill out this field'),
  firstName: yup.string().required('Please fill out this field'),
  lastName: yup.string().required('Please fill out this field'),
  middleName: yup.string().required('Please fill out this field'),
});

export const validatePhone = yup
  .string()
  .matches(/^((01)|(07))[0-9]+$/, 'Invalid format! Please try : 07XX...')
  .required('Please fill out this field')
  .min(10, 'Please enter a valid phone number')
  .max(10, 'Please enter a valid phone number');

export const validateCardExpiry = yup
  .string()
  .typeError('Not a valid expiration date. Example: MM/YY')
  .max(5, 'Not a valid expiration date. Example: MM/YY')
  .matches(
    /([0-9]{2})\/([0-9]{2})/,
    'Not a valid expiration date. Example: MM/YY'
  )
  .required('Expiration date is required')

  .test('test-credit-card-expiration-date', 'Expired!', (expirationDate) => {
    if (!expirationDate) {
      return false;
    }
    const today = new Date();
    const monthToday = today.getMonth() + 1;
    const yearToday = today.getFullYear().toString().substr(-2);

    const [expMonth, expYear] = expirationDate.split('/');

    if (Number(expYear) < Number(yearToday)) {
      return false;
    } else if (
      Number(expMonth) < monthToday &&
      Number(expYear) <= Number(yearToday)
    ) {
      return false;
    }

    return true;
  })
  .test(
    'test-credit-card-expiration-date',
    'Invalid Expiration Month',
    (expirationDate) => {
      if (!expirationDate) {
        return false;
      }
      const today = new Date().getFullYear().toString().substr(-2);

      const [expMonth] = expirationDate.split('/');

      if (Number(expMonth) > 12) {
        return false;
      }

      return true;
    }
  );
