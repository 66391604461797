import React ,{useState,useEffect}from 'react'
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ImageV  from '../assets/images/bg-main2.jpg';
import  {ExpandMore}  from '@material-ui/icons';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    ctn: {
      paddingTop: '32px',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    section1: {
      padding: '16px 0 0 32px',
    },
    section2: {
      padding: '24px 8px',
    },
    pageTitle: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: '1.5rem',
      textTransform: 'capitalize',
      fontWeight: 700,
    },
  
    stepContent: {
      borderLeft: '2px solid #E8E8E8',
      marginTop: 0,
      paddingRight: 0,
    },
  
    iconSpace: {
      marginRight: '4px',
    },
    stepComplete: {
      background: '#4A4062',
      color: '#FFFFFF',
      padding: '16px ',
      borderRadius: '4px',
    },
    stepIncomplete: {
      background: '#EBEBEB',
      color: '#525252',
      padding: '16px ',
      borderRadius: '4px',
    },
    customStepIcon: {
      color: '#E8E8E8',
      '&.MuiStepIcon-active': {
        color: '#3EA636',
      },
      '&.MuiStepIcon-completed': {
        color: '#3EA636',
      },
    },
    stepperRoot: {
      paddingTop: 0,
    },
    stepLabel: {
      // color: '#525252',
      background: '#F8F8F8',
      color: '#707071',
      padding: '16px ',
      borderRadius: '4px',
      '&.MuiStepLabel-active': {
        color: '#525252',
        background: '#EBEBEB',
      },
      '&.MuiStepLabel-completed': {
        background: '#4A4062',
        color: '#FFFFFF',
      },
    },
    contentCollapse: {
      background: '#FAF8FF',
      padding: '16px',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    // temp
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  
    mobileRoot:{
      width:"100%",
      height:"55px",
      background:"#D81E5B",
      display:"flex",
      alignItems:"center",
      justifyContent:"space-between",
      borderRadius:"0px"
    },
    mobileBox1:{
      display:"flex",
      alignItems:"center",
      justifyContent:"space-between",
      fontSize:"16px",
      width:"60%"
    },
    mobileBox2:{
      width:"40%"
    },
    cancelIcon:{
      color:"#fff",
      marginLeft:"10px"
    },
    bagText:{
      color:"#fff",
      font: "normal normal bold 16px/19px Montserrat",
      padding:"15px"
  
    },
    displayF:{
      display:"flex",
      alignItems:"center",
      justifyContent:"space-between",
     
      
      
    },
    displayF1:{
      display:"flex",
      alignItems:"right",
  
        
    },
    displayF12:{
      display:"flex",
      alignItems:"center",
      justifyContent:"space-between",
      marginTop:"10px",
      marginBottom:"15px"
    },
    tterte:{
      fontSize:"13px",
      color:"#111111",
      marginLeft:"10px",
      font:"Lato",
      padding:"15px"
  
    },
    ttrw:{
      color:"#491DFF",
      fontSize:"12px",
      marginRight:"10px",
      padding:"15px"
    },
    yyy:{
      width:"40px",
      height:"40px",
      borderRadius:"100%",
      marginLeft:"10px",
      marginTop:"10px",
      marginBottom:"10px"
    },
    rrwww:{
      marginLeft:"10px",
      color:"#4A4062",
      fontSize:"14px",
      padding:"15px",
      textAlign:"center"
    },
    rrwww1:{
      color:"4A4062",
      marginTop:"10px"
      
    },
    rqqt:{
      width:"150px",
      height:"150px",
    
    },
    v1:{
      fontSize:"12px",
      color:"#111111",
      font:"normal normal bold 9px/19px Lato",
      marginLeft:"13px",
      
    },
  
    v3:{
      fontSize:"12px",
      color:"#111111",
      font:"normal normal bold 9px/19px Lato",
      marginLeft:"13px",
      
    },
  
    v4:{
      fontSize:"12px",
      color:"#111111",
      font:"normal normal bold 9px/19px Lato",
      marginRight:"13px",
      
    },
    b1:{
      width:"150px"
    },
    v2:{
      fontSize:"12px",
      color:"#707070",
      marginRight:"10px",
      font:"normal normal normal 9px/19px Lato",
     
    },
    divHl:{
      width:"100%",
      height:"2px",
      marginTop:"10px",
      background:"#F5F5F5",
      marginLeft:"5px",
      marginRight:"5px"
    },
    btnCheckout:{
      width:"90%",
      padding:"15px",
      color:"#fff",
      background:" #4900FF",
      marginTop:"15px",
      marginLeft:"15px",
      marginRight:"15px",
      marginBottom:"18px",
      textTransform:"none",
      font:"bold normal normal 19px Lato",
    },
    ppclas:{
      width:"150px",
      margin:"10px",
      borderRadius:"5px"
    },
    mainj:{
        marginTop:"15px"
    },
    flexDivL:{
      display:"flex",
      width:"100%",
      overflow:"scroll",
      flexWrap:"wrap"
    }
  }));

  

function CartItems({cart_total,items}) {
  const [first, setfirst] = useState([])
  const history =  useHistory();
    const classes = useStyles();

    const handlesendToOtherPage = () =>{
      history.push("/delveryAndPaymentPageMobile");
    }


    useEffect(() => {

      const list = []
      
      items.map((u)=>{
        list.push(
          <Paper className={classes.ppclas}>
        <Box className={classes.b1}>
        <img className={classes.rqqt} src={u.image}></img>
        <Box className={classes.displayF12}>
        <Typography  className={classes.v1} variant='h6'>KSH {u.price}</Typography>
        <Typography className={classes.v2} variant='h6'>Size : {u.size}</Typography>

        </Box>
        </Box>
      </Paper>
        )

      })

      setfirst(list)
      
    }, [])


    console.log("listtsts", first)
    
  return (
    <Paper className={classes.mainj}>

  
        {/* one */}
        <Paper className={classes.displayF}>
            <Box className={classes.displayF}>
              <img className={classes.yyy} src={ImageV} ></img>
              <Typography className={classes.rrwww} variant='h6'>jersy254</Typography>
 
            </Box>

            <Box className={classes.displayF1}>
            <Typography className={classes.rrwww} variant='h6'>More Info <ExpandMore className={classes.rrwww1}></ExpandMore></Typography>
          
 
            </Box>
          </Paper>

          <div className={classes.flexDivL}>

            {first}
           

     


          {/* two */}

          
      </div>
         

          <div className={classes.divHl}></div>

          <Box className={classes.displayF12}>
            <Typography  className={classes.v3} variant='h6'>{items.length} items</Typography>
            <Typography className={classes.v4} variant='h6'><bold>Ksh {cart_total}</bold></Typography>

            </Box>

            <div className={classes.divHl}></div>
            <Button onClick={handlesendToOtherPage} className={classes.btnCheckout} variant='contained'>Checkout</Button>

           
        </Paper>
  )
}

export default CartItems