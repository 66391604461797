import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  font1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  font2: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    color: '#707070',
  },
  font3: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontWeight: 700,
    fontSize: '1rem',
  },
  bg: {
    backgroundColor: '#F8F8F8',
  },
}));

const SelectedPickupStore = ({
  deliveryOption,
  storeName,
  phoneNumber,
  location,
  deliveryCost,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Box width="50%">
        <p className="txt-mont txt-bold f-14 mb-4">
          Delivery via {deliveryOption}{' '}
        </p>
        <p className="f-12 txt-gray4 txt-bold ">{storeName}</p>
        <p className="f-12 txt-gray4">{phoneNumber}</p>
        <p className="f-12 txt-gray4">{location}</p>
        <p className="txt-bold  txt-black">KSh {deliveryCost}</p>
      </Box>
    </div>
  );
};

SelectedPickupStore.propTypes = {
  name: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  deliveryOption: PropTypes.string.isRequired,
  deliveryCost: PropTypes.string.isRequired,
};
export default SelectedPickupStore;
