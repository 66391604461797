/*
    This component offers navigation otpions to: 
    - Daily deals, -Express Delivery, -Top sellers , -Lukhu picks & - Newly arrived
 */

import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import dailyDealsIcon from '../../assets/images/icon-awesome-percentage.png';
import expressDeliveryIcon from '../../assets/images/icon-feather-package.png';
import topSellersIcon from '../../assets/images/icon-feather-star.png';
import newlyArrivedIcon from '../../assets/images/icon-material-new-releases.png';
import lukhuIcon from '../../assets/images/icon-lukhu-logo.png';

const productDisplayOptions = [
  {
    title: {
      top: 'Daily',
      bottom: 'Deals',
    },
    icon: dailyDealsIcon,
    bgColor: '#C73232',
    padding: '24px',
    linkTo: '/daily-deals',
  },
  {
    // title: 'Express Delivery',
    title: {
      top: 'Express',
      bottom: 'Delivery',
    },
    icon: expressDeliveryIcon,
    bgColor: '#2B7C34',
    padding: '16px',
    linkTo: '/express-delivery',
  },
  {
    title: {
      top: 'Top',
      bottom: 'sellers',
    },
    icon: topSellersIcon,
    bgColor: '#FDC300',
    padding: '24px',
    linkTo: '/top-sellers',
  },
  {
    title: {
      top: 'Lukhu',
      bottom: 'Picks',
    },
    // title: 'Lukhu Picks',
    icon: lukhuIcon,
    bgColor: '#D81E5B',
    padding: '24px',
    linkTo: '/lukhu-picks',
  },
  {
    // title: 'Newly Arrived',
    title: {
      top: 'Newly',
      bottom: 'Arrived',
    },
    icon: newlyArrivedIcon,
    bgColor: '#1E82D8',
    padding: '24px',
    linkTo: '/new-arrivals',
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '16px',
    paddingBottom: '16px',
    backgroundColor: '#ffffff',
  },
  card: {
    width: '200px',
    height: '120px',
    boxShadow: 'none',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    padding: '16px',
  },
}));

const OptionsNav = () => {
  const classes = useStyles();
  return (
    <section>
      {/* <div className="nav-home-secondary"> */}
      <Grid container justify="center" className={classes.root}>
        {productDisplayOptions.map((item, i) => (
          <Grid item xs={6} md={2} key={i}>
            <Card className={classes.card} component={Link} to={item.linkTo}>
              <CardActionArea className={classes.content}>
                <Avatar
                  className={classes.avatar}
                  style={{ backgroundColor: `${item.bgColor}` }}
                >
                  <img src={item.icon} alt="" />
                </Avatar>

                <CardContent>
                  <p className="txt-capitalize" style={{ color: '#111111' }}>
                    {item.title.top} <br /> {item.title.bottom}
                  </p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* </div> */}
    </section>
  );
};

export default OptionsNav;
