/*
 * Choose from previously used delivery addresses
 *  or
 *  Add a new delivery address
 */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogHeading from './DialogHeading';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Box from '@material-ui/core/Box';

import { formatPhoneNumber } from '../../_helpers/format-phone';

import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import BaseInput2 from '../../components/BaseInput2';
import flagIcon from '../../assets/images/flag_icon.jpg';
import PlacesAutocomplete from '../../components/PlacesAutocomplete';
import DeliveryAddressForm from './DeliveryAddressForm';
import SelectSavedDeliveryAddress from './SelectSavedDeliveryAddress';
import BaseCircularProgress from '../../components/BaseCircularProgress';
import {
  getsavedDeliveryAddress,
  // selectSavedDeliveryAddress,
} from '../../redux/checkout/checkoutActions';

const title = 'Delivery Details';
const titleId = 'sset-delivery-address-title';
const subtitle = 'Set up your preferred delivery addresses below';
const subtitleId = 'set-delivery-address-subtitle';

const useStyles = makeStyles((theme) => ({
  dialogCtnt: {
    padding: '24px',
  },
}));

const mapState = ({ checkout }) => ({
  savedDeliveryAddressLoading: checkout.savedDeliveryAddressLoading,
  savedDeliveryAddress: checkout.savedDeliveryAddress,
  pickupStoreDetails: checkout.pickupStoreDetails,
});

const SetDeliveryAddress = ({ handleClose, open, openNextTab }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { savedDeliveryAddressLoading, savedDeliveryAddress } = useSelector(
    mapState
  );

  const [isFormOpen, setisFormOpen] = useState(false);
  /** Get previously used delivery addresses */
  useEffect(() => {
    dispatch(getsavedDeliveryAddress());
    return () => {};
  }, [dispatch]);

  const closeDialog = () => {
    handleClose();
    openNextTab();
  };

  const handleAddNew = () => {
    setisFormOpen(true);
    //open new address form. Forward as prop maybe?
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="set-delivery-address"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogHeading
        subtitle={subtitle}
        subtitleId={subtitleId}
        title={title}
        titleId={titleId}
        onClose={handleClose}
      />
      <DialogContent classes={{ root: classes.dialogCtnt }}>
        {/* {isFormOpen ? (
          <DeliveryAddressForm closeDialog={closeDialog} />
        ) : (
          <SelectSavedDeliveryAddress
            closeDialog={closeDialog}
            handleAddNew={handleAddNew}
          />
        )} */}

        {isFormOpen && <DeliveryAddressForm closeDialog={closeDialog} />}

        {savedDeliveryAddressLoading && !isFormOpen && (
          <Box display="flex" justifyContent="center">
            {' '}
            <BaseCircularProgress />
          </Box>
        )}
        {savedDeliveryAddress &&
        savedDeliveryAddress.length > 0 &&
        !isFormOpen ? (
          <SelectSavedDeliveryAddress
            closeDialog={closeDialog}
            handleAddNew={handleAddNew}
            savedDeliveryAddress={savedDeliveryAddress}
          />
        ) : (
          <DeliveryAddressForm closeDialog={closeDialog} />
        )}
      </DialogContent>
    </Dialog>
  );
};

SetDeliveryAddress.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openNextTab: PropTypes.func.isRequired,
};
export default SetDeliveryAddress;
