import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const MerchantNotifyCancelledOrder = ({ timestamp, buyerName }) => {
  return (
    <Box display="flex">
      <Avatar style={{ background: '#707070', marginRight: '8px' }}>
        <PriorityHighIcon style={{ color: '#ffffff' }} />
      </Avatar>
      <div className="notification-box">
        <p className="txt-time f-10">{timestamp}</p>
        <p className="txt-bold txt-blackTrue">Your order was cancelled </p>

        <p className="txt-gray5 f-14">
          You will receive your item from{' '}
          <span className="txt-bold"> {buyerName} </span> soon
        </p>
      </div>
    </Box>
  );
};
MerchantNotifyCancelledOrder.propTypes = {
  buyerName: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default MerchantNotifyCancelledOrder;
