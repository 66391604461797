import { combineReducers } from 'redux';

import authReducer from './auth/authReducer';
import merchantReducer from './merchant/merchantReducer';
import cartReducer from './cart/cartReducer';
import checkoutReducer from './checkout/checkoutReducer';
import productsReducer from './products/productsReducer';
import searchReducer from './search/searchReducer';
import messageReducer from './message/messageReducer';
import socialsReducer from './socials/socialsReducer';
import walletReducer from './wallet/walletReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  merchant: merchantReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  product: productsReducer,
  search: searchReducer,
  message: messageReducer,
  social: socialsReducer,
  wallet: walletReducer,
});

export default rootReducer;
