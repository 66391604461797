export const GET_WALLET_START = 'GET_WALLET_START';
export const GET_WALLET_SUCCESS = 'GET_WALLET_SUCCESS';
export const GET_WALLET_FAIL = 'GET_WALLET_FAIL';

export const GET_TRANSACTIONS_START = 'GET_TRANSACTIONS_START';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAIL = 'GET_TRANSACTIONS_FAIL';

export const DEPOSIT_TO_WALLET = 'DEPOSIT_TO_WALLET';

export const DEPOSIT_SUCCESS = 'DEPOSIT_SUCCESS';
export const DEPOSIT_FAIL = 'DEPOSIT_FAIL';
