import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

import Typography from '@material-ui/core/Typography';

import { sampleCart } from '../Home/dummyData';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '16px 8px',
  },
  card: {
    display: 'flex',
    background: 'transparent',
    boxShadow: 'none',
    width: '314px',
    justifyContent: 'flex-end',
  },

  content: {
    flex: '1 0 auto',
    // padding: '0 8px',
    alignSelf: 'center',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  actions: {
    flex: '1 0 auto',
  },
  cover: {
    width: 101,
  },
  font1: {
    fontFamily: ['Montserrat', 'sans-serif'],
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  font2: {
    fontFamily: ['Lato', 'sans-serif'],
    fontSize: '0.875rem',
    textTransform: 'capitalize',
  },
  divSpacing: {
    margin: '16px 0',
  },
}));

const OrderInfo = () => {
  const classes = useStyles();

  return (
    <div>
      <Paper classes={{ root: classes.paper }}>
        <div style={{ margin: '4px' }}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.cover}
              image={sampleCart[0].productImg}
              title="product"
            />
            <CardContent className={classes.content}>
              <Typography
                component="p"
                variant="body1"
                className={classes.font1}
              >
                {sampleCart[0].productName}
              </Typography>

              <Typography
                component="p"
                variant="body1"
                className={classes.font2}
              >
                <span style={{ fontWeight: 700 }}>Size: </span>
                {sampleCart[0].productSize}
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={classes.font2}
              >
                <span style={{ fontWeight: 700 }}>Condition : </span>
                {sampleCart[0].productCondtion}
              </Typography>
            </CardContent>
            {/* <CardActions className={classes.actions}>
                <div style={{ alignSelf: 'center' }}>
                  <IconButton>
                    <Cancel style={{ color: '#A6A7A8', fontSize: '1.25rem' }} />
                  </IconButton>
                </div>
              </CardActions> */}
          </Card>
        </div>
        <Divider className={classes.divSpacing} />
        <div>
          <div className="d-flex d-flex-space">
            <div>
              <p className="f-14 txt-gray2">Item Price</p>
            </div>
            <div>
              <p className="f-14 txt-bold">Ksh {sampleCart[0].productPrice}</p>
            </div>
          </div>
          <div className="d-flex d-flex-space">
            <div>
              <p className="f-14 txt-gray2">Buyer Protection Fee</p>
            </div>
            <div>
              <p className="f-14 txt-bold">Ksh 150</p>
            </div>
          </div>
        </div>
        <Divider className={classes.divSpacing} />
        <div className="d-flex d-flex-space">
          <div>
            <p className="f-14 txt-bold txt-black">Subtotal</p>
          </div>
          <div>
            <p className=" f-14 txt-bold txt-black">KSh 1,500 </p>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default OrderInfo;
