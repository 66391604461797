import React, { useState } from 'react'
import HomeAppBar from '../components/home/HomeAppBar'
import { Paper } from '@material-ui/core'
import {Box} from '@material-ui/core'
import {Button} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MainForYou from '../components/home/forYou/MainForYou';
import MainHolder from '../components/home/MainHolder'


const useStyles = makeStyles((theme) => ({
  mainBox:{
    marginTop:"15px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  btn:{
    font: "normal normal bold 14px/13px Montserrat",
    color:" #111111"
  },
  div:{
    color:"#111111",
    height:"3px",
    background:"#111111",
    fontStyle:"bold",
    
  },
  root:{
    height:"100vh",
    background:"#F5F5F5"
  },
  forYoudIV:{

  }
}))

function HomeMobile() {
  const classes = useStyles();
  const [tabNumber, setTabNumber] = useState(1)

  const tabOneClick = () =>{
    setTabNumber(1)
  }

  const tabTwoClick = () =>{
    setTabNumber(2)
  }

  const tabThreeClick = () =>{
    setTabNumber(3)
  }
  return (

    <div className={classes.root}>
    <Paper>
      <HomeAppBar />

      <Box className={classes.mainBox}>
        <Box>
        <Button onClick={tabOneClick} className={classes.btn} variant="text">For You</Button>
          {tabNumber === 1 ? (<div className={classes.div}></div>) : (<div></div>)}
        </Box>

        <Box>
        <Button onClick={tabTwoClick} className={classes.btn} variant="text">Explore</Button>
        {tabNumber === 2 ? (<div className={classes.div}></div>) : (<div></div>)}
        </Box>

        <Box>
        <Button onClick = {tabThreeClick} className={classes.btn} variant="text">My Style</Button>
        {tabNumber === 3 ? (<div className={classes.div}></div>) : (<div></div>)}
        </Box>
      </Box>
    
    </Paper>

    <MainForYou />
    <MainHolder />

    </div>
  )
}

export default HomeMobile