import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import BaseBtn from '../../components/BaseBtn';
import BaseCard from '../../components/BaseCard';
import BaseInput2 from '../../components/BaseInput2';
import { displayPhoneNumber } from '../../_helpers/format-phone';
import { verifyPwdCode } from '../../redux/auth/authActions';

const useStyles = makeStyles({
  disabledBtn: {
    backgroundColor: '#E2E2E2',
    '&:hover': {
      backgroundColor: '#E2E2E2',
    },
  },
  activeBtn: {
    backgroundColor: '#4900ff',
    border: ' 2px solid #4900ff',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  disabledLink: {
    color: '#717171',
  },
  activeLink: {
    color: '#fff',
  },
  content: {
    height: '50vh',
  },
});

const validationSchema = yup.object({
  pwdResetToken: yup
    .string()
    .matches(/^-?\d+\.?\d*$/, 'invalid! Code can only contain numbers')
    .required('Please enter code')
    .min(6, 'code must be 6 digits long')
    .max(6, 'code must be 6 digits long'),
});

const mapState = ({ auth }) => ({
  userPhoneNumber: auth.passwordResetPhonenumber,
  isResetCodeValid: auth.isResetCodeValid,
  pwdResetCodeErr: auth.pwdResetCodeErr,
});
const VerifyPasswordCode = () => {
  const classes = useStyles();

  const { userPhoneNumber, isResetCodeValid, pwdResetCodeErr } = useSelector(
    mapState
  );

  const dispatch = useDispatch();

  const [tokenError, settokenError] = useState('');
  const formik = useFormik({
    initialValues: {
      pwdResetToken: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const pwdResetToken = values.pwdResetToken;
      // console.log(pwdResetToken);
      dispatch(verifyPwdCode(pwdResetToken));
      formik.resetForm({});
    },
  });

  // if (!userPhoneNumber) return <Redirect to="/auth/request_password_reset" />;
  if (isResetCodeValid) return <Redirect to="/auth/reset_password" />;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <BaseCard>
          <CardContent className={classes.content}>
            <h2
              className="txt-ruby h2x-large-bold"
              style={{ marginBottom: '16px' }}
            >
              Reset Password
            </h2>
            <p className="p-14 txt-violet" style={{ marginBottom: '48px' }}>
              Enter the 6-digit number sent to{' '}
              {/* {displayPhoneNumber(userPhoneNumber)} */}
              {userPhoneNumber}
            </p>
            <div className="mb-16">
              <BaseInput2
                id="pwdResetToken"
                name="pwdResetToken"
                type="text"
                placeholder="enter code"
                fullWidth
                value={formik.values.pwdResetToken}
                onChange={formik.handleChange}
              />
              {formik.errors.pwdResetToken && formik.touched.pwdResetToken && (
                <p className="input-error">
                  <small>{formik.errors.pwdResetToken}</small>
                </p>
              )}
              {tokenError && (
                <p className="input-error">
                  <small>{tokenError}</small>
                </p>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Link to="/auth/request_password_reset"> Resend code</Link>
              {/* <small>
                <a href="/" style={{ color: 'black' }}>
                  Resend code
                </a>
              </small> */}
            </div>
          </CardContent>
          <CardActions>
            <BaseBtn
              type="submit"
              fullWidth
              disabled={!formik.values.pwdResetToken || formik.isSubmitting}
              className={
                formik.values.pwdResetToken
                  ? classes.activeBtn
                  : classes.disabledBtn
              }
            >
              <span
                className={
                  formik.values.pwdResetToken
                    ? classes.activeLink
                    : classes.disabledLink
                }
              >
                continue
              </span>
            </BaseBtn>
          </CardActions>
          {pwdResetCodeErr && (
            <div className="mt-8 mb-8">
              <p
                style={{
                  fontSize: '0.875rem',
                  color: 'red',
                  textAlign: 'center',
                }}
              >
                An error occurred!{' '}
                <span style={{ color: 'blue', textDecoration: 'underline' }}>
                  <Link to="/auth/request_password_reset">Try Again </Link>
                </span>
              </p>
            </div>
          )}
        </BaseCard>
      </form>
    </>
  );
};

export default VerifyPasswordCode;
