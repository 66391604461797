/*
    This component displays 'My Shop' info on the Merchant Admin Pnael
*/

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import MerchantProductCard2 from '../../parts/Shops/MerchantProductCard2';
import MerchantAdminEmptyDisplay from './MerchantAdminEmptyDisplay';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
    minHeight: '100%',
    borderRadius: '5px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.07)',
  },
  sectionMain: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'auto',
    gridGap: '4px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
}));

const title = 'Your Shop';
const MerchantAdminPanelSelling = ({ products }) => {
// console.log(products)
  const classes = useStyles();
  if (products && products.length < 1) {
    return (
      <MerchantAdminEmptyDisplay txt="You have not listed any products yet" />
    );
  }

  return (
    <Paper elevation={0} className={classes.root}>
      <div className="mb-16">
        <h3 className="txt-capitalize"> {title} </h3>
      </div>

      <div className={classes.sectionMain}>
        {products &&
          products.map((product) => {
            return (
              <MerchantProductCard2
                key={product.id}
                product_id = {product.id}
                picture={product.image}
                price={product.price}
                size={product.size}
              />
            );
          })}
      </div>
    </Paper>
  );
};

MerchantAdminPanelSelling.propTypes = {
  products: PropTypes.array.isRequired,
};

export default MerchantAdminPanelSelling;
