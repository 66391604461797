import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import Alert from '@material-ui/lab/Alert';

import BaseInput2 from '../../components/BaseInput2';
import BaseCard from '../../components/BaseCard';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseLoadingProgress from '../../components/BaseLoadingProgress';
import { logIn } from '../../redux/auth/authActions';

const useStyles = makeStyles({
  loginBtn: {
    backgroundColor: '#4900ff',
    color: '#fff',
    marginBottom: '12px',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  loginBtnDisabled: {
    backgroundColor: '#E2E2E2',
    color: '#717171',
  },

  errorClass: {
    border: '1px solid red',
    borderRadius: '2px',
  },
});

const validationSchema = yup.object({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Please enter your password'),
});

const mapState = ({ auth }) => ({
  loginErr: auth.loginErr,
  isLoggedIn: auth.isLoggedIn,
  token: auth.token,
  profile: auth.profile,
  phoneVerSuccess: auth.phoneVerSuccess,
  loginLoading: auth.loginLoading,
});
const LoginForm = () => {
  const classes = useStyles();
  const [showPassword, setshowPassword] = useState(false);
  // const [displayAuthError, setdisplayAuthError] = useState(true);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // get auth state
  const {
    loginErr,
    token,
    profile,
    phoneVerSuccess,
    loginLoading,
  } = useSelector(mapState);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const creds = {
        Username: values.username,
        Password: values.password,
      };
      dispatch(logIn(creds));
    },
  });

  if (token && profile && profile.isPhoneVerified) return <Redirect to="/" />;

  return (
    <BaseCard>
      <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
        <CardContent>
          <h2
            className="txt-ruby h2x-large-bold"
            style={{ marginBottom: '16px' }}
          >
            Log in
          </h2>
          <p className="txt-violet f-14" style={{ marginBottom: '48px' }}>
            Welcome back !
          </p>

          <div className="mb-16">
            <BaseInput2
              id="username"
              name="username"
              type="text"
              placeholder="username *"
              value={formik.values.username}
              onChange={formik.handleChange}
              fullWidth
              className={
                formik.errors.username && formik.touched.username
                  ? classes.errorClass
                  : ''
              }
            />
            {formik.errors.username && formik.touched.username && (
              <p className="input-error">
                <small>{formik.errors.username}</small>
              </p>
            )}
          </div>

          <div className="mb-16">
            <BaseInput2
              fullWidth
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              placeholder="password *"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <MdVisibility fontSize="small" />
                    ) : (
                      <MdVisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              value={formik.values.password}
              onChange={formik.handleChange}
              className={
                formik.errors.password && formik.touched.password
                  ? classes.errorClass
                  : ''
              }
            />
            {formik.errors.password && formik.touched.password && (
              <p className="input-error">
                <small>{formik.errors.password}</small>
              </p>
            )}
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Link to="/auth/request_password_reset">
              <span className="f-12">Forgot password?</span>
            </Link>
          </div>
        </CardContent>

        <CardActions>
          <BaseBtnFilled
            type="submit"
            fullWidth
            disabled={loginLoading ? true : false}
          >
            {' '}
            {loginLoading ? 'loading ...' : 'log in'}
          </BaseBtnFilled>
        </CardActions>
        <div className="flex-center mb-16">
          {loginErr && <Alert severity="error">{loginErr}</Alert>}
        </div>
      </form>
    </BaseCard>
  );
};

export default LoginForm;
