import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 80,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
  },
  media: {
    width: 80,
  },

  prodRef: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
const SentMessageBox = ({ message, timestamp, productRef }) => {
  const classes = useStyles();

  return (
    <div className="msg-box msg-box-sent">
      <p className="f-12" style={{ color: '#A6A7A8' }}>
        {timestamp}
      </p>
      <div className={classes.prodRef}>
        <p className="f-14">{message}</p>
        {productRef && (
          <div style={{ marginLeft: '2px' }}>
            <Card className={classes.root}>
              <CardMedia
                component="img"
                image={productRef}
                className={classes.media}
              />
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};
SentMessageBox.propTypes = {
  message: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  productRef: PropTypes.string,
};

export default SentMessageBox;
