export const FETCH_SINGLE_PRODUCT_START = 'FETCH_SINGLE_PRODUCT_START';
export const FETCH_SINGLE_PRODUCT_SUCCESS = 'FETCH_SINGLE_PRODUCT_SUCCESS';
export const FETCH_SINGLE_PRODUCT_FAIL = 'FETCH_SINGLE_PRODUCT_FAIL';

export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = ' FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAIL = 'FETCH_PRODUCTS_FAIL';

export const FETCH_SIMILAR_PRODUCTS_START = 'FETCH_SIMILAR_PRODUCTS_START';
export const FETCH_SIMILAR_PRODUCTS_SUCCESS = ' FETCH_SIMILAR_PRODUCTS_SUCCESS';
export const FETCH_SIMILAR_PRODUCTS_FAIL = 'FETCH_SIMILAR_PRODUCTS_FAIL';

export const FETCH_SELLER_PRODUCTS_START = 'FETCH_SELLER_PRODUCTS_START';
export const FETCH_SELLER_PRODUCTS_SUCCESS = ' FETCH_SELLER_PRODUCTS_SUCCESS';
export const FETCH_SELLER_PRODUCTS_FAIL = 'FETCH_SELLER_PRODUCTS_FAIL';

export const FECTH_PRODUCT_STORE_DETAILS_START =
  'FECTH_PRODUCT_STORE_DETAILS_START';
export const FECTH_PRODUCT_STORE_DETAILS_SUCCESS =
  'FECTH_PRODUCT_STORE_DETAILS_SUCCESS';
export const FECTH_PRODUCT_STORE_DETAILS_FAIL =
  'FECTH_PRODUCT_STORE_DETAILS_FAIL';

export const CHANGE_DISPLAY_IMAGE = 'CHANGE_DISPLAY_IMAGE';

export const FETCH_PRODUCT_CONDITIONS_SUCCESS =
  'FETCH_PRODUCT_CONDITIONS_SUCCESS';
export const FETCH_PRODUCT_CONDITIONS_FAIL = 'FETCH_PRODUCT_CONDITIONS_FAIL';

export const FETCH_PRODUCT_DELIVERY_OPTIONS_FAIL =
  'FETCH_PRODUCT_DELIVERY_OPTIONS_FAIL';
export const FETCH_PRODUCT_DELIVERY_OPTIONS_SUCCESS =
  'FETCH_PRODUCT_DELIVERY_OPTIONS_SUCCESS';

export const FETCH_PRODUCT_COLORS_SUCCESS = 'FETCH_PRODUCT_COLORS_SUCCESS';
export const FETCH_PRODUCT_COLORS_FAIL = 'FETCH_PRODUCT_COLORS_FAIL';
