import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  ctn: {
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
  },
}));

const CustomContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.ctn}>
      {children}
    </Container>
  );
};

CustomContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomContainer;
