import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import BaseBtnFilled from '../../components/BaseBtnFilled';
import { sampleDraftsComplete } from '../Home/dummyData';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: 'transparent',
    boxShadow: 'none',
    width: '314px',
  },

  content: {
    flex: '1 0 auto',
    padding: '0 8px',
  },
  cover: {
    width: 101,
  },

  font1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.75rem',
  },
  font2: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '0.75rem',
    color: '#707070',
  },
  font3: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontWeight: 700,
    fontSize: '0.875rem',
  },
  flexLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    width: '150px',
  },
  paper: {},
}));
const DraftsComplete = ({ products }) => {
  const classes = useStyles();
  if (products && products.length < 1) {
    return (
      <div className={classes.spacing}>
        <p className="txt-violet txt-center">
          You don &#39; t have any complete product drafts.
        </p>
      </div>
    );
  }
  return (
    <div className={classes.spacing}>
      {sampleDraftsComplete.map((item) => (
        <div key={item.id} className={classes.flexLayout}>
          <div style={{ margin: '4px' }}>
            <Card className={classes.root}>
              <CardMedia
                className={classes.cover}
                image={item.productImg}
                title="product"
              />
              <CardContent className={classes.content}>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.font1}
                >
                  {item.productName}
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.font2}
                >
                  <span style={{ fontWeight: 700 }}>Condition: </span>
                  {item.productCondtion}
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.font2}
                >
                  <span style={{ fontWeight: 700 }}>Size: </span>
                  {item.productSize}
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.font3}
                >
                  Ksh {item.productPrice}
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div>
            <BaseBtnFilled className={classes.btn} size="small">
              Post Item
            </BaseBtnFilled>
          </div>
        </div>
      ))}
    </div>
  );
};

DraftsComplete.propTypes = {
  products: PropTypes.array.isRequired,
};

export default DraftsComplete;
