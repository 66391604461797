import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import StoreProductCard from '../Products/StoreProductCard';
import { Container } from '@material-ui/core';

import '../../assets/styles/Display.css';
const useStyles = makeStyles((theme) => ({
  // root: {
  //   width: '232px',
  //   maxWidth: '100%',
  //   backgroundColor: '#E4D9FF',
  //   color: '#481CFB',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E4D9FF',
    color: '#481CFB',
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // },
  },

  container: {
    marginTop: '48px',
    padding: '0 56px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
      marginTop: '16px',
    },
  },
}));

const EmptyCard = ({ productsNo, linkTo }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Link to={linkTo}>
        <p>See all {productsNo} items</p>
      </Link>
    </Card>
  );
};

EmptyCard.propTypes = {
  productsNo: PropTypes.number.isRequired,
  linkTo: PropTypes.string,
};

const MerchantItemsDisplay = ({
  productArr,
  title,
  headingColor,
  emptyDisplayMessage,
  loading,
  linkTo,
}) => {
  const slicedProductArr =
    productArr.length > 5 ? productArr.slice(0, 4) : productArr;

  const classes = useStyles();

  if (loading) {
    return (
      <div>
        <p>Loading ...</p>
      </div>
    );
  }

  return (
    <section className="disp-opt-wrapper">
      <Container className={classes.container}>
        <header className="disp-opt-header mb-24 ">
          <h3
            className="txt-uppercase txt-semi-bold"
            style={{ color: `${headingColor}` }}
          >
            {title}
          </h3>
        </header>
        <div className="c5-grid">
          {slicedProductArr.map((item, i) => (
            <StoreProductCard
              key={i}
              productName={item.name}
              productImg={
                item.images.length === 0 ? '' : item.images[0].secure_url
              }
              productSize={item.size}
              productCondtion={item.condition}
              productLocation={item.location}
              productPrice={item.price}
              productLikes={item.likes}
              productId={item.id}
            />
          ))}
          {productArr.length > 4 && (
            <EmptyCard productsNo={productArr.length} linkTo={linkTo} />
          )}
        </div>
        {!productArr.length && (
          <div className="txt-center  pb-16">
            <p className="txt-ruby">{emptyDisplayMessage}</p>
          </div>
        )}
      </Container>
    </section>
  );
};
MerchantItemsDisplay.propTypes = {
  productArr: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  headingColor: PropTypes.string.isRequired,
  emptyDisplayMessage: PropTypes.string,
  loading: PropTypes.bool,
  linkTo: PropTypes.string,
};

export default MerchantItemsDisplay;
