import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import AddIcon from '@material-ui/icons/Add';
import GradeIcon from '@material-ui/icons/Grade';
import TimerIcon from '@material-ui/icons/Timer';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { FaMoneyBillWave } from 'react-icons/fa';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Divider from '@material-ui/core/Divider';

import MerchantAvatar from '../../parts/Shops/MerchantAvatar';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtn from '../../components/BaseBtn';
import MerchantProfileTabs from '../../parts/Shops/MerchantProfileTabs';
import BaseBtn2 from '../../components/BaseBtn2';
import Verified from '../../assets/images/verified.png';

import {
  fetchMerchantStoreDetails,
  fetchPublicStoreDetails,
} from '../../redux/merchant/merchantActions';
import { url } from '../../api';
import {
  blockMerchant,
  fetchFollowedMerchants,
  followMerchant,
} from '../../redux/socials/socialsActions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    marginTop: '2px',
    width: '100%',
    padding: '32px 0',
  },
  grid: {
    width: '90%',
    margin: '0 auto',
  },
  gridItem1: {
    display: 'flex',
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  sectionContainer: {
    margin: theme.spacing(2, 0, 0, 2),
    width: '100%',
  },
  name: {
    fontFamily: ['Lato', 'sans-serif'].join(', '),
    fontWeight: 700,
    fontSize: '1.25rem',
    color: '#4A4062',
  },
  status: {
    backgroundColor: '#c3f2b1',
    fontFamily: ['Lato', 'sans-serif'].join(', '),
    textTransform: 'capitalize',
    color: '#378D17',
    padding: '2px 4px',
    alignSelf: 'center',
    margin: theme.spacing(0, 2),
    borderRadius: '2px',
  },
  section1: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0),
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  iconSmall: {
    fontSize: '1rem',
    margin: theme.spacing(0, 0.5, 0, 0),
    color: '#707070',
  },
  iconLarge: {
    margin: theme.spacing(0, 0.5, 0, 0),
    color: '#601DFF',
  },

  latoMedium: {
    fontFamily: ['Lato', 'sans-serif'].join(', '),
    color: '#707070',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'capitalize',
  },
  latoDark: {
    fontFamily: ['Lato', 'sans-serif'].join(', '),
    color: '#111111',
    fontSize: '1rem',
  },
  latoBlue: {
    fontFamily: ['Lato', 'sans-serif'].join(', '),
    color: '#601DFF',
    fontSize: '1rem',
  },
  latoRegular: {},
  iconText: {
    display: 'flex',

    alignItems: 'center',
    flexWrap: 'wrap',
  },
  latoRuby: {
    fontFamily: ['Lato', 'sans-serif'].join(', '),
    fontSize: '1rem',
  },

  section2: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: theme.spacing(2, 0),
  },
  section3: {
    margin: theme.spacing(2, 0),
  },
  section4: {
    margin: theme.spacing(1, 0),
  },

  btnFilled: {
    width: '150px',
    marginRight: '16px',
  },
  btnDisabled: {
    width: '150px',
    marginRight: '16px',
    background: '#eee',
    color: '#111111',
  },
  btnOutlined: {
    width: '150px',
  },
  btn1:{
    marginTop:"10px",
    color: '#481CFB',
    background: '#f3f0ff',
    bordeRadius:"3px !important",
    fontSize:"12px"
  },
  verif:{
    width:"20px"
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
}));

const mapState = ({ merchant, auth, social }) => ({
  storeDetails: merchant.storeDetailsPublic,
  merchantProducts: merchant.storeListedProducts,
  storeLoading: merchant.loading,
  storeProductsLoading: merchant.storeListedProductsLoading,
  storeListedProductsPublic: merchant.storeListedProductsPublic,
  storeListedProductsLoadingPublic: merchant.storeListedProductsLoadingPublic,
  storeListedProductsErrPublic: merchant.storeListedProductsErrPublic,
  token: auth.token,
  profile: auth.profile,
  following: social.following,
  followingErr: social.followingErr,
  followingLoading: social.followingLoading,
});

const MerchantProfile = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    storeDetails,
    merchantProducts,
    storeLoading,
    storeProductsLoading,
    storeListedProductsLoadingPublic,
    storeListedProductsPublic,
    storeListedProductsErrPublic,
    token,
    profile,
    following,
    followingErr,
    followingLoading,
  } = useSelector(mapState);
  const { storeName } = useParams();
  const id = storeDetails?.id;
  const history = useHistory();

  // todo , fetch followed merchant , find if merchant is followed

  const [isFollowing, setisFollowing] = useState(false);

  // console.log("store details",storeDetails)

  const handleFollow = () => {
    if (profile && token) {
      dispatch(followMerchant(id));
      setisFollowing(true);
    } else {
      history.push('/auth');
    }
  };

  useEffect(() => {
    if (profile && token) {
      dispatch(fetchFollowedMerchants());
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (profile && token && following && following.length > 0) {
      let currMerchant = following.find((item) => item.uuid === id);
      if (currMerchant) {
        setisFollowing(true);
      }
    }
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(fetchPublicStoreDetails(storeName));
  }, [dispatch]);

  const handleBlockMerchant = () => {
    dispatch(blockMerchant(id));
  };

  const setJoinDate = (val) => {
    const joinDate = new Date(val);
    const dateStr = joinDate.toDateString();
    const joinDateDate = new Date(dateStr);
    const joinYear = joinDateDate.getFullYear();

    return joinYear;
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const isMoreActionsMenuOpen = Boolean(anchorEl);
  const handleMoreActionsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreActionsClose = () => {
    setAnchorEl(null);
  };
  const renderMoreActionsMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      id="more-actions-menu"
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      getContentAnchorEl={null}
      open={isMoreActionsMenuOpen}
      onClose={handleMoreActionsClose}
    >
      <MenuItem onClick={handleBlockMerchant}>Block</MenuItem>
    </Menu>
  );

  if (storeLoading) {
    return <div>Loading...</div>;
  }
  return (
    <section>
      <div className={classes.root}>
        <Container>
          {storeDetails && (
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.gridItem1}>
                  <div>
                    <MerchantAvatar
                      image={storeDetails.image}
                      avatarStyles={classes.avatar}
                      verified={storeDetails.verified}
                    />
                  </div>
                  <div className={classes.sectionContainer}>
                    <div className="d-flex">
                      <Typography variant="body1" className={classes.name}>
                        {storeDetails.businessName}
                      </Typography>

                      {/* TODO: uncomment on availabilty of API */}

                      {/* {isOnline && (
                    <Typography variant="caption" className={classes.status}>
                      <span>online</span>
                    </Typography>
                  )} */}
                    </div>
                    <div className={classes.section1}>
                      <div>
                        <div
                          className={classes.iconText}
                          style={{ marginBottom: '4px' }}
                        >
                          <LocationOnIcon className={classes.iconSmall} />
                          <Typography
                            variant="body2"
                            className={classes.latoMedium}
                          >
                            {storeDetails.location}
                          </Typography>
                        </div>
                        <div className={classes.iconText}>
                          <AccessTimeIcon className={classes.iconSmall} />
                          <Typography
                            variant="body2"
                            className={classes.latoMedium}
                          >
                            Joined{' '}
                            <span style={{ textTransform: 'lowercase' }}>
                              in
                            </span>{' '}
                            {setJoinDate(storeDetails.createdOn)}
                          </Typography>
                        </div>

    {storeDetails.verified ?                     <BaseBtn2
  startIcon={<img className={classes.verif} src={Verified}></img>}
  className={classes.btn1}
  
>
  Verified Seller
</BaseBtn2>  : ""}
                      </div>

                      <Divider orientation="vertical" flexItem />

                      <div>
                        <Rating
                          name="merchant-rating"
                          value={storeDetails.rating}
                          readOnly
                          emptyIcon={
                            <StarBorderIcon style={{ fontSize: '1.5rem' }} />
                          }
                          style={{ fontSize: '1.5rem' }}
                        />
                        <Typography
                          variant="body2"
                          className={classes.latoMedium}
                        >
                          {storeDetails.numberOfRatings} reviews
                        </Typography>
                      </div>

                      <Divider orientation="vertical" flexItem />

                      <div>
                        <div
                          className={classes.iconText}
                          style={{ marginBottom: '4px' }}
                        >
                          <FaMoneyBillWave className={classes.iconSmall} />
                          <Typography
                            variant="body2"
                            style={{ color: '#111111', fontWeight: 'bold' }}
                          >
                            {storeDetails.numberOfTransactions}
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          className={classes.latoMedium}
                        >
                          Transactions
                        </Typography>
                      </div>
                    </div>

                    {/* <div className={classes.section2}>
                    <div className={classes.iconText}>
                      <GradeIcon
                        fontSize="small"
                        className={classes.iconLarge}
                      />
                      <p className={classes.latoBlue}>Trusted Seller</p>
                    </div>
                    <div className={classes.iconText}>
                      <TimerIcon
                        fontSize="small"
                        className={classes.iconLarge}
                      />
                      <p className={classes.latoBlue}>Fast Response</p>
                    </div>
                    <div className={classes.iconText}>
                      <LocalShippingIcon
                        contSize="small"
                        className={classes.iconLarge}
                      />
                      <p className={classes.latoBlue}>Often ships in a day</p>
                    </div>
                  </div> */}

                    <Divider />
                    <div className={classes.section3}>
                      <div className="d-flex">
                        <Typography
                          variant="body2"
                          className={classes.latoMedium}
                          style={{ marginRight: '16px' }}
                        >
                          <span className="txt-ruby txt-bold">
                            {storeDetails.numberOfFollowers}
                          </span>{' '}
                          {storeDetails.numberOfFollowers > 1 ||
                          storeDetails.numberOfFollowers === 0
                            ? 'Followers'
                            : 'Follower'}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.latoMedium}
                        >
                          <span className="txt-ruby txt-bold">
                            {storeDetails.numberFollowing === 0
                              ? '0'
                              : storeDetails.numberFollowing}
                          </span>{' '}
                          Following
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.section4}>
                      <Typography variant="body2" className={classes.latoDark}>
                        {storeDetails.description}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.actions}>
                  {isFollowing && (
                    <BaseBtn
                      startIcon={<DoneIcon fontSize="small" color="white" />}
                      className={classes.btnDisabled}
                      disabled
                    >
                      following
                    </BaseBtn>
                  )}
                  {!isFollowing && (
                    <BaseBtnFilled
                      startIcon={<AddIcon fontSize="small" color="white" />}
                      className={classes.btnFilled}
                      onClick={handleFollow}
                    >
                      follow
                    </BaseBtnFilled>
                  )}

                  <BaseBtnOutlined
                    startIcon={<AddIcon fontSize="small" />}
                    className={classes.btnOutlined}
                  >
                    message seller
                  </BaseBtnOutlined>
                  <IconButton onClick={handleMoreActionsOpen}>
                    <MoreHorizIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
      {storeDetails && (
        <MerchantProfileTabs
          merchantSelling={
            storeListedProductsPublic ? storeListedProductsPublic : []
          }
          merchantName={storeDetails.businessName}
        />
      )}
      {renderMoreActionsMenu}
    </section>
  );
};

export default MerchantProfile;
