import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import SecurityIcon from '@material-ui/icons/Security';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';

import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import AboutSeller from './AboutSeller';

// simulate api

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: 'none',
    width: '85%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '95%',
    },
    margin: '0 auto',
  },
  content: {
    padding: '0px 32px',
  },
  flexSpace: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  prodImg: {
    width: 450,
    height: 480,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  imgPreview: {
    paddingRight: '8px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
  },
  prodTitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },

  section1: {
    marginBottom: '16px',
  },
  section2: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },

  section3: {
    marginBottom: '16px',
  },

  section4: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },

  section5: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    marginBottom: '16px',
  },
  btn: {
    width: '136px',
    padding: '2px',
  },

  section6: {
    width: '100%',
    backgroundColor: 'rgb(96,29,255, 0.1)',
    borderRadius: '4px',
    color: '#481CFB',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: '16px',
  },
  imgcard: {
    height: '64px',
    width: '64px',
    marginBottom: '8px',
  },
  media: {
    height: '64px',
  },
}));

const ProductDetails = ({
  name,
  description,
  size,
  price,
  condition,
  brand,
  likes,
  productImages,
  selectedImage,
  handleSelectImage,
}) => {
  const classes = useStyles();

  // const [allProductImages, setAllProductImages] = useState([]);
  // const [selectedImage, setSelectedImage] = useState(null);

  // const handleSelectImage = (i) => {
  //   // const imageObject = allProductImages[i];
  //   // const imageFile = imageObject.img;
  //   // setSelectedImage(imageFile);
  //   console.log('TO DO !');
  // };

  return (
    <section>
      <div className="bg-white pt-8">
        <Card className={classes.root}>
          {/* img list */}
          <div className={classes.imgPreview}>
            {productImages.map((img) => (
              <Card className={classes.imgcard} key={img.url}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={img.secure_url}
                    title="Product Image"
                  />
                </CardActionArea>
              </Card>
            ))}
          </div>

          <CardMedia
            component="img"
            className={classes.prodImg}
            image={selectedImage}
            title="Product Image"
          />

          <CardContent className={classes.content}>
            <div className={classes.section1}>
              <h3 className="txt-semi-bold f-20">{name}</h3>
            </div>

            <div className={classes.section2}>
              <p className="f-14">
                <span className="txt-bold txt-capitalize">Brand</span> : {brand}
              </p>
              <p>
                <span className="txt-bold f-16 txt-capitalize">Size</span> :{' '}
                {size}
              </p>
              <p>
                <span className="txt-bold f-16 txt-capitalize">Condition</span>{' '}
                : {condition}
              </p>
            </div>
            <div className={classes.section3}>
              <p>{description}</p>
            </div>

            <div className={classes.section4}>
              <Box p={1} style={{ backgroundColor: '#F5F6F7' }}>
                <p className="txt-ruby">
                  Price: <span className="txt-bold">KSh {price}</span>
                </p>
              </Box>
              <Box className="flex-center">
                <span
                  style={{
                    fontFamily: "'Montserrat', 'sans-serif'",
                    fontSize: '0.875rem',
                  }}
                >
                  {likes}
                </span>
                <FavoriteBorderIcon
                  style={{
                    color: '#111111',
                    fontSize: '1.125rem',
                    marginLeft: '2px',
                  }}
                />{' '}
              </Box>
            </div>

            <Divider />

            <div className={classes.section5}>
              <BaseBtnFilled className={classes.btn}>buy now</BaseBtnFilled>
              <BaseBtnOutlined className={classes.btn}>
                make offer
              </BaseBtnOutlined>
              <BaseBtnOutlined className={classes.btn}>
                {' '}
                ask seller
              </BaseBtnOutlined>
            </div>
            <div className={classes.section6}>
              <div className="d-flex" style={{ alignItems: 'center' }}>
                <SecurityIcon
                  style={{ fontSize: '1.125rem', marginRight: '8px' }}
                />
                <p className="f-14">
                  All in-app purchases are covered by Lukhu Buyer Protection
                </p>
              </div>

              {/* TODO: Add Click handler to buyer protection policy  */}
              <IconButton
                aria-label="read the Lukhu buyer protection policy"
                color="inherit"
              >
                <ArrowForwardIcon style={{ fontSize: '1.125rem' }} />
              </IconButton>
            </div>

            <AboutSeller />
          </CardContent>
        </Card>
      </div>
    </section>
  );
};

ProductDetails.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  likes: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  productImages: PropTypes.array.isRequired,
  selectedImage: PropTypes.string.isRequired,
  handleSelectImage: PropTypes.func,
};

export default ProductDetails;
