import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useSocket from '../../hooks/useSocket';
import BaseSuccessDisplay from '../../components/BaseSuccessDisplay';

import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import SecurityIcon from '@material-ui/icons/Security';
import Box from '@material-ui/core/Box';

import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseInput2 from '../../components/BaseInput2';
import flagIcon from '../../assets/images/flag_icon.jpg';
import ConfirmPayment from '../Checkout/ConfirmPayment';

import mpesaLogo from '../../assets/images/mpesa-logo.svg';
import visaLogo from '../../assets/images/visa-logo.svg';
import mastercardLogo from '../../assets/images/mastercard-logo.svg';
import {
  createOrder,
  setMpesaDetails,
  setCardDetails,
} from '../../redux/checkout/checkoutActions';
import BaseErrorDisplay from '../../components/BaseErrorDisplay';
import OrderSuccessfulModal from '../Checkout/OrderSuccessfulModal';
import { validatePhone, validateCardExpiry } from '../../_helpers/validators';
import BaseInput3 from '../../components/BaseInput3';
import { Button } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import axios from 'axios';
import { setCartSubtotal,setCartTotal } from '../../redux/cart/cartActions';
import { cart_voucher } from '../../redux/cart/cartActions';
const mobileValidationSchema = yup.object({
  // phoneNumber: yup
  //   .string()
  //   .required('Please fill out this field')
  //   .matches(/^((01)|(07))[0-9]+$/, 'Invalid format! Please try : 07XX...')
  //   .min(10, 'Please enter a valid phone number')
  //   .max(10, 'Please enter a valid phone number'),
  phoneNumber: validatePhone,
});
const cardValidationSchema = yup.object({
  cardNumber: yup
    .number()
    .required('Please fill out this field')
    .typeError('Please enter a digit')
    // .matches(/^((4)|(5))[0-9]+$/, 'Invalid card format!')
    .min(13, 'Invalid card number!')
    .max(16, 'Invalid card number!'),
  cardCvv: yup
    .string()
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .required('Please fill out this field'),
  cardExpiry: validateCardExpiry,
  firstName: yup.string().required('Please fill out this field'),
  lastName: yup.string().required('Please fill out this field'),
  middleName: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  details: {
    paddingLeft: '32px',
  },
  inputStyle:{
    background:"#fff"

  },
  txt: {
    color: '#7B7B7B',
    fontWeight:500,
    lineHeight:"17px",
    fontSize: '0.875rem',
    fontFamily: ['Lato', 'sans-serif'].join(','),
  },
  layout: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  paper: {
    padding: '8px',
    height: 'fit-content',
    width: 'fit-content',
    maxWidth: '375px',
    borderColor: '#601DFF',
    marginBottom: '8px',
  },
  btn: { width: '150px', fontWeight: 700, padding: 4 },
  active: {},
  inactive: {},
  flagIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

const mapState = ({ checkout,cart }) => ({
  checkedOutCart: checkout.checkedOutCart,
  deliveryId: checkout.deliveryId,
  stkPushSuccess: checkout.completePaymentSucess,
  selectedDeliveryType: checkout.selectedDeliveryType,

  cartItems: cart.cartItems,
  shoppingBagItems: cart.shoppingBagItems,
  cartTotal:cart.cartTotal,
  cartSubtotal :cart.cartSubtotal,
  cartShippingTotal:cart.cartShippingTotal

});

const VoucherCodeCheck = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const[isError,setIsError] = useState(true);
  const[voucherErr,setVoucherErr] = useState("");
  
  
  const checkVoucherCodeee = (VoucherCodee) =>{
    setVoucherErr("")

   
    const c  = cartItems[0].cart_id

    var config = {
      method: 'get',
      url: `https://api.lukhu.co.ke/api/v1/vouchers/validate?code=${VoucherCodee}&cart=${c}`,
      headers: { }
    };

    axios(config)
    .then(function (response) {
      const d =  response.data;
      console.log("the response is",d)
      if(d.status.toLowerCase() === "fail"){
        setIsError(true)
        setVoucherErr(d.message)
      }else if(d.status.toLowerCase() === "success"){
        // todo ask for how voucher success message is
        // set it to total 
        console.log("resp",response.data)
        setIsError(false)
        setVoucherErr("Applied voucher successfully!")

        dispatch(cart_voucher(VoucherCodee))
        dispatch(setCartTotal(d.cartTotal))
        dispatch(setCartSubtotal(d.cartTotal))


      }
    })
    .catch(function (error) {
      const respD =  error.response.data;

      if(respD.status.toLowerCase() === "fail"){
        setIsError(true)
        setVoucherErr(respD.message)
      }
      console.log("error OCCURED",error.response);
    });


  }
  const { checkedOutCart, deliveryId, selectedDeliveryType,cartItems,cartTotal,cartSubtotal,cartShippingTotal } = useSelector(
    mapState
  );
  const { socket, disconnectSocket } = useSocket();

  const [selectedOption, setSelectedOption, stkPushSuccess] = useState(
    'mobile'
  );

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [openPaymentConfirmation, setopenPaymentConfirmation] = useState(false);

  const handleClosePaymentConfirmation = () => {
    setopenPaymentConfirmation(false);
  };

  const { depositInitiated, depositInitiatedErr } = useSelector(mapState);
  const formik1 = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: mobileValidationSchema,
    onSubmit: (values) => {
      
   

      // console.log('ORDER: ', body);
      dispatch(setMpesaDetails(values.phoneNumber));
      // dispatch(createOrder(body));
      setopenPaymentConfirmation(true);
    },
  });
  const formik2 = useFormik({
 
    validationSchema: cardValidationSchema,
    onSubmit: (values) => {
      const details = {
        cardNumber: values.cardNumber,
        cvv: values.cardCvv,
        cardExpiry: values.cardExpiry,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
      };


      // console.log('ORDER: ', body);
      dispatch(setCardDetails(details));
      // dispatch(createOrder(body));
      setopenPaymentConfirmation(true);
    },
  });

  // const checkVoucherCodew = (VoucherCodee) =>{
   
  //   const c  = cartItems[0].cart_id

  //   var config = {
  //     method: 'get',
  //     url: `https://api.lukhu.co.ke/api/v1/vouchers/validate?code=${VoucherCodee}&cart=${c}`,
  //     headers: { }
  //   };

  //   axios(config)
  //   .then(function (response) {
  //     const d =  response.data;
  //     if(d.status.toLowerCase() === "fail"){
        
  //       setVoucherErr(d.message)
  //       setIsError(true);
  //     }else if(d.status.toLowerCase() === "success"){
  //       // todo ask for how voucher success message is
  //       // set it to total 
  //       console.log("resp",response.data)
  //       setIsError(false);
  //       setVoucherErr("Applied voucher successfully!")

  //       dispatch(cart_voucher(VoucherCodee))
  //       dispatch(setCartTotal(d.cartTotal))
  //       dispatch(setCartSubtotal(d.cartTotal))

  //     }
  //   })
  //   .catch(function (error) {
  //     console.log("error");
  //   });


  // }

  const handleClick = (event) =>{
    if(formik1.values.phoneNumber.length > 0){
      checkVoucherCodeee(formik1.values.phoneNumber)
    }else{
      setIsError(true)
      setVoucherErr("Voucher input cannot be empty")
    }
 
  
  }


  return (
    <>
      <div>
        
                <>
                  <form onSubmit={formik1.handleSubmit} noValidate>
                    <BaseInput3
              
                      id="phoneNumber"
                      name="phoneNumber"
                      type="text"
                      placeholder="Voucher Code"
                      endAdornment={
                        <InputAdornment position="end">
                          {' '}
                        
                          <Button className={classes.txt}  onClick={handleClick} variant="text">Apply</Button>
                        </InputAdornment>
                      }
                      value={formik1.values.phoneNumber}
                      onChange={formik1.handleChange}
                      fullWidth
                    />
                  
                    <div className="mb-8"></div>

                   
                    {voucherErr && isError === true ? (
                      <BaseErrorDisplay message={voucherErr}></BaseErrorDisplay>
                      // <p className="inputError">
                        
                      // </p>
                    ):<BaseSuccessDisplay message={voucherErr}></BaseSuccessDisplay>
}
                  </form>
                </>
    
  
          </div>
  
  
   
    </>
  );
};

export default VoucherCodeCheck;
