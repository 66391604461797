// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const BaseBtn = withStyles({
  root: {
    borderRadius: 24,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: ['Lato', 'sans-serif'].join(','),
  },
})(Button);

export default BaseBtn;
