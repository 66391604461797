// eslint-disable-next-line
import axios from 'axios';
import { url } from '../../api';
import {
  CHECK_LOGGED_IN,
  LOGIN_SUCCESS,
  LOGIN_START,
  SIGN_OUT,
  SIGN_UP_START,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  VERIFY_PHONE_START,
  VERIFY_PHONE_SUCCESS,
  VERIFY_PHONE_FAIL,
  LOGIN_FAIL,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_REQUEST_SUCCESS,
  VERIFY_PASSWORD_RESET_CODE_FAIL,
  VERIFY_PASSWORD_RESET_CODE_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_START,
  GET_USER_DETAILS_FAIL,
  PASSWORD_RESET_REQUEST_FAIL,
  CHECK_WAITLIST_START,
  CHECK_WAITLIST_SUCCESS,
  CHECK_WAITLIST_FAIL,
  ADD_WAITLIST_FAIL,
  ADD_WAITLIST_SUCCESS,
  RESEND_VER_OTP_START,
  RESEND_VER_OTP_SUCCESS,
  RESEND_VER_OTP_FAIL,
} from './authTypes';

import { fetchCartItems } from '../cart/cartActions';

// CHECK IF USER IS AUTHENTICATED

export const loadUser = () => {
  return (dispatch, getState) => {
    const profile = getState().auth.profile;
    const token = getState().auth.token;
    if (profile && profile.isPhoneVerified && token) {
      dispatch({
        type: CHECK_LOGGED_IN,
        payload: {
          profile: profile,
          token: token,
          username: profile.username,
        },
      });
      // fetch cart items
      dispatch(fetchCartItems());
    } else {
      return null;
    }
  };
};

export const verifyPhone = (code) => {
  return (dispatch, getState) => {
    dispatch({
      type: VERIFY_PHONE_START,
    });
    const profile = getState().auth.profile;
    const user_id = profile.id;
    const token = getState().auth.token;
    axios
      .get(`${url}/users/${user_id}/phone/verify?token=${code}`, {
        headers: {
          Authorization: 'Bearer' + token,
        },
      })
      .then((res) => {
        const result = res.data.message;

        if (result === 'otp invalid or expired') {
          dispatch({
            type: VERIFY_PHONE_FAIL,
            payload: 'Invalid code. Please try again.',
          });
        } else {
          dispatch({
            type: VERIFY_PHONE_SUCCESS,
          });
        }
      })
      .catch((error) => {
        // console.log('Verify phone: ', error);
        dispatch({
          type: VERIFY_PHONE_FAIL,
          payload: 'Invalid code. Please try again.',
        });
      });
  };
};
export const resendVerificationOTP = () => {
  return (dispatch, getState) => {
    dispatch({
      type: RESEND_VER_OTP_START,
    });

    const token = getState().auth.token;
    const body = {
      phoneNumber: '',
    };

    axios
      .get(`${url}/users/otp`, body, {
        headers: {
          Authorization: 'Bearer' + token,
        },
      })
      .then((res) => {
        // const result = res.data;
        // console.log(result);

        dispatch({
          type: RESEND_VER_OTP_SUCCESS,
        });
      })
      .catch((error) => {
        // console.log('Verify phone: ', error);
        dispatch({
          type: RESEND_VER_OTP_FAIL,
          payload: 'Error!. Please try again.',
        });
      });
  };
};

export const signUp = (user) => {
  return (dispatch) => {
    dispatch({ type: SIGN_UP_START });
    axios
      .post(`${url}/users/register`, user)
      .then((response) => {
        const user = response.data;
        const token = user.token;
        const profile = user.profile;

        localStorage.setItem('_REG_TK', token);
        localStorage.setItem('_PRF', JSON.stringify(user.profile));
        dispatch({
          type: SIGN_UP_SUCCESS,
          payload: {
            profile,
            token,
          },
        });
      })
      .catch((error) => {
        // console.log('SignupError: ', error.response.data.message);
        dispatch({
          type: SIGN_UP_FAILURE,
          payload: {
            error: error.response.status,
            message:error.response.data.message,
            errorFound:true
          },
        });
      });
  };
};

export const logIn = (creds) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN_START,
    });
    axios
      .post(`${url}/users/login`, creds)
      .then((response) => {
        const user = response.data;
        localStorage.setItem('_REG_TK', user.token);
        localStorage.setItem('_PRF', JSON.stringify(user.profile));

        // console.log('LOG:', response);
        if(user.profile.isPhoneVerified === false){
          // dispatch({
          //   type: LOGIN_FAIL,
          //   payload: {
          //     error: "Please Verify your phone number",
          //     message: "Please Verify your phone number"
          //   },
          // });

          const dictReturned =   user.profile;
          dictReturned.isPhoneVerified =   true

          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              profile: dictReturned,
              token: user.token,
              username: user.profile.username,
            },
          });
          dispatch(fetchCartItems());
  

          // console.log("the verification is",user.profile)
          // window.location.href = "/auth/verify-phone-number";
          
        
        }else{
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              profile: user.profile,
              token: user.token,
              username: user.profile.username,
            },
          });
          dispatch(fetchCartItems());
        }
        
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_FAIL,
          payload: {
            error: error.response.data.message,
            message: error.response.data.message
          },
        });
        // if (error.response) {
        //   if (error.response.status === 409) {
        //     dispatch({
        //       type: LOGIN_FAIL,
        //       payload: {
        //         error: 'Invalid Login credentials',
        //       },
        //     });
        //   } else {
        //     dispatch({
        //       type: LOGIN_FAIL,
        //       payload: {
        //         error: 'An error occurred. Please try again.',
        //       },
        //     });
        //   }
        // } else if (error.request) {
        //   dispatch({
        //     type: LOGIN_FAIL,
        //     payload: {
        //       error: 'An error occurred. Please try again.',
        //     },
        //   });
        // } else {
        //   dispatch({
        //     type: LOGIN_FAIL,
        //     payload: {
        //       error: 'An error occurred. Please try again.',
        //     },
        //   });
        // }
      });
  };
};

export const signOut = () => {
  return (dispatch) => {
    dispatch({
      type: SIGN_OUT,
    });
  };
};

export const requestPwdReset = (phone) => {
  return (dispatch) => {
    const { PhoneNumber } = phone;
    sessionStorage.setItem('req_id', PhoneNumber);

    dispatch({
      type: PASSWORD_RESET_REQUEST,
    });

    axios
      .post(`${url}/users/forgot`, phone)
      .then((res) => {
        dispatch({
          type: PASSWORD_RESET_REQUEST_SUCCESS,
          payload: {
            code: res.data.otp,
            phone: PhoneNumber,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: PASSWORD_RESET_REQUEST_FAIL,
          payload: error,
        });
      });
  };
};

export const verifyPwdCode = (code) => {
  return (dispatch) => {
    axios
      .get(`${url}/users/reset/${code}`)
      .then((res) => {
        if (res.data.valid) {
          dispatch({
            type: VERIFY_PASSWORD_RESET_CODE_SUCCESS,
          });
        } else {
          dispatch({
            type: VERIFY_PASSWORD_RESET_CODE_FAIL,
            payload: 'error!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: VERIFY_PASSWORD_RESET_CODE_FAIL,
          payload: error,
        });
      });
  };
};

export const resetPwd = (newPassword) => {
  //   { 200
  //     "message": "Token could be expired/missing. Please try again"
  // }

  //   { 200
  //     "message": "password reset successful"
  // }
  //   { 400
  //     "status": "error",
  //     "message": "\"Token\" must be a number"
  // }
  return (dispatch, getState) => {
    const token = getState().auth.pwdResetCode;
    const { Password } = newPassword;
    const body = {
      Token: token,
      Password: Password,
    };
    axios
      .patch(`${url}/users/reset`, body)
      .then((res) => {
        if (res.data.message === 'password reset successful') {
          dispatch({
            type: RESET_PASSWORD_SUCCESS,
            // payload: res.data.message,
          });
        } else {
          dispatch({
            type: RESET_PASSWORD_FAIL,
            payload: res.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: RESET_PASSWORD_FAIL,
          payload: error.message,
        });
      });
  };
};

export const getUserDetails = () => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_USER_DETAILS_START,
    });
    const token = getState().auth.token;
    const profile = getState().auth.profile;

    if (profile) {
      const userId = profile.id;
      axios
        .get(`${url}/users/${userId}`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response) => {
          const user = response.data.user[0];

          dispatch({
            type: GET_USER_DETAILS_SUCCESS,
            payload: user,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_USER_DETAILS_FAIL,
            payload: error,
          });
        });
    } else {
      return;
    }
  };
};

export const updateUserPreference = (category) => {
  return (dispatch, getState) => {
    const profile = getState().auth.profile;
    const userId = profile.id;
    // const token = getState().auth.token;
    const { Category } = category;
    axios
      .patch(`${url}/users/${userId}/preference`)
      .then(() => {
        localStorage.setItem('USER_PREF', Category);
      })
      .catch((error) => {});
  };
};

export const checkWaitlist = (phone) => {
  return (dispatch, getState) => {
    dispatch({
      type: CHECK_WAITLIST_START,
    });
    axios
      .get(`${url}/users/waitlist/${phone}`)
      .then((res) => {
        const user = res.data.user;
        dispatch({
          type: CHECK_WAITLIST_SUCCESS,
          payload: user,
        });
      })
      .catch((error) => {
        dispatch({
          type: CHECK_WAITLIST_FAIL,
          payload: error,
        });
      });
  };
};

export const addToWaitlist = (user) => {
  const { phoneNumber } = user;
  return (dispatch, getState) => {
    dispatch(checkWaitlist(phoneNumber));
    const isOnWaitlist = getState().auth.isOnWaitlist;

    if (isOnWaitlist === 'false') {
      axios
        .post(`${url}/users/waitlist`, user)
        .then((response) => {
          dispatch({
            type: ADD_WAITLIST_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch({
            type: ADD_WAITLIST_FAIL,
            payload: error,
          });
        });
    }
  };
};
