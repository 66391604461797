/* 
  This component page renders all the compoents of the Checkout page. 
*/
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSocket from '../../hooks/useSocket';
import YourBag from '../../mobile/components/headers/YourBag';
import { makeStyles, withStyles } from '@material-ui/core/styles';


import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { FaShoppingBag, FaMoneyBillWave } from 'react-icons/fa';
import { FiPackage } from 'react-icons/fi';
import { Box } from '@material-ui/core';

import StepConnector from '@material-ui/core/StepConnector';
import  {ExpandMore}  from '@material-ui/icons';


import CheckoutTerms from '../../parts/Cart/CheckoutTerms';
import RenderPaymentOptions from '../../parts/Cart/RenderPaymentOptions';
import RenderCartItems from '../../parts/Cart/RenderCartItems';
import RenderDeliveryOptions from '../../parts/Checkout/RenderDeliveryOptions';
import OrderSuccessfulModal from '../../parts/Checkout/OrderSuccessfulModal';
import { viewContent } from '../../_helpers/facebookpixel/PixelEvents';
import IconButton from '@material-ui/core/IconButton';

import  {Cancel}  from '@material-ui/icons';
import ImageV  from '../../assets/images/bg-main2.jpg';
import CartItems from '../../components/CartItems';
import CheckOutPageMobile from '../../mobile/pages/CheckOutPageMobile';


// import CancelIcon from '@material-ui/icons-material/Cancel';
// import DeleteIcon from '@mui/icons-material/Delete';


import {
  setDeliveryOption,
  getDeliveryOptions,
  getsavedDeliveryAddress,
  getMtaaniRegions,
} from '../../redux/checkout/checkoutActions';
import VoucherCode from '../../parts/Cart/VoucherCode';

const useStyles = makeStyles((theme) => ({
  ctn: {
    paddingTop: '32px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  section1: {
    padding: '16px 0 0 32px',
  },
  section2: {
    padding: '24px 8px',
  },
  pageTitle: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '1.5rem',
    textTransform: 'capitalize',
    fontWeight: 700,
  },

  stepContent: {
    borderLeft: '2px solid #E8E8E8',
    marginTop: 0,
    paddingRight: 0,
  },

  iconSpace: {
    marginRight: '4px',
  },
  stepComplete: {
    background: '#4A4062',
    color: '#FFFFFF',
    padding: '16px ',
    borderRadius: '4px',
  },
  stepIncomplete: {
    background: '#EBEBEB',
    color: '#525252',
    padding: '16px ',
    borderRadius: '4px',
  },
  customStepIcon: {
    color: '#E8E8E8',
    '&.MuiStepIcon-active': {
      color: '#3EA636',
    },
    '&.MuiStepIcon-completed': {
      color: '#3EA636',
    },
  },
  stepperRoot: {
    paddingTop: 0,
  },
  stepLabel: {
    // color: '#525252',
    background: '#F8F8F8',
    color: '#707071',
    padding: '16px ',
    borderRadius: '4px',
    '&.MuiStepLabel-active': {
      color: '#525252',
      background: '#EBEBEB',
    },
    '&.MuiStepLabel-completed': {
      background: '#4A4062',
      color: '#FFFFFF',
    },
  },
  contentCollapse: {
    background: '#FAF8FF',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  // temp
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },

  mobileRoot:{
    width:"100%",
    height:"55px",
    background:"#D81E5B",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    borderRadius:"0px"
  },
  mobileBox1:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    fontSize:"16px",
    width:"60%"
  },
  mobileBox2:{
    width:"40%"
  },
  cancelIcon:{
    color:"#fff",
    marginLeft:"10px"
  },
  bagText:{
    color:"#fff",
    font: "normal normal bold 16px/19px Montserrat",
    padding:"15px"

  },
  displayF:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    borderRadius:"0px"
   
    
    
  },
  displayF1:{
    display:"flex",
    alignItems:"right",

      
  },
  displayF12:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    marginTop:"10px",
    marginBottom:"15px"
  },
  tterte:{
    fontSize:"13px",
    color:"#111111",
    marginLeft:"10px",
    font:"Lato",
    padding:"15px"

  },
  ttrw:{
    color:"#491DFF",
    fontSize:"12px",
    marginRight:"10px",
    padding:"15px"
  },
  yyy:{
    width:"40px",
    height:"40px",
    borderRadius:"100%",
    marginLeft:"10px",
    marginTop:"10px",
    marginBottom:"10px"
  },
  rrwww:{
    marginLeft:"10px",
    color:"#4A4062",
    fontSize:"14px",
    padding:"15px",
    textAlign:"center"
  },
  rrwww1:{
    color:"4A4062",
    marginTop:"10px"
    
  },
  rqqt:{
    width:"150px",
    height:"150px",
  
  },
  v1:{
    fontSize:"12px",
    color:"#111111",
    font:"normal normal bold 9px/19px Lato",
    marginLeft:"13px",
    
  },

  v3:{
    fontSize:"12px",
    color:"#111111",
    font:"normal normal bold 9px/19px Lato",
    marginLeft:"13px",
    
  },

  v4:{
    fontSize:"12px",
    color:"#111111",
    font:"normal normal bold 9px/19px Lato",
    marginRight:"13px",
    
  },
  b1:{
    width:"150px"
  },
  v2:{
    fontSize:"12px",
    color:"#707070",
    marginRight:"10px",
    font:"normal normal normal 9px/19px Lato",
   
  },
  divHl:{
    width:"100%",
    height:"2px",
    marginTop:"10px",
    background:"#F5F5F5",
    marginLeft:"5px",
    marginRight:"5px"
  },
  btnCheckout:{
    width:"90%",
    padding:"15px",
    color:"#fff",
    background:" #4900FF",
    marginTop:"15px",
    marginLeft:"15px",
    marginRight:"15px",
    marginBottom:"18px",
    textTransform:"none",
    font:"bold normal normal 19px Lato",
  },
  ppclas:{
    width:"150px",
    margin:"10px",
    borderRadius:"5px"
  }
}));

const CustomConnector = withStyles({
  active: {
    '& $line': {
      borderColor: '#3EA636',
      borderWidth: '2px',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3EA636',
      borderWidth: '2px',
    },
  },
  line: {
    borderColor: '#E8E8E8',
    borderWidth: '2px',
  },
  vogery:{
    overflowY:"auto"
  }
  
})(StepConnector);

function getSteps() {
  return ['YOUR SHOPPING BAG', 'DELIVERY DETAILS', 'PAYMENT CONFIRMATION'];
}

function getLabelIcon(step) {
  switch (step) {
    case 0:
      return <FaShoppingBag />;
    case 1:
      return <FiPackage />;
    case 2:
      return <FaMoneyBillWave />;
    default:
      return 'Unknown step';
  }
}

const pageTitle = 'Checkout';

const mapState = ({ checkout , cart }) => ({
  delDetailsLoading: checkout.delDetailsLoading,
  delDetails: checkout.delDetails,
  delDetailsErr: checkout.delDetailsErr,
  cartItemCheckout : cart.cartItems,
  shoppingBagItems: cart.shoppingBagItems,
});
const CheckoutPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;



  const {  cartItemCheckout } = useSelector(mapState);

  console.log("cart items checkout ", cartItemCheckout)


  const { delDetailsLoading, delDetails, delDetailsErr } = useSelector(
    mapState
  );

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const [openOrderSuccessFeedback, setOpenOrderSuccessFeedback] = useState(
    false
  );
  const { socket, disconnectSocket } = useSocket();

  console.log("checkout_info",delDetails)
  // useEffect(() => {
  //   dispatch(setDeliveryOption());
  //   return () => {};
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getDeliveryOptions());
    return () => {};
  }, [dispatch]);

  /** Get previously used delivery addresses */
  useEffect(() => {
    dispatch(getsavedDeliveryAddress());
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMtaaniRegions());
    return () => {};
  }, [dispatch]);

  useEffect(()=>{
viewContent("Checkout Page")
  },[])

  const handleOpenOrderSuccessFeedback = () => {
    setOpenOrderSuccessFeedback(true);
  };
  const handleCloseOrderSuccessFeedback = () => {
    setOpenOrderSuccessFeedback(false);
  };
  const [openOrderSuccessfulModal, setopenOrderSuccessfulModal] = useState(
    false
  );

  const handleCloseOrderSuccessfulModal = () => {
    setopenOrderSuccessfulModal(false);
  };

  useEffect(() => {
    if (socket) {
      socket.on('order-confirmed', (msg) => {
        // console.log('PAYMENT ACCEPTED', msg);
        // setopenPaymentConfirmation(false);
        setopenOrderSuccessfulModal(true);
      });
    }

    return () => {
      disconnectSocket();
    };
  }, [socket]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <RenderCartItems handleNext={handleNext} />;
      case 1:
        // return <DeliveryDetails handleNext={handleNext} />;
        return <RenderDeliveryOptions openNextTab={handleNext} />;

      case 2:
        return <RenderPaymentOptions />;
      default:
        return 'Unknown step';
    }
  }

  // return (
  //   <section>
  //     <Container maxWidth="lg" className={classes.ctn}>
  //       <Paper elevation={0}>
  //         <Grid container>
  //           <Grid item xs={12} className={classes.section1}>
  //             <Typography variant="h2" className={classes.pageTitle}>
  //               {pageTitle}
  //             </Typography>
  //           </Grid>
  //           <Grid item xs={12} md={8} className={classes.section2}>
  //             <div>
  //               <Stepper
  //                 activeStep={activeStep}
  //                 orientation="vertical"
  //                 connector={<CustomConnector />}
  //                 classes={{ root: classes.stepperRoot }}
  //               >
  //                 {steps.map((label, index) => (
  //                   <Step
  //                     key={label}
  //                     active={
  //                       index === activeStep - 2 ||
  //                       index === activeStep - 1 ||
  //                       index === activeStep
  //                     }
  //                   >
  //                     <StepLabel
  //                       classes={{
  //                         label: classes.stepLabel,
  //                       }}
  //                       StepIconProps={{
  //                         classes: { root: classes.customStepIcon },
  //                       }}
  //                     >
  //                       <div
  //                         style={{
  //                           display: 'flex',
  //                           justifyContent: 'space-between',
  //                         }}
  //                       >
  //                         <div>
  //                           {getLabelIcon(index)} {label}
  //                         </div>
  //                         {/* <div>edit</div> */}
  //                       </div>
  //                     </StepLabel>
  //                     <StepContent
  //                       classes={{
  //                         root: classes.stepContent,
  //                         transition: classes.contentCollapse,
  //                       }}
  //                     >
  //                       <div>{getStepContent(index)}</div>
  //                       {/* <div className={classes.actionsContainer}>
  //                         <div>
  //                           <Button className={classes.button}>Test</Button>
  //                           <Button
  //                             disabled={activeStep === 0}
  //                             onClick={handleBack}
  //                             className={classes.button}
  //                           >
  //                             Back
  //                           </Button>
  //                           <Button
  //                             variant="contained"
  //                             color="primary"
  //                             onClick={handleNext}
  //                             className={classes.button}
  //                           >
  //                             {activeStep === steps.length - 1
  //                               ? 'Finish'
  //                               : 'Next'}
  //                           </Button>
  //                         </div>
  //                       </div> */}
  //                     </StepContent>
  //                   </Step>
  //                 ))}
  //               </Stepper>
  //             </div>
  //           </Grid>
  //           <Grid item xs={12} md={4} className={classes.section2}>
  //             <VoucherCode />
  //             {/* <CheckoutTerms /> */}

  //             {/* <OrderSummary /> */}
  //           </Grid>
  //         </Grid>
  //       </Paper>
  //     </Container>
  //     <OrderSuccessfulModal
  //       open={openOrderSuccessfulModal}
  //       handleClose={handleCloseOrderSuccessfulModal}
  //     />
  //   </section>
  // );

  return (
    <section>
      <Container maxWidth="lg" className={classes.ctn}>
        <Paper elevation={0}>
          <Grid container>
            <Grid item xs={12} className={classes.section1}>
              <Typography variant="h2" className={classes.pageTitle}>
                {pageTitle}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} className={classes.section2}>
              <div>
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  connector={<CustomConnector />}
                  classes={{ root: classes.stepperRoot }}
                >
                  {steps.map((label, index) => (
                    <Step
                      key={label}
                      active={
                        index === activeStep - 2 ||
                        index === activeStep - 1 ||
                        index === activeStep
                      }
                    >
                      <StepLabel
                        classes={{
                          label: classes.stepLabel,
                        }}
                        StepIconProps={{
                          classes: { root: classes.customStepIcon },
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div>
                            {getLabelIcon(index)} {label}
                          </div>
                          {/* <div>edit</div> */}
                        </div>
                      </StepLabel>
                      <StepContent
                        classes={{
                          root: classes.stepContent,
                          transition: classes.contentCollapse,
                        }}
                      >
                        <div>{getStepContent(index)}</div>
                        {/* <div className={classes.actionsContainer}>
                          <div>
                            <Button className={classes.button}>Test</Button>
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              className={classes.button}
                            >
                              Back
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              className={classes.button}
                            >
                              {activeStep === steps.length - 1
                                ? 'Finish'
                                : 'Next'}
                            </Button>
                          </div>
                        </div> */}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </Grid>
            <Grid item xs={12} md={4} className={classes.section2}>
              <VoucherCode />
              {/* <CheckoutTerms /> */}

              {/* <OrderSummary /> */}
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <OrderSuccessfulModal
        open={openOrderSuccessfulModal}
        handleClose={handleCloseOrderSuccessfulModal}
      />
    </section>
  );
  // if(isMobile){

  //   return (
  //     <CheckOutPageMobile></CheckOutPageMobile>
  //   )

  // }else{
  //   // return (
  //   //   <section>
  //   //     <Container maxWidth="lg" className={classes.ctn}>
  //   //       <Paper elevation={0}>
  //   //         <Grid container>
  //   //           <Grid item xs={12} className={classes.section1}>
  //   //             <Typography variant="h2" className={classes.pageTitle}>
  //   //               {pageTitle}
  //   //             </Typography>
  //   //           </Grid>
  //   //           <Grid item xs={12} md={8} className={classes.section2}>
  //   //             <div>
  //   //               <Stepper
  //   //                 activeStep={activeStep}
  //   //                 orientation="vertical"
  //   //                 connector={<CustomConnector />}
  //   //                 classes={{ root: classes.stepperRoot }}
  //   //               >
  //   //                 {steps.map((label, index) => (
  //   //                   <Step
  //   //                     key={label}
  //   //                     active={
  //   //                       index === activeStep - 2 ||
  //   //                       index === activeStep - 1 ||
  //   //                       index === activeStep
  //   //                     }
  //   //                   >
  //   //                     <StepLabel
  //   //                       classes={{
  //   //                         label: classes.stepLabel,
  //   //                       }}
  //   //                       StepIconProps={{
  //   //                         classes: { root: classes.customStepIcon },
  //   //                       }}
  //   //                     >
  //   //                       <div
  //   //                         style={{
  //   //                           display: 'flex',
  //   //                           justifyContent: 'space-between',
  //   //                         }}
  //   //                       >
  //   //                         <div>
  //   //                           {getLabelIcon(index)} {label}
  //   //                         </div>
  //   //                         {/* <div>edit</div> */}
  //   //                       </div>
  //   //                     </StepLabel>
  //   //                     <StepContent
  //   //                       classes={{
  //   //                         root: classes.stepContent,
  //   //                         transition: classes.contentCollapse,
  //   //                       }}
  //   //                     >
  //   //                       <div>{getStepContent(index)}</div>
  //   //                       {/* <div className={classes.actionsContainer}>
  //   //                         <div>
  //   //                           <Button className={classes.button}>Test</Button>
  //   //                           <Button
  //   //                             disabled={activeStep === 0}
  //   //                             onClick={handleBack}
  //   //                             className={classes.button}
  //   //                           >
  //   //                             Back
  //   //                           </Button>
  //   //                           <Button
  //   //                             variant="contained"
  //   //                             color="primary"
  //   //                             onClick={handleNext}
  //   //                             className={classes.button}
  //   //                           >
  //   //                             {activeStep === steps.length - 1
  //   //                               ? 'Finish'
  //   //                               : 'Next'}
  //   //                           </Button>
  //   //                         </div>
  //   //                       </div> */}
  //   //                     </StepContent>
  //   //                   </Step>
  //   //                 ))}
  //   //               </Stepper>
  //   //             </div>
  //   //           </Grid>
  //   //           <Grid item xs={12} md={4} className={classes.section2}>
  //   //             <VoucherCode />
  //   //             {/* <CheckoutTerms /> */}
  
  //   //             {/* <OrderSummary /> */}
  //   //           </Grid>
  //   //         </Grid>
  //   //       </Paper>
  //   //     </Container>
  //   //     <OrderSuccessfulModal
  //   //       open={openOrderSuccessfulModal}
  //   //       handleClose={handleCloseOrderSuccessfulModal}
  //   //     />
  //   //   </section>
  //   // );


  //   // to do

  //     return (
  //   <section>
  //     <Container maxWidth="lg" className={classes.ctn}>
  //       <Paper elevation={0}>
  //         <Grid container>
  //           <Grid item xs={12} className={classes.section1}>
  //             <Typography variant="h2" className={classes.pageTitle}>
  //               {pageTitle}
  //             </Typography>
  //           </Grid>
  //           <Grid item xs={12} md={8} className={classes.section2}>
  //             <div>
  //               <Stepper
  //                 activeStep={activeStep}
  //                 orientation="vertical"
  //                 connector={<CustomConnector />}
  //                 classes={{ root: classes.stepperRoot }}
  //               >
  //                 {steps.map((label, index) => (
  //                   <Step
  //                     key={label}
  //                     active={
  //                       index === activeStep - 2 ||
  //                       index === activeStep - 1 ||
  //                       index === activeStep
  //                     }
  //                   >
  //                     <StepLabel
  //                       classes={{
  //                         label: classes.stepLabel,
  //                       }}
  //                       StepIconProps={{
  //                         classes: { root: classes.customStepIcon },
  //                       }}
  //                     >
  //                       <div
  //                         style={{
  //                           display: 'flex',
  //                           justifyContent: 'space-between',
  //                         }}
  //                       >
  //                         <div>
  //                           {getLabelIcon(index)} {label}
  //                         </div>
  //                         {/* <div>edit</div> */}
  //                       </div>
  //                     </StepLabel>
  //                     <StepContent
  //                       classes={{
  //                         root: classes.stepContent,
  //                         transition: classes.contentCollapse,
  //                       }}
  //                     >
  //                       <div>{getStepContent(index)}</div>
  //                       {/* <div className={classes.actionsContainer}>
  //                         <div>
  //                           <Button className={classes.button}>Test</Button>
  //                           <Button
  //                             disabled={activeStep === 0}
  //                             onClick={handleBack}
  //                             className={classes.button}
  //                           >
  //                             Back
  //                           </Button>
  //                           <Button
  //                             variant="contained"
  //                             color="primary"
  //                             onClick={handleNext}
  //                             className={classes.button}
  //                           >
  //                             {activeStep === steps.length - 1
  //                               ? 'Finish'
  //                               : 'Next'}
  //                           </Button>
  //                         </div>
  //                       </div> */}
  //                     </StepContent>
  //                   </Step>
  //                 ))}
  //               </Stepper>
  //             </div>
  //           </Grid>
  //           <Grid item xs={12} md={4} className={classes.section2}>
  //             <VoucherCode />
  //             {/* <CheckoutTerms /> */}

  //             {/* <OrderSummary /> */}
  //           </Grid>
  //         </Grid>
  //       </Paper>
  //     </Container>
  //     <OrderSuccessfulModal
  //       open={openOrderSuccessfulModal}
  //       handleClose={handleCloseOrderSuccessfulModal}
  //     />
  //   </section>
  // );
  // }

  
};

export default CheckoutPage;
