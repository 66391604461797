import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#d81e5b',
    color: '#ffffff',
    border: '1px solid #000000',
    width: '42px',
    height: '42px',
    fontFamily: 'Lato',
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
}));

const UserAvatar = ({ initials }) => {
  const classes = useStyles();

  return <Avatar className={classes.root}>{initials}</Avatar>;
};

UserAvatar.propTypes = {
  initials: PropTypes.string.isRequired,
};
export default UserAvatar;
