import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { FiPackage } from 'react-icons/fi';
import { FaShoppingBag } from 'react-icons/fa';

import BaseBtnFilled from '../../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../../components/BaseBtnOutlined';
import OrderImage from './OrderImage';
import NotificationSeparator from './NotificationSeparator';
import SelectDropoffStore from '../../Checkout/SelectDropoffStore';

const SERVER = process.env.REACT_APP_SERVER;

const MerchantConfirmOrder = ({
  buyerName,
  orderTotal,
  shippingTimeframe,
  timestamp,
  prodName,
  prodSize,
  prodCondition,
  prodPrice,
  prodImage,
  orderID,
  authToken,
}) => {
  const [openDropoff, setOpenDropoff] = useState(false);

  const handleCloseDropOff = () => {
    setOpenDropoff(false);
  };

  const handleOpenDropoff = () => {
    setOpenDropoff(true);
  };

  const handleCollect = (orderID, authToken) => {
    axios({
      method: 'post',
      url: `${SERVER}/orders/${orderID}/express`,
      headers: {
        Authorization: 'Bearer ' + authToken,
      },
    })
      .then((res) => {
        const result = res.data;
        console.log('EXPRESS', result);
      })
      .catch((error) => {
        console.log('EXPRESS ERR', error);
      });
  };

  return (
    <>
      <Box>
        <OrderImage
          prodName={prodName}
          prodSize={prodSize}
          prodCondition={prodCondition}
          prodPrice={prodPrice}
          prodImage={prodImage}
        />
        <NotificationSeparator />
        <Box display="flex">
          <Avatar style={{ background: '#45BC56', marginRight: '8px' }}>
            <FaShoppingBag style={{ color: '#ffffff' }} />
          </Avatar>
          <div className="notification-box">
            <p className="txt-time f-10">{timestamp}</p>
            <p className="txt-bold txt-blackTrue">Order Confirmed!</p>
            <p className="txt-gray5 f-14">
              <span className="txt-bold ">{buyerName}</span> confirmed this
              purchase. This payment will be marked as ‘Pending’ on your wallet
              until the item is successfully delivered.
            </p>
            <Box mt={2}>
              <p className="f-14 txt-gray5">
                Amount Paid: <span className="txt-bold">{orderTotal}</span>{' '}
              </p>

              <p className="f-14 txt-gray5">
                Shipping Timeframe:
                <span className="txt-bold">2 days</span>
              </p>
            </Box>
            <Box mt={2}>
              <p className="f-14 txt-gray5">
                To initiate the shipping process, select a drop-off agent or
                choose to have the item collected from your store below.
              </p>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Box>
                <BaseBtnOutlined
                  fullWidth
                  onClick={() => handleCollect(orderID, authToken)}
                >
                  Ready for Pickup
                </BaseBtnOutlined>
              </Box>
              <Box>
                <BaseBtnFilled fullWidth onClick={() => handleOpenDropoff()}>
                  Select Drop-off Agent
                </BaseBtnFilled>
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
      <SelectDropoffStore
        open={openDropoff}
        handleClose={handleCloseDropOff}
        orderId={orderID}
      />
    </>
  );
};

MerchantConfirmOrder.propTypes = {
  buyerName: PropTypes.string.isRequired,
  orderTotal: PropTypes.string.isRequired,
  shippingTimeframe: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  prodName: PropTypes.string.isRequired,
  prodSize: PropTypes.string.isRequired,
  prodPrice: PropTypes.string.isRequired,
  prodCondition: PropTypes.string.isRequired,
  prodImage: PropTypes.string.isRequired,
  orderID: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
};
export default MerchantConfirmOrder;
