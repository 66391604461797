import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import BaseBtn from './BaseBtn';

const useStyles = makeStyles({
  root: {
    width: '696px',
  },

  layout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '64px 0',
  },
  title: {
    color: '#4a4062',
    marginBottom: '24px',
  },
  btnFilled: {
    marginTop: '24px',
    backgroundColor: '#4900ff',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFFFFF',
    },
    width: '240px',
  },
  btnTxt: {
    marginTop: '16px',
    textTransform: 'capitalize',
    color: '#111111',
    textDecoration: 'underline',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    fontSize: '16px',
  },
});

const DialogSuccess = ({
  handleClose,
  open,
  title,
  img,
  btnLinkTo,
  btnTxt,
  handleMaybeLater,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
    >
      {/* <Card className={classes.root}> */}
      <div className={classes.layout}>
        <h3 className={classes.title}>{title}</h3>
        <img src={img} alt="" />
        <BaseBtn className={classes.btnFilled} component={Link} to={btnLinkTo}>
          <span style={{ color: '#ffffff' }}>{btnTxt}</span>
        </BaseBtn>
        <Button
          variant="text"
          className={classes.btnTxt}
          onClick={handleMaybeLater}
        >
          Maybe Later
        </Button>
      </div>
      {/* </Card> */}
    </Dialog>
  );
};

DialogSuccess.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  btnTxt: PropTypes.string.isRequired,
  btnLinkTo: PropTypes.string.isRequired,
  handleMaybeLater: PropTypes.func,
};

export default DialogSuccess;
