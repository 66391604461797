import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';

import PlacesAutocomplete from '../../components/PlacesAutocomplete';
import BaseInput2 from '../../components/BaseInput2';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import BaseFailDialog from '../../components/BaseFailDialog';

const url = process.env.REACT_APP_SERVER;
const useStyles = makeStyles({
  root: { borderRadius: '10px' },
  section1: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  section2: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  section3: {
    justifyContent: 'space-between',
  },
  btn: {
    width: '144px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '1.17rem',
    fontWeight: 'bold',
    color: ' #111',
  },
});

const useStyles2 = makeStyles((theme) => ({
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1.5em',
    width: '1.5em',
    color: '#fff',
    backgroundColor: '#4900FF',
    border: '2px solid white',
    borderRadius: '50%',
  },

  icon: {
    color: '#fff',
    fontSize: '12px',
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    background: '#813b0b',
  },
}));
const AvatarField = ({ image }) => {
  const classes = useStyles2();

  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={
        <div className={classes.iconWrap}>
          <CheckIcon className={classes.icon} />
        </div>
      }
    >
      <Avatar alt="Seller avatar" src={image} className={classes.avatar} />{' '}
    </Badge>
  );
};

AvatarField.propTypes = {
  image: PropTypes.string,
  avatarStyles: PropTypes.string,
};

const validationSchema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  userName: yup.string().required('Please fill out this field'),
  phoneNumber: yup
    .string()
    .required('Please enter phone number')
    .matches(/^((01)|(07))[0-9]+$/, 'Invalid format! Please try : 07XX...')
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number'),
  building: yup.string().required('Please fill out this field'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Please enter email'),
});

// id:"48fe5e51-fc9b-4955-a718-d4192ead6d13"
// image:null
// description:null
// mpesaNumber:null
// isPaymentVerfied:false
// verificationTimestamp:null
// businessName:"graceM"
// phoneNumber:"0729695435"
// building:"Fig Tree"
// location:"Ngara Rd, Nairobi City"
// placeId:"ChIJtzDwhSkXLxgRcyw0WdYW8RU"
// status:"INCOMPLETE"
// modifiedOn:null
// createdOn:"2022-02-09T07:27:03.000Z"

const mapState = ({ merchant, auth }) => ({
  storeDetails: merchant.storeDetailsPrivate,
  loading: merchant.storeDetailsPrivateLoading,
  token: auth.token,
  profile: auth.profile,
});
const UserProfile = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  // 185.141.63.56:3051/users/:uuid

  const [makeEditable, setMakeEditable] = useState(false);

  const { storeDetails, loading, token, profile } = useSelector(mapState);

  const [openFailDialog, setopenFailDialog] = useState(false);
  const handleCloseFailDialog = () => {
    setopenFailDialog(false);
  };

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userImg, setUserImg] = useState(null);
  const [userId, setUserId] = useState('');
  const [email, setemail] = useState('');

  // useEffect(() => {
  //   if (storeDetails) {
  //     setStoreImg(storeDetails.image);
  //     setBusinessName(storeDetails.businessName);
  //     setDescription(storeDetails.description);
  //     // setlocationName(storeDetails.location);
  //     setBuilding(storeDetails.building);
  //     setPhoneNumber(storeDetails.phoneNumber);
  //     setlocation(storeDetails.location);
  //     setstoreId(storeDetails.id);
  //   }

  //   return () => {};
  // }, [storeDetails]);

  const fetchUser = () => {
    axios
      .get(`${url}/users/${profile.id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        const result = res.data;
        const user = result.user[0];
        setUserId(user.id);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setPhoneNumber(user.phone);
        setUserName(user.username);
        setUserImg(user.image);
        setemail(user.email);

        // console.log('user', user);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  useEffect(() => {
    fetchUser();

    return () => {};
  }, []);

  // const formik = useFormik({
  //   initialValues: {
  //     businessName: businessName,
  //     phoneNumber: phoneNumber,
  //     building: building,
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: (values) => {
  //     if (!location) {
  //       setlocationErr('Please fill out this field');
  //       return;
  //     }
  //     if (location) {
  //       setlocationErr('');
  //     }
  //     const storeDetails = {
  //       businessName: values.businessName,
  //       phoneNumber: values.phoneNumber,
  //       placeId: location.place_id,
  //       building: values.building,
  //     };
  //     console.log(storeDetails);

  //     // openSuccessDialog();
  //   },
  // });

  const handleCancel = () => {
    setMakeEditable(false);
  };

  const handleEdit = () => {
    const body = {
      FirstName: firstName,
      LastName: lastName,
      Username: userName,
      PhoneNumber: phoneNumber,
      EmailAddress: email,
      image: userImg,
    };

    // console.log(body);

    axios
      .put(`${url}/users/${userId}`, body, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        const results = response.data;
        // history.push('/my-shop');
        setMakeEditable(false);
        fetchUser();

        console.log('RES : ', results);
      })
      .catch((error) => {
        // console.log('RESERR : ', error);
        setopenFailDialog(true);
      });
  };

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title="Your profile"
          classes={{ root: classes.section1, title: classes.title }}
          action={
            <IconButton
              aria-label="settings"
              onClick={() => setMakeEditable(!makeEditable)}
            >
              <EditIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent className={classes.section2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <AvatarField />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <BaseInput2
                id="firstName"
                name="firstName"
                type="text"
                placeholder="First Name *"
                readOnly={!makeEditable}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BaseInput2
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Last Name *"
                readOnly={!makeEditable}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BaseInput2
                id="userName"
                name="userName"
                type="text"
                placeholder="Username *"
                readOnly={!makeEditable}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BaseInput2
                id="businessName"
                name="phoneNumber"
                type="text"
                placeholder="Phone Number * e.g 07XX..."
                value={phoneNumber}
                readOnly={!makeEditable}
                onChange={(e) => setPhoneNumber(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BaseInput2
                id="email"
                name="email"
                type="email"
                placeholder="Email *"
                value={email}
                readOnly={!makeEditable}
                onChange={(e) => setemail(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.section3}>
          <BaseBtnFilled
            className={classes.btn}
            onClick={handleEdit}
            disabled={!makeEditable}
          >
            Save
          </BaseBtnFilled>
          <BaseBtnOutlined className={classes.btn} onClick={handleCancel}>
            Cancel
          </BaseBtnOutlined>
        </CardActions>
      </Card>
      <BaseFailDialog
        open={openFailDialog}
        handleClose={handleCloseFailDialog}
      />
    </>
  );
};

export default UserProfile;
