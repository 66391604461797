import axios from 'axios';
import 'dotenv/config';

import {
  CHECKOUT,
  FETCH_DELIVERY_OPTIONS,
  SET_DELIVERY_OPTION,
  ADD_DELIVERY_ADDRESS_DETAILS_START,
  ADD_DELIVERY_ADDRESS_DETAILS_SUCCESS,
  ADD_DELIVERY_ADDRESS_DETAILS_FAIL,
  CREATE_ORDER_START,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  COMPLETE_PAYMENT_START,
  COMPLETE_PAYMENT_SUCCESS,
  COMPLETE_PAYMENT_FAIL,
  ADD_MPESA_NO,
  GET_SAVED_DELIVERY_ADDRESS_START,
  GET_SAVED_DELIVERY_ADDRESS_SUCCESS,
  GET_SAVED_DELIVERY_ADDRESS_FAIL,
  ADD_CARD_DETAILS,
  FETCH_MTAANI_REGIONS_START,
  FETCH_MTAANI_REGIONS_SUCCESS,
  FETCH_MTAANI_REGIONS_FAIL,
  FETCH_MTAANI_ZONES_START,
  FETCH_MTAANI_ZONES_SUCCESS,
  FETCH_MTAANI_ZONES_FAIL,
  FETCH_MTAANI_STORES_SUCCESS,
  FETCH_MTAANI_STORES_START,
  FETCH_MTAANI_STORES_FAIL,
  GET_DELIVERY_DETAILS_START,
  GET_DELIVERY_DETAILS_SUCCESS,
  GET_DELIVERY_DETAILS_FAIL,
  SET_PICKUP_STORE,
} from './checkoutTypes';

import { url } from '../../api';

const api_url = process.env.REACT_APP_SERVER;

export const getDeliveryOptions = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    axios
      .get(`${api_url}/delivery/options`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        const results = response.data.options;
        dispatch({
          type: FETCH_DELIVERY_OPTIONS,
          payload: results,
        });
      })
      .catch((error) => {});
  };
};

export const checkoutFromCart = (id) => {
  return (dispatch) => {
    localStorage.setItem('CHECKOUT', id);

    dispatch({
      type: CHECKOUT,
      payload: id,
    });
  };
};

export const setDeliveryOption = (val) => {
  const { deliveryCost, description, option, id } = val;
  const details = { deliveryCost, description, option, id };
  return (dispatch) => {
    localStorage.setItem('HAS_DD', false);
    localStorage.setItem('DELIVERY', JSON.stringify(details)); //set name, and cost of delivery

    dispatch({
      type: SET_DELIVERY_OPTION,
      payload: details,
    });
  };
};

export const addDeliveryAddress = (addressDetails) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: ADD_DELIVERY_ADDRESS_DETAILS_START,
    });
    axios
      .post(`${url}/delivery/`, addressDetails, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        const result = response.data;
        const delID = result.deliveryId;
        const delAddress = result.deliveryAddress;
        localStorage.setItem('DEL_ID', delID);
        dispatch({
          type: ADD_DELIVERY_ADDRESS_DETAILS_SUCCESS,
          payload: {
            delID,
            delAddress,
          },
        });
      })
      .catch((error) => {
        // console.log('DELIVERY ADDRESS ERROR', error);
        dispatch({
          type: ADD_DELIVERY_ADDRESS_DETAILS_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchDeliveryDetails = (id) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    dispatch({
      type: GET_DELIVERY_DETAILS_START,
    });

    axios
      .get(`${url}/delivery/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        const result = res.data;
        const address = result.details[0];

        dispatch({
          type: GET_DELIVERY_DETAILS_SUCCESS,
          payload: address,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_DELIVERY_DETAILS_FAIL,
          payload: error,
        });
      });
  };
};
export const createOrder = (orderDetails) => {
  // orderDetails : cartId, deliveryId, paymentType: MPESA|CARD

  return (dispatch, getState) => {
    const { paymentType } = orderDetails;
    localStorage.setItem('PAY_OPT', paymentType);
    const token = getState().auth.token;
    dispatch({
      type: CREATE_ORDER_START,
    });
    axios
      .post(`${url}/orders/`, orderDetails, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        const order = response.data.order;
        console.log('CREATE ORDER DEETS:', order);
        localStorage.setItem('OrderId',order.orderId)
        dispatch({
          type: CREATE_ORDER_SUCCESS,
          payload: {
            orderId: order.orderId,
            deliveryFee: order.deliveryFee,
            orderSubTotal: order.subTotal,
            orderItemsTotal: order.orderTotal,
            orderGrandTotal: order.total,
            paymentOption: paymentType,
          },
        });
      })
      .catch((error) => {
        // console.log('ord err', error);
        dispatch({
          type: CREATE_ORDER_FAIL,
          payload: error,
        });
      });
  };
};

export const getsavedDeliveryAddress = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: GET_SAVED_DELIVERY_ADDRESS_START,
    });

    axios
      .get(`${url}/delivery/`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        const addresses = res.data.details;
        dispatch({
          type: GET_SAVED_DELIVERY_ADDRESS_SUCCESS,
          payload: addresses,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_SAVED_DELIVERY_ADDRESS_FAIL,
          payload: error,
        });
      });
  };
};

export const selectSavedDeliveryAddress = (addressId) => {
  return (dispatch) => {
    dispatch(fetchDeliveryDetails(addressId));
    dispatch({
      type: ADD_DELIVERY_ADDRESS_DETAILS_SUCCESS,
      payload: {
        deliveryId: addressId,
      },
    });
    localStorage.setItem('DEL_ID', addressId);
  };
};

export const setMpesaDetails = (phoneNo) => {
  return (dispatch) => {
    dispatch({
      type: ADD_MPESA_NO,
      payload: phoneNo,
    });
  };
};

export const setCardDetails = (cardDetails) => {
  const {
    cardNumber,
    cvv,
    cardExpiry,
    firstName,
    middleName,
    lastName,
  } = cardDetails;
  return (dispatch) => {
    dispatch({
      type: ADD_CARD_DETAILS,
      payload: {
        cardNumber,
        cvv,
        cardExpiry,
        firstName,
        middleName,
        lastName,
      },
    });
  };
};

export const completePayment = (paymentDetails) => {
  // orderId, phoneNumber, paymentType, cardNumber, cvv, cardExpiry
  console.log("person details",paymentDetails)
  return (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: COMPLETE_PAYMENT_START,
    });
    axios
      .post(`${url}/orders/payment`, paymentDetails, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log("response",response.data)
        const dd =  response.data;
        if(dd.message  === "payment saved"){
          localStorage.setItem("paymentId",dd.order.paymentId)
          // TODO: handle background payment over mpesa
          // window.open(dd.order.paymentLink, '_blank');
        }
        dispatch({
          type: COMPLETE_PAYMENT_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: COMPLETE_PAYMENT_FAIL,
          payload: error,
        });
      });
  };
};

export const getMtaaniRegions = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: FETCH_MTAANI_REGIONS_START,
    });

    axios
      .get(`${api_url}/delivery/mtaani/regions`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        const results = res.data.regions; //  id: '' , region:""

        dispatch({
          type: FETCH_MTAANI_REGIONS_SUCCESS,
          payload: results,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_MTAANI_REGIONS_FAIL,
          payload: error,
        });
      });
  };
};

export const getMtaaniZones = (regionID) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: FETCH_MTAANI_ZONES_START,
    });

    axios
      .get(`${api_url}/delivery/mtaani/streets?id=${regionID}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        const results = res.data.streets; //  id: '' , street:""
        dispatch({
          type: FETCH_MTAANI_ZONES_SUCCESS,
          payload: results,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_MTAANI_ZONES_FAIL,
          payload: error,
        });
      });
  };
};

export const getMtaaniStores = (zoneID) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: FETCH_MTAANI_STORES_START,
    });

    axios
      .get(`${api_url}/delivery/mtaani/stores?id=${zoneID}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        const results = res.data.stores;
        //     {
        //   "operatingDays": "Monday - Saturday",
        //   "operatingHours": "9:00AM to 9:00PM",
        //   "address": "Vero Africa Curio, Ruaka, Limuru Rd",
        //   "contacts": "+254726794524",
        //   "deliveryCost": 150
        // }

        dispatch({
          type: FETCH_MTAANI_STORES_SUCCESS,
          payload: results,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_MTAANI_STORES_FAIL,
          payload: error,
        });
      });
  };
};

export const setPickupStore = (store) => {
  const { id } = store;
  return (dispatch) => {
    localStorage.setItem('P_STORE', JSON.stringify(store));
    dispatch({
      type: SET_PICKUP_STORE,
      payload: {
        store,
        delID: id,
      },
    });
  };
};
