import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { Link } from '@material-ui/core';

import webBanner from '../../assets/images/free-shipping.png';
import mobileBanner from '../../assets/images/free-shipping-mobile.png';

const useStyles = makeStyles((theme) => ({
  btn: {
    color: 'white',
    width: '96px',
    marginTop: '0.35rem',
  },
  htext: {
    fontFamily: ['Syncopate', 'sans-serif'].join(','),
    fontWeight: 'bold',
    marginBottom: '16px',
    fontSize: '1.85rem',
  },
  ptext: {
    color: '#525252',
    marginBottom: '16px',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '1rem',
  },
  img: {
    height: '100%',
    width: ' 100%',
    objectFit: 'cover',
  },
  textBox: {
    paddingLeft: '120px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
    },
  },
}));

const FreeShippingBanner = ({
  promoImg,
  promoTitle,
  bannerBackgroundColor,
  themeColor,
  link,
}) => {
  const classes = useStyles();
  return (
    <Link href = {link}>

  <Box>
      <Hidden xsDown>
        <img src={webBanner} style={{ width: '100%' }} />
      </Hidden>
      <Hidden smUp>
        {' '}
        <img src={mobileBanner} style={{ width: '100%' }} />
      </Hidden>
    </Box>
    </Link>
    
    // <div
    //   className="prom-banner-wrapper"
    //   style={{ backgroundColor: `${bannerBackgroundColor}` }}
    // >
    //   <div className="prom-banner-title">
    //     <Container maxWidth="sm" className={classes.textBox}>
    //       {/* <Box py={2}>
    //         <img src={logo} style={{ width: '24px' }} />
    //       </Box> */}
    //       <Typography
    //         variant="h4"
    //         className={classes.htext}
    //         style={{ color: `${themeColor}` }}
    //       >
    //         {promoTitle}
    //       </Typography>
    //       <Typography variant="subtitle1" className={classes.ptext}>
    //         The safest and fastest way to buy and sell second-hand clothing
    //         online
    //       </Typography>
    //     </Container>
    //   </div>
    //   <div className="prom-banner-media">
    //     <img src={promoImg} alt="welcome banner" className={classes.img} />
    //   </div>
    // </div>
  );
};
FreeShippingBanner.propTypes = {
  promoImg: PropTypes.string.isRequired,
  promoTitle: PropTypes.string.isRequired,
  bannerBackgroundColor: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
};

export default FreeShippingBanner;
