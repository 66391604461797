import { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import Rating from '@material-ui/lab/Rating';

import CloseIcon from '@material-ui/icons/Close';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import BaseBtnFilled from '../../components/BaseBtnFilled';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '32px 0',
  },

  btn: {
    padding: '4px',
  },
  btnSection: {
    width: '40%',
  },

  txtArea: {
    // '&input': {
    //   fontSize: '0.75rem',
    // },
    outlineColor: '#D7D5DF',
    backgroundColor: '#F5F2FE',
  },

  txtAreaSection: {
    width: '80%',
    margin: '0 auto',
    marginBottom: '32px',
  },

  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  closeIcon: {
    color: '#737373',
    fontSize: '1.25rem',
  },

  rating: {
    fontSize: '4rem',
  },
  ratingFilled: {
    color: '#FFCB1F',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const OrderFeedbackDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="ask-seller-dialog-title"
      fullWidth="sm"
    >
      <DialogTitle
        id="ask-seller-dialog-title"
        className={classes.title}
        disableTypography
      >
        <h3 className="txt-violet  txt-center">Let us know what you think</h3>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>

      <Divider style={{ marginBottom: '16px' }} />

      <DialogContent>
        <Box
          component="fieldset"
          mb={3}
          borderColor="transparent"
          className={classes.center}
        >
          <p className="f-14 mb-8 txt-black txt-center">
            How was your shopping experience?
          </p>
          <Rating
            classes={{ root: classes.rating, iconFilled: classes.ratingFilled }}
            name="shopping-experience-rating"
            size="large"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
          />
        </Box>

        <p className="f-14 mb-16 txt-black txt-center">
          Add an optional message about your experience below
        </p>

        <div className={classes.txtAreaSection}>
          <TextField
            classes={{
              root: classes.txtArea,
            }}
            id="message"
            fullWidth
            multiline
            rows={4}
            placeholder="Type your message…"
            variant="outlined"
          />
        </div>
        <Box className={classes.center} mb={3}>
          <div className={classes.btnSection}>
            <BaseBtnFilled fullWidth className={classes.btn}>
              Submit Feedback
            </BaseBtnFilled>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
OrderFeedbackDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default OrderFeedbackDialog;
