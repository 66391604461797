/* eslint-disable react/prop-types */

import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';

import OnboardingCardContent from '../../parts/Onboarding/OnboardingCardContent';
import BaseCard from '../../components/BaseCard';
import BaseBtn from '../../components/BaseBtn';

const useStyles = makeStyles({
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
  },

  signup: {
    backgroundColor: '#4900ff',
    border: ' 2px solid #4900ff',
    color: '#fff',
    marginBottom: '12px',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  login: {
    backgroundColor: 'inherit',
    border: ' 2px solid #4900ff',
    color: '#4900ff',
  },
});

const SignupLogin = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleSignup = () => {
    history.push('/auth/signup');
  };
  const handleLogin = () => {
    history.push('/auth/login/login-form');
  };

  return (
    <>
      <BaseCard>
        <OnboardingCardContent />
        <CardActions className={classes.cardActions}>
          <BaseBtn
            fullWidth
            className={classes.signup}
            // component={Link}
            // to={`${match.url}/signup`}
            // to="/auth/signup"
            onClick={handleSignup}
          >
            <span className="txt-white">Sign Up</span>
          </BaseBtn>

          <BaseBtn
            fullWidth
            className={classes.login}
            onClick={handleLogin}
            // component={Link}
            // to="/login"
          >
            <span style={{ color: '#4900ff' }}>log in</span>
          </BaseBtn>
        </CardActions>
      </BaseCard>
      {/* <Switch>
        <Route exact path={match.path + '/signup'} component={Signup} />
      </Switch> */}

      {/* <Route path={match.url + '/signup'} component={Carnitas} /> */}
    </>
  );
};

export default SignupLogin;
