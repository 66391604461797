import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';


import EmptyDisplay from './EmptyDisplay';
import ListProducts from '../../pages/Shops/ListProducts';

const displayMessage = 'isn’t selling anything yet.';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 224,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    borderRadius: '5px',
  },
  media: {
    // width: 320,
    maxHeight: '218px',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const MerchantListedProducts = ({ listedProducts, merchantName }) => {
  const classes = useStyles();
  const history = useHistory();

  console.log("list",listedProducts)

  const viewProductDetails = (id) => {
    history.push(`/catalog/${id}`);
  };
  return (
    <>
      {listedProducts.length ? (
        <div className="c5-grid">
          {listedProducts.map((item, i) => (
            <Card key={i} className={classes.root}
            
            onClick={() => viewProductDetails(item?.id)}>
              <CardMedia
                component="img"
                // image={item.images[0].secure_url}
                image={
                  item.images.length === 0 ? '' : item.images[0].secure_url
                }
                className={classes.media}
              />
              <CardContent className={classes.content}>
                <p className="txt-bold ">KSh {item.price}</p>
                <p className="txt-light">Size: {item.size}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      ) : (
        <EmptyDisplay
          merchantName={merchantName}
          displayMessage={displayMessage}
        />
      )}
    </>
  );
};

MerchantListedProducts.propTypes = {
  listedProducts: PropTypes.array.isRequired,
  merchantName: PropTypes.string.isRequired,
};

export default MerchantListedProducts;
