/* 
  This component  displays an error message if the merchant doesn't have 
  any listed products, likes or feedback on their profile page.
 */

import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

import BaseBtnFilled from '../../components/BaseBtnFilled';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  center: {
    textAlign: 'center',
  },
  text: {
    color: '#707070',
  },

  btn: {
    marginTop: '24px',
    width: '180px',
    padding: 4,
    fontWeight: 'bold',
  },
});
const EmptyDisplay = ({ merchantName, displayMessage }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleClick = () => {
    history.push('/');
  };
  return (
    <div className={classes.root}>
      <div className={classes.center}>
        {/* TODO: add the box icon/ image */}
        <p className="txt-lightColor">
          <span className="txt-bold">{merchantName} </span>
          <span className="txt-lowercase">{displayMessage}</span>
        </p>
        <BaseBtnFilled className={classes.btn} onClick={handleClick}>
          Go Back Home
        </BaseBtnFilled>
      </div>
    </div>
  );
};

EmptyDisplay.propTypes = {
  merchantName: PropTypes.string.isRequired,
  displayMessage: PropTypes.string.isRequired,
};

export default EmptyDisplay;
