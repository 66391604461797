import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Typography } from '@material-ui/core';

import { makeStyles,withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@material-ui/core/Avatar';
import { url } from '../../api';
import axios from 'axios';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseInput2 from '../../components/BaseInput2';
import flagIcon from '../../assets/images/flag_icon.jpg';

import mpesaLogo from '../../assets/images/mpesa-logo.svg';
import visaLogo from '../../assets/images/visa-logo.svg';
import mastercardLogo from '../../assets/images/mastercard-logo.svg';
import { depositToWallet } from '../../redux/wallet/walletActions';
import {Box,FormControl,FormLabel,TextField,Button} from '@material-ui/core';
import qs from 'qs'
import Alert from '@material-ui/lab/Alert';


const WithdrawBtn = withStyles({
  root: {
    borderRadius: 32,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    backgroundColor: '#4822FC',
    border: '2px solid #4900ff',
    color: '#FFFFFF',
    width: '220px',
    padding: '4px 6px',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
    '&:disabled': {
      backgroundColor: '#E2E2E2',
      color: '#717171',
      border: 'none',
    },
  },
})(Button);

const WithdrawBtn2 = withStyles({
  root: {
    borderRadius: 32,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    backgroundColor: '#E2E2E2',
  
    color: '#717171',
    width: '220px',
    padding: '4px 6px',
   
   
  },
})(Button);

const mobileValidationSchema = yup.object({
  phoneNumber: yup
    .string()
    .required('Please fill out this field')
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number'),

  amount: yup
    .string()
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .required('Please fill out this field'),
});
const cardValidationSchema = yup.object({
  cardAmount: yup
    .string()
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .required('Please fill out this field'),
  cardNumber: yup
    .string()
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .required('Please fill out this field'),
  cardCvv: yup
    .string()
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .required('Please fill out this field'),
  cardExpiry: yup.string().required('Please fill out this field'),
  firstName: yup.string().required('Please fill out this field'),
  lastName: yup.string().required('Please fill out this field'),
  middleName: yup.string().required('Please fill out this field'),
});

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },

  layout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  paper: {
    padding: '16px',
    height: 'fit-content',
    width: '100%',
    // maxWidth: '375px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
    margin: '8px 0',
  },
  btn: { width: '150px', fontWeight: 700, padding: 4 },
  active: {},
  inactive: {},
  flagIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  paragraphClass:{
    fontFamily:'Lato',
    fontStyle:'normal',
    fontWeight:500,
    fontSize:'20px',
    lineHeight:'24px',
    color:'#111111',
    marginBottom:15
    

  },
  mainHeader:{
    fontWeight:700,
    color: '#D81E5B',
    fontSize:'28px',
    lineHeight:'34px',
    fontFamily:'Montserrat',
    fontStyle:'normal'


  },
  title: {
    paddingBottom: 0,
    margin: '0 auto',
  },
  subtitle: {
    paddingTop: 0,
    margin: '0 auto',
    textTransform: 'capitalize',
  },
  selectMpesa:{
    fontWeight:600,
    fontSize:'14px',
    color:"#000000 !important",
    fontFamily:'Montserrat',
    fontStyle:'normal',
    lineHeight:'17px',
    marginTop:10,
    marginBottom:10

  },
  flexTwo:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    background: '#481CFB'

  },
  typographyStyle:{
    margin:10,
    fontStyle:'normal',
    fontFamily:'Montserrat',
    fontWeight:600,
    fontSize:'16px',
    lineHeight:'22px',
    color:'#fff'
  },
  inputStyle:{
    width:'100%'
  },
  TextFieldProperties:{
    width:"100%",
    background:'#F5F2FE',
   
    
  },
  radioIcon: {},
  flexDisplay:{
    display:"flex",
  
    margin:10,
    width:'100%'
   
  },
  radioCtn:{
    marginTop:20
  },
  ht:{
    marginLeft:2
  },
  bt2:{
    marginTop:20,
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    backgroundColor: '#4822FC',
    color:"#fff",
    borderRadius:32,
    "&:hover":{
      backgroundColor: '#4822FC',
      color:"#fff",
    }
  },
  alertb:{
    marginTop:20
  }
}));

const mapState = ({ wallet }) => ({
  depositInitiated: wallet.depositInitiated,
  depositInitiatedErr: wallet.depositInitiatedErr,
});

const WithdrawalPopUp = ({ open, handleClose }) => {
  const [mpesaConfirm, setMpesaConfirm] = useState(false);
  const [cardConfirm, setCardConfirm] = useState(false);

  const dispatch = useDispatch();

  const { depositInitiated, depositInitiatedErr } = useSelector(mapState);
 

  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState('mobile');
  const [mpesaNos,setMpesaNo] = useState([]);
  const [getN,setN] = useState("");
  const [mpeseSelected,setMpesaSelected] = useState("");
  const [fillALLDetails,setfillALlDetails] = useState("")
  const [loading,setLoading] = useState(false);
  const[verError,setverError] = useState(false);
  const[resendSuccess,setresendSuccess] = useState(false)

  // const[alelart,setAlert]= useState(false);
  

  const getT =  localStorage.getItem("_REG_TK")
   

  
  const handleChange = (e) =>{
    setMpesaSelected(e.target.value)
    console.log(e.target.value)
  }
  
  const handleSetN = (e) =>{
    setN(e.target.value)
  }

  const handleClick = () =>{
    setLoading(true)
    if(getN.length > 0 && mpeseSelected.length > 0){
      submitWithdrwal(String(getN),String(mpeseSelected))

    }else{
      setLoading(false)
      setverError(true)
      setresendSuccess(false)
      setfillALlDetails("Please fill all details")

    }
  }

  const submitWithdrwal = async (amount,number) =>{
    
    var qs = require('qs');
    var data = qs.stringify({
      'amount': amount,
      'phone': number
    });
    var config = {
      method: 'post',
      url: `${url}/wallets/withdraw`,
      headers: {
        'Authorization': `Bearer ${getT}`
       },
      data : data
    };

    axios(config)
    .then(function (response) {
      setresendSuccess(true)
      setverError(false)
      setfillALlDetails(response.data.message)
      console.log(JSON.stringify(response.data));
      setLoading(false)
    })
    .catch(function (error) {
      console.log(error);
      setverError(true)
      setresendSuccess(false)
      setfillALlDetails("An Error Occured")
      setLoading(false)
    });
  }

  const getNos = async ()=>{
  
      var config = {
        method: 'get',
        url: `${url}/users/mpesa`,
        headers: {
          'Authorization': `Bearer ${getT}`
         }
      };

      axios(config)
      .then(function (response) {
        const k =  response.data.numbers;

        const empty_list = []
        for(const i in k){
          const u =  k[i]
          empty_list.push(u.phoneNumber)
          
        }

        console.log("got nos",k.numbers)
        setMpesaNo(empty_list)
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getNos()
    
  
  
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle
        disableTypography
        id="wallet-withdrawal-title"
        classes={{ root: classes.title }}
      >
        
      </DialogTitle>
     
      <DialogContent>
      <Box>
          <Typography variant="h3" gutterBottom component="div"  className={classes.mainHeader}>
                    Withdraw funds
                  </Typography>

            <p className={classes.paragraphClass}>
              Enter an amount and select an M-Pesa number to withdraw funds to. Your funds will be deposited into your M-Pesa in less than an hour
            </p>
          
          </Box>


          <Box mb={3}>
            {/* <p className={classes.font4}>SELECT M-PESA NUMBER FOR WITHDRAWAL</p> */}
            <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend" className={classes.selectMpesa}>
                Enter Amount
              </FormLabel>
              <Paper elevation={0}>

                <Box  className={classes.flexDisplay}>
                  <Box className={classes.flexTwo}>
                  <Typography variant="h6" gutterBottom component="div" className={classes.typographyStyle}>
                    KES
                  </Typography>
                  </Box>
                  <TextField value={getN} onChange={handleSetN} className={classes.TextFieldProperties}  variant="outlined" />

                </Box>
              </Paper>
              <FormLabel component="legend" className={classes.selectMpesa}>
                Select M-Pesa Number
              </FormLabel>

              <RadioGroup
                aria-label="mpesa numbers"
                name="mpesaNo"
                // value={value}
                onChange={handleChange}
              >
                {mpesaNos.map((item, i) => (
                  <Paper key={i} className={classes.radioCtn}>
                    <FormControlLabel
                    className={classes.ht}
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  </Paper>
                ))}
              </RadioGroup>
              {verError &&(
                  <Alert className={classes.alertb} severity="error">{fillALLDetails}</Alert>
            )}
            
            {resendSuccess && (
                  <Alert className={classes.alertb} severity="success">{fillALLDetails}</Alert>
                )}

              {
                loading?(
                  <Button className={classes.bt2} >Loading ....</Button>
                ):(
                  <Button className={classes.bt2} onClick={handleClick}>Withdraw Funds</Button>
                )
              }
             

              {/* <WithdrawBtn onClick={handleClick}>Withdraw Funds</WithdrawBtn> */}
            </FormControl>
           
           
            
          </Box>
          {/* {
            loading?(<WithdrawBtn2>Loading...</WithdrawBtn2>):(
              
                
              // <WithdrawBtn onClick={handleClick}>Withdraw Funds</WithdrawBtn>
        
            )
          } */}
      </DialogContent>
    </Dialog>
  );
};

WithdrawalPopUp.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default WithdrawalPopUp;
