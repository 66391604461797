import {
  FETCH_USER_MESSAGES_START,
  FETCH_USER_MESSAGES_FAIL,
  FETCH_USER_MESSAGES_SUCCESS,
  FETCH_CURRENT_CHAT_START,
  FETCH_CURRENT_CHAT_SUCCESS,
  FETCH_CURRENT_CHAT_FAIL,
  SEND_CHAT_MSG,
  SET_CURRENT_CHATID,
  FETCH_UNREAD_START,
  FETCH_UNREAD_SUCCESS,
  FETCH_UNREAD_FAIL,
} from './messageTypes';

const initialState = {
  messagesLoading: false,
  messages: null,
  messagesErr: null,

  currChat: null,
  currChatErr: null,
  currChatLoading: false,

  currChatId: null,

  sentMessage: null,

  unreadLoading: null,
  unread: null,
  unreadFail: null,

  // readyForPickupLoading: false,
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_MESSAGES_START: {
      return {
        ...state,
        messagesLoading: true,
      };
    }

    case FETCH_USER_MESSAGES_SUCCESS: {
      return {
        ...state,
        messagesLoading: false,
        messages: action.payload,
      };
    }
    case FETCH_USER_MESSAGES_FAIL: {
      return {
        ...state,
        messagesLoading: false,
        messagesErr: action.payload,
      };
    }
    case SET_CURRENT_CHATID: {
      return {
        ...state,
        currChatId: action.payload,
      };
    }
    case FETCH_CURRENT_CHAT_START: {
      return {
        ...state,
        currChatLoading: true,
      };
    }
    case FETCH_CURRENT_CHAT_SUCCESS: {
      return {
        ...state,
        currChatLoading: false,
        currChat: action.payload.chat,
        // currChatId: action.payload.id,
      };
    }
    case FETCH_CURRENT_CHAT_FAIL: {
      return {
        ...state,
        currChatLoading: false,
        currChatErr: action.payload,
      };
    }
    case SEND_CHAT_MSG: {
      return {
        ...state,
        sentMessage: action.payload,
      };
    }
    case FETCH_UNREAD_START: {
      return {
        ...state,
        unreadLoading: true,
      };
    }
    case FETCH_UNREAD_SUCCESS: {
      return {
        ...state,
        unreadLoading: false,
        unread: action.payload.unreadMessages,
      };
    }
    case FETCH_UNREAD_FAIL: {
      return {
        ...state,
        unreadLoading: false,
        unreadFail: action.payload,
      };
    }
    default:
      return state;
  }
};

export default messageReducer;
