import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';

import OnboardingCardContent from '../../parts/Onboarding/OnboardingCardContent';
import BaseBtn from '../../components/BaseBtn';
import BaseCard from '../../components/BaseCard';

const useStyles = makeStyles({
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
  },

  signup: {
    backgroundColor: '#4900ff',
    border: ' 2px solid #4900ff',
    color: '#fff',
    marginBottom: '12px',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  btnFacebook: {
    border: '1.5px solid #4267B2',
    color: '#4267B2',
    fontFamily: 'inherit',
    marginBottom: '8px',
  },
  btnGoogle: {
    border: '1.5px solid #DB4437',
    color: '#DB4437',
    marginBottom: '8px',
  },
});

const Signup = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleSignupForm = () => {
    history.push('/auth/signup-form');
  };

  return (
    <BaseCard>
      <OnboardingCardContent />
      <CardActions className={classes.cardActions}>
        {/*
         * TO BE RE ADDED POST MVP
         */}

        {/* <BaseBtn fullWidth className={classes.btnFacebook}>
          <span style={{ textTransform: 'capitalize' }}>
            continue
            <span style={{ textTransform: 'lowercase' }}> with</span> Facebook
          </span>
        </BaseBtn> */}

        {/* <BaseBtn fullWidth className={classes.btnGoogle}>
          <span style={{ textTransform: 'capitalize' }}>
            continue
            <span style={{ textTransform: 'lowercase' }}> with</span> Google
          </span>
        </BaseBtn> */}
        <BaseBtn
          fullWidth
          className={classes.signup}
          onClick={handleSignupForm}
        >
          <span style={{ color: 'white' }}>Sign Up</span>
        </BaseBtn>
      </CardActions>
    </BaseCard>
  );
};

export default Signup;
