import React from 'react'
import Badge from '@material-ui/core/Badge';
import { FaShoppingBag } from 'react-icons/fa';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LogoHolder from '../../../assets/images/logo-holder.png';

const useStyles = makeStyles((theme) => ({
    badge: {
        backgroundColor: '#AA2222',
        color: 'white',
        fontWeight: 'bold',
        fontFamily: ['Lato', 'sans-serif'].join(','),
        border: '1px solid #ffffff',
        marginRight:"13px",
        marginTop:"3px"
      
      },
      maindiv:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
      },
      img:{
        width:"201px",
        height:"30px",
        marginTop:"10px"
      },
      shopping:{
        marginRight:"15px",
        fontSize:"20px"
      }
}))

function HomeAppBar() {

    const classes = useStyles();
  return (
    <div className={classes.maindiv}>



        <div>
            <img className={classes.img} src={LogoHolder}></img>
          
        </div>

        <Badge badgeContent={10}
                      classes={{
                        badge: classes.badge,
                      }}>
          <FaShoppingBag className={classes.shopping} fontSize="small" />
           
          </Badge>
        
    </div>
  )
}

export default HomeAppBar