import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';

import BaseInput2 from '../../components/BaseInput2';
import BaseBtn from '../../components/BaseBtn';
import flagIcon from '../../assets/images/flag_icon_2.jpg';
import DialogSuccess from '../../components/DialogSuccess';
import happyfaceImg from '../../assets/images/happy-face-illustration1.png';
import { formatPhoneNumber } from '../../_helpers/format-phone';
import {
  providePhoneNo,
  verifyVerificationCode,
} from '../../redux/merchant/merchantActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 16px',
    borderRadius: '16px',
    width: '424px',
  },

  disabledBtn: {
    backgroundColor: '#E2E2E2',
    color: '#717171',
    '&:hover': {
      backgroundColor: '#E2E2E2',
    },
  },
  activeBtn: {
    backgroundColor: '#4900ff',
    border: ' 2px solid #4900ff',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  smallIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  mbCtnt: {
    marginBottom: '16px',
  },
  content: {
    height: '40vh',
  },
}));

// formik validation
const phoneNoValidation = yup.object({
  phoneNo: yup
    .string()
    .required('Please enter phone number')
    .matches(/^-?\d+\.?\d*$/, 'Phone number can only contain numbers')
    .min(10, 'Invalid phone number format! Correct format: 0712345678')
    .max(10, 'Invalid phone number format! Correct format: 0712345678'),
  verifyPhoneNo: yup
    .string()
    .oneOf([yup.ref('phoneNo'), null], "Phone numbers don't match!")
    .required('Please verify your phone number')
    .matches(/^-?\d+\.?\d*$/, 'Phone number can only contain numbers')
    .min(10, 'Invalid phone number format! Correct format: 0712345678')
    .max(10, 'Invalid phone number format! Correct format: 0712345678'),
});

const verCodeValidation = yup.object({
  verCode: yup
    .string()
    // .matches(/^-?\d+\.?\d*$/, 'invalid! Code can only contain numbers')
    .required('Please enter code')
    .min(6, 'code must be 6 digits long')
    .max(6, 'code must be 6 digits long'),
});

const EnterMobileNo = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const formik = useFormik({
    initialValues: {
      phoneNo: '',
      verifyPhoneNo: '',
    },
    validationSchema: phoneNoValidation,
    onSubmit: (values) => {
      const merchantPhone = {
        paymentNumber: formatPhoneNumber('254', values.phoneNo),
      };
      // console.log(merchantPhone);

      dispatch(providePhoneNo(merchantPhone, auth.token));
    },
  });

  return (
    <>
      <Card className={classes.root}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <CardContent className={classes.mbCtnt}>
            <p className="f-16 txt-violet" style={{ marginBottom: '48px' }}>
              Set up your M-Pesa or Airtel Money number below to make it easier
              to receive funds from your sales through{' '}
              <span style={{ color: '#FD0046' }}> LukhuPay</span> , our escrow
              payment wallet.
            </p>

            <BaseInput2
              id="phoneNo"
              name="phoneNo"
              type="text"
              placeholder=" Enter Phone Number (07**)"
              value={formik.values.phoneNo}
              onChange={formik.handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <Avatar
                    alt="Kenyan flag icon"
                    src={flagIcon}
                    className={classes.smallIcon}
                  />
                </InputAdornment>
              }
              fullWidth
            />

            <div className="mb-16">
              {formik.errors.phoneNo && formik.touched.phoneNo && (
                <p className="input-error">
                  <small>{formik.errors.phoneNo}</small>
                </p>
              )}
            </div>

            <BaseInput2
              id="verifyPhoneNo"
              name="verifyPhoneNo"
              type="text"
              placeholder="Verify Phone Number"
              value={formik.values.verifyPhoneNo}
              onChange={formik.handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <Avatar
                    alt="Kenyan flag icon"
                    src={flagIcon}
                    className={classes.smallIcon}
                  />
                </InputAdornment>
              }
              fullWidth
            />

            {formik.errors.verifyPhoneNo && formik.touched.verifyPhoneNo && (
              <p className="input-error">
                <small>{formik.errors.verifyPhoneNo}</small>
              </p>
            )}
          </CardContent>

          <CardActions>
            <BaseBtn
              type="submit"
              fullWidth
              disabled={!formik.values.phoneNo || !formik.values.verifyPhoneNo}
              className={
                !formik.values.phoneNo || !formik.values.verifyPhoneNo
                  ? classes.disabledBtn
                  : classes.activeBtn
              }
            >
              send code
            </BaseBtn>
          </CardActions>
        </form>
      </Card>
    </>
  );
};

const EnterVerCode = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const merchantPhoneNo = useSelector((state) => state.merchant.phoneVal);
  const auth = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      verCode: '',
    },
    validationSchema: verCodeValidation,
    onSubmit: (values) => {
      const code = {
        token: values.verCode,
      };
      // console.log('VerCode: ', code);
      dispatch(verifyVerificationCode(code, auth.token));
    },
  });

  return (
    <>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <p className="f-16 txt-violet" style={{ marginBottom: '48px' }}>
              Please enter the 6-digit verification sent to{' '}
              <span style={{ fontWeight: 'bold' }}>{merchantPhoneNo}</span>
            </p>
            <BaseInput2
              id="verCode"
              name="verCode"
              type="text"
              value={formik.values.verCode}
              onChange={formik.handleChange}
              placeholder="Enter code"
              fullWidth
            />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <small>
                <a href="/" style={{ color: 'black' }}>
                  Resend Code
                </a>
              </small>
            </div>
          </CardContent>
          <CardActions>
            <BaseBtn
              type="submit"
              fullWidth
              disabled={!formik.values.verCode}
              className={
                formik.values.verCode ? classes.activeBtn : classes.disabledBtn
              }
            >
              Send Code
            </BaseBtn>
            {formik.errors.verCode && formik.touched.verCode && (
              <p>
                <small>{formik.errors.verCode}</small>
              </p>
            )}
          </CardActions>
        </Card>
      </form>
    </>
  );
};

const EditDetails = () => {
  const classes = useStyles();

  /*
    Pop up dialog box 1 second after 'Edit Details' component mounts 
*/
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleMaybeLater = () => {
    setOpen(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleOpenDialog();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Card className={classes.root}>
        <div className="flex-center">
          <Link to="/setup-store">
            <span
              style={{
                color: '#4800FB',
                textTransform: 'capitalize',
                textDecoration: 'underline',
              }}
            >
              Edit Details
            </span>
          </Link>
        </div>
      </Card>
      {
        <DialogSuccess
          handleClose={handleCloseDialog}
          open={open}
          title="You’re all set!"
          img={happyfaceImg}
          btnTxt="I want to list!"
          btnLinkTo="/list-product"
          txtLinkTo="/"
          handleMaybeLater={handleMaybeLater}
        />
      }
    </>
  );
};

const LukhupayForm = () => {
  const merchantState = useSelector((state) => state.merchant);
  // console.log(('State :', merchantState));

  return (
    <>
      {JSON.parse(!merchantState.isPhoneProvided) &&
        JSON.parse(!merchantState.isPhoneVerified) && <EnterMobileNo />}
      {JSON.parse(merchantState.isPhoneProvided) &&
        JSON.parse(!merchantState.isPhoneVerified) && <EnterVerCode />}
      {JSON.parse(merchantState.isPhoneVerified) && <EditDetails />}
    </>
  );
};

export default LukhupayForm;
