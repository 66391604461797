import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';

import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  cardWrapper: {
    width: '100%',
  },
  content: {
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    // flexWrap: 'wrap',
  },
}));

const SavedAddressCard = ({
  customerName,
  phoneNo,
  location,
  buildingDetails,
  otherDetails,
  selectedAddress,
  handleChange,
  addressId,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.cardWrapper}>
      <CardContent>
        <Box display="flex">
          <Box mr={1}>
            <p className="f-12 txt-black txt-mont txt-bold ">{customerName}</p>
          </Box>
          <Box mr={1}>
            <p className="txt-gray4 f-12">{phoneNo}</p>
          </Box>
          <Box mr={1}>
            <p className="txt-gray4 f-12">{location}</p>
          </Box>
          <Box mr={1}>
            <p className="txt-gray4 f-12">{otherDetails}</p>
          </Box>
        </Box>
      </CardContent>
    </Card>
    // </div>
  );
};

SavedAddressCard.propTypes = {
  customerName: PropTypes.string.isRequired,
  phoneNo: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  buildingDetails: PropTypes.string.isRequired,
  otherDetails: PropTypes.string.isRequired,
  selectedAddress: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  addressId: PropTypes.string.isRequired,
};

export default SavedAddressCard;
