import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import DraftsComplete from '../../parts/Products/DraftsComplete';
import DraftsIncomplete from '../../parts/Products/DraftsIncomplete';

const useStyles = makeStyles((theme) => ({
  spacing: { padding: '16px' },
  btnCustom: {
    borderRadius: 0,
    border: '1px solid #7E7E7E',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    color: '#7E7E7E',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'inherit',
      color: '#7E7E7E',
    },
  },
  btnCustomSelected: {
    borderRadius: 0,
    fontFamily: ['Lato', 'sans-serif'].join(','),
    background: '#000000',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#000000',
      color: '#FFFFFF',
    },
  },
  flexLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const MerchantAdminPanelDrafts = ({ products }) => {
  const classes = useStyles();

  const [showComplete, setshowComplete] = useState(true);
  const [showIncomplete, setshowIncomplete] = useState(false);
  const [isBtn1Selected, setisBtn1Selected] = useState(true);
  const [isBtn2Selected, setisBtn2Selected] = useState(false);

  const handleClickIncomplete = () => {
    setshowComplete(false);
    setshowIncomplete(true);
    setisBtn1Selected(false);
    setisBtn2Selected(true);
  };

  const handleClickComplete = () => {
    setshowIncomplete(false);
    setshowComplete(true);
    setisBtn1Selected(true);
    setisBtn2Selected(false);
  };

  return (
    <Paper elevation={0}>
      <div className={`${classes.spacing} ${classes.flexLayout}`}>
        <h3>Your Drafts</h3>
        <div style={{ display: 'flex' }}>
          <Button
            variant="outlined"
            size="small"
            className={
              isBtn1Selected ? classes.btnCustomSelected : classes.btnCustom
            }
            onClick={handleClickComplete}
          >
            Ready to Post
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={
              isBtn2Selected ? classes.btnCustomSelected : classes.btnCustom
            }
            onClick={handleClickIncomplete}
          >
            Incomplete
          </Button>
        </div>
      </div>
      <Divider />
      <div className={classes.spacing}>
        {showComplete && <DraftsComplete products={products} />}

        {showIncomplete && <DraftsIncomplete />}
      </div>
    </Paper>
  );
};
MerchantAdminPanelDrafts.propTypes = {
  products: PropTypes.array.isRequired,
};
export default MerchantAdminPanelDrafts;
