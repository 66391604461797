import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useSocket from '../../hooks/useSocket';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import SecurityIcon from '@material-ui/icons/Security';
import Box from '@material-ui/core/Box';
import qs from 'qs';

import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseInput2 from '../../components/BaseInput2';
import flagIcon from '../../assets/images/flag_icon.jpg';
import ConfirmPayment from '../Checkout/ConfirmPayment';
import ConfirmPaymentCard from '../Checkout/ConfirmPaymentCard';

import mpesaLogo from '../../assets/images/mpesa-logo.svg';
import visaLogo from '../../assets/images/visa-logo.svg';
import mastercardLogo from '../../assets/images/mastercard-logo.svg';

import {
  createOrder,
  setMpesaDetails,
  setCardDetails,
} from '../../redux/checkout/checkoutActions';
import OrderSuccessfulModal from '../Checkout/OrderSuccessfulModal';
import { validatePhone, validateCardExpiry } from '../../_helpers/validators';
import { url } from '../../api';

const mobileValidationSchema = yup.object({
  // phoneNumber: yup
  //   .string()
  //   .required('Please fill out this field')
  //   .matches(/^((01)|(07))[0-9]+$/, 'Invalid format! Please try : 07XX...')
  //   .min(10, 'Please enter a valid phone number')
  //   .max(10, 'Please enter a valid phone number'),
  phoneNumber: validatePhone,
});
const cardValidationSchema = yup.object({
  cardNumber: yup
    .number()
    .required('Please fill out this field')
    .typeError('Please enter a digit')
    // .matches(/^((4)|(5))[0-9]+$/, 'Invalid card format!')
    .min(13, 'Invalid card number!')
    .max(16, 'Invalid card number!'),
  cardCvv: yup
    .string()
    .matches(/^[0-9]+$/, 'Please enter a digit')
    .required('Please fill out this field'),
  cardExpiry: validateCardExpiry,
  firstName: yup.string().required('Please fill out this field'),
  lastName: yup.string().required('Please fill out this field'),
  middleName: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  details: {
    paddingLeft: '32px',
  },
  txt: {
    color: '#7B7B7B',
    fontSize: '0.875rem',
    fontFamily: ['Lato', 'sans-serif'].join(','),
  },
  layout: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  paper: {
    padding: '8px',
    height: 'fit-content',
    width: 'fit-content',
    maxWidth: '375px',
    borderColor: '#601DFF',
    marginBottom: '8px',
  },
  btn: { width: '150px', fontWeight: 700, padding: 4 },
  active: {},
  inactive: {},
  flagIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

const mapState = ({ checkout,cart }) => ({
  checkedOutCart: checkout.checkedOutCart,
  deliveryId: checkout.deliveryId,
  stkPushSuccess: checkout.completePaymentSucess,
  selectedDeliveryType: checkout.selectedDeliveryType,
  voucher:cart.carVoucher
});

const RenderPaymentOptions = () => {
  const token  =  localStorage.getItem("_REG_TK")
  const classes = useStyles();
  const dispatch = useDispatch();
  const[firstNamef,setFirstNamef] = useState("");
  const [lastNamef,setLastNamef] = useState("")
  const[emailf,setEmailf]=useState("")
  const[phoneNumberf,setPhoneNumberf] = useState("")

  const { checkedOutCart, deliveryId, selectedDeliveryType,voucher } = useSelector(
    mapState
  );
  const { socket, disconnectSocket } = useSocket();

  const [selectedOption, setSelectedOption, stkPushSuccess] = useState(
    'mobile'
  );

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleFirstName =(e)=>{
    setFirstNamef(e.target.value)
  }

  const handleLastName = (e)=>{
    setLastNamef(e.target.value)
  }

  const handleEMail = (e) =>{
    setEmailf(e.target.value)
  }

  const handlePhoneNumber = (e) =>{
    setPhoneNumberf(e.target.value)
  }

  const [openPaymentConfirmation, setopenPaymentConfirmation] = useState(false);
  const[openConfirmPaymentCard,setOpenConfirmPaymentCard] = useState(false);
  

  const handleConfirmPaymentClose  = () =>{
    setOpenConfirmPaymentCard(false)
  }

  const handleClosePaymentConfirmation = () => {
    setopenPaymentConfirmation(false);
  };

  const handleSubmitb = () =>{
    if(firstNamef.length > 0 && lastNamef.length > 0 && phoneNumberf.length > 0 && emailf.length > 0 ){
      const details = {
        firstName: firstNamef,
        email: emailf,
        lastName: lastNamef,
        phoneNumber: phoneNumberf,
      
      };

      
      const body = {
        paymentType: 'CARD',
        cartId: checkedOutCart,
        deliveryId: deliveryId,
        deliveryType: selectedDeliveryType.id,
        voucherCode:voucher
      };

      // console.log('ORDER: ', body);
      dispatch(setCardDetails(details));
      dispatch(createOrder(body));
      setOpenConfirmPaymentCard(true);
    }
  }


 

  const { depositInitiated, depositInitiatedErr } = useSelector(mapState);
  const formik1 = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: mobileValidationSchema,
    onSubmit: (values) => {
      //       cartId
      // 110
      // deliveryId
      // 31
      // paymentType
      // MPESA
      // MPESA | CARD

      // deliveryType
      // 1
      // Options: 1 - Express 2 - Mtaani

      const body = {
        paymentType: 'MPESA',
        cartId: checkedOutCart,
        deliveryId: deliveryId,
        deliveryType: selectedDeliveryType.id,
        voucherCode:voucher
      };

      // console.log('ORDER: ', body);
      dispatch(setMpesaDetails(values.phoneNumber));
      dispatch(createOrder(body));
      setopenPaymentConfirmation(true);
    },
  });
  const formik2 = useFormik({
    initialValues: {
      email:'',
      firstName: '',
      phoneNumber: '',
      lastName: '',
    },
    validationSchema: cardValidationSchema,
    onSubmit: (values) => {
      console.log('Deposit', values);
      const details = {
        firstName: values.firstName,
        email: values.email,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
      
      };

      console.log('Deposit', details);
      const body = {
        paymentType: 'CARD',
        cartId: checkedOutCart,
        deliveryId: deliveryId,
        deliveryType: selectedDeliveryType.id,
        voucherCode:voucher
      };

      // console.log('ORDER: ', body);
      dispatch(setCardDetails(details));
      dispatch(createOrder(body));
      setopenPaymentConfirmation(true);
    },
  });
  return (
    <>
      <div>
        <RadioGroup
          aria-label="payment-options"
          name="payment"
          value={selectedOption}
          onChange={handleChange}
        >
          <div className={classes.layout}>
            <Paper variant="outlined" className={classes.paper}>
              {' '}
              <div className="d-flex d-flex-space d-flex-align-center">
                <FormControlLabel
                  value="mobile"
                  control={<Radio />}
                  label="Mobile Money"
                  classes={{
                    label: classes.label,
                  }}
                />
                {selectedOption === 'mobile' && (
                  <div className="brand-logo">
                    <img src={mpesaLogo} alt="M-Pesa Logo" />
                  </div>
                )}
              </div>
              {selectedOption === 'mobile' && (
                <>
                  <form onSubmit={formik1.handleSubmit} noValidate>
                    <BaseInput2
                      id="phoneNumber"
                      name="phoneNumber"
                      type="text"
                      placeholder="Phone Number *"
                      startAdornment={
                        <InputAdornment position="start">
                          {' '}
                          <Avatar
                            alt="flag of Kenya"
                            src={flagIcon}
                            className={classes.flagIcon}
                          />
                        </InputAdornment>
                      }
                      value={formik1.values.phoneNumber}
                      onChange={formik1.handleChange}
                      fullWidth
                    />
                    {formik1.errors.phoneNumber && formik1.touched.phoneNumber && (
                      <p className="input-error">
                        <small>{formik1.errors.phoneNumber}</small>
                      </p>
                    )}
                    <div className="mb-8"></div>

                    <BaseBtnFilled className={classes.btn} type="submit">
                      Confirm
                    </BaseBtnFilled>

                    {depositInitiated && (
                      <p className="f-14 txt-center txt-elecViolet">
                        Check your mobile phone to enter your M-pesa pin{' '}
                      </p>
                    )}
                    {depositInitiatedErr && (
                      <p className="inputError">
                        An error occured. Please try again.
                      </p>
                    )}
                  </form>
                </>
              )}
            </Paper>
            <Paper variant="outlined" className={classes.paper}>
              <div className="d-flex d-flex-space d-flex-align-center">
                <FormControlLabel
                  value="card"
                  control={<Radio />}
                  label="Debit or Credit Card"
                  classes={{
                    label: classes.label,
                  }}
                />
                {selectedOption === 'card' && (
                  <div
                    className="d-flex  d-flex-align-center"
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <div className="brand-logo">
                      {' '}
                      <img src={mastercardLogo} alt="Mastercard Logo" />
                    </div>
                    <div className="brand-logo">
                      <img src={visaLogo} alt="Visa Logo" />
                    </div>
                  </div>
                )}
              </div>
              {selectedOption === 'card' && (
                <>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                  
                  <form onSubmit={formik2.handleSubmit} noValidate>
                    {/* bio data */}
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: '16px',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ width: '45%' }}>
                        <BaseInput2
                          id="firstName"
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          value={firstNamef}
                          onChange={handleFirstName}
                          fullWidth
                        />
                        {formik2.errors.firstName &&
                          formik2.touched.firstName && (
                            <p className="input-error">
                              <small>{formik2.errors.firstName}</small>
                            </p>
                          )}
                      </div>
                      <div style={{ width: '50%' }}>
                        <BaseInput2
                          id="lastName"
                          name="lastName"
                          type="text"
                          placeholder="Last Name"
                          value={lastNamef}
                          onChange={handleLastName}
                          fullWidth
                          autoComplete="off"
                        />

                        {formik2.errors.lastName &&
                          formik2.touched.lastName && (
                            <p className="input-error">
                              <small>{formik2.errors.lastName}</small>
                            </p>
                          )}
                      </div>
                    
                    </div>

                    {/* not bio data */}

                    <div
                      style={{
                        display: 'flex',
                        marginBottom: '16px',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ width: '45%' }}>
                        <BaseInput2
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Email Address"
                          value={emailf}
                          onChange={handleEMail}
                          fullWidth
                        />
                        {formik2.errors.email &&
                          formik2.touched.email && (
                            <p className="input-error">
                              <small>{formik2.errors.email}</small>
                            </p>
                          )}
                      </div>
                      <div style={{ width: '50%' }}>
                        <BaseInput2
                          id="phoneNumber"
                          name="phoneNumber"
                          type="text"
                          placeholder="Phone Number"
                          value={phoneNumberf}
                          onChange={handlePhoneNumber}
                          fullWidth
                          autoComplete="off"
                        />

                        {formik2.errors.phoneNumber &&
                          formik2.touched.phoneNumber && (
                            <p className="input-error">
                              <small>{formik2.errors.phoneNumber}</small>
                            </p>
                          )}
                      </div>
                    
                    </div>



                    {/* end not bio data */}


                   
                    <div className="mb-8"></div>

                    <BaseBtnFilled className={classes.btn}  onClick={handleSubmitb} >
                      Confirm
                    </BaseBtnFilled>
                  </form>
                  </Box>
                </>
              )}
            </Paper>
          </div>
        </RadioGroup>
        <div className="flex-center mt-16 mb-16 " style={{ color: '#8E8E8F' }}>
          <SecurityIcon style={{ fontSize: '1rem' }} />
          <p className="f-14">
            All in-app purchases are covered by Lukhu Buyer Protection.{' '}
            <span className="txt-bold">
              <a
                href="https://lukhu.helpscoutdocs.com/collection/14-payments"
                target="_blank"
                rel="noreferrer"
              >
                Learn More
              </a>
            </span>
          </p>
        </div>
      </div>
      <ConfirmPayment
        open={openPaymentConfirmation}
        handleClose={handleClosePaymentConfirmation}
      />
      <ConfirmPaymentCard  open={openConfirmPaymentCard}
      handleClose={handleConfirmPaymentClose} firstName={firstNamef}
      lastName = {lastNamef}
      email={emailf}
      phoneNumber={
        phoneNumberf
      } />
    </>
  );
};

export default RenderPaymentOptions;
