import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Rating from '@material-ui/lab/Rating';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { FaMoneyBillWave, FaRegEdit } from 'react-icons/fa';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Divider from '@material-ui/core/Divider';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Hidden from '@material-ui/core/Hidden';

import MerchantAvatar from './MerchantAvatar';
import BaseBtn from '../../components/BaseBtn';
import { useHistory } from 'react-router-dom';
import Verified   from '../../assets/images/verified.png';
import { Button } from '@material-ui/core';
import BaseBtn2 from '../../components/BaseBtn2';



const useStyles = makeStyles((theme) => ({
  root: {
    padding: '24px 16px',
  },
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  icon1: {
    color: '#601dff',
    fontSize: '1rem',
    margin: theme.spacing(0, 0.5, 0, 0),
  },
  icon2: {
    fontSize: '1.125rem',
    display: 'inline-flex',
    flexShrink: '0',
    // margin: theme.spacing(0, 0.5, 0, 0),
    maxWidth: '52px',
    color: '#707070',
  },

  section1: {
    display: 'flex',
    justifyContent: 'space-between',
    // margin: theme.spacing(1, 0),
    alignItems: 'center',
    paddingBottom: '16px',

    // flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },

  iconText: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    // flexWrap: 'wrap',
  },
  btn: {
    color: '#481CFB',
    background: '#f3f0ff',
    bordeRadius:"0px !important",
    fontSize:"12px"
  },
  btn1: {
    color: '#481CFB',
    background: '#f3f0ff',
    bordeRadius:"3px !important",
    fontSize:"12px"
  },
  lItemGutters: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  liTextPrimary: {
    fontFamily: ['Lato', 'sans-serif'].join(', '),
    color: '#707070',
    fontSize: '0.875rem',
  },
  liTextPrimary2: {
    color: '#111111',
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
  lIcon: {
    minWidth: '20px',
  },
  imgs:{
    position:"absolute",
    width:"15px",
    height:"15px",
    margileft:"30px"
  },
  verif:{
    width:"20px"
  }
}));

const MerchantProfileCard = ({
  verified,
  image,
  name,
  joinDate,
  description,
  location,
  rating,
  reviews,
  transactions,
  followers,
  following,
  handleEditProfile,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const handleEditStoreProfile = () => {
    history.push('/my-profile/edit-store');
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <div className="d-flex d-flex-align-center pb-16">
        <div>
         
          <MerchantAvatar verified={verified} avatarStyles={classes.avatar} image={image} />
      
        </div>
        <div style={{ marginLeft: '16px' ,display: "flex",}}>
          <p className="txt-violet txt-bold f-18">{name}</p>

          {/* TO BE ADDED AFTER MVP LAUNCH */}
          {/* <div className="d-flex d-flex-wrap d-flex-space">
            <div className={classes.iconText}>
              <GradeIcon className={classes.icon1} />
              <p className="txt-elecViolet f-14">Trusted Seller</p>
            </div>
            <div className={classes.iconText}>
              <TimerIcon className={classes.icon1} />
              <p className="txt-elecViolet f-14">Fast Response</p>
            </div>
            <div className={classes.iconText}>
              <LocalShippingIcon className={classes.icon1} />
              <p className="txt-elecViolet f-14">Often ships in a day</p>
            </div>
          </div> */}
        </div>
      </div>

      <div className={classes.section1}>
        <div>
          <List dense>
            <ListItem
              classes={{
                gutters: classes.lItemGutters,
              }}
            >
              <ListItemIcon classes={{ root: classes.lIcon }}>
                <LocationOnIcon className={classes.icon2} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.liTextPrimary }}
                primary={location}
              />
            </ListItem>
            <ListItem
              classes={{
                gutters: classes.lItemGutters,
              }}
            >
              <ListItemIcon classes={{ root: classes.lIcon }}>
                <AccessTimeIcon className={classes.icon2} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.liTextPrimary }}
                primary={
                  <>
                    <span className="txt-lightColor txt-capitalize f-14">
                      Joined{' '}
                      <span style={{ textTransform: 'lowercase' }}>in</span>{' '}
                      {joinDate}
                    </span>
                  </>
                }
              />
            </ListItem>


{
  verified ?   <BaseBtn2
  startIcon={<img className={classes.verif} src={Verified}></img>}
  className={classes.btn1}

>
  Verified Seller
</BaseBtn2> : ""
}
          
          </List>
        </div>

        <Hidden smDown>
          <Divider orientation="vertical" flexItem />
        </Hidden>
        <Hidden mdUp>
          <Divider alignSelf="stretch" style={{ margin: '4px 0' }} />
        </Hidden>

        <div>
          <List dense>
            <ListItem
              classes={{
                gutters: classes.lItemGutters,
              }}
            >
              <ListItemText
                classes={{ primary: classes.liTextPrimary2 }}
                primary={
                  <>
                    <Rating
                      name="merchant-rating"
                      value={rating}
                      readOnly
                      emptyIcon={
                        <StarBorderIcon style={{ fontSize: '1.5rem' }} />
                      }
                      style={{ fontSize: '1.5rem' }}
                    />
                  </>
                }
              />
            </ListItem>
            <ListItem
              classes={{
                gutters: classes.lItemGutters,
              }}
            >
              <ListItemText
                classes={{ primary: classes.liTextPrimary }}
                primary={`${reviews} reviews`}
              />
            </ListItem>
          </List>
        </div>

        <Hidden smDown>
          <Divider orientation="vertical" flexItem />
        </Hidden>
        <Hidden mdUp>
          <Divider alignSelf="stretch" style={{ margin: '4px 0' }} />
        </Hidden>

        <div>
          <List dense>
            <ListItem
              classes={{
                gutters: classes.lItemGutters,
              }}
            >
              <ListItemIcon classes={{ root: classes.lIcon }}>
                <FaMoneyBillWave className={classes.icon2} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.liTextPrimary2 }}
                primary={transactions}
              />
            </ListItem>
            <ListItem
              classes={{
                gutters: classes.lItemGutters,
              }}
            >
              <ListItemText
                classes={{ primary: classes.liTextPrimary }}
                primary="Transactions"
              />
            </ListItem>
          </List>
        </div>
      </div>
      <Divider />
      <div className="flex-center pt-16 ">
        <div className="d-flex">
          <p
            className="txt-lightColor txt-capitalize f-14"
            style={{ marginRight: '16px' }}
          >
            <span className="txt-ruby txt-bold">{followers}</span>{' '}
            {followers > 1 || followers === 0 ? 'Followers' : 'Follower'}
          </p>
          <p className="txt-lightColor txt-capitalize f-14">
            <span className="txt-ruby txt-bold">{following}</span> Following
          </p>
        </div>
      </div>
      <div className="flex-center pt-16 pb-16">
        <div>
          <p className="f-14">{description}</p>
        </div>
      </div>
      <div className="flex-center">
        <BaseBtn
          startIcon={<FaRegEdit />}
          className={classes.btn}
          onClick={handleEditStoreProfile}
        >
          Edit Store Profile
        </BaseBtn>

        {/* <BaseBtn
          startIcon={<img className={classes.verif} src={Verified}></img>}
          className={classes.btn}
          onClick={handleEditStoreProfile}
        >
          Verified Seller
        </BaseBtn> */}
      </div>
    </Paper>
  );
};

MerchantProfileCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  joinDate: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  reviews: PropTypes.number.isRequired,
  transactions: PropTypes.number.isRequired,
  followers: PropTypes.number.isRequired,
  following: PropTypes.number.isRequired,
  handleEditProfile: PropTypes.func.isRequired,
};

export default MerchantProfileCard;
