import React from 'react';
import OrderInfo from './OrderInfo';

const InfoSection = () => {
  return (
    <div className="info">
      {/* <MerchantInfo /> */}
      {/* <OrderInfo /> */}
    </div>
  );
};

export default InfoSection;
