import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import BaseInput2 from '../../components/BaseInput2';
import BaseBtn from '../../components/BaseBtn';
import PlacesAutocomplete from '../../components/PlacesAutocomplete';
import { createStore } from '../../redux/merchant/merchantActions';

const useStyles = makeStyles((theme) => ({
  disabledBtn: {
    backgroundColor: '#E2E2E2',
    color: '#717171',
    '&:hover': {
      backgroundColor: '#E2E2E2',
    },
  },
  activeBtn: {
    backgroundColor: '#4900ff',
    border: ' 2px solid #4900ff',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
}));

const validationSchema = yup.object({
  fname: yup.string().required('Please fill out this field'),
  lname: yup.string().required('Please fill out this field'),
  phoneNumber: yup
    .string()
    .required('Please enter phone number')
    .matches(/^((01)|(07))[0-9]+$/, 'Invalid format! Please try : 07XX...')
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number'),
  building: yup.string().required('Please fill out this field'),
});

const AddressFormIndividual = ({ openNextStep, openSuccessDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [locationErr, setlocationErr] = useState(null);
  const [location, setlocation] = useState(null);

  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      phoneNumber: '',
      building: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!location) {
        setlocationErr('Please fill out this field');
        return;
      }
      if (location) {
        setlocationErr('');
      }
      const storeDetails = {
        storeType: 'individual',
        businessName: `${values.fname} ${values.lname}`,
        phoneNumber: values.phoneNumber,
        placeId: location.place_id,
        building: values.building,
      };

      dispatch(createStore(storeDetails));
      formik.resetForm();
      // openNextStep();
      openSuccessDialog();
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <BaseInput2
              id="fname"
              name="fname"
              type="text"
              placeholder="first name *"
              value={formik.values.fname}
              onChange={formik.handleChange}
              fullWidth
            />

            {formik.errors.fname && formik.touched.fname && (
              <p className="input-error">
                <small>{formik.errors.fname}</small>
              </p>
            )}
          </Grid>
          <Grid item xs={6}>
            <BaseInput2
              id="lname"
              name="lname"
              type="text"
              placeholder="last name *"
              value={formik.values.lname}
              onChange={formik.handleChange}
              fullWidth
            />
            {formik.errors.lname && formik.touched.lname && (
              <p className="input-error">
                <small>{formik.errors.lname}</small>
              </p>
            )}
          </Grid>
          <Grid item xs={12}>
            <BaseInput2
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              placeholder="Phone Number * e.g 07XX..."
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              fullWidth
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber && (
              <p className="input-error">
                <small>{formik.errors.phoneNumber}</small>
              </p>
            )}
          </Grid>
          <Grid item xs={12}>
            <PlacesAutocomplete value={location} setValue={setlocation} />
            <p className="f-14">
              <small>* Start typing to select your location</small>
            </p>
            {locationErr && (
              <p className="input-error">
                <small>{locationErr}</small>
              </p>
            )}
          </Grid>
          <Grid item xs={12}>
            <BaseInput2
              id="building"
              name="building"
              type="text"
              placeholder="Building Name*"
              value={formik.values.building}
              onChange={formik.handleChange}
              fullWidth
            />
            {formik.errors.building && formik.touched.building && (
              <p className="input-error">
                <small>{formik.errors.building}</small>
              </p>
            )}
          </Grid>

          <Grid item xs={12} style={{ marginTop: '16px' }}>
            <BaseBtn
              type="submit"
              fullWidth
              disabled={!formik.dirty}
              className={
                !formik.dirty ? classes.disabledBtn : classes.activeBtn
              }
            >
              submit
            </BaseBtn>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

AddressFormIndividual.propTypes = {
  openNextStep: PropTypes.func.isRequired,
  openSuccessDialog: PropTypes.func.isRequired,
};
export default AddressFormIndividual;
