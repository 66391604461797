import { useState } from 'react';
import { useHistory, Redirect, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { url } from '../../api';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import BetaLayout from './BetaLayout';
import CustomContainer from './CustomContainer';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import CustomInput from './CustomInput';
import flagIcon from '../../assets/images/flag_icon_2.jpg';
import { signUp } from '../../redux/auth/authActions';
import GoBackLink from './GoBackLink';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    boxShadow: '0px 6px 13px #4242421A',
    maxWidth: '450px',
  },
  content: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 32px 32px 32px',
  },
  actions: {
    flexDirection: 'column',
  },
  btn: {
    width: '360px',
    textTransform: 'inherit',
    fontSize: '0.875rem',
  },
  ctn: {
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  errorClass: {
    border: '1px solid red',
    borderRadius: '2px',
  },
}));
const validationSchema = yup.object({
  username: yup.string().required('Username is required'),
  phoneNumber: yup
    .string()
    .required('Please enter phone number')
    .matches(
      /^((01)|(07)|(7)|(1))[0-9]+$/,
      'Invalid format! Please try : 07XX...'
    )
    .min(9, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .required('Please enter password')
    .matches(
      /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,

      'Password  must contain at least 6 characters, one uppercase, one number and one special case character'
    ),
});

const mapState = ({ auth }) => ({
  signupError: auth.regErr,
  isPhoneVerified: auth.isPhoneVerified,
  isLoggedIn: auth.isLoggedIn,
  token: auth.token,
  profile: auth.profile,
});

const RedirectSaveSeat = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isPhoneVerified, signupError, token, profile } = useSelector(
    mapState
  );
  const [showPassword, setshowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [isUsernameAvailable, setisUsernameAvailable] = useState({
    status: '',
    msg: '',
  });
  const verifyUsernameAvailbility = (name) => {
    if (name.length < 5) {
      setisUsernameAvailable({
        status: 'err',
        msg: 'Username must be at least 5 characters long',
      });
    }
    axios
      .get(`${url}/users/username/verify?username=${name}`)
      .then((response) => {
        const status = response.data.availability;
        status
          ? setisUsernameAvailable({
              status: 'true',
              msg: 'This username is available',
            })
          : setisUsernameAvailable({
              status: 'false',
              msg: 'Oh no! This username is already taken!',
            });
      });
  };
  const handleOnBlurUsername = (e) => {
    const username = e.target.value;
    verifyUsernameAvailbility(username);
  };

  const handleOnBlurEmail = () => {
    setisUsernameAvailable({
      status: '',
      msg: '',
    });
  };
  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      username: '',
      password: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      verifyUsernameAvailbility(values.username);

      const user = {
        Username: values.username,
        EmailAddress: values.email,
        PhoneNumber: values.phoneNumber,
        Password: values.password,
      };

      dispatch(signUp(user));
      formik.resetForm();
    },
  });

  if (token && profile && isPhoneVerified) {
    return <Redirect to="/select-preference" />;
  }
  if (token && profile && !isPhoneVerified) {
    return <Redirect to="/auth/signup/verify-phone-number" />;
  }
  return (
    <BetaLayout>
      <CustomContainer>
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <h2 className="txt-ruby">Hold on! 😁</h2>
            <div className="mb-24" />
            <p className="txt-violet mb-16">
              The phone number you have entered is already on our waitlist. The
              next step is completing your account activation below.
            </p>
            <div className="mb-24" />
            <Box width="85%">
              <form
                onSubmit={formik.handleSubmit}
                noValidate
                autoComplete="off"
              >
                <CustomInput
                  id="username"
                  name="username"
                  type="text"
                  placeholder="username *"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={handleOnBlurUsername}
                  fullWidth
                  className={
                    formik.errors.username && formik.touched.username
                      ? classes.errorClass
                      : ''
                  }
                />
                {formik.errors.username && formik.touched.username && (
                  <p className="input-error">
                    <small>{formik.errors.username}</small>
                  </p>
                )}
                {isUsernameAvailable.status === 'true' && (
                  <p className="input-success">
                    <small>{isUsernameAvailable.msg}</small>
                  </p>
                )}
                {isUsernameAvailable.status === 'false' && (
                  <p className="input-error">
                    <small>{isUsernameAvailable.msg}</small>
                  </p>
                )}
                {isUsernameAvailable.status === 'err' && (
                  <p className="input-error">
                    <small>{isUsernameAvailable.msg}</small>
                  </p>
                )}
                <div style={{ marginBottom: '16px' }} />
                <CustomInput
                  id="email"
                  name="email"
                  type="email"
                  fullWidth
                  placeholder="Email Address *"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={handleOnBlurEmail}
                  fullWidth
                  className={
                    formik.errors.email && formik.touched.email
                      ? classes.errorClass
                      : ''
                  }
                />
                {formik.errors.email && formik.touched.email && (
                  <p className="input-error">
                    <small>{formik.errors.email}</small>
                  </p>
                )}
                <div style={{ marginBottom: '16px' }} />
                <CustomInput
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  placeholder="phone number *"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  fullWidth
                  startAdornment={
                    <>
                      <img
                        src={flagIcon}
                        style={{
                          marginRight: '8px',
                          width: '24px',
                          height: '24px',
                        }}
                      />
                      <p
                        className=" txt-mont  f-14 txt-black"
                        style={{ marginRight: '8px' }}
                      >
                        +254
                      </p>
                    </>
                  }
                  placeholder="Phone Number"
                />

                {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                  <p className="input-error">
                    <small>{formik.errors.phoneNumber}</small>
                  </p>
                )}
                <div style={{ marginBottom: '16px' }} />
                <CustomInput
                  fullWidth
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  placeholder="password *"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <MdVisibility fontSize="small" />
                        ) : (
                          <MdVisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className={
                    formik.errors.password && formik.touched.password
                      ? classes.errorClass
                      : ''
                  }
                />
                {formik.errors.password && formik.touched.password && (
                  <p className="input-error">
                    <small>{formik.errors.password}</small>
                  </p>
                )}
                <div className="txt-center mb-8">
                  {signupError === 409 && (
                    <Alert
                      severity="error"
                      // onClose={() => setdisplayAuthError(false)}
                    >
                      Oops! There is a registered user with that phone number or
                      email. Try again or{' '}
                      <span
                        style={{ color: 'blue', textDecoration: 'underline' }}
                      >
                        {' '}
                        <Link to="/auth/login/login-form">go to Login </Link>
                      </span>
                    </Alert>
                  )}
                </div>

                <div style={{ marginBottom: '48px' }} />

                <BaseBtnFilled type="submit" className={classes.btn}>
                  Let me in
                </BaseBtnFilled>
              </form>
              <GoBackLink />
            </Box>
          </CardContent>
        </Card>
      </CustomContainer>
    </BetaLayout>
  );
};

export default RedirectSaveSeat;
