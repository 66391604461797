import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import BaseBtn from '../../components/BaseBtn';
import BaseCard from '../../components/BaseCard';
import Alert from '@material-ui/lab/Alert';

import {
  verifyPhone,
  resendVerificationOTP,
} from '../../redux/auth/authActions';
import BaseInput2 from '../../components/BaseInput2';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  disabledBtn: {
    backgroundColor: '#eee',
    '&:hover': {
      backgroundColor: '#eee',
      color: '#000',
    },
  },
  activeBtn: {
    backgroundColor: '#4900ff',
    border: ' 2px solid #4900ff',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },
  content: {
    height: '50vh',
  },
  txtBtn: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    color: '#111',
    textTransform: 'capitalize',
    fontSize: '0.875rem',
  },
});

const validationSchema = yup.object({
  verCode: yup
    .string()
    .required('Please enter code')
    .min(6, 'code must be 6 digits long')
    .max(6, 'code must be 6 digits long'),
});

const mapState = ({ auth }) => ({
  token: auth.token,
  profile: auth.profile,
  verLoading: auth.phoneVerLoading,
  verSuccess: auth.phoneVerSuccess,
  verError: auth.phoneVerErr,
  resendLoading: auth.resendVerLoading,
  resendSuccess: auth.resendVerSuccess,
  resendFail: auth.resendVerFail,
});

const VerifyPhoneNumber = () => {
  const classes = useStyles();
  const {
    token,
    profile,
    verSuccess,
    verError,
    verLoading,
    resendFail,
    resendLoading,
    resendSuccess,
  } = useSelector(mapState);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      verCode: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const verCode = values.verCode;

      dispatch(verifyPhone(verCode));

      formik.resetForm({});
    },
  });

  const handleResend = () => {
    dispatch(resendVerificationOTP());
    // 185.141.63.56:3051/users/otp
  };

  // if (token && verSuccess) {
  //   return <Redirect to="/select-preference" />;
  // }
  if (token && profile && verSuccess) {
    return <Redirect to="/select-preference" />;
  }
  return (
    <BaseCard>
      <form onSubmit={formik.handleSubmit}>
        <CardContent className={classes.content}>
          <h2
            className="txt-ruby h2x-large-bold"
            style={{ marginBottom: '16px' }}
          >
            Verify your number
          </h2>
          <p className="p-14 txt-violet" style={{ marginBottom: '48px' }}>
            Enter the 6-digit verification code sent to {profile.phone}
          </p>
          <div className="mb-16">
            <BaseInput2
              id="verCode"
              name="verCode"
              type="text"
              value={formik.values.verCode}
              onChange={formik.handleChange}
              placeholder="Enter code"
              fullWidth
            />
            {formik.errors.verCode && formik.touched.verCode && (
              <p className="input-error">
                <small>{formik.errors.verCode}</small>
              </p>
            )}
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* make to btn */}
            <Button
              className={classes.txtBtn}
              onClick={handleResend}
              disabled={verLoading || resendLoading || verSuccess}
            >
              Resend code
            </Button>
          </div>
          <div className="flex-center mb-16">
            {verError && !resendSuccess && (
              <Alert severity="error">{verError}</Alert>
            )}
            {resendSuccess && (
              <Alert severity="success">Your OTP has been resent.</Alert>
            )}
          </div>
        </CardContent>
        <CardActions>
          <BaseBtn
            type="submit"
            fullWidth
            disabled={!formik.values.verCode || formik.isSubmitting}
            className={
              formik.values.verCode ? classes.activeBtn : classes.disabledBtn
            }
          >
            continue
          </BaseBtn>
        </CardActions>
      </form>
    </BaseCard>
  );
};

export default VerifyPhoneNumber;
