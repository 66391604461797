import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '2px',
    boxShadow: 'none',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    marginRight: theme.spacing(2),
  },
  text1: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '1rem',
  },
  text2: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: '0.875rem',
  },
  contentSection1: {
    display: 'flex',
    alignItems: 'center',
    width: '85%',
  },
}));
const FeedbackCard = ({
  feedbackBy,
  ratingValue,
  comment,
  datePosted,
  avatarStyles,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.contentSection1}>
          <div>
            <Avatar className={avatarStyles} />
          </div>
          <div>
            <Typography
              variant="subtitle1"
              // gutterBottom
              className={classes.text1}
            >
              {feedbackBy}
            </Typography>

            <Rating
              name="merchant-rating"
              value={ratingValue}
              readOnly
              emptyIcon={<StarBorderIcon style={{ fontSize: '1.5rem' }} />}
              style={{ fontSize: '1.5rem' }}
            />
            <Typography variant="body1" className={classes.text2}>
              {comment}
            </Typography>
          </div>
        </div>

        <div>
          <p>{datePosted}</p>
        </div>
      </CardContent>
    </Card>
  );
};
FeedbackCard.propTypes = {
  feedbackBy: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  ratingValue: PropTypes.string.isRequired,
  datePosted: PropTypes.string.isRequired,
  avatarStyles: PropTypes.object.isRequired,
};
export default FeedbackCard;
