import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'timeago.js';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';

import ReceivedMessageBox from './ReceivedMessageBox';
import SendMessageForm from './SendMessageForm';
import SentMessageBox from './SentMessageBox';
import BuyerConfirmOrder from './Notifications/BuyerConfirmOrder';
import BuyerNotifyShipping from './Notifications/BuyerNotifyShipping';
import BuyerNotifyArrivalMtaani from './Notifications/BuyerNotifyArrivalMtaani';
import BuyerNotifyArrivalExpress from './Notifications/BuyerNotifyArrivalExpress';
import BuyerConfirmItemStatus from './Notifications/BuyerConfirmItemStatus';
import BuyerNotifyItemOkay from './Notifications/BuyerNotifyItemOkay';
import BuyerNotifyGiveFeedback from './Notifications/BuyerNotifyGiveFeedback';
import BuyerNotifyGaveFeedback from './Notifications/BuyerNotifyGaveFeedback';

import MerchantConfirmOrder from './Notifications/MerchantConfirmOrder';
import MerchantNotifyShipping from './Notifications/MerchantNotifyShipping';
import MerchantNotifyShippingWarning1 from './Notifications/MerchantNotifyShippingWarning1';
import MerchantNotifyItemReceived from './Notifications/MerchantNotifyItemReceived';
import MerchantNotifyItemOkay from './Notifications/MerchantNotifyItemOkay';
import MerchantNotifyFeedback from './Notifications/MerchantNotifyFeedback';
import MerchantNotifyIssue from './Notifications/MerchantNotifyIssue';
import MerchantNotifyIssueResolved from './Notifications/MerchantNotifyIssueResolved';
import MerchantNotifyCancelledOrder from './Notifications/MerchantNotifyCancelledOrder';
import MerchantNotifyReturn from './Notifications/MerchantNotifyReturn';
import MerchantNotifyReturnShipping from './Notifications/MerchantNotifyReturnShipping';
import MerchantNotifyReturnArrival from './Notifications/MerchantNotifyReturnArrival';
import MerchantNotifyReturnSuccessful from './Notifications/MerchantNotifyReturnSuccessful';

import img from '../../assets/images/messaging.svg';
import { s_server } from '../../api';
import { sampleChat } from '../Home/dummyData';
import {
  sendChatMsg,
  fetchCurrentChat,
} from '../../redux/message/messageActions';
import useSocket from '../../hooks/useSocket';

const ChatSectionEmpty = () => {
  return (
    <div className="chat-screen d-flex  d-flex-col d-flex-center ">
      <div className="mb-24">
        <img src={img} alt="chat screen illustration" />
      </div>

      <div className="txt-center">
        <h3 className="txt-violet">Your Messages</h3>
        <p className="txt-violet">
          Send direct messages to your fellow Lukhu peeps!
        </p>
      </div>
    </div>
  );
};

const mapState = ({ auth, message }) => ({
  token: auth.token,
  chat: message.currChat,
  err: message.currChatErr,
  loading: message.currChatLoading,
  receiver: message.currChatId,
  sentMessage: message.sentMessage,
  currChatId: message.currChatId,
});

const ChatSection = () => {
  const messagesEndRef = useRef();

  const { socket, disconnectSocket } = useSocket();

  const dispatch = useDispatch();

  const [activeChat, setActiveChat] = useState(false);

  // const [chat, setchat] = useState([]);
  const {
    token,
    chat,
    err,
    loading,
    receiver,
    sentMessage,
    currChatId,
  } = useSelector(mapState);

  const [currChat, setcurrChat] = useState(null);
  const [message, setMessage] = useState('');
  const [emptyMsgErr, setemptyMsgErr] = useState('');

  const handleSendMessage = () => {
    if (!message) {
      setemptyMsgErr('Cannot send empty message!');
      return;
    }

    const body = {
      message: message,
      to: receiver,
      type: 'CHAT',
    };

    dispatch(sendChatMsg(body));
    setMessage('');
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    // console.log('i happened');
    scrollToBottom();
  }, [chat, currChat, sentMessage]);

  useEffect(() => {
    if (currChatId) {
      dispatch(fetchCurrentChat(currChatId));
    }
    return () => {};
  }, [currChatId]);

  useEffect(() => {
    if (chat) {
      setcurrChat(chat);
      // console.log('chat', currChat);
    }

    return () => {};
  }, [chat]);

  useEffect(() => {
    if (sentMessage) {
      let temp = currChat;
      temp.unshift(sentMessage);
      setcurrChat([...temp]);
    }

    return () => {};
  }, [sentMessage]);

  useEffect(() => {
    if (socket) {
      let temp = currChat;
      socket.on('message', (msg) => {
        // console.log('message content', msg);
        temp.unshift(msg);
        setcurrChat([...temp]);
        // console.log('reading curr chat...');
        // console.log('CURR CHAT', currChat);
      });
    }

    return () => {
      disconnectSocket();
    };
  }, [socket]);

  // const renderNotification = (notificationType) => {
  //   switch (notificationType) {
  //     case 'CHAT':
  //       return {

  //       }
  //       break;

  //     default:
  //       break;
  //   }
  // }

  if ((!loading && chat && chat.length === 0) || (!loading && !chat))
    return <ChatSectionEmpty />;
  return (
    <div className="chat-screen">
      <div className="chat-screen-wrapper">
        <div className="chat-screen-top">
          {/* NOT IMPLEMENTED */}

          {/* <BuyerNotifyGaveFeedback /> */}
          {/* <MerchantNotifyShippingWarning1 /> */}
          {/* <MerchantNotifyFeedback /> */}
          {/* <MerchantNotifyIssue /> */}
          {/* <MerchantNotifyIssueResolved /> */}
          {/* <MerchantNotifyCancelledOrder /> */}
          {/* <MerchantNotifyReturn /> */}
          {/* <MerchantNotifyReturnShipping /> */}
          {/* <MerchantNotifyReturnArrival /> */}
          {/* <MerchantNotifyReturnSuccessful /> */}

          {/*END OF NOT IMPLEMENTED */}

          <div>
            {loading && (
              <div className="flex-center">
                <p className="txt-violet">loading ...</p>
              </div>
            )}

            <div>
              {!loading &&
                currChat &&
                currChat
                  .slice(0)
                  .reverse()
                  .map((msg, i) => {
                    const {
                      id,
                      type,
                      message,
                      received,
                      created_on,
                      details,
                      isOrder,
                      image,
                      from,
                    } = msg;

                    if (type === 'CHAT') {
                      if (received) {
                        return (
                          <ReceivedMessageBox
                            key={i}
                            message={message}
                            timestamp={format(created_on)}
                            productRef={details ? details.image : null}
                          />
                        );
                      } else {
                        return (
                          <SentMessageBox
                            key={i}
                            message={message}
                            timestamp={format(created_on)}
                            productRef={details ? details.image : null}
                          />
                        );
                      }
                    } else if (
                      type === 'SELLER_ORDER_CONFIRMED_EXPRESS' ||
                      type === 'SELLER_ORDER_CONFIRMED_MTAANI'
                    ) {
                      return (
                        <MerchantConfirmOrder
                          key={i}
                          buyerName={from}
                          orderTotal={details.orderTotal}
                          shippingTimeframe={details.shippingTimeframe}
                          timestamp={format(created_on)}
                          prodName={details.productName}
                          prodSize={details.productSize}
                          prodCondition={details.productCondition}
                          prodImage={details.productImage}
                          prodPrice={details.orderTotal}
                          orderID={details.orderNumber}
                          authToken={token}
                        />
                      );
                    } else if (
                      type === 'BUYER_PURCHASE_SUCCESS_EXPRESS' ||
                      type === 'BUYER_PURCHASE_SUCCESS_MTAANI'
                    ) {
                      return (
                        <BuyerConfirmOrder
                          key={i}
                          sellerName={from}
                          orderNo={details.orderNumber}
                          orderTotal={details.orderTotal}
                          shippingTimeframe={details.shippingTimeframe}
                          timestamp={format(created_on)}
                          prodName={details.productName}
                          prodSize={details.productSize}
                          prodCondition={details.productCondition}
                          prodImage={details.productImage}
                          prodPrice={details.orderTotal}
                        />
                      );
                    } else if (
                      type === 'SELLER_ORDER_SHIPPED_EXPRESS' ||
                      type === 'SELLER_ORDER_SHIPPED_MTAANI'
                    ) {
                      return (
                        <MerchantNotifyShipping
                          key={i}
                          buyerName={from}
                          timestamp={format(created_on)}
                        />
                      );
                    } else if (
                      type === 'BUYER_ORDER_SHIPPED_EXPRESS' ||
                      type === 'BUYER_ORDER_SHIPPED_MTAANI'
                    ) {
                      return (
                        <BuyerNotifyShipping
                          orderID={details.orderNumber}
                          timestamp={format(created_on)}
                        />
                      );
                    } else if (type === 'BUYER_ORDER_ARRIVED_MTAANI') {
                      return (
                        <BuyerNotifyArrivalMtaani
                          key={i}
                          orderID={details.orderNumber}
                          timestamp={format(created_on)}
                        />
                      );
                    } else if (type === 'BUYER_ORDER_ARRIVED_EXPRESS') {
                      return (
                        <BuyerNotifyArrivalExpress
                          key={i}
                          orderID={details.orderNumber}
                          timestamp={format(created_on)}
                        />
                      );
                    } else if (
                      type === 'SELLER_ORDER_ARRIVED_EXPRESS' ||
                      type === 'SELLER_ORDER_ARRIVED_MTAANI'
                    ) {
                      return (
                        <MerchantNotifyItemReceived
                          buyerName={from}
                          timestamp={format(created_on)}
                        />
                      );
                    } else if (
                      type === 'BUYER_ORDER_FIDELITY_EXPRESS' ||
                      type === 'BUYER_ORDER_FIDELITY_MTAANI'
                    ) {
                      return (
                        <BuyerConfirmItemStatus
                          timestamp={format(created_on)}
                          orderID={details.orderNumber}
                        />
                      );
                    } else if (
                      type === 'BUYER_FEEDBACK_1_EXPRESS' ||
                      type === 'BUYER_FEEDBACK_1_MTAANI'
                    ) {
                      return (
                        <BuyerNotifyItemOkay
                          timestamp={format(created_on)}
                          orderID={details.orderNumber}
                        />
                      );
                    } else if (
                      type === 'SELLER_FEEDBACK_1_EXPRESS' ||
                      type === 'SELLER_FEEDBACK_1_MTAANI'
                    ) {
                      return (
                        <MerchantNotifyItemOkay
                          timestamp={format(created_on)}
                          buyerName={from}
                        />
                      );
                    } else if (
                      type === 'SELLER_ORDER_CANCELLED_MTAANI' ||
                      type === 'SELLER_ORDER_CANCELLED_EXPRESS'
                    ) {
                      return (
                        <MerchantNotifyCancelledOrder
                          timestamp={format(created_on)}
                        />
                      );
                    }
                    // "SELLER_FEEDBACK_1_EXPRESS"
                  })}
            </div>
            <div ref={messagesEndRef} />
          </div>
        </div>

        <div className="chat-screen-bottom">
          <form>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onFocus={(e) => setemptyMsgErr('')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleSendMessage}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {emptyMsgErr && (
              <div>
                <p className="input-error">{emptyMsgErr}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChatSection;
