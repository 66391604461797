import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
const MerchantNotifyItemOkay = ({ buyerName, timestamp }) => {
  return (
    <Box display="flex">
      <Avatar style={{ background: '#45BC56', marginRight: '8px' }}>
        <DoneIcon style={{ color: '#ffffff' }} />
      </Avatar>
      <div className="notification-box">
        <p className="txt-time f-10">{timestamp}</p>
        <p className="txt-bold txt-blackTrue">
          {buyerName} marked their items as OK!
        </p>
        <p className="txt-gray5 f-14">
          And we are more than happy that they did!
        </p>
      </div>
    </Box>
  );
};

MerchantNotifyItemOkay.propTypes = {
  timestamp: PropTypes.string.isRequired,
  buyerName: PropTypes.string.isRequired,
};
export default MerchantNotifyItemOkay;
