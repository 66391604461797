import { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';

import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import BaseInput2 from './BaseInput2';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  options: {
    fontFamily: 'Lato',
    fontSize: '12px',
  },
  input: {
    maxHeight: '40px',
    backgroundColor: '#F5F2FE',
    padding: '4px 8px',
    fontSize: '12px',
    // outline: 'none',
    // outlineColor: 'none',
  },
}));

export default function PlacesAutocomplete({ value, setValue }) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [displayOptions, setDisplayOptions] = useState([]);
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }
  // const options = {
  //   componentRestrictions: { country: 'us' },
  // };

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setDisplayOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setDisplayOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <>
      <Autocomplete
        // classes={{ input: classes.input }}
        size="small"
        id="google-map-demo"
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={displayOptions}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setDisplayOptions(
            newValue ? [newValue, ...displayOptions] : displayOptions
          );
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <BaseInput2
              {...params.inputProps}
              placeholder="Location"
              fullWidth
            />
          </div>
        )}
        renderOption={(option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} fontSize="small" />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <p className={classes.displayOptions}>
                  {option.structured_formatting.secondary_text}
                </p>
              </Grid>
            </Grid>
          );
        }}
      />
    </>
  );
}

PlacesAutocomplete.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
};
