// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const BaseBtnOutlined = withStyles({
  root: {
    border: '1px solid #481CFB',
    color: '#471CFB',
    borderRadius: 24,
    textTransform: 'capitalize',
    fontWeight: 'medium',
    fontFamily: 'inherit',
    maxWidth: '100%',
  },
})(Button);

export default BaseBtnOutlined;
