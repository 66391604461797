import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '32px 0',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '98%',
    },
  },
  layout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    padding: '4px',
  },
  btnSection: {
    margin: '0 auto',
    marginBottom: '48px',
    textAlign: 'center',
    width: '60%',
  },

  infoSection: {
    width: '60%',
    padding: '0 16px',
    margin: '0 auto',
    marginBottom: '24px',
  },

  txtArea: {
    '&input': {
      fontSize: '0.875rem',
    },
  },
  txtAreaSection: {
    width: '80%',
    margin: '0 auto',
    marginBottom: '32px',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeIcon: {
    color: '#737373',
    fontSize: '1.25rem',
  },
}));
const AskSellerDialogTemplate = ({ open, handleClose, children }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="ask-seller-dialog-title"
      fullWidth="sm"
    >
      <DialogTitle
        id="ask-seller-dialog-title"
        className={classes.title}
        disableTypography
      >
        <h3 className="txt-violet f-24 txt-center">Ask Seller</h3>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>

      <Divider style={{ marginBottom: '16px' }} />
      <DialogContent>
        {children}

        <div className={classes.infoSection}>
          <p className="txt-center f-12">
            To ensure that you are covered by Lukhu Buyer Protection, make all
            payments in-app through Lukhu Pay
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};

AskSellerDialogTemplate.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
export default AskSellerDialogTemplate;
