import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';

import PlacesAutocomplete from '../../components/PlacesAutocomplete';
import BaseInput2 from '../../components/BaseInput2';
import BaseBtnFilled from '../../components/BaseBtnFilled';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import BaseFailDialog from '../../components/BaseFailDialog';
import { fetchMerchantStoreDetails } from '../../redux/merchant/merchantActions';
import { url } from '../../api';

const useStyles = makeStyles({
  root: { borderRadius: '10px' },
  section1: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  section2: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  section3: {
    justifyContent: 'space-between',
  },
  btn: {
    width: '144px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '1.17rem',
    fontWeight: 'bold',
    color: ' #111',
  },
});

const useStyles2 = makeStyles((theme) => ({
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1.5em',
    width: '1.5em',
    color: '#fff',
    backgroundColor: '#4900FF',
    border: '2px solid white',
    borderRadius: '50%',
  },

  icon: {
    color: '#fff',
    fontSize: '12px',
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    background: '#813b0b',
  },
}));
const AvatarField = ({ image }) => {
  const classes = useStyles2();

  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={
        <div className={classes.iconWrap}>
          <CheckIcon className={classes.icon} />
        </div>
      }
    >
      <Avatar alt="Seller avatar" src={image} className={classes.avatar} />{' '}
    </Badge>
  );
};

AvatarField.propTypes = {
  image: PropTypes.string,
  avatarStyles: PropTypes.string,
};

const validationSchema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  userName: yup.string().required('Please fill out this field'),
  phoneNumber: yup
    .string()
    .required('Please enter phone number')
    .matches(/^((01)|(07))[0-9]+$/, 'Invalid format! Please try : 07XX...')
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number'),
  building: yup.string().required('Please fill out this field'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Please enter email'),
});

// id:"48fe5e51-fc9b-4955-a718-d4192ead6d13"
// image:null
// description:null
// mpesaNumber:null
// isPaymentVerfied:false
// verificationTimestamp:null
// businessName:"graceM"
// phoneNumber:"0729695435"
// building:"Fig Tree"
// location:"Ngara Rd, Nairobi City"
// placeId:"ChIJtzDwhSkXLxgRcyw0WdYW8RU"
// status:"INCOMPLETE"
// modifiedOn:null
// createdOn:"2022-02-09T07:27:03.000Z"

const mapState = ({ merchant, auth }) => ({
  storeDetails: merchant.storeDetailsPrivate,
  loading: merchant.storeDetailsPrivateLoading,
  token: auth.token,
});
const MerchantEditProfile = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { storeDetails, loading, token } = useSelector(mapState);

  const [openFailDialog, setopenFailDialog] = useState(false);
  const handleCloseFailDialog = () => {
    setopenFailDialog(false);
  };

  const [locationErr, setlocationErr] = useState(null);
  const [location, setlocation] = useState({ name: '', placeId: '' });
  const [businessName, setBusinessName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [building, setBuilding] = useState('');
  const [description, setDescription] = useState('');
  const [storeImg, setStoreImg] = useState(null);
  const [locationName, setlocationName] = useState('');
  const [storeId, setstoreId] = useState('');

  useEffect(() => {
    if (storeDetails) {
      setStoreImg(storeDetails.image);
      setBusinessName(storeDetails.businessName);
      setDescription(storeDetails.description);
      // setlocationName(storeDetails.location);
      setBuilding(storeDetails.building);
      setPhoneNumber(storeDetails.phoneNumber);
      setlocation(storeDetails.location);
      setstoreId(storeDetails.id);
    }

    return () => {};
  }, [storeDetails]);

  const formik = useFormik({
    initialValues: {
      businessName: businessName,
      phoneNumber: phoneNumber,
      building: building,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!location) {
        setlocationErr('Please fill out this field');
        return;
      }
      if (location) {
        setlocationErr('');
      }
      const storeDetails = {
        businessName: values.businessName,
        phoneNumber: values.phoneNumber,
        placeId: location.place_id,
        building: values.building,
      };
      // console.log(storeDetails);

      // openSuccessDialog();
    },
  });

  const handleCancel = () => {
    history.push('/my-profile/listed-items');
    // console.log();
  };

  const handleEdit = () => {
    const body = {
      businessName: storeDetails.businessName,
      description,
      building,
      phoneNumber,
      placeId: location.place_id ? location.place_id : storeDetails.placeId,
    };

    // console.log(body);

    axios
      .put(`${url}/store/${storeId}`, body, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        const results = response.data;
        dispatch(fetchMerchantStoreDetails());

        history.push('/my-profile/listed-items');

        // console.log('RES : ', results);
      })
      .catch((error) => {
        // console.log('RESERR : ', error);
        setopenFailDialog(true);
      });
  };

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title="Edit your store profile"
          classes={{ root: classes.section1, title: classes.title }}
        />
        <Divider />
        <CardContent className={classes.section2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <AvatarField />
              </Box>
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <BaseInput2
                id="businessName"
                name="businessName"
                type="text"
                placeholder="Business Name *"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                fullWidth
              />
            </Grid> */}
            <Grid item xs={12}>
              <BaseInput2
                id="description"
                name="description"
                type="text"
                value={description}
                placeholder="Description *"
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BaseInput2
                id="businessName"
                name="phoneNumber"
                type="text"
                placeholder="Phone Number * e.g 07XX..."
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <PlacesAutocomplete value={location} setValue={setlocation} />
              <p className="f-14">
                <small>* Start typing to select your location</small>
              </p>
            </Grid>

            <Grid item xs={12} md={6}>
              <BaseInput2
                id="building"
                name="building"
                type="text"
                placeholder="Building *"
                value={building}
                onChange={(e) => setBuilding(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.section3}>
          <BaseBtnFilled className={classes.btn} onClick={handleEdit}>
            Save
          </BaseBtnFilled>
          <BaseBtnOutlined className={classes.btn} onClick={handleCancel}>
            Cancel
          </BaseBtnOutlined>
        </CardActions>
      </Card>
      <BaseFailDialog
        open={openFailDialog}
        handleClose={handleCloseFailDialog}
      />
    </>
  );
};

export default MerchantEditProfile;
