import axios from 'axios';
import 'dotenv/config';

// import { url } from '../../api';

import {
  FETCH_USER_MESSAGES_START,
  FETCH_USER_MESSAGES_FAIL,
  FETCH_USER_MESSAGES_SUCCESS,
  SEND_CHAT_MSG,
  SEND_CHAT_MSG_FAIL,
  FETCH_CURRENT_CHAT_START,
  FETCH_CURRENT_CHAT_SUCCESS,
  FETCH_CURRENT_CHAT_FAIL,
  SET_CURRENT_CHATID,
  FETCH_UNREAD_START,
  FETCH_UNREAD_SUCCESS,
  FETCH_UNREAD_FAIL,
} from './messageTypes';

const url = process.env.REACT_APP_SERVER;

export const fetchUserMessages = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: FETCH_USER_MESSAGES_START,
    });

    axios
      .get(`${url}/messages/?page=1&pageSize=10&status=all`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        const allMessages = res.data.messages;
        // console.log('MSG', allMessages);
        dispatch({
          type: FETCH_USER_MESSAGES_SUCCESS,
          payload: allMessages,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_USER_MESSAGES_FAIL,
          payload: error,
        });
      });
  };
};

export const fetchCurrentChat = (senderId) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type: FETCH_CURRENT_CHAT_START,
    });

    axios
      .get(`${url}/messages/${senderId}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        const chat = res.data.messages;
        // console.log('chat', chat);
        dispatch({
          type: FETCH_CURRENT_CHAT_SUCCESS,
          payload: {
            chat: chat,
            id: senderId,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_CURRENT_CHAT_FAIL,
          payload: error,
        });
      });
  };
};

export const sendChatMsg = (message) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    axios
      .post(`${url}/messages/`, message, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        dispatch({
          type: SEND_CHAT_MSG,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SEND_CHAT_MSG_FAIL,
          payload: error,
        });
      });
  };
};

export const setCurrentChatId = (senderId) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_CHATID,
      payload: senderId,
    });
  };
};

export const fetchUnread = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    dispatch({
      type: FETCH_UNREAD_START,
    });

    axios
      .get(`${url}/messages/?page=0&pageSize=0&status=all`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        const result = res.data;
        const unreadMessages = result.unreadMessages;

        dispatch({
          type: FETCH_UNREAD_SUCCESS,
          payload: {
            unreadMessages,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_UNREAD_FAIL,
          payload: error,
        });
      });
  };
};
