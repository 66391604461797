import React from 'react'
import { Box,IconButton,Badge } from '@material-ui/core'
import NewOne from '../../../../assets/images/top_seller.png'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import Badge, { BadgeProps } from '@@material-ui/Badge';



const useStyles = makeStyles((theme) => ({
    root:{
      display:"flex",
      
      
    },
    img:{

    }
}))

// const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
//     '& .MuiBadge-badge': {
//       right: -3,
//       top: 13,
//       border: `2px solid ${theme.palette.background.paper}`,
//       padding: '0 4px',
//     },
//   }));

function Holder() {
    const classes = useStyles();
  return (
    <div>
        <Box className={classes.root}>
            <Box>
                <img src={NewOne} alt="New One" />
                <h1>Top1 </h1>
            </Box>

            <Box>
                <h1>Top1 </h1>
                <IconButton >
                    <FavoriteBorderIcon/>
                </IconButton>
            </Box>
        </Box>
    </div>
  )
}

export default Holder