export const FETCH_DELIVERY_OPTIONS_LOADING = 'FETCH_DELIVERY_OPTIONS_LOADING';
export const FETCH_DELIVERY_OPTIONS = 'FETCH_DELIVERY_OPTIONS';

export const CHECKOUT = 'CHECKOUT';
export const SET_DELIVERY_OPTION = 'SET_DELIVERY_OPTION ';

export const ADD_DELIVERY_ADDRESS_DETAILS_START =
  'ADD_DELIVERY_ADDRESS_DETAILS_START';
export const ADD_DELIVERY_ADDRESS_DETAILS_SUCCESS =
  'ADD_DELIVERY_ADDRESS_DETAILS_SUCCESS';
export const ADD_DELIVERY_ADDRESS_DETAILS_FAIL =
  'ADD_DELIVERY_ADDRESS_DETAILS_FAIL';

export const GET_SAVED_DELIVERY_ADDRESS_START =
  'GET_SAVED_DELIVERY_ADDRESS_START';
export const GET_SAVED_DELIVERY_ADDRESS_SUCCESS =
  ' GET_SAVED_DELIVERY_ADDRESS_SUCCESS';
export const GET_SAVED_DELIVERY_ADDRESS_FAIL =
  'GET_SAVED_DELIVERY_ADDRESS_FAIL';

export const CREATE_ORDER_START = 'CREATE_ORDER_START';
export const CREATE_ORDER_SUCCESS = ' CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = ' CREATE_ORDER_FAIL';

export const COMPLETE_PAYMENT_START = 'COMPLETE_PAYMENT_START';
export const COMPLETE_PAYMENT_SUCCESS = 'COMPLETE_PAYMENT_SUCCESS';
export const COMPLETE_PAYMENT_FAIL = 'COMPLETE_PAYMENT_FAIL';

export const ADD_MPESA_NO = 'ADD_MPESA_NO';
export const ADD_CARD_DETAILS = 'ADD_CARD_DETAILS';

export const FETCH_MTAANI_REGIONS_START = 'FETCH_MTAANI_REGIONS_START';
export const FETCH_MTAANI_REGIONS_SUCCESS = 'FETCH_MTAANI_REGIONS_SUCCESS';
export const FETCH_MTAANI_REGIONS_FAIL = 'FETCH_MTAANI_REGIONS_FAIL';
export const FETCH_MTAANI_ZONES_START = 'FETCH_MTAANI_ZONES_START';
export const FETCH_MTAANI_ZONES_SUCCESS = 'FETCH_MTAANI_ZONES_SUCCESS';
export const FETCH_MTAANI_ZONES_FAIL = 'FETCH_MTAANI_ZONES_FAIL';
export const FETCH_MTAANI_STORES_SUCCESS = 'FETCH_MTAANI_STORES_SUCCESS';
export const FETCH_MTAANI_STORES_START = 'FETCH_MTAANI_STORES_START';
export const FETCH_MTAANI_STORES_FAIL = 'FETCH_MTAANI_STORES_FAIL';

export const GET_DELIVERY_DETAILS_START = 'GET_DELIVERY_DETAILS_START';
export const GET_DELIVERY_DETAILS_SUCCESS = 'GET_DELIVERY_DETAILS_SUCCESS';
export const GET_DELIVERY_DETAILS_FAIL = 'GET_DELIVERY_DETAILS_FAIL';

export const SET_PICKUP_STORE = 'SET_PICKUP_STORE';
