import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

import {
  getDeliveryOptions,
  getMtaaniRegions,
  getsavedDeliveryAddress,
  setDeliveryOption,
} from '../../redux/checkout/checkoutActions';

import BaseBtnFilled from '../../components/BaseBtnFilled';
import SelectPickupStore from './SelectPickupStore';
// import SelectedPickupStore from './SelectedPickupStore';
import SetDeliveryAddress from '../Checkout/SetDeliveryAddress';
import EnteredDeliveryDetails from './EnteredDeliveryDetails';
import BaseCircularProgress from '../../components/BaseCircularProgress';
import SelectedPickupStore from './SelectedPickupStore';
const useStyles = makeStyles({
  label: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  details: {
    paddingLeft: '32px',
  },
  txt: {
    color: '#7B7B7B',
    fontSize: '0.875rem',
    fontFamily: ['Lato', 'sans-serif'].join(','),
  },
  btn: {
    padding: '4px 16px',
    fontWeight: 700,
  },
  actionArea: {
    padding: '8px 0 16px 32px',
  },
  legend: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '0.875rem',
    color: '#111111',
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: '#707070',
    opacity: '0.5',
    marginTop: '16px',
    marginBottom: '16px',
  },
});

const DeliveryDetails = ({ details, option, cost, timeline }) => {
  return (
    <EnteredDeliveryDetails
      name={`${details.firstName} ${details.lastName}`}
      phoneNumber={details.phoneNumber}
      location={`${details.city}, ${details.street}, ${details.buildingName}`}
      otherInfo={details.additionalInfo}
      deliveryOption={option}
      deliveryCost={cost}
      deliveryTimeline={timeline}
      // building={details?.building}
      // otherNumber={details?.building}
    />
  );
};

DeliveryDetails.propTypes = {
  details: PropTypes.object.isRequired,
  option: PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired,
  timeline: PropTypes.string.isRequired,
};

const mapState = ({ checkout }) => ({
  deliveryOptions: checkout.deliveryOptions,
  delDetailsLoading: checkout.delDetailsLoading,
  delDetails: checkout.delDetails,
  delDetailsErr: checkout.delDetailsErr,
  selectedDeliveryType: checkout.selectedDeliveryType,
  pickupStoreDetails: checkout.pickupStoreDetails,
});
const RenderDeliveryOptions = ({ openNextTab }) => {
  const classes = useStyles();
  const [value, setValue] = useState('1');
  const {
    deliveryOptions,
    delDetailsLoading,
    delDetails,
    delDetailsErr,
    selectedDeliveryType,
    pickupStoreDetails,
  } = useSelector(mapState);
  const dispatch = useDispatch();

  // id:1
  // type:"ADDRESS"
  // option:"Get your order delivered within 1 day at your preferred delivery address."
  // description:"Lukhu Express"
  // deliveryCost:200

  const handleChange = (event) => {
    const selectedId = event.target.value;
    setValue(selectedId);
  };

  useEffect(() => {
    const selected = deliveryOptions.find((item) => item.id == value);
    // console.log(selected);
    dispatch(setDeliveryOption(selected));
    return () => {};
  }, [value, deliveryOptions]);

  const [openPickupStoreForm, setopenPickupStoreForm] = useState(false);
  const [openDeliveryAddressForm, setOpenDeliveryAddressForm] = useState(false);

  const handleOpenPickupStoreForm = () => {
    setopenPickupStoreForm(true);
  };
  const handleClosePickupStoreForm = () => {
    setopenPickupStoreForm(false);
  };
  const handleOpenDeliveryAddressForm = () => {
    setOpenDeliveryAddressForm(true);
  };
  const handleCloseDeliveryAddressForm = () => {
    setOpenDeliveryAddressForm(false);
  };

  return (
    <>
      {pickupStoreDetails && (
        <SelectedPickupStore
          storeName={pickupStoreDetails.storeName}
          location={pickupStoreDetails.address}
          phoneNumber={pickupStoreDetails.contacts}
          deliveryOption={selectedDeliveryType.description}
          deliveryCost={selectedDeliveryType.deliveryCost}
        />
      )}
      {delDetailsLoading && (
        <Box display="flex" justifyContent="center">
          {' '}
          <BaseCircularProgress />
        </Box>
      )}
      {!delDetailsLoading && delDetails && (
        <DeliveryDetails
          details={delDetails}
          cost={selectedDeliveryType.deliveryCost}
          option={selectedDeliveryType.description}
          timeline={selectedDeliveryType.option}
        />
        // <div>{delDetails.phoneNumber}</div>
      )}

      {!delDetails && !delDetailsLoading && !pickupStoreDetails && (
        <>
          <div>
            <h3 className={classes.legend}>Select Delivery Option</h3>
          </div>
          <FormControl component="fieldset" fullWidth>
            {deliveryOptions && (
              <RadioGroup
                aria-label="delivery-options"
                name="delivery-options"
                value={value}
                onChange={handleChange}
              >
                <Box>
                  <FormControlLabel
                    value={String(deliveryOptions[0].id)}
                    control={<Radio />}
                    label={deliveryOptions[0].description}
                    classes={{
                      label: classes.label,
                    }}
                  />
                  <Box>
                    <div className={classes.details}>
                      <p className={classes.txt}>
                        <span style={{ fontWeight: 'bold' }}>
                          Delivery Cost:
                        </span>{' '}
                        KSh {deliveryOptions[0].deliveryCost}
                      </p>
                    </div>
                    {value == deliveryOptions[0].id && (
                      <div className={classes.actionArea}>
                        <BaseBtnFilled
                          onClick={handleOpenDeliveryAddressForm}
                          className={classes.btn}
                        >
                          Set Delivery Address
                        </BaseBtnFilled>
                      </div>
                    )}
                  </Box>
                </Box>
                <Box component="div" className={classes.divider} />

                <Box>
                  <FormControlLabel
                    value={String(deliveryOptions[1].id)}
                    control={<Radio />}
                    label={deliveryOptions[1].description}
                    classes={{
                      label: classes.label,
                    }}
                  />
                  <Box>
                    <div className={classes.details}>
                      <p className={classes.txt}>
                        <span style={{ fontWeight: 'bold' }}>
                          Delivery Cost:
                        </span>{' '}
                        KSh {deliveryOptions[1].deliveryCost}
                      </p>
                    </div>
                    {value == deliveryOptions[1].id && (
                      <div className={classes.actionArea}>
                        <BaseBtnFilled
                          onClick={handleOpenPickupStoreForm}
                          className={classes.btn}
                        >
                          Set Pickup Store
                        </BaseBtnFilled>
                      </div>
                    )}
                  </Box>
                </Box>
              </RadioGroup>
            )}
          </FormControl>
        </>
      )}

      <SelectPickupStore
        open={openPickupStoreForm}
        handleClose={handleClosePickupStoreForm}
        openNextTab={openNextTab}
      />
      <SetDeliveryAddress
        open={openDeliveryAddressForm}
        handleClose={handleCloseDeliveryAddressForm}
        openNextTab={openNextTab}
      />
    </>
  );
};

RenderDeliveryOptions.propTypes = {
  openNextTab: PropTypes.func.isRequired,
};
export default RenderDeliveryOptions;
