import { useState, useEffect } from 'react';
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import ResultsLayout from './ResultsLayout';
import WelcomeBanner from '../../parts/Home/WelcomeBanner';
import ListingCard from '../../parts/Products/ListingCard';
import DealsPageNav from '../../parts/Search/DealsPageNav';
import NoResults from '../../parts/Search/NoResults';
import {
  fetchUnder1kResults,
  fetchOver1kResults,
  fetchAllCategories,
} from '../../redux/search/searchActions';
import {
  fetchProductConditions,
  fetchProductColorsOptions,
} from '../../redux/products/productsActions';

import promo1 from '../../assets/images/black-hat-model.jpg';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const mapState = ({ search, product }) => ({
  allCategories: search.allCategories,
  //   displayCategories: search.l2CategoriesList,
  productConditions: product.productConditions,
  productColors: product.productColors,

  results: search.over1kProductsResults,
  resultsCount: search.over1kProductsResultsCount,
  resultsError: search.over1kProductsResultsError,
  loading: search.loadingOver1kProductsResults,
});

const DealsPageResultsOver = () => {
  // const classes = useStyles();
  //   const history = useHistory();
  const { category } = useParams();
  const { pathname, search } = useLocation();
  //   const { url } = useRouteMatch();
  let query = useQuery();

  const dispatch = useDispatch();
  const {
    allCategories,
    displayCategories,
    productColors,
    productConditions,
    loading,
    results,
    resultsCount,
    resultsError,
  } = useSelector(mapState);

  useEffect(() => {
    dispatch(fetchOver1kResults());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllCategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductConditions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductColorsOptions());
  }, [dispatch]);

  return (
    <section>
      <div className="bg-home" style={{ minHeight: '80vh' }}>
        <ResultsLayout
          menu={
            <DealsPageNav
              allCategories={allCategories}
              //   displayCategories={displayCategories}
              productColors={productColors}
              productConditions={productConditions}
            />
          }
          content={
            <>
              {loading && <NoResults msg="Loading ..." />}
              {resultsError && <NoResults msg="Error!" />}
              {results && resultsCount < 1 && <NoResults msg="No listings" />}
              {results &&
                results.map((item) => (
                  <Grid item xs={6} sm={4} md={3} key={item.id}>
                    <ListingCard product={item} />
                  </Grid>
                ))}
            </>
          }
          title={
            resultsCount === 0
              ? 'No available listings'
              : resultsCount > 1
              ? `${resultsCount} listings`
              : `${resultsCount} listing`
          }
        />
      </div>
    </section>
  );
};

export default DealsPageResultsOver;
