import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import Verified from '../../assets/images/verified.png'

const useStyles = makeStyles((theme) => ({
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1.5em',
    width: '1.5em',
    color: '#fff',
    backgroundColor: '#4900FF',
    border: '2px solid white',
    borderRadius: '50%',
  },

  icon: {
    color: '#fff',
    fontSize: '20px',
    width:"20px"
  },
}));

const MerchantAvatar = ({ image, avatarStyles,verified }) => {
  const classes = useStyles();
  if(verified){
    return (
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={
          <div className={classes.iconWrap}>
            <img className={classes.icon} src={Verified}></img>
            {/* <CheckIcon className={classes.icon} /> */}
          </div>
        }
      >
        <Avatar alt="Seller avatar" src={image} className={avatarStyles} />
       {/* <Avatar alt="Seller avatar" src={image} className={avatarStyles} /> */}
      </Badge> 
    );
  }else{
    return (
 
        <Avatar alt="Seller avatar" src={image} className={avatarStyles} />
    
    );
  }
 
};

MerchantAvatar.propTypes = {
  image: PropTypes.string,
  avatarStyles: PropTypes.string,
};

export default MerchantAvatar;
