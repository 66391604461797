import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

// DUMMY DATA
import MerchantAvatar from '../Shops/MerchantAvatar';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  spacing: {
    marginRight: '8px',
  },
}));

const ChatMessageSnippet = ({
  senderImg,
  senderName,
  message,
  handleClick,
  isClicked,
}) => {
  const classes = useStyles();

  return (
    <div className="d-flex d-flex-align-center" onClick={handleClick}>
      <div className={classes.spacing}>
        <MerchantAvatar avatarStyles={classes.avatar} image={senderImg} />
      </div>
      <div style={{ width: 'calc(100% - 64px)' }}>
        <p className="txt-black txt-bold ">{senderName}</p>
        <p className="chat-snippet f-14">{message}</p>
      </div>
    </div>
  );
};

ChatMessageSnippet.propTypes = {
  senderImg: PropTypes.string.isRequired,
  senderName: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isClicked: PropTypes.bool.isRequired,
};
export default ChatMessageSnippet;
