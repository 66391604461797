import { useEffect, useState } from 'react';
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import SearchProductResults from '../../parts/Search/SearchProductResults';
import ListingCard from '../../parts/Products/ListingCard';

import Category2FilterNav from '../../parts/Search/Category2FilterNav';
import {
  fetchLevel2Search,
  fetchLevel2CatgoriesChildren,
  fetch,
} from '../../redux/search/searchActions';
import {
  fetchProductConditions,
  fetchProductColorsOptions,
} from '../../redux/products/productsActions';
import WelcomeBanner from '../../parts/Home/WelcomeBanner';
import promo1 from '../../assets/images/black-hat-model.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  grid: {
    margin: '0 auto',
    paddingTop: '24px',
    paddingBottom: '16px',
  },
}));

const mapState = ({ search, product }) => ({
  results: search.l1Results,
  count: search.l1Count,
  loading: search.l1Loading,
  error: search.l1Error,
  productConditions: product.productConditions,
  productColors: product.productColors,
  categories: search.l2Children,
  catId: search.l1Id,
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Category2SearchPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let query = useQuery();

  const {
    results,
    count,
    loading,
    error,
    productColors,
    productConditions,
    categories,
    catId,
  } = useSelector(mapState);

  useEffect(() => {
    dispatch(fetchProductConditions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductColorsOptions());
  }, [dispatch]);

  return (
    <div className="bg-home">
      <Container maxWidth="lg" classes={{ root: classes.root }}>
        <Grid container className={classes.grid}>
          <Grid item xs={12}>
            <div className="d-flex d-flex-space pb-16">
              <h4>
                {count === 0
                  ? 'No available listings'
                  : count > 1
                  ? `${count} listings`
                  : `${count} listing`}
              </h4>
              {/* <div className="d-flex">
              <h4 className="f-16 txt-medium">Sort by : </h4>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={sortBy}
                onChange={handleSortBy}
                input={<CustomSelect />}
              >
                <MenuItem value="other value">other values</MenuItem>
              </Select>
            </div> */}
            </div>
            <Divider />
          </Grid>
        </Grid>
        <Grid container className={classes.grid}>
          <Grid container item xs={12} md={2}>
            <Category2FilterNav
              categories={categories}
              productColors={productColors}
              productConditions={productConditions}
              catId={catId}
            />
          </Grid>
          <Grid container item xs={12} md={10} spacing={1}>
            {results &&
              results.map((item) => (
                <Grid item xs={6} sm={4} md={3} key={item.id}>
                  <ListingCard product={item} />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Category2SearchPage;
