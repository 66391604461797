import { useHistory } from 'react-router-dom';

import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';

import BaseCard from '../../components/BaseCard';
import OnboardingCardContent from '../../parts/Onboarding/OnboardingCardContent';
import BaseBtn from '../../components/BaseBtn';

const useStyles = makeStyles({
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
  },

  signup: {
    backgroundColor: '#4900ff',
    border: ' 2px solid #4900ff',
    color: '#fff',
    marginBottom: '12px',
    '&:hover': {
      backgroundColor: '#4900ff',
      color: '#FFF',
    },
  },

  btnFacebook: {
    border: '1.5px solid #4267B2',
    color: '#4267B2',
    marginBottom: '8px',
  },
  btnGoogle: {
    border: '1.5px solid #DB4437',
    color: '#DB4437',
    marginBottom: '8px',
  },
});

const LoginOptions = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleLoginForm = () => {
    history.push('/auth/login-form');
  };

  return (
    <BaseCard>
      <OnboardingCardContent />
      <CardActions className={classes.cardActions}>
        {/* add back post mvp */}
        {/* <BaseBtn fullWidth className={classes.btnFacebook}>
          <span style={{ textTransform: 'capitalize' }}>
            continue
            <span style={{ textTransform: 'lowercase' }}> with</span> Facebook
          </span>
        </BaseBtn>
        <BaseBtn fullWidth className={classes.btnGoogle}>
          <span style={{ textTransform: 'capitalize' }}>
            continue
            <span style={{ textTransform: 'lowercase' }}> with</span> Google
          </span>
        </BaseBtn> */}
        <BaseBtn
          fullWidth
          className={classes.signup}
          // component={Link}
          // to="/login-form"
          onClick={handleLoginForm}
        >
          <span style={{ textTransform: 'lowercase', color: 'white' }}>
            <span style={{ textTransform: 'capitalize' }}>log</span> in with
            username
          </span>
        </BaseBtn>
      </CardActions>
    </BaseCard>
  );
};

export default LoginOptions;
