import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from  'axios';
import { url } from '../../api';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Typography } from '@material-ui/core';
import {TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons//ChevronRight';

import MerchantAdminPanelAddNo from './MerchantAdminPanelAddNo';
import BaseBtnOutlined from '../../components/BaseBtnOutlined';
import BaseBtn from '../../components/BaseBtn';
import mIcon from '../../assets/images/micon.png'
import AddMpesaNoPopUp from '../../parts/Shops/AddMpesaNoPopUp';
import EnterOtpMpesaPopUp from '../../parts/Shops/EnterOtpMpesaPopUp';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
  },
  font1: {
    color: '#1E802C',
    fontWeight: 700,
    fontSize: '1.5rem',
  },
  font2: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: '0.625rem',
    color: '#111111',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  font3: {
    color: '#6F7070',
    fontWeight: 700,
    fontSize: '1.5rem',
  },
  font4: {
    color: '#898989',
    fontSize: '0.75rem',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeight: 600,
  },
  card: {
    maxWidth: '128px',
  },
  btn1: {
    padding: '4px 24px',
    fontSize: '0.875rem',
  },
  radioCtn: {
    marginBottom: '8px',
    padding: '0 16px',
    boxShadow: '0px 3px 6px #00000012',
    borderRadius: '0px 7px 7px 0px',
    background: '#F5F2FE'
    // width: '100%',
  },
  radioIcon: {},
  selectMpesa:{
    fontWeight:600,
    fontSize:'14px',
    color:"#000000 !important",
    fontFamily:'Montserrat',
    fontStyle:'normal',
    lineHeight:'17px',
    marginTop:10,
    marginBottom:10

  },
  flexDisplay:{
    display:"flex",
  
    margin:10,
    width:'100%'
   
  },
  flexTwo:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    background: '#481CFB'

  },
  typographyStyle:{
    margin:10,
    fontStyle:'normal',
    fontFamily:'Montserrat',
    fontWeight:600,
    fontSize:'16px',
    lineHeight:'22px',
    color:'#fff'
  },
  inputStyle:{
    width:'100%'
  },
  mainHeader:{
    fontWeight:700,
    color: '#D81E5B',
    fontSize:'28px',
    lineHeight:'34px',
    fontFamily:'Montserrat',
    fontStyle:'normal'


  },
  TextFieldProperties:{
    background:'#F5F2FE',
    borderRadius:'0px 7px 7px 0px',
    marginLeft:'3px',
  },
  paragraphClass:{
    fontFamily:'Lato',
    fontStyle:'normal',
    fontWeight:500,
    fontSize:'20px',
    lineHeight:'24px',
    color:'#111111',
    marginBottom:15
    

  },

  marginT:{
    marginTop:5,
    marginBottom:5
  },
  boxMpesa:{
    
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    border:"1px solid #000"
  },
  boxMpesa2:{
    marginTop:10,
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  mpesaHolder:{
    width:"40",
    
  },
  btnActive:{
    width:"100%",
    background:"#000",
    color:"#fff",
    borderRadius:0
  },
  bt2ACtive:{
    width:"100%",
    borderRadius:0
    
  },
  btn: {
    width:"70%",
    marginLeft:"10px",
    color: '#481CFB',
    background: '#f3f0ff',
  
  },
  fullWidth:{
  
    marginTop:20,
    height:"70px",
    padding:"2px",
    width:"100%",
    background:"#53B33F",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
  
    
  },
  fullWidth1:{
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"

  },
  fullWidth2:{
    
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  imageHolder:{
    marginLeft:"5px",
    width:"30px",
    height:"30px",
    borderRadius:"100%",
    background:"#fff",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  mpesaNo1:{
    
    color:"#fff",
    fontSize:"16px",
    padding:3,
    fontStyle:"normal",
    fontFamily:"Lato",
   
  },
  flexb:{
    width:"100%",
    display:"flex",
    alignItems:"center",
    flexDirection:"column"
  }

}));



const MerchantAdminPanelWithdraw = ({
  availableBalance,
  pendingBalance,
  handleBack,
}) => {
  const classes = useStyles();

  const [clickAddNo, setclickAddNo] = useState(false);
  const [btnActive,setBtnActive] = useState(true);
  const[downBtn,setDownbtn] = useState(false)
  const [mpesaNos,setMpesaNo] = useState([]);
  const [getN,setN] = useState("");
  const [mpeseSelected,setMpesaSelected] = useState("");
  const [fillALLDetails,setfillALlDetails] = useState("")
  const [openPopUp,setOpenPopUp] = useState(false);
  const [openPOpUpOtp,setOPenPopUpOtp] =  useState(false)

  const getT =  localStorage.getItem("_REG_TK")
   
  const handleChange = (e) =>{
    setMpesaSelected(e.target.value)
    console.log(e.target.value)
  }
  const handleAddNo = () => {
    setclickAddNo(true);
  };
  const backToWithdraw = () => {
    setclickAddNo(false);
  };

  const changebtnDown = () =>{
    setDownbtn(!downBtn)
  }

  const addMpesaNos = async (mpesaNo) =>{
   
    var qs = require('qs');
    var data = qs.stringify({
      'phoneNumber': mpesaNo 
    });
    var config = {
      method: 'post',
      url: `${url}/users/mpesa`,
      headers: { },
      data : data
    };

    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const submitWithdrwal = async (amount,number) =>{
    
    var qs = require('qs');
    var data = qs.stringify({
      'amount': amount,
      'phone': number
    });
    var config = {
      method: 'post',
      url: `${url}/wallets/withdraw`,
      headers: {
        'Authorization': `Bearer ${getT}`
       },
      data : data
    };

    axios(config)
    .then(function (response) {
      setfillALlDetails(response.data.message)
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }
 
  const getNos = async ()=>{
  
      var config = {
        method: 'get',
        url: `${url}/users/mpesa`,
        headers: {
          'Authorization': `Bearer ${getT}`
         }
      };

      axios(config)
      .then(function (response) {
        const k =  response.data.numbers;
        console.log("kkais",k)

        const empty_list = []
        for(const i in k){
          const u =  k[i]
          empty_list.push(u.phoneNumber)
          
        }

        // console.log("got nos",k.numbers)
        setMpesaNo(empty_list)
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleSetN = (e) =>{
    setN(e.target.value)
  }

  const handleOPenpop = () =>{
    setOpenPopUp(true)
  }

  const handleMpesaClicked = ()=>{
    setBtnActive(!btnActive)
  }

  const handleCloseTopup = () =>{
    setOpenPopUp(false)
  }

  const handleClick = () =>{
    if(getN.length > 0 && mpeseSelected.length > 0){
      submitWithdrwal(String(getN),String(mpeseSelected))

    }else{
      setfillALlDetails("Please fill all details")

    }
  }
  useEffect(() => {
    getNos()
    
  
  
  }, [])
  
  return (
    <>
      {clickAddNo ? (
        <MerchantAdminPanelAddNo handleBack={backToWithdraw} />
      ) : (
        <Paper elevation={0} className={classes.root}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Box display="flex" alignItems="center">
              <IconButton className={classes.icnBtn} onClick={handleBack}>
                <ArrowBackIosIcon
                  style={{ fontSize: '1rem', color: '#111111' }}
                />
              </IconButton>
              <h3>Wallet Details</h3>
            </Box>
            
          </Box>

          <Box className={classes.boxMpesa}>
          <Button onClick={handleMpesaClicked} className={btnActive ? classes.btnActive : classes.bt2ACtive }>Mobile Money</Button>
          <Button onClick={handleMpesaClicked} className={btnActive === false ? classes.btnActive : classes.bt2ACtive}>Credit/Debit Cards</Button>
          

          </Box>

         

          <Box className={classes.boxMpesa2}>

            <Box className={classes.flexb}>
            {
            mpesaNos.map((item)=>(
              <Box className={classes.fullWidth}>

           

              <Box className={classes.fullWidth1}>
                <Box className={classes.imageHolder}>
                  <img src={mIcon} alt="Mpesa Logo"></img>
                </Box>
                <p className={classes.mpesaNo1}>M-Pesa Number</p>
              </Box>
              <Box className={classes.fullWidth2}>
                <p className={classes.mpesaNo1} >{item}</p>
                <IconButton onClick={changebtnDown}>
                  {
                    downBtn? (<ExpandMoreIcon className={classes.mpesaNo1}  />) : (<ArrowForwardIosIcon className={classes.mpesaNo1} />)
                  }
                  

                </IconButton>
              </Box>
            </Box>
            ))
          }

            </Box>
         
            



              <BaseBtn
            
            className={classes.btn}
            onClick={handleOPenpop}
          >
           Add New Number
          </BaseBtn>
          </Box>

          {/* <EnterOtpMpesaPopUp open={openPopUp} handleClose={handleCloseTopup} /> */}
          <AddMpesaNoPopUp open={openPopUp} handleClose={handleCloseTopup} />
        </Paper>
      )}
    </>
  );
};

MerchantAdminPanelWithdraw.propTypes = {
  availableBalance: PropTypes.string.isRequired,
  pendingBalance: PropTypes.number.isRequired,
  handleBack: PropTypes.func.isRequired,
};
export default MerchantAdminPanelWithdraw;
