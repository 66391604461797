import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  empty: {
    padding: '16px',
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const MerchantAdminEmptyDisplay = ({ txt }) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.empty}>
      <p className="txt-violet ">{txt}</p>
    </Paper>
  );
};

MerchantAdminEmptyDisplay.propTypes = {
  txt: PropTypes.string.isRequired,
};
export default MerchantAdminEmptyDisplay;
