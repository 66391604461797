import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import StarIcon from '@material-ui/icons/Star';

import BaseBtnFilled from '../../../components/BaseBtnFilled';

const BuyerNotifyGiveFeedback = ({ timestamp, orderID }) => {
  const sampleNot = {
    time: '9.08 AM',
  };
  return (
    <Box display="flex">
      <Avatar style={{ background: '#45BC56', marginRight: '8px' }}>
        <StarIcon style={{ color: '#ffffff' }} />
      </Avatar>
      <div className="notification-box">
        <p className="txt-time f-10">{timestamp}</p>
        <p className="txt-bold txt-blackTrue">Let us know what you think</p>
        <p className="txt-gray5 f-14">
          Before enjoying your new item, leave your rating and feedback below
        </p>

        <Box mt={2}>
          <BaseBtnFilled fullWidth>Give Feedback</BaseBtnFilled>
        </Box>
      </div>
    </Box>
  );
};

BuyerNotifyGiveFeedback.propTypes = {
  orderID: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};
export default BuyerNotifyGiveFeedback;
