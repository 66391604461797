import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import OptionsNav from '../../parts/Home/OptionsNav';
import PromotionBanner from '../../parts/Home/PromotionBanner';
import ShopCategoriesSection from './ShopCategoriesSection';
import SelectDisplayOptions from '../../parts/Home/SelectDisplayOptions';

// PROMOTION BANNER IMAGES
import promo1 from '../../assets/images/black-hat-model.jpg';
import promo2 from '../../assets/images/pastel-outfit-model.jpg';
import freeShippingPromo  from  '../../assets/images/free-shipping.png'

import { url } from '../../api';
import WelcomeBanner from '../../parts/Home/WelcomeBanner';
import ResultsLayout from '../Results/ResultsLayout';
import { TRIAL_FIRST } from '../../common/firebaseConstants';
import {log_clicks_to_firebase,log_firebase_event} from '../../common/FirebaseCommon.js'
import FreeShippingBanner from '../../parts/Home/FreeShippingBanner';

import { viewContent } from '../../_helpers/facebookpixel/PixelEvents';
import { trackPageViewPixel } from '../../_helpers/facebookpixel/PixelEvents';
import useScript from '../../hooks/useScript';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '200px',
    height: '120px',
    boxShadow: 'none',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),

    padding: '16px',
  },
  circleDisplay: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '96px',
    width: '96px',
  },
  icon: {
    padding: '24px',
  },
  pageMargins: {
    padding: '16px 64px',
  },
  spacing: {
    marginBottom: '48px',
  },
}));

const Home = () => {
  const classes = useStyles();
  localStorage.setItem("pageName","Home")
  useScript("./assets/pixel/home.js");

  const [under1000, setunder1000] = useState([]);
  const [recentAdded,setRecentAdded] =  useState([]);
  useEffect(() => {
    // viewContent("Home Page");
    const fetchRecentAdded  =  async () =>{
      try {
        const response = await axios.get(
          `${url}/listing/?page=1&sort=createdAt:desc&limit=10`
        );
        const recentAdded = response.data.data;
        console.log('recent added', recentAdded);
        
        if(!isCancelled){
          setRecentAdded(recentAdded);
        }
        
      } catch (error) {
        // console.log(error);
      }
    };

    fetchRecentAdded();
    let isCancelled = false;
    const fetchUnder1000 = async () => {
      try {
        const response = await axios.get(
          `${url}/listing/?name=&color=&page=1&limit=10&price=0,1000`
        );
        const allDeals = response.data.data;
        // console.log('1000', allDeals);
        if (!isCancelled) {
          setunder1000(allDeals);
        }
      } catch (error) {
        // console.log(error);
      }
    };

    fetchUnder1000();
    return () => (isCancelled = true);
  }, []);

  //

  return (
    <section>
      <WelcomeBanner
        bannerBackgroundColor="#FFF5F6"
        themeColor="#D81E5B"
        promoImg={promo1}
        promoTitle="WELCOME TO LUKHU"
      />

      <ShopCategoriesSection />

      <div className={classes.spacing}></div>
      
      <SelectDisplayOptions
        title="RECENTLY ADDED"
        productArr={recentAdded}
        link="/under-1000"
        linkName="see all"
      />

<div className={classes.spacing}></div>

    <FreeShippingBanner
        bannerBackgroundColor="#FFF5F6"
        themeColor="#D81E5B"
        promoImg={freeShippingPromo}
        link = "/over-1000"
        promoTitle="FREE SHIPPING"
      />

      <div className={classes.spacing}></div>

      <SelectDisplayOptions
        title="DEALS UNDER KSH 1,000"
        productArr={under1000}
        link="/under-1000"
        linkName="see all"
      />
      <div className={classes.spacing}></div>
    </section>
  );
};

export default Home;
